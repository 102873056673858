import React, { useState, useEffect } from "react";
import Sidebar from "../../../Common/components/sidebar";
import Topbar from "../Components/topbar";
import Account from "./account";
// import Profile from './profile'

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { investorState } from '../investorState'

import "../../../../CSS/InvestorPage/Dashboard/account.css";
// import { companyState } from "../companyState";
// import Deals from "./deals";
import { ToastContainer } from "react-toastify";
const CompanyAccountPage = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { activeButton } = location.state || { activeButton: 1 };
  const [activeBtn, setActiveBtn] = useState(activeButton);
  return (
    <div className="InvestorSide">
      <Sidebar active="account" userRole="company" />
      <Topbar title="Settings" />
      <ToastContainer position="top-center" containerId="accountPage" />
      <div className="InvestorSide-box" style={{ overflowX: "hidden" }}>
        <div className="InvestorSide-content">
          <div className="cdetails-div">
            <Account />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAccountPage;
