import React from "react";
import {
  StyledFormControl,
  StyledFormLabel,
  assetClassOptions,
} from "../../../../Common/functions";
import { useFormikContext } from "formik";
import CustomInput from "../../../../Common/components/custom/customInput";
import CustomSelect from "../../../../Common/components/custom/customSelect";
import { Checkbox, FormControlLabel } from "@mui/material";
const InvestmentFocus = () => {
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } =
    useFormikContext();
  return (
    <div className=" relative flex flex-col w-full gap-10">
      <div
        className="editCompanyDetails-form"
        style={{
          gridTemplateColumns: "none",
        }}
      >
        <div className="gap-y-300 gap-x-600 grid grid-cols-2">
          <StyledFormControl
            fullWidth
            sx={{
              gridColumn: "1/-1",
            }}
          >
            <StyledFormLabel>Investment Thesis</StyledFormLabel>
            <CustomInput
              placeholder="Enter Investment Thesis"
              name={`investmentThesis`}
              value={values.investmentThesis}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(
                touched.investmentThesis && errors.investmentThesis
              )}
              helperText={touched.investmentThesis && errors.investmentThesis}
              multiline
              minRows={4}
            />
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledFormLabel>Asset Class</StyledFormLabel>
            <CustomSelect
              label="Asset Class"
              name="assetClass"
              multiple={true}
              value={values.assetClass}
              onChange={handleChange}
              options={assetClassOptions}
              placeholder="Select Asset Class"
              error={Boolean(touched.assetClass && errors.assetClass)}
              helperText={touched.assetClass && errors.assetClass}
            />
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledFormLabel className="!text-neutral-12">
              Co-Investments?
            </StyledFormLabel>
            <div className="flex gap-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.coInvestment === true}
                    onChange={() => setFieldValue("coInvestment", true)}
                    color="primary"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: "30px" } }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.coInvestment === false}
                    onChange={() => setFieldValue("coInvestment", false)}
                    color="primary"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: "30px" } }}
                  />
                }
                label="No"
              />
            </div>
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledFormLabel>Initial Check Size (Rs Cr.)</StyledFormLabel>
            <div className="flex gap-2">
              <CustomInput
                placeholder={`Min Check Size`}
                type="number"
                value={values?.ticketSizevalue[0]}
                onChange={(e) =>
                  setFieldValue(`ticketSizevalue`, [
                    e.target.value,
                    values?.ticketSizevalue[1],
                  ])
                }
                onBlur={handleBlur}
                error={Boolean(
                  touched.ticketSizevalue && errors.ticketSizevalue
                )}
              />
              <CustomInput
                placeholder={`Max Check Size`}
                type="number"
                value={values?.ticketSizevalue[1]}
                onChange={(e) =>
                  setFieldValue(`ticketSizevalue`, [
                    values?.ticketSizevalue[0],
                    e.target.value,
                  ])
                }
                onBlur={handleBlur}
                error={Boolean(
                  touched.ticketSizevalue && errors.ticketSizevalue
                )}
              />
            </div>
            {touched.ticketSizevalue && errors.ticketSizevalue && (
              <div className="error text-[#d32f2f] text-xs ml-4">
                {errors.ticketSizevalue}
              </div>
            )}
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledFormLabel>Total Portfolio Exposure (Rs Cr.)</StyledFormLabel>
            <div className="flex gap-2">
              <CustomInput
                placeholder={`Min Exposure`}
                type="number"
                value={values.totalExposure[0]}
                onChange={(e) =>
                  setFieldValue(`totalExposure`, [
                    e.target.value,
                    values?.totalExposure[1],
                  ])
                }
                onBlur={handleBlur}
                error={Boolean(touched.totalExposure && errors.totalExposure)}
              />
              <CustomInput
                placeholder={`Max Exposure`}
                type="number"
                value={values.totalExposure[1]}
                onChange={(e) =>
                  setFieldValue(`totalExposure`, [
                    values?.totalExposure[0],
                    e.target.value,
                  ])
                }
                onBlur={handleBlur}
                error={Boolean(touched.totalExposure && errors.totalExposure)}
              />
            </div>
            {touched.totalExposure && errors.totalExposure && (
              <div className="error text-[#d32f2f] text-xs ml-4">
                {errors.totalExposure}
              </div>
            )}
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledFormLabel>Stake Acquired (%)</StyledFormLabel>
            <div className="flex gap-2">
              <CustomInput
                placeholder={`Min Stake`}
                type="number"
                value={values?.stakeAcquired[0]}
                onChange={(e) =>
                  setFieldValue(`stakeAcquired`, [
                    e.target.value,
                    values?.stakeAcquired[1],
                  ])
                }
                onBlur={handleBlur}
                error={Boolean(touched.stakeAcquired && errors.stakeAcquired)}
              />
              <CustomInput
                placeholder={`Max Stake`}
                type="number"
                value={values?.stakeAcquired[1]}
                onChange={(e) =>
                  setFieldValue(`stakeAcquired`, [
                    values?.stakeAcquired[0],
                    e.target.value,
                  ])
                }
                onBlur={handleBlur}
                error={Boolean(touched.stakeAcquired && errors.stakeAcquired)}
              />
            </div>
            {touched.stakeAcquired && errors.stakeAcquired && (
              <div className="error text-[#d32f2f] text-xs ml-4">
                {errors.stakeAcquired}
              </div>
            )}
          </StyledFormControl>
        </div>
      </div>
    </div>
  );
};

export default InvestmentFocus;
