import axios from "axios";

const backend_url = process.env.REACT_APP_BACKEND_URL;
export const getAnalyticsData = async (startupId) => {
  try {
    const response = await axios.get(
      `${backend_url}/analytics/getAnalyticsData/${startupId}`
      // {
      //   headers: {
      //     Authorization: token,
      //     "Content-Type": "application/json",
      //   },
      // }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const postSupportMessage = async (values, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/common/postSupportMessage`,
      values,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const verifyEmail = async (email, verificationCode, token) => {
  const finalFormData = {
    email: email,
    code: verificationCode,
  };
  try {
    const response = await axios.post(
      `${backend_url}/auth/verify-email`,
      finalFormData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const resendCode = async (token) => {
  try {
    const response = await fetch(
      // "http://localhost:3000/auth/resend-verification",
      `${backend_url}/auth/resend-verification`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return { response: response, error: null };
  } catch (error) {
    return { response: null, error: error };
  }
};

export const sendOtpPasswordChange = async (role, email) => {
  try {
    const response = await fetch(
      // "http://localhost:3000/auth/send-otp-password-change",
      `${backend_url}/auth/send-otp-password-change`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ role: role, email: email }),
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const verifyEmailPasswordChange = async (
  token,
  email,
  verificationCode
) => {
  try {
    const response = await fetch(
      `${backend_url}/auth/verify-email-password-change`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          email: email, // Assuming this is from your component's state
          code: verificationCode,
        }),
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const updatePasswordRequest = async (secondToken, newPassword) => {
  try {
    const response = await fetch(`${backend_url}/auth/update-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: secondToken,
      },
      body: JSON.stringify({
        password: newPassword,
      }),
    });
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const changePassword = async (body, token) => {
  try {
    const response = await axios.post(
      // "http://localhost:3000/auth/resend-verification",
      `${backend_url}/auth/changePassword`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return { response: response, error: null };
  } catch (error) {
    return { response: null, error: error };
  }
};

export const generateShortURL = async (url, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/url/generateShortURL`,
      {
        URL: url,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getOriginalURL = async (url) => {
  try {
    const response = await axios.get(
      `${backend_url}/url/getOriginalURL/${url}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const streamFile = async (fileURL) => {
  try {
    const response = await axios.post(
      `${backend_url}/common/streamFile`,
      {
        fileURL,
      },
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching file:", error);
    return false;
  }
};
export const dumpInvestorInteraction = async (interaction, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/interaction/dumpInvestorInteraction`,
      interaction,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getAnalystCalendarFreeSlots = async () => {
  try {
    const response = await axios.get(
      `${backend_url}/calendar/microsoft/calendar-slots-analyst`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const scheduleMeetingWithAnalyst = async (values, start, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/calendar/microsoft/schedule-meeting-analyst`,
      {
        companyName: values?.companyName,
        email: values?.email,
        name: values?.name,
        start,
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const getDummyAnalytics = async () => {
  try {
    const response = await axios.get(`
      ${backend_url}/common/sampleAnalytics`);
    return response.data;
  } catch (error) {
    return null;
  }
};
export const getDownloadUrl = async (query = {}, token) => {
  try {
    const serializedQuery = Object.entries(query)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    const response = await axios.get(
      `${backend_url}/common/getDownloadUrl${
        serializedQuery ? `?${serializedQuery}` : ""
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in getDownloadUrl:", error.message);
    return null;
  }
};
