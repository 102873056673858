import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import Topbar from "../Company/Deal/topbar";
import SecondarySidebar from "./secondarySidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllInvestors } from "../../../endpoints/investor";
import {
  clearDealIdIndustryVerticals,
  getInvestorIdIndustryVerticals,
  setDealToInvestor,
} from "../../../endpoints/admin";
import Toast from "../../Common/Toast";
import CustomModal from "../../Common/components/custom/customModal";

const SecondaryRecommendInvestors = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [investors, setInvestors] = useState([]);
  const token = localStorage.getItem(`token`);
  const { dealId } = location.state || {};
  const [fit, setFit] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [fitSelections, setFitSelections] = useState({});
  const [selectInvestorID, setSelectedInvestorID] = useState();
  useEffect(() => {
    const fetchAllInvestors = async () => {
      try {
        const response = await getAllInvestors(token);
        setInvestors(response.data);
      } catch (error) {}
    };
    fetchAllInvestors();
  }, []);

  useEffect(() => {
    const fetchInvestorDeals = async () => {
      try {
        const response = await getInvestorIdIndustryVerticals(dealId, token);
        const fitMap = {};
        response.data.forEach((investor) => {
          fitMap[investor.investorId] = {
            ideal: investor.ideal,
            moderate: investor.moderate,
            nonFit: investor.nonFit,
          };
        });
        setFitSelections(fitMap);
      } catch (error) {}
    };
    fetchInvestorDeals();
  }, []);

  const handleFitChange = async () => {
    try {
      const response = await setDealToInvestor(
        selectInvestorID,
        dealId,
        fit,
        token,
        "secondary"
      );
      setFitSelections((prevState) => ({
        ...prevState,
        [selectInvestorID]: {
          ideal: fit === "ideal",
          moderate: fit === "moderate",
          nonFit: fit === "nonFit",
        },
      }));
      Toast(
        "Fit type set successfully!",
        "success",
        "secondaryRecommendInvestors"
      );
      setModalOpen(false);
      setSelectedInvestorID("");
      setFit("");
    } catch (error) {
      Toast("Failed to set fit type.", "error", "secondaryRecommendInvestors");
    }
  };

  const handleClearSelection = async (investorId) => {
    try {
      const response = await clearDealIdIndustryVerticals(
        investorId,
        dealId,
        token
      );
      setFitSelections((prevState) => {
        const newState = { ...prevState };
        delete newState[investorId];
        return newState;
      });
      Toast("Fit type cleared successfully!", "success", "investorPanel");
    } catch (error) {
      Toast("Failed to clear fit type.", "error", "investorPanel");
    }
  };
  return (
    <>
      <Helmet>
        <title>Admin Panel - Secondary Deal</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        containerId={`secondaryRecommendInvestors`}
      />
      <CustomModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Assign match"
        description="This will trigger an email to associated investor"
        primaryAction={handleFitChange}
        secondaryAction={() => setModalOpen(false)}
        customStyles={{ width: "500px", height: "fit-content" }}
      />
      <Topbar />
      <SecondarySidebar dealId={dealId} active={`recommendInvestors`} />
      <div className="mt-20 h-[90vh] ml-[270px] overflow-scroll">
        <div className="font-semibold text-2xl">Recommend Investors</div>
        <div className="InvestorSide-box table-container">
          <table>
            <thead>
              <tr>
                <th>Investor Firm Name</th>
                <th>Investor ID</th>
                <th>Preview</th>
                <th>Recommend</th>
                <th>Clear</th>
              </tr>
            </thead>
            <tbody>
              {investors.map((investor) => (
                <tr key={investor.investorId}>
                  <td>{investor.companyName}</td>
                  <td>{investor.investorID}</td>
                  <td>
                    <button
                      className="toggleStatusbtn"
                      onClick={() =>
                        navigate("/admin/investor/preview", {
                          state: {
                            investor,
                          },
                        })
                      }
                    >
                      Preview
                    </button>
                  </td>
                  <td>
                    {/* {fitSelections[investor.investorID]?.ideal ? <span>&#8226;</span> : null} */}
                    <input
                      type="radio"
                      name={`fit-${investor.investorID}`}
                      value="ideal"
                      checked={
                        fitSelections[investor.investorID]?.ideal || false
                      }
                      onChange={() => {
                        setSelectedInvestorID(investor.investorID);
                        setFit("ideal");
                        setModalOpen(true);
                      }}
                    />
                  </td>
                  <td>
                    <button
                      onClick={() => handleClearSelection(investor.investorID)}
                      className="download_btn"
                    >
                      Clear
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SecondaryRecommendInvestors;
