import React, { useEffect, useState } from "react";
import Card from "../../../Common/components/Card";
import NoDeals from "../Components/noDeals";

import { Helmet } from "react-helmet";

const DealsBox = ({
  allDeals,
  filterDeals,
  details,
  title,
  setWishlistDealIds,
  stage,
  wishlistDealIds,
  recommendedDeals,
  isPassed = false,
  recommendedOnly,
  SetRecommendedOnly,
}) => {
  const pageTitle = title || "deals";
  const [deals, setDeals] = useState([]);
  const [isEmpty, SetIsEmpty] = useState(false);
  useEffect(() => {
    // Filter and sort deals
    let filteredDeals = filterDeals
      .filter((deal) =>
        allDeals.some((filteredDeal) => filteredDeal.dealId === deal.dealId)
      )
      .sort((a, b) => {
        if (details?.modules?.secondary && !details?.modules?.primary) {
          if (a.dealType === "secondary" && b.dealType !== "secondary") {
            return -1;
          }
          if (b.dealType === "secondary" && a.dealType !== "secondary") {
            return 1;
          }
          return 0;
        } else {
          return 0;
        }
      })
      .sort((a, b) => {
        const aRecommended = a?.isRecommended;
        const bRecommended = b?.isRecommended;
        // Recommended deals should come first
        if (aRecommended && !bRecommended) return -1;
        if (!aRecommended && bRecommended) return 1;
        return 0;
      });

    setDeals(filteredDeals);
    if (recommendedOnly) {
      SetIsEmpty(
        filteredDeals.filter((deal) => deal?.isRecommended).length === 0
      );
    } else {
      SetIsEmpty(false);
    }
  }, [allDeals, filterDeals, recommendedDeals, recommendedOnly]);

  return (
    <>
      <Helmet>
        <title>Investor | {pageTitle}</title>
      </Helmet>
      {deals.length > 0 && !isEmpty ? (
        <div className="gap-200 w-fit pt-050 xl:grid-cols-3 grid grid-cols-2 mx-auto">
          {deals.map((deal, index) => {
            if (recommendedOnly && !deal?.isRecommended) {
              return null;
            }
            return (
              <Card
                key={deal.organizationId}
                isNew={false}
                investorId={details?.investorID}
                isStar={wishlistDealIds?.includes(deal.dealId) ? true : false}
                isPassed={isPassed}
                deal={deal}
                stage={stage}
                investorVerified={details?.isApplicationReviewed}
                profileCompleted={details?.profileCompleted}
                index={index}
                isRecommended={deal?.isRecommended || false}
                setWishlistDealIds={setWishlistDealIds}
                primaryModule={details?.modules?.primary}
                secondaryModule={details?.modules?.secondary}
                primaryModuleRequest={details?.modules?.primaryRequested}
                secondaryModuleRequest={details?.modules?.secondaryRequested}
              />
            );
          })}
        </div>
      ) : (
        <>
          <NoDeals
            text={title}
            isEmpty={isEmpty}
            SetRecommendedOnly={SetRecommendedOnly}
          />
        </>
      )}
    </>
  );
};

export default DealsBox;
