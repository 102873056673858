import {
  Box,
  Modal,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getCollectiveCalendarFreeSlots,
  scheduleMicrosoftEvent,
} from "../../../../endpoints/investor";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { ToastContainer } from "react-toastify";
import Toast from "../../../Common/Toast";
import { StyledFormControl } from "../../../Common/functions";
import CustomInput from "../../../Common/components/custom/customInput";
import { CalendarIcon, GlobeIcon } from "@radix-ui/react-icons";
import { useLoader } from "../../../Common/LoaderProvider";
import {
  dumpInvestorInteraction,
  getAnalystCalendarFreeSlots,
} from "../../../../endpoints/common";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { CustomDialogWithActions } from "../../../Common/components/custom/customDialog";
import whiteArrow from "../../../../Assets/Images/icons/whiteArrow.svg";
import { StyledFormLabelOptional } from "../../../Common/functions";
import { FaAngleLeft } from "react-icons/fa6";
import { GoPerson } from "react-icons/go";
import Success from "../../../../Assets/Images/icons/successFilled.svg";

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomCalendarHeaderRoot = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 16px",
  alignItems: "center",
});

function CustomCalendarHeader(props) {
  const { currentMonth, onMonthChange } = props;

  // Handlers for month/year navigation
  const selectNextMonth = () =>
    onMonthChange(currentMonth.add(1, "month"), "left");
  const selectNextYear = () =>
    onMonthChange(currentMonth.add(1, "year"), "left");
  const selectPreviousMonth = () =>
    onMonthChange(currentMonth.subtract(1, "month"), "right");
  const selectPreviousYear = () =>
    onMonthChange(currentMonth.subtract(1, "year"), "right");

  return (
    <CustomCalendarHeaderRoot>
      {/* Navigation Buttons - Left */}
      <Stack spacing={1} direction="row">
        <IconButton
          onClick={selectPreviousMonth}
          title="Previous month"
          sx={{
            color: "#B5BEC6",
          }}
        >
          <ChevronLeft />
        </IconButton>
      </Stack>
      {/* Centered Month and Year */}
      <Typography
        variant="body2"
        style={{
          fontWeight: "500",
          fontFamily: "Satoshi",
          color: "#4A5660",
        }}
      >
        {currentMonth.format("MMMM YYYY")}
      </Typography>
      {/* Navigation Buttons - Right */}
      <Stack spacing={1} direction="row">
        <IconButton
          onClick={selectNextMonth}
          title="Next month"
          sx={{
            color: "#B5BEC6",
          }}
        >
          <ChevronRight />
        </IconButton>
      </Stack>
    </CustomCalendarHeaderRoot>
  );
}

const ScheduleMeeting = ({ deal, modal, setModal, stage }) => {
  const loader = useLoader();
  const [selectedDate, setSelectedDate] = useState(null);
  const [freeSlots, setFreeSlots] = useState([]);
  const token = localStorage.getItem(`token`);
  const [data, setData] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [step, setStep] = useState(1);
  const [meetingAgenda, setMeetingAgenda] = useState("");

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      borderRadius: "4px",
      height: "42px",
      border: "1px solid #141414",
      color: "#141414",
      width: "120px",
      padding: "12px 8px",
      fontSize: "12px",
      fontFamily: "Satoshi",
      '&[aria-pressed="true"]': {
        color: "white",
        background: "#4172F1",
        borderColor: "#4172F1",
        border: "1px solid #4172F1",
        "&:hover": {
          backgroundColor: "#4172F1",
        },
      },
    },
  }));
  const dumpInteraction = async () => {
    try {
      await dumpInvestorInteraction(
        {
          dealId: deal.dealId,
          type: "click",
          page: "companyOverview",
          stageOfDeal: stage,
          element: "scheduleMeeting",
        },
        token
      );
    } catch (error) {
      console.error("Error dumping interaction", error);
    }
  };
  const handleInput = (e) => {
    setMeetingAgenda(e.target.value);
  };
  const handleSchedule = () => {
    if (step === 3) {
      setStep(1);
      setModal(false);
      setMeetingAgenda(null);
      setSelectedDate(null);
      setFreeSlots([]);
    }
    if (step === 1) {
      if (selectedSlot) setStep(2);
      else Toast(`Please select a slot.`, `info`, `ScheduleMeeting`);
    }
    if (step === 2) {
      if (selectedSlot) {
        handleSubmit();
      } else {
        Toast(`Please select a slot.`, `info`, `ScheduleMeeting`);
      }
    }
  };

  const handleSubmit = async () => {
    loader.start(`Scheduling...`);
    dumpInteraction();
    const response = await scheduleMicrosoftEvent(
      deal.dealId || null,
      meetingAgenda,
      selectedSlot,
      token,
      deal?.dealType === "secondary"
    );
    loader.stop();
    if (response?.data) {
      setStep(3);
    } else {
      Toast(
        `Failed to schedule meeting. Please try again.`,
        `error`,
        `ScheduleMeeting`
      );
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedSlot(null);
  };
  const handleSlotClick = (e) => {
    setSelectedSlot(e.target.value);
  };
  useEffect(() => {
    if (selectedDate && data?.length > 0) {
      // Get the current time and add the IST offset (5 hours 30 minutes)
      const currentTime = dayjs().tz(`Asia/Kolkata`);

      // Filter the slots based on the selected date and time
      const filteredSlots = data.filter((slot) => {
        // Parse the start and end times as dayjs objects, assuming they are in IST
        const slotStart = dayjs(slot.start).tz(`Asia/Kolkata`);
        // Check if the slot's date matches the selectedDate
        const isSameDate = slotStart.isSame(selectedDate, "day");

        // Ensure the slot's start time is greater than the current time
        const isAfterCurrentTime = slotStart.isAfter(currentTime);
        return isSameDate && isAfterCurrentTime;
      });
      // Set the filtered slots to the state
      setFreeSlots(filteredSlots);
    }
  }, [data, selectedDate]);

  const fetchFreeSlots = async () => {
    if (modal) {
      loader.start(`Fetching Slots`);
      let response;
      if (deal?.dealType === "secondary") {
        response = await getAnalystCalendarFreeSlots();
      } else {
        response = await getCollectiveCalendarFreeSlots(
          deal?.organizationId,
          token
        );
      }
      loader.stop();
      setData(response);
    }
  };
  useEffect(() => {
    fetchFreeSlots();
  }, [modal]);

  const handleClose = () => {
    setModal(false);
  };

  const disabledDates = useMemo(() => {
    if (!data?.length) return new Set(); // Return an empty Set if no data is available

    const currentTime = dayjs().tz("Asia/Kolkata");

    // Create a Set to store disabled dates
    const disabledDatesSet = new Set();

    // Define the date range
    const minDate = dayjs().tz("Asia/Kolkata").add(24, "hours").startOf("day");
    const maxDate = dayjs().tz("Asia/Kolkata").add(9, "days").endOf("day");

    // Loop through each day in the range
    for (
      let date = minDate;
      date.isBefore(maxDate);
      date = date.add(1, "day")
    ) {
      const hasAvailableSlot = data.some((slot) => {
        const slotStart = dayjs(slot.start).tz("Asia/Kolkata");
        return slotStart.isSame(date, "day") && slotStart.isAfter(currentTime);
      });

      if (!hasAvailableSlot) {
        disabledDatesSet.add(date.format("YYYY-MM-DD")); // Add the disabled date to the Set
      }
    }

    return disabledDatesSet;
  }, [data]);

  const shouldDisableDate = (date) => {
    return disabledDates.has(date.format("YYYY-MM-DD")); // Check if the date is in the disabled set
  };
  const CustomDateCalendar = styled(DateCalendar)`
    margin: 0 auto; /* Center the calendar horizontally */
    width: 80%;
    max-width: 600px; /* Set a maximum width */

    .MuiPickersDay-root {
      width: 48px; /* Keep the aspect ratio same */
      height: 28px;
    }

    .MuiDayCalendar-weekContainer {
      column-gap: 16px;
      row-gap: 16px;
    }
    .MuiDayCalendar-header {
      display: flex;
      justify-content: space-between;
    }
  `;
  return (
    <>
      <ToastContainer containerId={`ScheduleMeeting`} position="top-center" />
      <CustomDialogWithActions
        open={modal}
        onClose={handleClose}
        className={
          step === 1 ? "w-[900px]" : step === 2 ? "w-[650px]" : "w-[500px]"
        }
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            padding: "12px",
            overflow: "hidden",
          }}
        >
          {step === 1 && (
            <div className="w-full">
              <p className="text-neutral-12 text-desktop-p-xl pt-100">
                Schedule a Call
              </p>
              <div className="grid grid-cols-2">
                <div className="border-r-[1px] border-neutral-5 h-[280px] w-[400px]">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateCalendar"]}>
                      <CustomDateCalendar
                        disablePast
                        showDaysOutsideCurrentMonth
                        views={["day"]}
                        value={selectedDate}
                        onChange={handleDateChange}
                        shouldDisableDate={shouldDisableDate}
                        minDate={dayjs().tz(`Asia/Kolkata`).add(24, "hours")}
                        slots={{ calendarHeader: CustomCalendarHeader }}
                        dayOfWeekFormatter={(day) => {
                          const fullDayNames = [
                            "sun",
                            "mon",
                            "tue",
                            "wed",
                            "thu",
                            "fri",
                            "sat",
                          ];
                          return fullDayNames[day];
                        }}
                        maxDate={dayjs()
                          .tz(`Asia/Kolkata`)
                          .add(9, "day")
                          .add(24, "hours")}
                        slotProps={{ textField: { fullWidth: true } }}
                        sx={{
                          fontFamily: "Satoshi, Arial, sans-serif",
                          "& .MuiPickersDay-today": {
                            border: "1px solid #D9D9D9",
                            borderRadius: "50%",
                          },
                          "& .MuiDayCalendar-weekDayLabel": {
                            color: "#B5BEC6", // Change to your desired color
                            fontWeight: "500", // Optional: make it bold
                            fontSize: "10px",
                          },

                          "& .MuiPickersDay-root:not(.Mui-disabled):not(.Mui-selected)":
                            {
                              color: "#4A5660",
                              "&:hover": {
                                backgroundColor: "rgba(30, 65, 255, 0.40)",
                                color: "#fff",
                              },
                              fontWeight: "bold",
                            },
                          // Styles for disabled dates
                          "& .Mui-disabled": {
                            color: "rgba(74, 86, 96, 0.40)", // Color for disabled dates
                            fontWeight: "bold",
                          },
                          // Styles for selected dates with focus
                          "& .Mui-selected:focus": {
                            backgroundColor: "#1E41FF", // Background for focused selected date
                            color: "#fff !important", // Ensure text color remains white

                            "&:hover": {
                              backgroundColor: "#1E41FF", // Maintain same background on hover
                            },
                          },
                          // Ensure general selected styles are not overridden
                          "& .Mui-selected": {
                            backgroundColor: "#1E41FF", // Background for selected date
                            color: "#fff", // Text color for selected date
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className="w-full ml-[20px] flex flex-col gap-300">
                  <p className="text-neutral-12 pl-100 flex w-full mt-3 font-semibold">
                    Pick a slot
                  </p>
                  <Box
                    sx={{
                      overflowY: "auto",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      height: "200px",
                      padding: "0px 8px",
                    }}
                  >
                    <StyledToggleButtonGroup
                      exclusive
                      aria-label="Period"
                      value={selectedSlot}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: "12px",
                      }}
                      onChange={handleSlotClick}
                    >
                      {freeSlots?.length > 0
                        ? freeSlots.map((freeSlot, index) => (
                            <ToggleButton key={index} value={freeSlot.start}>
                              {`${
                                dayjs(freeSlot.start).format("mm") === "00"
                                  ? dayjs(freeSlot.start).format("hA")
                                  : dayjs(freeSlot.start).format("h:mmA")
                              } - ${
                                dayjs(freeSlot.start)
                                  .add(30, "minutes")
                                  .format("mm") === "00"
                                  ? dayjs(freeSlot.start)
                                      .add(30, "minutes")
                                      .format("hA")
                                  : dayjs(freeSlot.start)
                                      .add(30, "minutes")
                                      .format("h:mmA")
                              }`}
                            </ToggleButton>
                          ))
                        : freeSlots?.length === 0 && (
                            <div>
                              {selectedDate ? (
                                <div>No slots available.</div>
                              ) : (
                                <div>Please select a date</div>
                              )}
                            </div>
                          )}
                    </StyledToggleButtonGroup>
                  </Box>
                </div>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="gap-300 flex flex-col justify-center">
              <p className="text-neutral-12 pt-100 font-bold">
                Schedule a Meeting
              </p>
              <div className="flex gap-150 p-150 bg-[#E1FAF0] rounded-050">
                <img src={Success} alt="success" />
                <div className="gap-075 flex flex-col">
                  <p className="text-neutral-12 text-desktop-p-l font-bold">
                    Meeting Scheduled
                  </p>
                  <p className="text-desktop-p-m ">
                    A calendar invitation has been sent to your email address.
                  </p>
                </div>
              </div>
              <div className="p-150 border-neutral-5 rounded-050 gap-300 flex flex-col justify-center border">
                <div className="text-neutral-12 text-desktop-p-l font-bold">
                  Meeting Info
                </div>
                <div className="gap-150 flex flex-col">
                  <div className="text-desktop-p-m gap-150 text-neutral-12 flex items-center font-normal">
                    <GoPerson fontSize={"16px"} /> Fundrev,{" "}
                    {deal?.dealType !== "secondary" ? deal?.companyName : ""}
                  </div>
                  <div className="text-desktop-p-m gap-150 text-neutral-12 flex items-center font-normal">
                    <CalendarIcon fontSize={"16px"} />{" "}
                    {dayjs(selectedSlot).tz(`Asia/Kolkata`).format(`HH:mma`)} -{" "}
                    {dayjs(selectedSlot)
                      .tz(`Asia/Kolkata`)
                      .add(30, `minutes`)
                      .format(`HH:mma`)}
                    , {dayjs(selectedSlot).tz(`Asia/Kolkata`).format(`dddd`)},
                    {dayjs(selectedSlot)
                      .tz(`Asia/Kolkata`)
                      .format(` MMMM, D, YYYY `)}
                  </div>
                  <div className="text-desktop-p-m gap-150 text-neutral-12 flex items-center font-normal">
                    <GlobeIcon fontSize={"16px"} /> Indian Standard Time
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="gap-200 flex flex-col">
              <div className="gap-150 flex items-center">
                <div
                  className="p-[11px] border border-neutral-5 rounded-050 cursor-pointer"
                  onClick={() => setStep(step - 1)}
                >
                  <FaAngleLeft />
                </div>
                <p className="text-desktop-p-xl">Schedule a Meeting</p>
              </div>
              <StyledFormControl fullWidth>
                <StyledFormLabelOptional>
                  Meeting agenda
                </StyledFormLabelOptional>
                <CustomInput
                  placeholder="Please write down your meeting agenda."
                  name="meetingAgenda"
                  value={meetingAgenda}
                  onChange={handleInput}
                  multiline={true} // Enable multiline
                  minRows={5}
                />
              </StyledFormControl>
            </div>
          )}
          <div className="flex items-center justify-center">
            {step < 4 && (
              <div
                className={`w-full flex justify-end ${step >= 2 && `mt-300`}`}
              >
                <CustomBlackBtn
                  text={
                    step === 1 ? (
                      <div className="gap-150 flex">
                        Next <img src={whiteArrow} />
                      </div>
                    ) : step === 2 ? (
                      <div className="gap-150 flex">
                        Schedule <img src={whiteArrow} />
                      </div>
                    ) : (
                      `Close`
                    )
                  }
                  mode="blue"
                  border="sm"
                  onClick={handleSchedule}
                />
              </div>
            )}
          </div>
        </Box>
      </CustomDialogWithActions>
    </>
  );
};

export default ScheduleMeeting;
