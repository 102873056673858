import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Typography } from "@mui/material";
import UploadCustomerTape from "./uploadCustomerTape";
import UploadFinancialStatement from "./uploadFinancialStatement";
import { ToastContainer } from "react-toastify";
import AnalyticsCompanyMapper from "./AnalyticsCompanyMapper";
import { getCompanyMapper } from "../../../../endpoints/admin";
import UploadCashFlow from "./uploadCashFlow";
import UploadBalanceSheet from "./uploadBalanceSheet";
import UploadBankStatement from "./uploadBankStatement";
import { handleFileDownload } from "../../../Common/functions";

const AnalyticsMapper = () => {
  const location = useLocation();
  const { dealId, startupId } = location.state || {};
  const [data, setData] = useState(null);
  const token = localStorage.getItem("token");
  const getMapper = async () => {
    const response = await getCompanyMapper(startupId, token);
    if (response) {
      setData(response.data);
    }
  };
  useEffect(() => {
    getMapper();
  }, [dealId, startupId]);
  return (
    <>
      <ToastContainer position="top-center" containerId={`analyticsMapper`} />
      <Helmet>
        <title>Admin Panel - analytics mapper</title>
      </Helmet>
      <div className="InvestorSide-box">
        <Typography variant="h4">Upload Data</Typography>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          <div>
            Customer Tape Upload <br />
            <UploadCustomerTape startupId={startupId} /> <br />
            <button
              className="mt-1 text-[var(--link-color)]"
              onClick={() =>
                handleFileDownload(
                  `https://fundrevstorage2.blob.core.windows.net/documents/analytics-sample-files/CustomerTape_Hanumancare.xlsx`,
                  `CustomerTape_Hanumancare.xlsx`,
                  false,
                  `adminCompanyMapper`
                )
              }
            >
              Sample File
            </button>
          </div>
          <div>
            Financial Statement Upload <br />
            <UploadFinancialStatement startupId={startupId} />
            <br />
            <button
              className="mt-1 text-[var(--link-color)]"
              onClick={() =>
                handleFileDownload(
                  `https://fundrevstorage2.blob.core.windows.net/documents/analytics-sample-files/IncomeStatement_Hanumcare.xlsx`,
                  `IncomeStatement_Hanumcare.xlsx`,
                  false,
                  `adminCompanyMapper`
                )
              }
            >
              Sample File
            </button>
          </div>
          <div>
            CashFlow Statement Upload <br />
            <UploadCashFlow startupId={startupId} />
            <br />
            <button
              className="mt-1 text-[var(--link-color)]"
              onClick={() =>
                handleFileDownload(
                  `https://fundrevstorage2.blob.core.windows.net/documents/analytics-sample-files/CashFlow_Hanumancare.xlsx`,
                  `CashFlow_Hanumancare.xlsx`,
                  false,
                  `adminCompanyMapper`
                )
              }
            >
              Sample File
            </button>
          </div>
          <div>
            Balance Sheet Upload <br />
            <UploadBalanceSheet startupId={startupId} />
            <br />
            <button
              className="mt-1 text-[var(--link-color)]"
              onClick={() =>
                handleFileDownload(
                  `https://fundrevstorage2.blob.core.windows.net/documents/analytics-sample-files/BalanceSheet_Hanumancare.xlsx`,
                  `BalanceSheet_Hanumancare.xlsx`,
                  false,
                  `adminCompanyMapper`
                )
              }
            >
              Sample File
            </button>
          </div>
          <div>
            Bank Statement Upload <br />
            <UploadBankStatement startupId={startupId} />
            <br />
            <button
              className="mt-1 text-[var(--link-color)]"
              onClick={() =>
                handleFileDownload(
                  `https://fundrevstorage2.blob.core.windows.net/documents/analytics-sample-files/kotak-results.xlsx`,
                  `kotak-results.xlsx`,
                  false,
                  `adminCompanyMapper`
                )
              }
            >
              Sample File
            </button>
          </div>
        </Box>
        {data ? (
          <AnalyticsCompanyMapper
            data={data?.companyMapper}
            startupId={startupId}
          />
        ) : (
          <p
            style={{
              marginTop: "5px",
              color: "red",
            }}
          >
            No Mapper Data Found
          </p>
        )}
      </div>
    </>
  );
};

export default AnalyticsMapper;
