import {
  styled,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from "@mui/material";
import React, { useState } from "react";
import { formatToLakhs } from "../../../utilities/numberFormatter";

const TopCustomerTable = ({ data }) => {
  const [option, setOption] = useState(`ltm`);
  const handleSelect = (event) => {
    setOption(event.target.value);
  };
  const headers = data?.data?.header;
  const IMPRows = ["Top 5 Customers", "Total Revenue"];
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      borderRadius: "4px",
      fontFamily: "Satoshi",
      fontSize: "10px",
      maxWidth: "48px",
      color: "#141414",
      fontWeight: 500,
      '&[aria-pressed="true"]': {
        backgroundColor: "white",
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.05)",
        "&:hover": {
          backgroundColor: "white",
        },
      },
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
      {
        marginLeft: "4px",
      },
  }));
  return (
    <>
      <div className="flex items-center justify-between">
        <p className="text-desktop-p-lb">
          {data.name}
          <p className="text-desktop-p-xs">In lakhs</p>
        </p>
        <StyledToggleButtonGroup
          className="bg-neutral-4 border border-neutral-5 p-050 h-[30px]"
          exclusive
          value={option}
          onChange={handleSelect}
        >
          <ToggleButton
            value={`ltm`}
            sx={{
              border: 0,
              textTransform: "none",
              height: "20px",
              padding: "10px 24px",
            }}
          >
            LTM
          </ToggleButton>
          <ToggleButton
            value={`overall`}
            sx={{
              border: 0,
              textTransform: "none",
              height: "20px",
              padding: "10px 24px",
            }}
          >
            Overall
          </ToggleButton>
        </StyledToggleButtonGroup>
      </div>
      <div className="mt-200">
        <table>
          <thead>
            <tr>
              {headers?.map((header, index) => (
                <th
                  key={header}
                  className={`p-150 bg-neutral-3 text-neutral-8 text-desktop-p-s font-bold rounded-050 ${
                    index === 0 ? "text-left" : "text-right"
                  }`}
                  align={index === 0 ? "left" : "right"}
                >
                  {header !== "Customer" && header}
                  {header === "Customer" && `Customer`}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.data?.[option]?.map((row, index) => (
              <tr key={index}>
                <td
                  align="left"
                  className={`text-neutral-12 p-150 text-desktop-p-s text-left ${
                    IMPRows.includes(row.customer) &&
                    `bg-aero-blue-50 border-t-[1.5px] border-t-aero-blue-700 font-bold`
                  }`}
                >
                  {row.customer}
                </td>
                <td
                  align="right"
                  className={`text-neutral-12  p-150 text-desktop-p-s text-right ${
                    IMPRows.includes(row.customer) &&
                    `bg-aero-blue-50 border-t-[1.5px] border-t-aero-blue-700 font-bold`
                  }`}
                >
                  {/* {Math.round(row.revenue)} */}
                  {formatToLakhs(row.revenue)}
                </td>
                <td
                  align="right"
                  className={`text-neutral-12  p-150 text-desktop-p-s text-right ${
                    IMPRows.includes(row.customer) &&
                    `bg-aero-blue-50 border-t-[1.5px] border-t-aero-blue-700 font-bold`
                  }`}
                >
                  {row.percentage}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TopCustomerTable;
