import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { investorState } from "../investorState";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../Common/components/navbar";

const InvestorProfileSubHeader = ({ colsCount }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [investor] = useRecoilState(investorState);
  const [profileCompleted, setProfileCompleted] = useState(false);
  useEffect(() => {
    if (investor?.firm?.profileCompleted) {
      setProfileCompleted(true);
    } else {
      setProfileCompleted(false);
    }
  }, [investor]);
  const navItems = [
    {
      label: "Account",
      isActive: location.pathname === "/investor/account",
      onClick: () => navigate("/investor/account"),
    },
    {
      label: "Profile",
      isActive:
        location.pathname === "/investor/profile" ||
        location.pathname === "/investor/myProfile",
      onClick: () =>
        profileCompleted
          ? navigate("/investor/myProfile")
          : navigate("/investor/profile"),
    },
    {
      label: "Members",
      isActive: location.pathname === "/investor/members",
      onClick: () => navigate("/investor/members"),
    },
  ];
  return (
    <>
      <Navbar
        title="Investor Profile"
        navItems={navItems}
        className={""}
        colsCount={colsCount}
        centerAlign={
          location.pathname === "/investor/myProfile" ||
          location.pathname === "/investor/account"
            ? true
            : false
        }
      />
    </>
  );
};

export default InvestorProfileSubHeader;
