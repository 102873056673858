import { Box, Modal } from "@mui/material";
import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import React, { useEffect } from "react";
import dayjs from "dayjs";
import CustomBlackBtn from "../components/custom/customBlackBtn";

const SecondaryViewBid = ({
  secondaryViewModal,
  setSecondaryViewModal,
  deal,
  investorID,
  page,
  getLatestDealInterest,
  bid,
  bidDate,
}) => {
  const token = localStorage.getItem("token");
  useEffect(() => {
    getLatestDealInterest();
  }, [deal, investorID]);
  useEffect(() => {}, [bid]);
  return (
    <>
      <Modal
        open={secondaryViewModal}
        onClose={() => setSecondaryViewModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box"
          sx={{
            width: "700px",
            padding: "20px",
          }}
        >
          <div className="IOI-bid-modal-heading-section">
            <p>Bid Details</p>
            <img
              src={closeIcon}
              alt="close"
              style={{
                cursor: "pointer",
              }}
              onClick={() => setSecondaryViewModal(false)}
            />
          </div>
          <div className="IOI-bids-details">
            <div className="IOI-bid-details-left">
              <div className="IOI-bid-details-row">
                <p className="IOI-bid-details-row-first mt-0.5">
                  Bid Amount (₹Cr) :
                </p>
                <p className="IOI-bid-details-row-second">
                  {bid?.investmentAmount || `-`}
                </p>
              </div>
              <div className="IOI-bid-details-row">
                <p className="IOI-bid-details-row-first mt-0.5">
                  Valuation (₹Cr) :
                </p>
                <p className="IOI-bid-details-row-second">
                  {bid?.valuation || `-`}
                </p>
              </div>
              <div className="IOI-bid-details-row">
                <p className="IOI-bid-details-row-first mt-0.5">Stake (%) :</p>
                <p className="IOI-bid-details-row-second italic">
                  {bid?.stake?.toFixed(2) || `-`}
                </p>
              </div>
              <div className="IOI-bid-details-row">
                <p className="IOI-bid-details-row-first mt-0.5">Bid Date :</p>
                <p className="IOI-bid-details-row-second italic">
                  {dayjs(bidDate).format("DD MMM YYYY") || `-`}
                </p>
              </div>
            </div>
            <div className="IOI-bid-details-right">
              <div className="IOI-bid-details-row">
                <p className="IOI-bid-details-row-first mt-0.5">
                  Deal Size (₹Cr) :
                </p>
                <p className="IOI-bid-details-row-second">
                  {deal?.fundingRequired || `-`}
                </p>
              </div>
              <div className="IOI-bid-details-row">
                <p className="IOI-bid-details-row-first mt-0.5">
                  Expected Valuation(₹Cr) :
                </p>
                <p className="IOI-bid-details-row-second">
                  {deal?.expectedValuation || `-`}
                </p>
              </div>
              <div className="IOI-bid-details-row">
                <p className="IOI-bid-details-row-first mt-0.5">
                  Stake Offered (%) :
                </p>
                <p className="IOI-bid-details-row-second italic">
                  {deal?.stakeOffered?.toFixed(2) || `-`}
                </p>
              </div>
            </div>
          </div>
          <div className="sign-in-btn-section mt-5">
            <CustomBlackBtn
              type="button"
              text="Close"
              onClick={() => setSecondaryViewModal(false)}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SecondaryViewBid;
