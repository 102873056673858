import React, { useState } from "react";
import Topbar from "./topbar";
import Sidebar from "./Sidebar";
import DealDetailsForm from "./dealDetailsForm";
import InvestorPanel from "../../Investor/Onboarding/InvestorPanel";
import DataroomUpload from "./DataroomUpload";
import AnalyticsMapper from "./AnalyticsMapper";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import TextEditor from "../../../Common/components/custom/textEditor";

import { useLocation } from "react-router-dom";
import { copyCompanyDataToDeal } from "../../../../endpoints/admin";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Helmet } from "react-helmet";
import { Modal } from "@mui/material";
import { Box } from "@mui/joy";

import closeIcon from "../../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import Toast from "../../../Common/Toast";
import { ToastContainer } from "react-toastify";
import { useLoader } from "../../../Common/LoaderProvider";
import FoundingTeamProfile from "../../../Founders/Dashboard/editProfile.jsx/foundingTeamProfile";
const DealDetailsEdit = () => {
  const location = useLocation();
  const loader = useLoader();
  const token = localStorage.getItem("token");
  const [updateDetails, setUpdateDetails] = useState(false);
  const [cardType, setCardType] = useState("DealDetails");
  const { dealId, startupId } = location.state || {};
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleCardTypeChange = (event, newCardType) => {
    if (newCardType !== null) setCardType(newCardType);
  };
  let ComponentToRender;

  const importCompanyDetails = async () => {
    try {
      loader.start("Import data");
      const res = await copyCompanyDataToDeal(startupId, token);
      if (res && res.data.message === "Data copied successfully") {
        Toast("Successfully imported data", "success", "dealDetailsCompanies");
      } else {
        Toast("Error in importing data", "error", "dealDetailsCompanies");
      }
      setUpdateDetails(true);
      loader.stop();
      setConfirmationModalOpen(false);
    } catch (error) {
      Toast("Error in importing data", "error", "dealDetailsCompanies");
    }
  };
  switch (cardType) {
    case "DealDetails":
      ComponentToRender = <DealDetailsForm updateDetails={updateDetails} />;
      break;

    case "FoundingTeamDetails":
      ComponentToRender = (
        // <FoundingTeamDetailsForm startupId={startupId} dealId={dealId} />
        <FoundingTeamProfile isAdmin={true} />
      );
      break;
    case "InvestorPanel":
      ComponentToRender = (
        <InvestorPanel startupId={startupId} dealId={dealId} />
      );
      break;
    case "Dataroom":
      ComponentToRender = (
        <DataroomUpload startupId={startupId} dealId={dealId} />
      );
      break;
    case "AnalyticsMapper":
      ComponentToRender = (
        <AnalyticsMapper startupId={startupId} dealId={dealId} />
      );
      break;
    default:
      ComponentToRender = <DealDetailsForm />;
  }
  return (
    <section className="dealDetailsEdit">
      <Helmet>
        <title>Admin Panel - deal details Edit</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        containerId="dealDetailsCompanies"
      />
      <Topbar />
      <Modal
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box reason-box"
          style={{ width: "600px", height: "fit-content" }}
        >
          <img
            className="reason-box-closeicon"
            src={closeIcon}
            style={{
              cursor: "pointer",
              position: "fixed",
              right: "20px",
              top: "20px",
            }}
            alt="close"
            onClick={() => setConfirmationModalOpen(false)}
          ></img>
          <div className="text-box">
            <div className="heading">
              <p className="heading text-[20px] text-[var(--Primary-Text-Color)]">
                Are you sure?
              </p>
            </div>
            <div className="subheading text-[16px] text-[var(--Secondary-Text-Color)]">
              <p>
                Do you want all the details from associated company Database. It
                will overwrite all the existing filed details (except noName
                details)
              </p>
            </div>
          </div>

          <div className="btn-box">
            <CustomBlackBtn
              text="Cancel"
              filled={false}
              onClick={() => setConfirmationModalOpen(false)}
            />
            <CustomBlackBtn text="Confirm" onClick={importCompanyDetails} />
          </div>
        </Box>
      </Modal>
      <Sidebar dealId={dealId} active={"dealDetails"} startupId={startupId} />
      <div className="dealDetailsEdit-box">
        <div className="flex items-center justify-between">
          <ToggleButtonGroup
            color="primary"
            value={cardType}
            exclusive
            onChange={handleCardTypeChange}
            aria-label="card-type"
            className="toggle-card-type-btn"
          >
            <ToggleButton
              value="DealDetails"
              sx={{
                textTransform: "capitalize",
              }}
            >
              Deal Details
            </ToggleButton>
            <ToggleButton
              value="FoundingTeamDetails"
              sx={{
                textTransform: "capitalize",
              }}
            >
              FoundingTeam Details
            </ToggleButton>
            <ToggleButton
              value="InvestorPanel"
              sx={{
                textTransform: "capitalize",
              }}
            >
              Choose Investors
            </ToggleButton>
            <ToggleButton
              value="Dataroom"
              sx={{
                textTransform: "capitalize",
              }}
            >
              Dataroom
            </ToggleButton>
            <ToggleButton
              value="AnalyticsMapper"
              sx={{
                textTransform: "capitalize",
              }}
            >
              Analytics Mapper
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        {ComponentToRender}
      </div>
    </section>
  );
};

export default DealDetailsEdit;
