import React, { useEffect, useState } from "react";
import AnalyticsOverview from "./analyticsOverview";
import { Box, Container } from "@mui/material";
import { useLoader } from "../Common/LoaderProvider";
import BusinessAnalytics from "./businessAnalytics";
import { getAnalyticsData } from "../../endpoints/common";
import HistoricalFinancials from "./historicalFinancials";
import { Helmet } from "react-helmet";
import emptyState from "../../Assets/Images/icons/emptyStateFolder.svg";

const Analytics = ({ deal, stage, startupId, analytics, setAnalytics }) => {
  const token = localStorage.getItem("token");
  const companyName = deal ? deal.companyName : "";
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const loader = useLoader();
  const [analyticsData, setAnalyticsData] = useState(deal);
  useEffect(() => {
    getAnalytics();
  }, []);

  const getAnalytics = async () => {
    try {
      let response;
      if (deal?.dealId?.startsWith("SD")) {
        response = await getAnalyticsData(deal?.dealId);
      } else {
        response = await getAnalyticsData(startupId);
      }
      setAnalyticsData(response.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      loader.stop();
      setLoading(false);
    }
  };

  if (loading) {
    loader.start("Fetching data...");
  }

  if (error) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "calc(100vh - 70px)",
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src={emptyState} alt="!" className="mb-200" />
            <p
              style={{
                fontSize: "16px",
                marginBottom: "40px",
                textAlign: "center",
                width: "300px",
              }}
            >
              No analytics available at the moment. Data is being processed.
              Please try again soon.
            </p>
          </Box>
        </Box>
      </Container>
    );
  }
  return (
    <>
      <Helmet>
        <title>{`${companyName} - Analytics`}</title>
      </Helmet>
      <section>
        <div
          className="analytics-page"
          style={{ paddingBottom: "120px", width: "auto" }}
        >
          {analytics === "summary" && analyticsData?.summary && (
            <AnalyticsOverview
              deal={deal}
              data={analyticsData?.data}
              setAnalytics={setAnalytics}
              mapper={analyticsData?.summary}
              stage={stage}
            />
          )}
          {analytics === "sales" && analyticsData?.sales && (
            <BusinessAnalytics
              data={analyticsData?.data}
              mapper={analyticsData?.sales}
              deal={deal}
              stage={stage}
            />
          )}
          {analytics === "financials" && analyticsData?.financials && (
            <HistoricalFinancials
              data={analyticsData?.data}
              mapper={analyticsData?.financials}
              deal={deal}
              stage={stage}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default Analytics;
