import React from "react";
import { Checkbox, Radio } from "@mui/joy";
import Button from "@mui/material/Button";

const CheckboxGroup = ({
  title,
  options,
  selectedOptions,
  setSelectedOptions,
  isSortFilter = false, // New property to differentiate filters
  className,
}) => {
  const handleOptionChange = (option, checked) => {
    if (isSortFilter) {
      // For sort filters, set single selected option
      setSelectedOptions(option);
    } else {
      // For normal filters, toggle selection
      setSelectedOptions((prev) =>
        checked ? [...prev, option] : prev.filter((item) => item !== option)
      );
    }
  };

  const handleSelectAll = () => {
    if (!isSortFilter) {
      setSelectedOptions(options);
    }
  };

  const handleClearAll = () => {
    if (!isSortFilter) {
      setSelectedOptions([]);
    }
  };

  const isAllSelected = selectedOptions.length === options.length;
  const isNoneSelected = selectedOptions.length === 0;

  return (
    <div
      className={`gap-200 ${className}`}
      style={{
        borderBottom: title !== "Sort By" ? "0.5px solid #D9D9D9" : "0px",
      }}
    >
      <div className="gap-200 flex flex-col p-4">
        <div className="flex justify-between">
          <p className="text-neutral-12 font-medium">{title}</p>
          {!isSortFilter && (
            <div>
              <Button
                variant="text"
                onClick={handleSelectAll}
                disabled={isAllSelected}
                sx={{
                  textTransform: "none",
                  fontWeight: "400",
                  color: isAllSelected ? "#D9D9D9" : "#114FEE",
                }}
              >
                Select All
              </Button>
              <Button
                variant="text"
                onClick={handleClearAll}
                disabled={isNoneSelected}
                sx={{
                  textTransform: "none",
                  fontWeight: "400",
                  color: isNoneSelected ? "#D9D9D9" : "#114FEE",
                }}
              >
                Clear All
              </Button>
            </div>
          )}
        </div>
        <div className="gap-x-200 gap-y-200 grid grid-cols-3">
          {options.map((option, index) =>
            isSortFilter ? (
              <Radio
                className="col-span-3"
                key={index}
                label={option}
                checked={selectedOptions === option}
                onChange={() => handleOptionChange(option, true)}
                size="sm"
              />
            ) : (
              <Checkbox
                key={index}
                label={option}
                checked={selectedOptions.includes(option)}
                onChange={(e) => handleOptionChange(option, e.target.checked)}
                sx={{
                  "& .MuiCheckbox-label": {
                    fontSize: "14px", // Adjust the font size as needed
                  },
                }}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckboxGroup;
