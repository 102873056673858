import React, { useEffect, useState } from "react";
import { getProfileCompletionPercentage } from "../../../../endpoints/investor";

import { investorState } from "../investorState";
import {
  fetchInvestorDetails,
  PostDetails,
} from "../../../../endpoints/investor";
import { useRecoilState, useRecoilValue } from "recoil";
import { Helmet } from "react-helmet";

import uploadImg from "../../../../Assets/Images/signup/upload-img.svg";
import { ToastContainer } from "react-toastify";
import { useLoader } from "../../../Common/LoaderProvider";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { randomId } from "@mui/x-data-grid-generator";

import InvestmentFirmDetails from "./steps/investmentFirmDetails";
import InvestmentDetails from "./steps/investmentDetails";
import InvestmentFocus from "./steps/investmentFocus";
import InvestmentCriteria from "./steps/investmentCriteria";
import ProgressStepper from "./steps/progressStepper";
import { isEntireProfileCompleted } from "./stepCompletion";
import GreenTickModal from "../../../Common/components/custom/greenTickModal";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "../../../Common/Toast";
import Sidebar from "../../../Common/components/sidebar";
import InvestorProfileSubHeader from "./editInvestorProfileSubHeader";
import SectionContainer from "../../../Common/components/SectionContainer";

const Profile = ({ investorID }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [greenTickModal, setGreenTickModal] = useState(false);
  const { editMode, activeSection } = location.state || {};
  const [rows, setRows] = useState([]);
  const [keyInvestmentRows, setKeyInvestmentRows] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [state, setState] = useRecoilState(investorState);
  const [firmData, setFirmData] = useState({});
  const [investor, setInvestor] = useState();

  const [investorLogoPreview, setInvestorLogoPreview] = useState(uploadImg);

  const [initialValues, setInitialValues] = useState({
    investorLogo: investorLogoPreview,
    companyName: firmData?.companyName || "",
    headquarters: firmData?.headquarters || "",
    foundedYear: firmData?.foundedYear || "",
    investorType: firmData?.investorType || "",
    employeeCount: firmData?.employeeCount || "",
    investorWebsite: firmData?.investorWebsite || "",
    investorDescription: firmData?.investorDescription || "",
    investmentThesis: firmData?.investmentThesis || "",
    ticketSizevalue: firmData?.ticketSizevalue || [],
    totalExposure: firmData?.totalExposure || [],
    stakeAcquired: firmData?.stakeAcquired || [],
    investmentStage: firmData?.investmentStage || [],
    assetClass: firmData?.assetClass || [],
    coInvestment: firmData?.coInvestment || false,
    otherCriteria: firmData?.otherCriteria || "",
    financialCriteria: firmData?.financialCriteria || [],
    industryVerticalOptions: firmData?.industryVerticalOptions || [],
    endMarketFocus: firmData?.endMarketFocus || [],
    investmentDetails: firmData?.investmentDetails || [
      {
        id: randomId(),
        fund: "",
        vintage: "",
        investments: "",
        aum: "",
        dryPowder: "",
      },
    ],
    keyInvestmentsDetails: firmData?.keyInvestmentsDetails || [
      {
        id: randomId(),
        company: "",
        industry: "",
        round: "",
        year: "",
      },
    ],
    onBoardingComplete: firmData?.onBoardingComplete || false,
    investorID: firmData?.investorID || "",
    keyInvestments: firmData?.keyInvestments || "",
    profileCompleted: firmData?.profileCompleted || false,
  });

  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({
      investorLogo: Yup.mixed().required(`Investor Logo is required`),
      companyName: Yup.string().required(`Firm Name is required`),
      investorType: Yup.string().required(`Investor Type is required`),
      investorWebsite: Yup.string()
        .matches(
          /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
          "Please enter a valid URL"
        )
        .required("Company Website is required"),
      investmentThesis: Yup.string().required(`Investment Thesis is required`),
      investorDescription: Yup.string().required(
        `Investor Description is required`
      ),
      // employeeCount: Yup.number()
      //   .required("Number of employees is required")
      //   .min(1, "Number of employees at least 1"),
      ticketSizevalue: Yup.array()
        .of(Yup.number().moreThan(0, "Values must be greater than zero"))
        .min(2, "Initial Check Size is required")
        .test("min-max-check", "Max must be greater than Min", (value) => {
          if (!Array.isArray(value) || value.length !== 2) {
            return false;
          }
          const [min, max] = value;
          return max >= min;
        }),
      totalExposure: Yup.array()
        .of(Yup.number().moreThan(0, "Values must be greater than zero"))
        .min(2, "Total Exposure is required")
        .test("min-max-check", "Max must be greater than Min", (value) => {
          if (!Array.isArray(value) || value.length !== 2) {
            return false;
          }
          const [min, max] = value;
          return max >= min;
        }),
      stakeAcquired: Yup.array()
        .of(
          Yup.number()
            .min(0, "Values must be at least 0")
            .max(100, "Values must be at most 100")
        )
        .min(2, "Stake Acquired is required")
        .test("min-max-check", "Max must be greater than Min", (value) => {
          if (!Array.isArray(value) || value.length !== 2) {
            return false;
          }
          const [min, max] = value;
          return max >= min;
        }),
      investmentStage: Yup.array().min(1, `Investment Stage is required`),
      assetClass: Yup.array().min(1, `Asset Class is required`),
      coInvestment: Yup.bool().required(`Co-Investment is required`),
      industryVerticalOptions: Yup.array().min(
        1,
        "Industry Verticals is required"
      ),
      endMarketFocus: Yup.array().min(1, "End Market Focus is required"),
    })
  );
  useEffect(() => {
    setInitialValues({
      investorLogo: investorLogoPreview,
      companyName: firmData?.companyName || "",
      headquarters: firmData?.headquarters || "",
      foundedYear: firmData?.foundedYear || "",
      investorType: firmData?.investorType || "",
      investorWebsite: firmData?.investorWebsite || "",
      employeeCount: firmData?.employeeCount || "",
      investmentThesis: firmData?.investmentThesis || "",
      investorDescription: firmData?.investorDescription || "",
      ticketSizevalue: firmData?.ticketSizevalue || [],
      totalExposure: firmData?.totalExposure || [],
      stakeAcquired: firmData?.stakeAcquired || [],
      investmentStage: firmData?.investmentStage || [],
      assetClass: firmData?.assetClass || [],
      coInvestment: firmData?.coInvestment || false,
      otherCriteria: firmData?.otherCriteria || "",
      financialCriteria: firmData?.financialCriteria || [],
      industryVerticalOptions: firmData?.industryVerticalOptions || [],
      endMarketFocus: firmData?.endMarketFocus || [],
      investmentDetails: firmData?.investmentDetails || [
        {
          id: randomId(),
          fund: "",
          vintage: "",
          investments: "",
          aum: "",
          dryPowder: "",
        },
      ],
      keyInvestmentsDetails: firmData?.keyInvestmentsDetails || [
        {
          id: randomId(),
          company: "",
          industry: "",
          round: "",
          year: "",
        },
      ],
      onBoardingComplete: firmData?.onBoardingComplete || false,
      investorID: firmData?.investorID || "",
      keyInvestments: firmData?.keyInvestments || "",
      profileCompleted: firmData?.profileCompleted || false,
    });
  }, [firmData]);

  const fetchDetails = async () => {
    if (!token) return;
    let response;
    try {
      loader.start("Fetching investor details...");
      response = await fetchInvestorDetails(token, investorID);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
    if (response?.data !== null) {
      const responseData = response.data;
      const { data } = responseData;
      const {
        investorLogo,
        companyName,
        headquarters,
        foundedYear,
        employeeCount,
        investorType,
        investorWebsite,
        investmentThesis,
        investorDescription,
        ticketSizevalue,
        totalExposure,
        stakeAcquired,
        investmentStage,
        assetClass,
        coInvestment,
        otherCriteria,
        financialCriteria,
        industryVerticalOptions,
        endMarketFocus,
        investmentDetails,
        keyInvestmentsDetails,
        onBoardingComplete,
        investorID,
        keyInvestments,
        profileCompleted,
      } = data;
      const newState = {
        ...state,
        firm: {
          ...data,
        },
      };
      setState(newState);
      setInvestor(data);
      const parsedDetails =
        investmentDetails &&
        Array.isArray(investmentDetails) &&
        investmentDetails.map((detail) => JSON.parse(detail));
      const parsedKeyInvestmentDetails =
        keyInvestmentsDetails &&
        Array.isArray(investmentDetails) &&
        keyInvestmentsDetails.map((detail) => JSON.parse(detail));
      if (investorLogo) setInvestorLogoPreview(investorLogo);
      setFirmData({
        investorLogo,
        companyName,
        headquarters,
        foundedYear,
        investorType,
        investorWebsite,
        employeeCount,
        investmentThesis,
        investorDescription,
        ticketSizevalue: ticketSizevalue.map((detail) => JSON.parse(detail))[0],
        totalExposure: totalExposure.map((detail) => JSON.parse(detail))[0],
        stakeAcquired: stakeAcquired.map((detail) => JSON.parse(detail))[0],
        investmentStage: investmentStage.map((detail) => JSON.parse(detail))[0],
        assetClass: assetClass.map((detail) => JSON.parse(detail))[0],
        coInvestment: coInvestment || false,
        otherCriteria,
        financialCriteria: financialCriteria.map((detail) =>
          JSON.parse(detail)
        )[0],
        industryVerticalOptions: industryVerticalOptions.map((detail) =>
          JSON.parse(detail)
        )[0],
        endMarketFocus:
          endMarketFocus.length > 0
            ? endMarketFocus.map((detail) => JSON.parse(detail))[0]
            : [],
        investmentDetails: parsedDetails
          ? parsedDetails[0]
          : [
              {
                id: randomId(),
                fund: "",
                vintage: "",
                investments: "",
                aum: "",
                dryPowder: "",
              },
            ],
        keyInvestmentDetails: parsedKeyInvestmentDetails
          ? parsedKeyInvestmentDetails[0]
          : [
              {
                id: randomId(),
                company: "",
                industry: "",
                round: "",
                year: "",
              },
            ],
        onBoardingComplete,
        investorID,
        keyInvestments,
        profileCompleted,
      });
      setRows(
        parsedDetails?.length > 0
          ? parsedDetails[0]?.map((row) => ({ id: randomId(), ...row }))
          : [
              {
                id: randomId(),
                fund: "",
                vintage: "",
                investments: "",
                aum: "",
                dryPowder: "",
              },
            ]
      );
      setKeyInvestmentRows(
        parsedKeyInvestmentDetails?.length > 0
          ? parsedKeyInvestmentDetails[0]?.map((row) => ({
              id: randomId(),
              ...row,
            }))
          : [
              {
                id: randomId(),
                company: "",
                industry: "",
                round: "",
                year: "",
              },
            ]
      );
    } else {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [token, investorID]);
  const handleFormSubmit = async (values) => {
    const isProfileAlreadyComplete = values.profileCompleted;
    const formDataInput = new FormData();
    const isEntireProfileComplete = isEntireProfileCompleted(values);
    const newState = {
      ...state,
      profileCompleted: isEntireProfileComplete,
    };
    setState(newState);
    values.investmentDetails = rows;
    values.keyInvestmentsDetails = keyInvestmentRows;
    values.profileCompleted = isEntireProfileComplete;
    const data = values;
    for (const key in data) {
      if (data[key] === true || data[key] === false) {
        formDataInput.append(key, data[key]);
      } else if (data.hasOwnProperty(key)) {
        if (data[key]) {
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            formDataInput.append(key, JSON.stringify(data[key]));
          } else {
            formDataInput.append(key, data[key]);
          }
        } else if (data[key] === "") {
          formDataInput.append(key, data[key]);
        }
      }
    }
    if (!data.investorID) {
      formDataInput.append("investorID", values.investorID);
    }
    try {
      const response = await PostDetails(formDataInput, token);
      if (response.data.data !== null) {
        if (isEntireProfileComplete && !isProfileAlreadyComplete) {
          setGreenTickModal(true);
        }
      }
      profileCompletionPercentage();
    } catch (error) {
      loader.stop();
    }
  };
  const profileCompletionPercentage = async () => {
    try {
      const response = await getProfileCompletionPercentage(token);
      const percent = response.data.percentage;

      const newState = {
        ...state,
        percentComplete: percent,
      };
      setState(newState);
    } catch (error) {}
  };

  const investmentDetailsColumns = [
    {
      name: "fund",
      label: "Fund",
      placeholder: "Fund",
      editable: true,
    },
    {
      name: "vintage",
      label: "Vintage Year",
      placeholder: "2020",
      type: "number",
    },
    {
      name: "investments",
      label: "Investments",
      placeholder: "5",
      type: "number",
    },
    {
      name: "aum",
      label: "AUM (Rs Cr.)",
      placeholder: "100",
      type: "number",
    },
    {
      name: "dryPowder",
      label: "Dry Powder",
      placeholder: "100",
      type: "number",
    },
  ];
  const [keyInvestmentColumns, setKeyInvestmentColumns] = useState([
    {
      name: "company",
      label: "Company",
      placeholder: "Company Name",
      editable: true,
      width: 200,
    },
    {
      name: "industry",
      label: "Industry",
      placeholder: "eCommerce",
      type: "number",
      width: 200,
    },
    {
      name: "round",
      label: "Round",
      placeholder: "Seed",
      type: "number",
      width: 200,
    },
    {
      name: "year",
      label: "Year",
      placeholder: "2020",
      type: "number",
      width: 200,
    },
  ]);
  useEffect(() => {
    if (role === "admin") {
      setKeyInvestmentColumns((prevColumns) => [
        ...prevColumns.map((column) => ({
          ...column,
          width: 160,
        })),
        {
          name: "website",
          label: "Website",
          placeholder: "website",
          editable: true,
          width: 160,
        },
      ]);
    }
  }, [role]);
  useEffect(() => {
    if (role === "admin") {
      setValidationSchema(null);
    }
  }, [role]);
  const renderStepComponent = () => {
    const renderSection = (title, content) => (
      <SectionContainer
        title={title}
        className="mb-[40px] investor-section h-[calc(100vh-160px)] max-h-fit overflow-y-scroll"
      >
        {content}
      </SectionContainer>
    );

    switch (activeStep) {
      case 1:
        return renderSection(
          "Basic Details",
          <InvestmentFirmDetails
            investorLogoPreview={investorLogoPreview}
            setInvestorLogoPreview={setInvestorLogoPreview}
            investor={investor}
          />
        );
      case 2:
        return renderSection(
          "Investment Details",
          <InvestmentDetails
            rows={rows}
            setRows={setRows}
            investmentDetailsColumns={investmentDetailsColumns}
            keyInvestmentColumns={keyInvestmentColumns}
            keyInvestmentRows={keyInvestmentRows}
            setKeyInvestmentRows={setKeyInvestmentRows}
            handleFormSubmit={handleFormSubmit}
          />
        );
      case 3:
        return renderSection("Investment Focus", <InvestmentFocus />);
      case 4:
        return renderSection("Investment Criteria", <InvestmentCriteria />);
      default:
        return null;
    }
  };

  useEffect(() => {
    if (investor?.profileCompleted && !editMode && role !== "admin") {
      navigate("/investor/myProfile");
    }
  }, [investor]);
  return (
    <>
      <Helmet>
        <title>Investor | Profile</title>
      </Helmet>
      <GreenTickModal
        open={greenTickModal}
        onClose={() => {
          setGreenTickModal(false);
          //To Eliminate flickering
          setInvestor((...prev) => ({
            ...prev,
            profileCompleted: false,
          }));
          navigate("/investor/myProfile");
        }}
        title={`Profile Completed!`}
      />
      {role !== "admin" && (
        <>
          <Sidebar userRole="investor" active="profile" />
          <InvestorProfileSubHeader />
        </>
      )}

      <div className="custom-container h-[calc(100vh-70px)] overflow-y-hidden">
        <Formik
          initialValues={initialValues}
          validationSchema={null}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          {({ values }) => (
            <Form>
              <div className="gap-200 flex items-stretch w-full mt-4">
                <ProgressStepper
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  activeSection={activeSection}
                  validationSchema={validationSchema}
                  setValidationSchema={setValidationSchema}
                />

                {renderStepComponent()}
              </div>
              <div className="showInterestButtons-div">
                <div className="btns-insideContent">
                  {activeStep > 1 && !editMode && (
                    <CustomBlackBtn
                      text="Previous Step"
                      filled={false}
                      onClick={() => setActiveStep(activeStep - 1)}
                    />
                  )}

                  <CustomBlackBtn
                    text={
                      activeStep === 4
                        ? "Submit"
                        : investor?.profileCompleted && editMode
                        ? "Save"
                        : "Save & Continue"
                    }
                    onClick={(e) => {
                      //Simulating click for datagrid to save the details
                      // document.getElementById("complete-profile").click(); // Triggers a click outside the table
                      setTimeout(async () => {
                        await handleFormSubmit(values);
                        if (investor?.profileCompleted && editMode) {
                          await fetchDetails();
                          navigate("/investor/myProfile");
                        } else if (
                          activeStep === 4 &&
                          !isEntireProfileCompleted(values)
                        ) {
                          Toast(
                            "Please complete your profile to continue",
                            "error",
                            "investorAccount"
                          );
                        } else {
                          if (activeStep < 4) setActiveStep(activeStep + 1);
                        }
                      }, 100);
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Profile;
