import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../../Common/components/sidebar";
import Analytics from "../../../analytics/analytics";
import Navbar from "../../../Common/components/navbar";
const InvestorCompanyAnalytics = () => {
  const location = useLocation();
  const { deal, stage, startupId } = location.state || {};
  const [analytics, setAnalytics] = useState(`summary`);
  const navItems = [
    {
      label: "Summary",
      isActive: analytics === "summary",
      onClick: () => handleChange("summary"),
    },
    {
      label: "Sales",
      isActive: analytics === "sales",
      onClick: () => handleChange("sales"),
    },
    {
      label: "Financials",
      isActive: analytics === "financials",
      onClick: () => handleChange("financials"),
    },
  ];
  const handleChange = (analytics) => {
    setAnalytics(analytics);
  };
  return (
    <>
      <div>
        <Sidebar active="ongoingDeals" userRole="investor" />
        <Navbar navItems={navItems} title={`Analytics`} isBack={false} />
        <div
          className="custom-container h-[calc(100vh-70px)] overflow-y-scroll"
          style={{ overflowX: "hidden" }}
        >
          <div
            className="grid grid-cols-12 gap-200"
            style={{ overflowX: "hidden" }}
          >
            <div className="col-span-12">
              <div className="analytics-page">
                <Analytics
                  deal={deal}
                  analytics={analytics}
                  setAnalytics={setAnalytics}
                  startupId={startupId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorCompanyAnalytics;
