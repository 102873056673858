import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import file from "../../../Assets/Images/icons/file.svg";
import folder from "../../../Assets/Images/icons/folder.svg";
import folderOpen from "../../../Assets/Images/icons/folderOpen.svg";

/**
 * Utility function to conditionally join class names.
 * @param  {...(string|boolean|undefined|null)} classes - Class names to join.
 * @returns {string} - A string of joined class names.
 */
function cn(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Button component
const Button = React.forwardRef(
  ({ variant = "default", size = "md", className, ...props }, ref) => {
    const variantClasses = {
      default: "bg-blue-600 text-white hover:bg-blue-700",
      ghost: "bg-transparent text-blue-600 hover:bg-blue-50",
      // Add more variants as needed
    };

    const sizeClasses = {
      sm: "px-2 py-1 text-sm",
      md: "px-4 py-2",
      lg: "px-6 py-3 text-lg",
      // Add more sizes as needed
    };

    return (
      <button
        ref={ref}
        className={cn(
          "inline-flex items-center justify-center rounded-md focus:outline-none focus:ring",
          variantClasses[variant],
          sizeClasses[size],
          className
        )}
        {...props}
      />
    );
  }
);

Button.displayName = "Button";

// ScrollArea component
const ScrollArea = React.forwardRef(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "overflow-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-400 hover:scrollbar-thumb-gray-500",
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);

ScrollArea.displayName = "ScrollArea";

// Tree context and hook
const TreeContext = createContext(null);

const useTree = () => {
  const context = useContext(TreeContext);
  if (!context) {
    throw new Error("useTree must be used within a TreeProvider");
  }
  return context;
};

// Tree component
const Tree = forwardRef(
  (
    {
      className,
      elements,
      initialSelectedId,
      initialExpandedItems,
      children,
      indicator = true,
      openIcon,
      closeIcon,
      dir,
      ...props
    },
    ref
  ) => {
    const [selectedId, setSelectedId] = useState(initialSelectedId);
    const [expandedItems, setExpandedItems] = useState(initialExpandedItems);

    const selectItem = useCallback((id) => {
      setSelectedId(id);
    }, []);

    const handleExpand = useCallback((id) => {
      setExpandedItems((prev) => {
        if (prev?.includes(id)) {
          return prev.filter((item) => item !== id);
        }
        return [...(prev ?? []), id];
      });
    }, []);

    const expandSpecificTargetedElements = useCallback((elements, selectId) => {
      if (!elements || !selectId) return;
      const findParent = (currentElement, currentPath = []) => {
        const isSelectable = currentElement.isSelectable ?? true;
        const newPath = [...currentPath, currentElement.id];
        if (currentElement.id === selectId) {
          if (isSelectable) {
            setExpandedItems((prev) => [...(prev ?? []), ...newPath]);
          } else {
            if (newPath.includes(currentElement.id)) {
              newPath.pop();
              setExpandedItems((prev) => [...(prev ?? []), ...newPath]);
            }
          }
          return;
        }
        if (
          isSelectable &&
          currentElement.children &&
          currentElement.children.length > 0
        ) {
          currentElement.children.forEach((child) => {
            findParent(child, newPath);
          });
        }
      };
      elements.forEach((element) => {
        findParent(element);
      });
    }, []);

    useEffect(() => {
      if (initialSelectedId) {
        expandSpecificTargetedElements(elements, initialSelectedId);
      }
    }, [initialSelectedId, elements, expandSpecificTargetedElements]);

    const direction = dir === "rtl" ? "rtl" : "ltr";

    return (
      <TreeContext.Provider
        value={{
          selectedId,
          expandedItems,
          handleExpand,
          selectItem,
          setExpandedItems,
          indicator,
          openIcon,
          closeIcon,
          direction,
        }}
      >
        <div className={cn("size-full", className)}>
          <ScrollArea ref={ref} className="relative h-full px-2" dir={dir}>
            <AccordionPrimitive.Root
              {...props}
              type="multiple"
              defaultValue={expandedItems}
              value={expandedItems}
              className="flex flex-col gap-1"
              //!Here we have commented out this code for not expanding or closing the folders
              onValueChange={(value) =>
                setExpandedItems((prev) => [...(prev ?? []), value[0]])
              }
              dir={dir}
            >
              {children}
            </AccordionPrimitive.Root>
          </ScrollArea>
        </div>
      </TreeContext.Provider>
    );
  }
);

Tree.displayName = "Tree";

// TreeIndicator component
const TreeIndicator = forwardRef(({ className, ...props }, ref) => {
  const { direction } = useTree();

  return (
    <div
      dir={direction}
      ref={ref}
      className={cn(
        "h-full w-px bg-gray-300 absolute left-1.5 rtl:right-1.5 py-3 rounded-md hover:bg-gray-400 duration-300 ease-in-out",
        className
      )}
      {...props}
    />
  );
});

TreeIndicator.displayName = "TreeIndicator";

// Folder component
const Folder = forwardRef(
  (
    {
      className,
      element,
      value,
      isSelectable = true,
      isSelect,
      children,
      isActive,
      ...props
    },
    ref
  ) => {
    const {
      direction,
      handleExpand,
      expandedItems,
      indicator,
      setExpandedItems,
      openIcon,
      closeIcon,
    } = useTree();

    return (
      <AccordionPrimitive.Item
        {...props}
        value={value}
        className="relative h-full overflow-hidden"
      >
        <AccordionPrimitive.Trigger
          className={cn(
            "flex items-center gap-1 text-sm rounded-md mb-100",
            className,
            {
              "bg-gray-100": isSelect && isSelectable,
              "cursor-pointer": isSelectable,
              "cursor-not-allowed opacity-50": !isSelectable,
            }
          )}
          disabled={!isSelectable}
          //!Here we have commented out this code for not expanding or closing the folder. Folder will be by default open
        >
          {expandedItems?.includes(value)
            ? openIcon ?? (
                <img
                  src={folderOpen}
                  alt={"folderOpen"}
                  className="w-4 h-4"
                  onClick={() => {
                    handleExpand(value);
                  }}
                />
              )
            : closeIcon ?? (
                <img
                  src={folder}
                  alt={"folder"}
                  className="w-4 h-4"
                  onClick={() => {
                    handleExpand(value);
                  }}
                />
              )}
          <span>{element}</span>
        </AccordionPrimitive.Trigger>
        <AccordionPrimitive.Content className="text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down relative overflow-hidden h-full">
          {element && indicator && <TreeIndicator aria-hidden="true" />}
          <AccordionPrimitive.Root
            dir={direction}
            type="multiple"
            className="rtl:mr-5 flex flex-col gap-1 py-1 ml-5"
            defaultValue={expandedItems}
            value={expandedItems}
            onValueChange={(value) => {
              setExpandedItems?.((prev) => [...(prev ?? []), value[0]]);
            }}
          >
            {children}
          </AccordionPrimitive.Root>
        </AccordionPrimitive.Content>
      </AccordionPrimitive.Item>
    );
  }
);

Folder.displayName = "Folder";

// File component
const File = forwardRef(
  (
    {
      value,
      className,
      handleSelect,
      isSelectable = true,
      isSelect,
      fileIcon,
      children,
      ...props
    },
    ref
  ) => {
    const { direction, selectedId, selectItem } = useTree();
    const isSelected = isSelect ?? selectedId === value;
    return (
      <AccordionPrimitive.Item value={value} className="relative">
        <AccordionPrimitive.Trigger
          ref={ref}
          {...props}
          dir={direction}
          disabled={!isSelectable}
          aria-label="File"
          className={cn(
            "flex items-center gap-1 text-sm pr-1 rtl:pl-1 rtl:pr-0 rounded-md duration-200 ease-in-out",
            {
              "bg-gray-100": isSelected && isSelectable,
            },
            isSelectable ? "cursor-pointer" : "opacity-50 cursor-not-allowed",
            className
          )}
          onClick={() => selectItem(value)}
        >
          {fileIcon ?? <img src={file} alt="file" className="w-4 h-4" />}
          {children}
        </AccordionPrimitive.Trigger>
      </AccordionPrimitive.Item>
    );
  }
);

File.displayName = "File";

// CollapseButton component
const CollapseButton = forwardRef(
  ({ className, elements, expandAll = false, children, ...props }, ref) => {
    const { expandedItems, setExpandedItems } = useTree();

    const expendAllTree = useCallback(
      (elements) => {
        const expandTree = (element) => {
          const isSelectable = element.isSelectable ?? true;
          if (isSelectable && element.children && element.children.length > 0) {
            setExpandedItems?.((prev) => [...(prev ?? []), element.id]);
            element.children.forEach(expandTree);
          }
        };

        elements.forEach(expandTree);
      },
      [setExpandedItems]
    );

    const closeAll = useCallback(() => {
      setExpandedItems?.([]);
    }, [setExpandedItems]);

    useEffect(() => {
      if (expandAll) {
        expendAllTree(elements);
      }
    }, [expandAll, elements, expendAllTree]);

    return (
      <Button
        variant="ghost"
        className={cn("h-8 w-fit p-1 absolute bottom-1 right-2", className)}
        onClick={
          expandedItems && expandedItems.length > 0
            ? closeAll
            : () => expendAllTree(elements)
        }
        ref={ref}
        {...props}
      >
        {children}
        <span className="sr-only">Toggle</span>
      </Button>
    );
  }
);

CollapseButton.displayName = "CollapseButton";

// Export components
export { cn, Button, ScrollArea, Tree, Folder, File, CollapseButton };
