import React, { useMemo, useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import { formatToLakhs } from "../../../utilities/numberFormatter";
import {
  getRelativeKey,
  useKeySelection,
} from "../../../hooks/analytics/getComparisonKey";
import arrowUp from "../../../Assets/Images/icons/analytics/ArrowUp.svg";
import arrowDown from "../../../Assets/Images/icons/analytics/ArrowDown.svg";

const FinancialStatementSmallComparisonTable = ({ data }) => {
  const [type, setType] = useState("m");
  const [option, setOption] = useState(`previous${type}`);
  const keys = useMemo(
    () => Object.keys(data?.data[type]?.["Revenue"] || {}),
    [type, data?.data]
  );
  const { key, ckey } = useKeySelection(data, type, option, keys);

  const rows = [
    "Revenue",
    "Cost of Revenue",
    "Gross Profit",
    "Gross Profit %",
    "SG&A",
    "EBITDA",
    "EBITDA %",
  ];
  const percentageRows = [
    "Gross Profit %",
    "EBITDA %",
    "Net Profit Margin",
    "EBIT %",
  ];
  const IMPRows = [
    "Gross Profit",
    "EBITDA",
    "Net Profit",
    "EBIT",
    "Profit (Before Tax)",
  ];

  function getVariance(row) {
    if (row !== "Gross Profit %" && row !== "EBITDA %" && row !== "SG&A") {
      const a = data?.data[type]?.[row]?.[key];
      const b = data?.data[type]?.[row]?.[ckey];
      if (a === 0 || b === 0 || !a || !b) {
        return 0;
      }
      return (((a - b) / Math.abs(b)) * 100).toFixed(1);
    } else if (row === "SG&A") {
      const a = data?.data[type]?.[row]["Total SG&A Expenses"]?.[key];
      const b = data?.data[type]?.[row]["Total SG&A Expenses"]?.[ckey];
      if (a === 0 || b === 0 || !a || !b) {
        return 0;
      }
      return (((a - b) / Math.abs(b)) * 100).toFixed(1);
    }
  }
  const isLTMVisible = () => {
    let result = false;
    const keys = Object.keys(data?.data[type]?.["Revenue"] || {});
    const ckey = getRelativeKey(
      keys,
      keys?.[keys?.length - 1],
      "previousltm",
      false
    ).key;
    rows.forEach((row) => {
      const value =
        row === "SG&A"
          ? data?.data[`ltm`]?.[row]["Total SG&A Expenses"]?.[ckey]
          : data?.data[`ltm`]?.[row]?.[ckey];
      if (value) {
        result = true;
      }
    });
    return result;
  };
  const isPYVisible = () => {
    let result = false;
    const ckey = getRelativeKey(
      keys,
      keys?.[keys?.length - 1],
      `last${type}`,
      false
    ).key;
    rows.forEach((row) => {
      const value =
        row === "SG&A"
          ? data?.data[type]?.[row]["Total SG&A Expenses"]?.[ckey]
          : data?.data[type]?.[row]?.[ckey];
      if (value) {
        result = true;
      }
    });
    return result;
  };

  return (
    <>
      <TimePeriodButton
        title={data?.name}
        type={type}
        setType={setType}
        select={true}
        ltm={isLTMVisible()}
        year={false}
        option={option}
        setOption={setOption}
        py={isPYVisible()}
      />
      <div className="mt-200">
        <table aria-label="Financial statement comparison table">
          <thead>
            {["Summary", ckey, key, "%Change"].map((column, index) => (
              <th
                key={index}
                align={index === 0 ? "left" : "right"}
                className={`p-150 bg-neutral-3 rounded-050 text-desktop-p-s font-medium text-neutral-8 !uppercase ${
                  index === 0 ? "text-left" : "text-right"
                }`}
              >
                {column}
              </th>
            ))}
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td
                  className={`text-desktop-p-s p-150 !border-b-neutral-4 text-left ${
                    IMPRows.includes(row) &&
                    "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                  }`}
                  align="left"
                >
                  {row}
                </td>
                <td
                  className={`text-desktop-p-s p-150 !border-b-neutral-4 text-right ${
                    IMPRows.includes(row) &&
                    "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                  }`}
                  align="right"
                >
                  {row === "SG&A"
                    ? formatToLakhs(
                        data?.data[type]?.[row]["Total SG&A Expenses"]?.[ckey]
                      ) || `-`
                    : formatToLakhs(data?.data[type]?.[row]?.[ckey]) || `-`}
                </td>
                <td
                  className={`text-desktop-p-s p-150 !border-b-neutral-4 text-right ${
                    IMPRows.includes(row) &&
                    "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                  }`}
                  align="right"
                >
                  {row === "SG&A"
                    ? formatToLakhs(
                        data?.data[type]?.[row]["Total SG&A Expenses"]?.[key]
                      ) || `-`
                    : formatToLakhs(data?.data[type]?.[row]?.[key]) || `-`}
                </td>
                <td
                  className={`text-desktop-p-s p-150 !border-b-neutral-4 text-right ${
                    IMPRows.includes(row) &&
                    "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                  } ${
                    getVariance(row)
                      ? getVariance(row) > 0
                        ? "text-[#20BD7E]"
                        : "text-[#FF5454]"
                      : ""
                  }`}
                  align="right"
                >
                  <div className="flex items-center gap-100 justify-end">
                    {getVariance(row) ? (
                      getVariance(row) > 0 ? (
                        <img src={arrowUp} alt="up" />
                      ) : (
                        <img src={arrowDown} alt="down" />
                      )
                    ) : (
                      ``
                    )}
                    {getVariance(row) ? getVariance(row) + `%` : `-`}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FinancialStatementSmallComparisonTable;
