import React, { useState } from "react";
import { useTable } from "react-table";
import { RiDeleteBinFill } from "react-icons/ri";

const EditableTable = ({ tableConfig }) => {
  const { initialData = [], columns = [] } = tableConfig;

  const [data, setData] = useState(initialData);

  const updateData = (rowIndex, columnId, value) => {
    setData((oldData) =>
      oldData.map((row, index) => {
        if (index === rowIndex) {
          return { ...row, [columnId]: value };
        }
        return row;
      })
    );
  };

  const deleteRow = (rowIndex) => {
    setData((oldData) => oldData.filter((_, index) => index !== rowIndex));
  };

  const addRow = () => {
    const newRow = columns.reduce(
      (acc, column) => ({
        ...acc,
        [column.accessor]: "",
      }),
      {}
    );
    setData((oldData) => [...oldData, newRow]);
  };

  const columnsWithActions = React.useMemo(
    () => [
      ...columns.map((col) => ({
        Header: col.Header,
        accessor: col.accessor,
        Cell: ({ value, row, column }) => (
          <input
            value={value || ""}
            placeholder={col.placeholder || ""}
            onChange={(e) => updateData(row.index, column.id, e.target.value)}
            className="focus:outline-none w-full bg-transparent"
            style={{
              width: col.width || "auto",
            }}
          />
        ),
      })),
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <button
            onClick={() => deleteRow(row.index)}
            className="flex items-center gap-1 text-[#FF5454]"
          >
            <RiDeleteBinFill /> Delete
          </button>
        ),
      },
    ],
    [columns]
  );

  const tableInstance = useTable({ columns: columnsWithActions, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div className="m-4">
      {/* Wrapping div for rounded corners and outer border */}
      <div className="overflow-hidden border border-b-0 border-gray-300 rounded">
        <table
          {...getTableProps()}
          className="w-full border-collapse table-fixed"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="bg-gray-100"
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`border-b border-r border-gray-300 text-left p-3 font-semibold ${
                      index === headerGroup.headers.length - 1
                        ? "border-r-0"
                        : ""
                    }`}
                    style={{
                      width: column.width || "auto",
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="hover:bg-gray-50 transition"
                >
                  {row.cells.map((cell, index) => (
                    <td
                      {...cell.getCellProps()}
                      className={`border-b border-r border-gray-300 p-3 ${
                        index === row.cells.length - 1 ? "border-r-0" : ""
                      }`}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <button
        onClick={addRow}
        className="hover:bg-blue-50 px-4 py-2 mt-4 text-blue-500 bg-white border border-blue-500 rounded-[4px]"
      >
        + Add Row
      </button>
    </div>
  );
};

const exampleTable = () => {
  const tableConfig = {
    initialData: [
      {
        company: "flipkart",
        industry: "eCommerce",
        round: "Seed",
        year: "2020",
        id: "123",
      },
    ],
    columns: [
      {
        Header: "Company",
        accessor: "company",
        placeholder: "Enter company",
      },
      {
        Header: "Industry",
        accessor: "industry",
        placeholder: "Enter industry",
      },
      { Header: "Round", accessor: "round", placeholder: "Enter round" },
      { Header: "Year", accessor: "year", placeholder: "Enter year" },
    ],
  };
  return (
    <>
      <EditableTable tableConfig={tableConfig} />
    </>
  );
};
export default exampleTable;
