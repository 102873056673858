import React from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Navbar = ({
  navItems,
  children,
  title,
  isBack = true,
  className,
  centerAlign = false,
  colsCount = 8,
}) => {
  const navigate = useNavigate();
  const NavItem = ({ label, isActive = false, onClick }) => {
    return (
      <button
        className={`gap-2 self-stretch p-2 py-2.5 my-auto rounded min-h-[36px] ${
          isActive ? "font-medium text-blue-800 bg-violet-100" : "bg-white"
        }`}
        role="tab"
        tabIndex={0}
        aria-selected={isActive}
        onClick={onClick}
      >
        {label}
      </button>
    );
  };

  return (
    <div className=" border-l-zinc-300 border-zinc-300 w-full bg-white shadow-sm border-l-[0.2px] relative !z-[60]">
      <div
        className={`relative ${className} ${
          centerAlign && "max-w-[1320px] px-8 grid grid-cols-12 gap-200"
        }  m-auto`}
      >
        {centerAlign && (
          <div className={`col-span-${(12 - colsCount) / 2}`}></div>
        )}

        <div
          className={`order-solid flex flex-wrap items-center justify-between col-span-8 gap-10 py-3 ${
            centerAlign ? `col-span-${colsCount}` : "custom-container"
          }`}
        >
          <div className="flex flex-wrap gap-6 items-center self-stretch my-auto min-w-[240px] max-md:max-w-full">
            <div
              className={`flex items-center self-stretch gap-4 my-auto text-sm font-medium text-black ${
                !isBack && "gap-0"
              }`}
            >
              <div
                className={`w-[35px] h-[35px] border border-neutral-5 flex items-center justify-center rounded-050 cursor-pointer ${
                  !isBack && "hidden w-0"
                }`}
                onClick={() => navigate(-1)}
              >
                <MdKeyboardArrowLeft fontSize="18px" />
              </div>
              <div className="text-desktop-p-l text-neutral-12 self-stretch my-auto font-bold">
                {title}
              </div>
            </div>
            <div
              className="flex gap-2 items-center self-stretch my-auto text-sm whitespace-nowrap min-w-[240px] text-neutral-900"
              role="tablist"
            >
              {navItems.map((item, index) => (
                <NavItem key={index} {...item} />
              ))}
            </div>
          </div>
          {children && (
            <div className="flex items-start self-stretch gap-3 my-auto">
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
