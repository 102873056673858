import React, { useState, useEffect } from "react";
import {
  formatSize,
  getFileExtension,
  handleFileDownload,
  timeDiff,
  stages,
} from "../functions";
import { useLoader } from "../LoaderProvider";
import Toast from "../Toast";
import FileTree from "./filestructure";
import BreadCrumbs from "./breadCrumbs";

import {
  dumpInvestorInteraction,
  getDownloadUrl,
} from "../../../endpoints/common";
import { fetchAllFiles } from "../../../endpoints/dataroom";

import moment from "moment";
import { Popover } from "@mui/material";
import { ToastContainer } from "react-toastify";

import { FaChevronLeft } from "react-icons/fa";
import { MdOutlineFileDownload, MdOutlineFileOpen } from "react-icons/md";
import emptyStateFolder from "../../../Assets/Images/icons/emptyStateFolder.svg";
const Dataroom = ({ deal, stage }) => {
  const loader = useLoader();
  const [activeDataRoomBtn, setActiveDataRoomBtn] = useState(1);
  const [recentlyUploadedFiles, setRecentlyUploadedFiles] = useState([]);
  const [recentlyUpdatedFiles, setRecentlyUpdatedFiles] = useState([]);
  const [activeFiles, setActiveFiles] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [activePath, setActivePath] = useState("");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [fileInfo, setFileInfo] = useState([]);
  const [activeIndices, setActiveIndices] = useState("");
  const [openDialogs, setOpenDialogs] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentItemId, setCurrentItemId] = useState(null);
  const isPopOpen = Boolean(anchorEl);
  const [breadCrumbs, setBreadCrumbs] = useState([
    {
      label: "abc",
      path: "",
    },
  ]);
  const handlePopOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCurrentItemId(id);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
    setCurrentItemId(null);
  };

  useEffect(() => {
    const dumpInteraction = async () => {
      try {
        await dumpInvestorInteraction(
          {
            dealId: deal?.dealId,
            type: "view",
            page: "dataroom",
            stageOfDeal: stage,
            element: "page",
          },
          token
        );
      } catch (error) {
        console.error("Error dumping interaction", error);
      }
    };
    dumpInteraction();
  }, []);

  const dumpFolderViewInteraction = async (path) => {
    try {
      await dumpInvestorInteraction(
        {
          dealId: deal?.dealId,
          type: "view",
          page: "dataroom",
          stageOfDeal: stage,
          element: "folderView",
          pageSection: path,
        },
        token
      );
    } catch (error) {
      console.error("Error dumping interaction", error);
    }
  };
  const folderClick = (path, index, fileStructure) => {
    if (path.startsWith("/home")) {
      path = path.slice(5);
    }
    if (activePath === path) {
      // Prevent reprocessing if the same path is clicked
      return;
    }
    dumpFolderViewInteraction(path);
    let newActiveIndex;
    if (fileStructure) {
      //This is case when someone trie to click from the file structure given.
      //We are removing the first 1. as it was got added because we added /home path manually to each path
      newActiveIndex = index ? index.split(".").slice(1).join(".") : "";
    } else {
      newActiveIndex = activeIndices
        ? `${activeIndices}.${index + 1}`
        : `${index + 1}`;
    }

    // Update state
    setActiveIndices(newActiveIndex);
    setActiveFiles([]);
    setRecentlyUpdatedFiles([]);
    setRecentlyUploadedFiles([]);
    setActivePath(path);
  };
  const BreadCrumbClick = (path, index) => {
    if (activePath === path) {
      // Prevent reprocessing if the same path is clicked
      return;
    }
    const newActiveIndices = activeIndices.split(".").slice(0, index).join(".");
    setActiveIndices(newActiveIndices);
    setActiveFiles([]);
    setRecentlyUpdatedFiles([]);
    setRecentlyUploadedFiles([]);
    setActivePath(path);
  };
  const getFilesAndFolders = (path) => {
    fileInfo.map((file) => {
      if (file.path.startsWith(path)) {
        const remainingPath = file.path.substring(path.length);
        const len = remainingPath.split("/").length;
        if (len === 2) {
          setActiveFiles((prev) => {
            const fileExists = prev.some(
              (existingFile) => existingFile.path === file.path
            );
            if (!fileExists) {
              return [...prev, file];
            }
            return prev;
          });
          setRecentlyUploadedFiles((prev) => {
            const fileExists = prev.some(
              (existingFile) => existingFile.path === file.path
            );
            if (timeDiff(file.createdAt, 168) && !fileExists) {
              return [...prev, file];
            }

            return prev;
          });
          setRecentlyUpdatedFiles((prev) => {
            const fileExists = prev.some(
              (existingFile) => existingFile.path === file.path
            );
            if (timeDiff(file.updatedAt, 168) && !fileExists) {
              return [...prev, file];
            }

            return prev;
          });
        } else {
        }
      }
    });
  };

  const fetchAllFilesAndFolders = async () => {
    try {
      loader.start("Fetching all files and folders...");
      const response = await fetchAllFiles(deal.dealId, token);
      loader.stop();
      if (response.data) {
        setFileInfo(response.data);
      } else {
        Toast("Incorrect details provided", "error", "invDataroom");
      }
    } catch (err) {
      loader.stop();

      Toast("Failed to fetch all files and folders", "error", "invDataroom");
    }
  };
  const handlePathChange = () => {
    const pathnames = activePath.split("/").filter((x) => x);
    pathnames.unshift("home");
    setBreadCrumbs(pathnames);

    const breadcrumbList = pathnames.map((label, index) => {
      const path =
        index === 0 ? "" : `/${pathnames.slice(1, index + 1).join("/")}`;
      return { label, path };
    });

    setBreadCrumbs(breadcrumbList);
  };
  const calculateFolderSize = (path) => {
    let size = 0;
    fileInfo.map((file) => {
      if (file.path.startsWith(path) && !file.isFolder) {
        size += file.size;
      }
    });
    return size;
  };
  useEffect(() => {
    if (deal?.dealId && token) {
      fetchAllFilesAndFolders();
    }
  }, [token, deal?.dealId]);
  useEffect(() => {
    if (fileInfo.length > 0) {
      getFilesAndFolders(activePath);
    }
  }, [fileInfo, activePath]);
  useEffect(() => {
    handlePathChange();
  }, [activePath]);
  const downloadAZip = async (path) => {
    try {
      await dumpInvestorInteraction(
        {
          dealId: deal.dealId,
          type: "download",
          page: "dataroom",
          stageOfDeal: stage,
          element: "downloadZipButton",
          pageSection: path || `/`,
        },
        token
      );
    } catch (e) {
      console.error("Error dumping interaction", e);
    }
    try {
      const query = {
        downloadPath: "/dataroom/downloadZip",
        dealId: deal?.dealId,
        path: path || "/",
      };
      loader.start("Getting data...");
      //By default all the zip
      const data = await getDownloadUrl(query, token);
      window.location.href = data?.downloadUrl;
      loader.stop();
    } catch (error) {
      console.error("Error downloading zip:", error);
      Toast("Failed to download zip", "error", "invDataroom");
    }
  };
  function isAccessible(type) {
    if (role === "company") {
      return true;
    } else if (type === "preliminary") {
      return true;
    } else if (type === "fullDD" && stages.indexOf(stage) >= 4) {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    if (activeDataRoomBtn === 1) setAllFiles(activeFiles);
    else if (activeDataRoomBtn === 2) setAllFiles(recentlyUploadedFiles);
    else if (activeDataRoomBtn === 3) setAllFiles(recentlyUpdatedFiles);
    else setAllFiles(setActiveFiles);
  }, [
    activeDataRoomBtn,
    activeFiles,
    recentlyUpdatedFiles,
    recentlyUploadedFiles,
  ]);
  const shiftDataroomOneStep = () => {
    const pathSegments = activePath.split("/");
    pathSegments.pop();
    const newPath = pathSegments.join("/");
    BreadCrumbClick(newPath, activeIndices.length - 2);
  };
  return (
    <>
      <ToastContainer containerId={"invDataroom"} position="top-center" />
      <div className="gap-200 grid grid-cols-12">
        <div className="border-neutral-5 rounded-100 col-span-4 border">
          <FileTree
            dealId={deal.dealId}
            filesData={fileInfo}
            folderClick={folderClick}
            activePath={activePath}
            isAccessible={isAccessible}
            downloadAZip={downloadAZip}
          />
        </div>
        <div
          className="col-span-8 h-[calc(100vh-120px)] overflow-y-scroll max-h-[800px]"
          id="scrollContainer"
        >
          <div className="p-300 rounded-100 border-neutral-5 gap-400 flex flex-col h-full bg-white border">
            <div className=" flex items-center justify-between">
              <div className="gap-150 flex items-center">
                <div
                  className="border-neutral-5 rounded-050  border p-[11px] cursor-pointer"
                  onClick={shiftDataroomOneStep}
                >
                  <FaChevronLeft fontSize={"12px"} />
                </div>
                <BreadCrumbs
                  breadCrumbs={breadCrumbs}
                  BreadCrumbClick={BreadCrumbClick}
                />
              </div>
              <>
                <div className="dataroom-btns-left">
                  {/* <CustomToolTip
                  title="Download Zip"
                  position="left"
                  theme="black"
                >
                  <div onClick={() => downloadAZip(activePath)}>
                    <MdOutlineFileDownload
                      style={{
                        fontSize: "26px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </CustomToolTip> */}
                  <button
                    className={`border border-neutral-5 rounded-050 px-150 py-100 text-desktop-p-m ${
                      activeDataRoomBtn === 1 &&
                      "text-aero-blue-700 !border-aero-blue-500 bg-aero-blue-50"
                    }`}
                    onClick={() => setActiveDataRoomBtn(1)}
                  >
                    All
                  </button>
                  <button
                    className={`border border-neutral-5 rounded-050 px-150 py-100 text-desktop-p-m ${
                      activeDataRoomBtn === 2 &&
                      "text-aero-blue-700 !border-aero-blue-500 bg-aero-blue-50"
                    }`}
                    onClick={() => setActiveDataRoomBtn(2)}
                  >
                    Recently Uploaded
                  </button>
                  <button
                    className={`border border-neutral-5 rounded-050 px-150 py-100 text-desktop-p-m ${
                      activeDataRoomBtn === 3 &&
                      "text-aero-blue-700 !border-aero-blue-500 bg-aero-blue-50"
                    }`}
                    onClick={() => setActiveDataRoomBtn(3)}
                  >
                    Recently Updated
                  </button>
                </div>
              </>
            </div>
            <div className="flex-1 overflow-y-auto">
              <table className="w-full max-h-full overflow-y-scroll">
                <thead className=" text-neutral-12">
                  <tr>
                    {/* <th style={{ width: "2%" }} className="top-left-th"></th> */}
                    <th
                      className="bg-blue-50 text-desktop-p-m py-200 rounded-tl-100"
                      style={{ width: "6%" }}
                    >
                      #
                    </th>
                    <th
                      className="bg-blue-50 text-desktop-p-m py-200"
                      style={{ width: "30%" }}
                    >
                      File name
                    </th>
                    <th
                      className="bg-blue-50 text-desktop-p-m py-200"
                      style={{ width: "15%" }}
                    >
                      Size
                    </th>
                    <th
                      className="bg-blue-50 text-desktop-p-m py-200"
                      style={{ width: "15%" }}
                    >
                      Type
                    </th>
                    <th
                      className="bg-blue-50 text-desktop-p-m py-200"
                      style={{ width: "15%" }}
                    >
                      Date Uploaded
                    </th>
                    <th
                      className="bg-blue-50 text-desktop-p-m py-200"
                      style={{ width: "15%" }}
                    >
                      Last Updated
                    </th>
                    <th
                      className="bg-blue-50 text-desktop-p-m py-200 rounded-tr-100"
                      style={{ width: "4%" }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {allFiles && allFiles?.length > 0 ? (
                    allFiles.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          opacity: isAccessible(item.type) ? 1 : 0.5,
                        }}
                      >
                        <td className="text-desktop-p-m py-200 ">
                          {activeIndices === ""
                            ? `${index + 1}`
                            : `${activeIndices}.${index + 1}`}
                        </td>
                        <td
                          className="text-desktop-p-m py-200"
                          style={{
                            backgroundColor: "CCCCCC",
                          }}
                        >
                          <div
                            className="folder-cell"
                            style={{ cursor: "pointer" }}
                          >
                            {item.isFolder ? (
                              <div>
                                {/* <ContextMenuTrigger id={item.path}> */}
                                <div
                                  className="admin-folder-name"
                                  onClick={() => folderClick(item.path, index)}
                                >
                                  <div className="w-[20px]">
                                    <img
                                      src={`https://fundrevstorage2.blob.core.windows.net/website-images/file-icons/Folder.svg`}
                                      alt="file"
                                    />
                                  </div>
                                  <p>{item.path.split("/").slice(-1)[0]}</p>
                                </div>
                              </div>
                            ) : (
                              <div className="admin-folder-name">
                                <div className="w-[20px]">
                                  <img
                                    src={`https://fundrevstorage2.blob.core.windows.net/website-images/file-icons/${getFileExtension(
                                      item.path
                                    )}.svg`}
                                    alt="file"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        "https://fundrevstorage2.blob.core.windows.net/website-images/file-icons/file.svg";
                                    }}
                                  />
                                </div>
                                <p
                                  onClick={() =>
                                    handleFileDownload(
                                      item?.url,
                                      item?.name,
                                      loader,
                                      "invDataroom",
                                      true,
                                      deal.dealId,
                                      token
                                    )
                                  }
                                  className="overflow-ellipsis truncate cursor-pointer w-[220px]"
                                  title={item.name}
                                >
                                  {item.name}
                                </p>
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="text-desktop-p-m py-200">
                          {item.isFolder
                            ? formatSize(calculateFolderSize(item.path))
                            : formatSize(item.size)}
                        </td>
                        <td className="text-desktop-p-m py-200 whitespace-nowrap capitalize">
                          {`${item.type} ${
                            isAccessible(item.type) ? "" : "🔒"
                          }`}
                        </td>
                        <td className="text-desktop-p-m py-200">
                          {moment(item.createdAt)
                            .tz("Asia/Kolkata")
                            .format("MMM D, YYYY")}
                        </td>
                        <td className="text-desktop-p-m py-200">
                          {moment(item.updatedAt)
                            .tz("Asia/Kolkata")
                            .format("MMM D, YYYY")}
                        </td>
                        <td className="relative">
                          <p
                            onClick={(e) => handlePopOpen(e, item._id)}
                            style={{ cursor: "pointer" }}
                          >
                            :
                          </p>
                          <Popover
                            open={isPopOpen && currentItemId === item._id}
                            anchorEl={anchorEl}
                            onClose={handlePopClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            slotProps={{
                              paper: {
                                elevation: 0,
                              },
                            }}
                          >
                            <div className="p-100 gap-050 border-neutral-5 rounded-050 flex flex-col bg-white border w-[160px]">
                              {item.isFolder ? (
                                <>
                                  <div
                                    className="flex gap-[10px] items-center p-100 hover:bg-blue-50 rounded-050 cursor-pointer"
                                    onClick={() => {
                                      handlePopClose();
                                      folderClick(item.path, index);
                                    }}
                                  >
                                    <MdOutlineFileOpen />
                                    <p>Open</p>
                                  </div>

                                  <div
                                    className="flex gap-[10px] items-center p-100 hover:bg-blue-50 rounded-050 cursor-pointer"
                                    onClick={() => {
                                      handlePopClose();
                                      downloadAZip(item?.path);
                                    }}
                                  >
                                    <MdOutlineFileDownload />
                                    <p>Download</p>
                                  </div>
                                </>
                              ) : (
                                <div
                                  className="flex gap-[10px] items-center p-100 hover:bg-blue-50 rounded-050 cursor-pointer"
                                  onClick={() => {
                                    handlePopClose();
                                    handleFileDownload(
                                      item?.url,
                                      item?.name,
                                      loader,
                                      "invDataroom",
                                      true,
                                      deal.dealId,
                                      token
                                    );
                                  }}
                                >
                                  <MdOutlineFileDownload />
                                  <p>Download</p>
                                </div>
                              )}
                            </div>
                          </Popover>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={6}
                        style={{
                          border: 0,
                          textAlign: "center",
                          fontFamily: "Karla, sans-serif",
                          fontSize: "16px",
                          color: "var(--Secondary-Text-Color)",
                          height: "150px",
                        }}
                      >
                        <div className="flex justify-center w-full">
                          <div className="gap-100 flex flex-col items-center">
                            <img src={emptyStateFolder} alt="emptyState" />
                            <p className="opacity-30 text-neutral-12">
                              No Files
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dataroom;
