import axios from "axios";

const backend_url = process.env.REACT_APP_BACKEND_URL;

export const getSecondaryDeals = async (token) => {
  try {
    const response = await axios.get(`${backend_url}/secondary/getAllDeals`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching secondary deals:", error);
    return { data: null, error: error };
  }
};

export const getAllSecondaryDeals = async (investorID, token) => {
  try {
    const response = await axios.get(
      `${backend_url}/secondary/getAllDeals/${investorID}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error fetching secondary deals by investor:", error);
    return { data: null, error: error };
  }
};

export const getOpenSecondaryDeals = async (token) => {
  try {
    const response = await axios.get(`${backend_url}/secondary/getOpenDeals`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.log("Error fetching open secondary deals:", error);
    return { data: null, error: error };
  }
};

export const updateSecondaryDeal = async (updatedData, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/secondary/saveDealDetails`,
      updatedData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error updating secondary deal:", error);
    return { data: null, error: error };
  }
};

export const toggleSecondaryDealIsOpen = async (dealId, token) => {
  try {
    const response = await axios.get(
      `${backend_url}/secondary/toggleIsOpen/${dealId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error updating secondary deal:", error);
    return { data: null, error: error };
  }
};

export const toggleSecondaryDealOpenListing = async (dealId, token) => {
  try {
    const response = await axios.get(
      `${backend_url}/secondary/toggleOpenListing/${dealId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error updating secondary deal:", error);
    return { data: null, error: error };
  }
};

export const getSecondaryDealById = async (dealId, token, investorId) => {
  try {
    const response = await axios.get(
      `${backend_url}/secondary/getDealById/${dealId}/${investorId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error fetching secondary deal by id:", error);
    return { data: null, error: error };
  }
};

export const createSecondaryDeal = async (token) => {
  try {
    const response = await axios.get(`${backend_url}/secondary/createDeal`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error creating secondary deal:", error);
    return { data: null, error: error };
  }
};
export const getLatestDealInterestByInvestorDealId = async (
  dealId,
  investorId,
  token
) => {
  try {
    const response = await axios.get(
      `${backend_url}/secondary/getDealInterestByInvestorDealId/${investorId}/${dealId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(
      "Error getting latest deal interest by investor deal id",
      error
    );
    return { data: null, error: error };
  }
};

export const getLatestStageInvestorsByDealId = async (dealId, token) => {
  try {
    const response = await axios.get(
      `${backend_url}/secondary/getInterestedInvestors/${dealId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error getting latest stageInvestors", error);
    return { data: null, error: error };
  }
};
