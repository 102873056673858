import { Link, useNavigate } from "react-router-dom";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import Logo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import MenuIcon from "@mui/icons-material/Menu";
import whiteArrow from "../../../Assets/Images/icons/whiteArrow.svg";
import { useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import FundrevLogo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";

export default function Header({ handleScroll: customHandleScroll }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const Navigate = useNavigate();
  const defaultHandleScroll = () => {
    window.location.href = "/";
  };
  const handleScroll = customHandleScroll || defaultHandleScroll;
  const token = localStorage.getItem("token");
  const handleLogout = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("token");
    Navigate("/");
  };
  return (
    <>
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-50 flex justify-center w-full p-4">
        <div
          className={`w-[750px] 2xl:w-[937.5px] m-auto flex items-center justify-between pl-6 pr-2 py-2 2xl:pr-3 2xl:py-3  rounded-full transition-all duration-300 bg-white/80 backdrop-blur-md shadow-md`}
        >
          {/* Logo */}
          <Link
            className="flex items-center gap-2 w-[81px] 2xl:w-[100px]"
            to="/"
          >
            <img src={Logo} alt="Fundrev logo" />
          </Link>

          {/* Navigation Links */}
          <nav className="laptop-design flex items-center space-x-6">
            <Link
              onClick={() => handleScroll("founders")}
              className="text-[var(--Primary-Text-Color)] text-sm 2xl:text-res-sm font-medium hover:text-primary transition-colors"
            >
              For Companies
            </Link>
            <Link
              onClick={() => handleScroll("investors")}
              className="text-[var(--Primary-Text-Color)] text-sm 2xl:text-res-sm font-medium hover:text-primary transition-colors"
            >
              For Investors
            </Link>
          </nav>

          {/* Auth Buttons */}
          <nav className="laptop-design flex items-center space-x-6">
            <Link
              className="text-[var(--Primary-Text-Color)] text-sm 2xl:text-res-sm font-medium hover:text-primary transition-colors"
              to="/signin"
            >
              Login
            </Link>
            <CustomBlackBtn
              text="Get Started"
              border="lg"
              variant="sm"
              style={{
                fontWeight: 500,
              }}
              onClick={() => Navigate("/signup")}
              additionalClasses="md:text-sm 2xl:h-[50px] 2xl:px-5 2xl:text-res-sm 2xl:px-7"
            />
          </nav>

          {/* Mobile Menu Icon */}
          <nav className="phone-design mx-2 my-2">
            <MenuIcon
              onClick={() => {
                setMenuOpen(true);
              }}
            />
          </nav>
        </div>
      </div>

      {/* Swipeable Drawer */}
      <SwipeableDrawer
        anchor="bottom"
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onOpen={() => setMenuOpen(true)}
        disableSwipeToOpen={false} // Allow swipe gestures
      >
        <div className="flex flex-col px-6 pt-2.5 pb-8 w-full bg-white">
          {/* Drag Handle */}
          <div className="flex self-center rounded-xl bg-zinc-900 bg-opacity-10 h-[5px] w-[110px]" />

          {/* Menu Items */}
          <div className="flex flex-col gap-[50px] w-full">
            <Link to={"/"}>
              <img
                loading="lazy"
                src={FundrevLogo}
                alt=""
                className="object-contain mt-6 aspect-[4.5] w-[90px]"
              />
            </Link>
            <div className="flex flex-col items-start">
              <button
                className="mt-[30px] first:mt-0"
                onClick={() => {
                  setMenuOpen(false);
                  handleScroll("founders");
                }}
              >
                For Companies
              </button>
              <button
                className="mt-[30px] first:mt-0"
                onClick={() => {
                  setMenuOpen(false);
                  handleScroll("investors");
                }}
              >
                For Investors
              </button>
              {!token && (
                <button
                  className="mt-[30px] first:mt-0"
                  onClick={() => {
                    Navigate("/signin");
                  }}
                >
                  Login
                </button>
              )}
            </div>

            {/* Get Started Button */}
            <div>
              {token ? (
                <CustomBlackBtn
                  text={"Logout"}
                  width="100%"
                  mode="danger"
                  border="sm"
                  filled={false}
                  onClick={handleLogout}
                />
              ) : (
                <CustomBlackBtn
                  text={
                    <>
                      <p>Get Started</p>
                      <img src={whiteArrow} alt="arrow" />
                    </>
                  }
                  width="100%"
                  mode="blue"
                  border="sm"
                  onClick={() => Navigate("/signup")}
                />
              )}

              <button
                className="text-[var(--Primary-Text-Color)] w-full mt-5 text-sm"
                onClick={() => {
                  setMenuOpen(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
}
