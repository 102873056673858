import React, { useEffect, useState } from "react";
import Topbar from "../../../Founders/Dashboard/Components/topbar";
import Sidebar from "../../../Common/components/sidebar";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { toggleButtonStyles } from "../../../Common/functions";
import Card from "../../../Common/components/Card";
import { useLocation } from "react-router-dom";
import {
  getDealDetailsByDealId,
  getDealIdbyStartupId,
} from "../../../../endpoints/deal";
import { getSecondaryDealById } from "../../../../endpoints/secondary";
import { useLoader } from "../../../Common/LoaderProvider";
import Profile from "../../../Common/components/company/profile";
import { getStartupDetails } from "../../../../endpoints/admin";
import { fetchStartupDetails } from "../../../../endpoints/startup";
const AdminProfilePreview = () => {
  const location = useLocation();
  const [cardType, setCardType] = useState("noNameCard");
  const [deal, setDeal] = useState({});
  const loader = useLoader();
  const handleCardTypeChange = (event, newCardType) => {
    if (newCardType !== null) setCardType(newCardType);
  };
  const { startupId, token, analystID, dealId, hideBtn, dealType } =
    location.state || {};
  const getDealDetails = async () => {
    try {
      let response;
      if (dealType === "secondary") {
        response = await getSecondaryDealById(dealId);
      } else {
        response = await getDealDetailsByDealId(dealId, token);
        console.log(response);
      }
      setDeal((prev) => {
        return {
          ...prev,
          ...response.data,
        };
      });
      loader.stop();
    } catch (error) {
      loader.stop();
    }
  };
  const getStartupData = async () => {
    if (dealType !== "secondary") {
      if (analystID) {
        try {
          const response = await getStartupDetails(startupId, token);
          const data = response?.data || false;
          if (data) {
            setDeal((prev) => {
              return {
                ...prev,
                ...data,
              };
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const response = await fetchStartupDetails(token);
          const data = response?.data?.data || false;
          if (data) {
            setDeal((prev) => ({
              ...prev,
              ...data,
            }));
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  useEffect(() => {
    if (dealId) getDealDetails();
  }, [dealId]);
  useEffect(() => {
    if (startupId) getStartupData();
  }, [startupId]);
  return (
    <>
      <Topbar title="Company profile" />
      <Sidebar userRole="company" />
      <div className="custom-container text-nowrap">
        <div className="gap-150 flex flex-col w-full h-[calc(100vh-71px-100px)] mt-[100px]">
          <ToggleButtonGroup
            color="primary"
            value={cardType}
            exclusive
            onChange={handleCardTypeChange}
            aria-label="card-type"
            sx={{
              backgroundColor: "#D2DCFF",
              padding: "4px 8px",
              borderRadius: "8px",
              width: "fit-content",
            }}
          >
            <ToggleButton value="noNameCard" sx={toggleButtonStyles}>
              No Name Card
            </ToggleButton>
            <ToggleButton value="detailedCard" sx={toggleButtonStyles}>
              Detailed Card
            </ToggleButton>
          </ToggleButtonGroup>

          {cardType === "noNameCard" && deal.dealId ? (
            <Card
              key={startupId}
              isNew={false}
              investorId={""}
              isStar={false}
              isPassed={true}
              deal={deal}
              stage={"initialStage"}
              investorVerified={true}
              profileCompleted={true}
              index={0}
              isRecommended={false}
              setWishlistDealIds={""}
            />
          ) : (
            <Profile deal={deal} />
          )}
        </div>
      </div>
    </>
  );
};

export default AdminProfilePreview;
