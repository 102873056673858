import React, { useState, useEffect, useRef } from "react";
import { getAllNoNameDeals } from "../../../endpoints/deal";
import Card from "../../Common/components/Card";
import Header from "../../LandingPage/sections/header";
import Footer from "../../LandingPage/Footer";
import NoDeal from "../../Investor/Dashboard/Opportunities/NoDeal";
import Toast from "../../Common/Toast";
import DealsFilterBox from "../../Common/components/DealsFilterBox";

import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "../../../CSS/InvestorPage/noNameDeals.css";
import { FiFilter } from "react-icons/fi";
import { useLoader } from "../../Common/LoaderProvider";
import { ShinyButton } from "../../Common/animations/shinyButton";
import frontSectionBg from "../../../Assets/Images/LandingPage/frontSectionBg.svg";
import { TopInvestors } from "../../LandingPage/sections/topInvestors";
import { MdFilterList } from "react-icons/md";

const NoNameDeals = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const elementRef = useRef(null);
  const [deals, setDeals] = useState([]);
  const token = localStorage.getItem("token");
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const loader = useLoader();
  async function getNoNameDeals() {
    try {
      loader.start();
      const data = await getAllNoNameDeals(token);
      setDeals(data.data);
      loader.stop();
    } catch (error) {
      Toast("Error getting NoNameDeals", "error", "noNameDeals");
      loader.stop();
    }
  }
  useEffect(() => {
    getNoNameDeals();
  }, [token]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 991) setFilterBoxOpen(false);
  }, [windowWidth]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getRequiredHeight = () => {
    const ele = document.getElementsByClassName("company-tags");
    const ele2 = document.getElementsByClassName("no-name-card");
    const box = document.getElementsByClassName("investor-noName-deals");
    const len = ele.length;
    if (len > 4) {
      const rect = ele[4].getBoundingClientRect();
      const rect2 = ele2[0].getBoundingClientRect();
      const height = rect.top - rect2.top;
      if (box.length > 0) {
        box[0].style.height = height + 240 + "px";
      }
    } else if (window.innerWidth < 768 && len > 0) {
      const cards = Array.from(document.getElementsByClassName("no-name-card"));
      let totalHeight = 0;
      if (cards.length > 0) {
        cards.forEach((card) => {
          totalHeight += card.offsetHeight;
        });
      }
      if (box.length > 0) {
        box[0].style.height = totalHeight + 50 + "px";
      }
    } else if (window.innerWidth < 768 && len === 0) {
      if (box.length > 0) {
        box[0].style.height = 0 + "px";
      }
    }
  };

  // Execute when the document is fully loaded
  window.onload = () => {
    getRequiredHeight();
  };
  useEffect(() => {
    getRequiredHeight();
  });
  return (
    <section
      className="open-listing-page"
      style={{
        backgroundImage: `url(${frontSectionBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      }}
    >
      <Helmet>
        <title>Investor | Deals</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="noNameDeals" />
      <Header />
      <div className="investor-noName-deals relative">
        <div>
          <div className="flex items-center justify-between">
            <p className="text-desktop-p-xl font-normal">
              Find highly qualified investments on Fundrev
            </p>

            <div
              className={`flex gap-2.5 justify-center items-center p-3 rounded border border-neutral-5 cursor-pointer ${
                filterBoxOpen ? "bg-neutral-4" : "bg-white hover:bg-neutral-3"
              }`}
              onClick={() => setFilterBoxOpen(!filterBoxOpen)}
              id="toggleButton"
            >
              <MdFilterList fontSize={"20px"} />
            </div>
            {/* <div className="mobile-stats">
              <button
                className="InvestorSide-content-button-1 mobile-stats"
                onClick={() => setFilterBoxOpen(!filterBoxOpen)}
                style={{ padding: "10px 20px", borderRadius: "10px" }}
              >
                <CiFilter />
              </button>
            </div> */}
          </div>

          <DealsFilterBox
            deals={deals}
            setFilteredDeals={setFilteredDeals}
            filterBoxOpen={filterBoxOpen}
            setFilterBoxOpen={setFilterBoxOpen}
            shouldUpdateUrl={true}
            className={"right-0"}
          />
        </div>
        <div className="min-[1180px]:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid gap-5 py-5 place-items-center">
          {filteredDeals && filteredDeals.length > 0 ? (
            filteredDeals.map((deal) => (
              <Card
                startPoint="noNameDeals"
                key={deal.dealId}
                isNew={false}
                deal={deal}
                elementRef={elementRef}
              />
            ))
          ) : windowWidth > 768 ? (
            <NoDeal />
          ) : (
            <></>
          )}
        </div>
      </div>
      <section className="scroll-mt-[70px]" ref={elementRef}>
        <div className="noName-signup-box flex overflow-hidden justify-center items-center px-14 py-14 bg-white border-solid backdrop-blur-[200px] max-md:px-6 border-t-[2px] border-[#000] border-opacity-40 ">
          <div className="flex flex-col justify-center items-center self-stretch my-auto min-w-[240px] w-[1191px]">
            <div className="flex flex-col w-full max-w-[1191px] max-md:max-w-full">
              <div className="max-md:max-w-full flex flex-wrap items-center w-full gap-6 mt-4">
                <TopInvestors page={"openListing"} />
              </div>
            </div>
            <div className="flex flex-col items-center mt-16 max-w-full text-base font-medium tracking-tighter text-white w-[783px] max-md:mt-5">
              <Link to="/investor/Register">
                {/* <ShimmerButtonDemo text="Join Now" /> */}

                <ShinyButton
                  color="#407BFF"
                  className="md:text-base text-base flex h-12 px-6 py-3 font-medium 2xl:px-[30px] 2xl:h-[60px] 2xl:py-[18px]"
                  text="text-base 2xl:text-res-base"
                >
                  <p>JOIN NOW</p>
                </ShinyButton>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div className="landing-page ">
        <Footer />
      </div>
    </section>
  );
};

export default NoNameDeals;
