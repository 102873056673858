import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { companyState, saveState } from "../companyState";
import ProfileBox from "./profileBox";
import { fetchStartupMemberDetails } from "../../../../endpoints/startup";

import { ClickAwayListener } from "@mui/material";

const Topbar = ({ title }) => {
  const token = localStorage.getItem("token");
  const [modal, setModal] = useState(false);
  const [state, setState] = useRecoilState(companyState);
  useEffect(() => {
    const fetchDetails = async () => {
      let response;
      if (token) {
        response = await fetchStartupMemberDetails(token);
      }
      if (token) {
        if (response && response.data !== null) {
          const newState = {
            ...state,
            startupId: response.data.data.startupId,
            startupMemberId: response.data.data.startupMemberID,
          };
          setState(newState);
          saveState(newState);
        } else {
        }
      }
    };

    fetchDetails();
  }, [token]);
  const handleClickAway = () => {
    setModal(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="topbardiv">
        <ProfileBox modal={modal} />
        <div className="topbardiv-2" style={{ paddingLeft: "16px" }}>
          {title}
        </div>
        <div className="topbardiv-3">
          <div className="topbar-utilities"></div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default Topbar;
