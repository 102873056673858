import React, { useEffect, useState } from "react";
import InvestorProfile from "../../../Common/components/investor/profile";
import Sidebar from "../../../Common/components/sidebar";
import Topbar from "../Components/topbar";
import EditInvestorProfileSubHeader from "./editInvestorProfileSubHeader";
import { useLoader } from "../../../Common/LoaderProvider";
import { fetchInvestorDetails } from "../../../../endpoints/investor";

const MyProfile = ({ investorID }) => {
  const loader = useLoader();
  const token = localStorage.getItem("token");
  const [investor, setInvestor] = useState();
  const [activeBtn, setActiveBtn] = useState(2);
  // useEffect(() => {
  //   if (firmData.profileCompleted === false) {
  //     navigate("/investor/account");
  //   }
  // }, [firmData]);
  const fetchDetails = async () => {
    if (!token) return;
    let response;
    try {
      loader.start("Fetching investor details...");
      response = await fetchInvestorDetails(token, investorID);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
    if (response?.data !== null) {
      const responseData = response.data;
      const { data } = responseData;
      setInvestor(data);
    } else {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchDetails();
  }, []);
  return (
    <>
      <Sidebar active="profile" userRole="investor" />
      {/* <Topbar title="Settings" /> */}
      <EditInvestorProfileSubHeader activeButton={activeBtn} />
      <div className="custom-container !h-[calc(100vh-70px)] py-300">
        {investor?.profileCompleted && (
          <InvestorProfile isCompany={false} investor={investor} />
        )}
      </div>
    </>
  );
};

export default MyProfile;
