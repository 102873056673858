import React, { useState } from "react";

import Sidebar from "../../../Common/components/sidebar";
import Topbar from "../Components/topbar";
import EditCompanyProfileSubheader from "./editCompanyProfileSubheader";
import EditCompanyDetails from "./editCompanyDetails";
import FoundingTeamProfile from "./foundingTeamProfile";
import EditDealTeam from "./editDealTem";

import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";

import "../../../../CSS/FounderPage/Dashboard/editCompanyProfile.css";

const EditCompanyProfile = () => {
  const [activeButton, setActiveButton] = useState(1);
  return (
    <div className="InvestorSide">
      <Helmet>
        <title>Fundrev | Company onboarding</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="editCompanyProfile" />
      <Sidebar active="profile" userRole="company" />
      <Topbar title="Edit Profile" />
      <div className="InvestorSide-box">
        <div className="InvestorSide-content">
          <EditCompanyProfileSubheader
            activeButton={activeButton}
            setActiveButton={setActiveButton}
          />
          {activeButton === 1 && <EditCompanyDetails />}
          {activeButton === 2 && <FoundingTeamProfile />}
          {activeButton === 3 && <EditDealTeam />}
        </div>
      </div>
    </div>
  );
};

export default EditCompanyProfile;
