import React, { useRef } from "react";
import { useTable } from "react-table";
import { RiDeleteBinFill } from "react-icons/ri";
import CustomBlackBtn from "./customBlackBtn";

const CustomEditableTable = ({ columns, data, setData }) => {
  const inputRefs = useRef({});

  const updateData = (rowIndex, columnId, value) => {
    setData((oldData) =>
      oldData.map((row, index) => {
        if (index === rowIndex) {
          const updatedRow = { ...row, [columnId]: value };

          if (columnId === "stake" || columnId === "raised") {
            const stake = parseFloat(updatedRow.stake) / 100;
            const raised = parseFloat(updatedRow.raised);

            updatedRow.valuation =
              !isNaN(raised) && !isNaN(stake) && stake > 0
                ? parseFloat((raised / stake).toFixed(2)).toString()
                : "";
          }

          return updatedRow;
        }
        return row;
      })
    );
  };

  const deleteRow = (rowIndex) => {
    setData((oldData) => {
      const newData = oldData.filter((_, index) => index !== rowIndex);
      if (newData.length === 0) {
        // If no rows remain, add an empty row
        const newRow = columns.reduce(
          (acc, column) => ({
            ...acc,
            [column.accessor]: "",
          }),
          {}
        );
        return [newRow];
      }
      return newData;
    });
  };

  const addRow = () => {
    const newRow = columns.reduce(
      (acc, column) => ({
        ...acc,
        [column.accessor]: "",
      }),
      {}
    );
    setData((oldData) => [...oldData, newRow]);
  };

  const columnsWithActions = React.useMemo(
    () => [
      ...columns.map((col, colIndex) => ({
        Header: col.Header,
        accessor: col.accessor,
        Cell: ({ value, row }) => {
          const cellKey = `${row.index}-${colIndex}`;
          if (!inputRefs.current[cellKey]) {
            inputRefs.current[cellKey] = React.createRef();
          }

          const handleKeyDown = (e) => {
            if (e.key === "Tab") {
              e.preventDefault();
              const isShiftPressed = e.shiftKey;
              const currentKey = `${row.index}-${colIndex}`;
              const allKeys = Object.keys(inputRefs.current);
              const currentIndex = allKeys.indexOf(currentKey);

              const nextIndex = isShiftPressed
                ? currentIndex - 1
                : currentIndex + 1;

              if (
                nextIndex >= 0 &&
                nextIndex < allKeys.length &&
                inputRefs.current[allKeys[nextIndex]]
              ) {
                inputRefs.current[allKeys[nextIndex]].current.focus();
              }
            }
          };

          const handleBlur = (e) => {
            updateData(row.index, col.accessor, e.target.value);
          };

          return (
            <input
              ref={inputRefs.current[cellKey]}
              type={col.type || "text"} // Use 'date' if column type is date
              defaultValue={
                col.type === "date" ? value?.split("T")[0] : value || ""
              }
              placeholder={col.placeholder || ""}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              className="focus:outline-none w-full bg-transparent"
              style={{
                width: col.width || "auto",
              }}
            />
          );
        },
      })),
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <button
            onClick={() => deleteRow(row.index)}
            className="flex items-center gap-1 text-[#FF5454]"
          >
            <RiDeleteBinFill /> Delete
          </button>
        ),
      },
    ],
    [columns, setData]
  );

  const tableInstance = useTable({ columns: columnsWithActions, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div>
      <div className="mb-200 overflow-hidden border border-b-0 border-gray-300 rounded">
        <table
          {...getTableProps()}
          className="w-full border-collapse table-fixed"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="bg-gray-100"
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`border-b border-r border-gray-300 text-left p-[10px] font-medium text-desktop-p-m ${
                      index === headerGroup.headers.length - 1
                        ? "border-r-0"
                        : ""
                    }`}
                    style={{
                      width: "auto",
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="hover:bg-gray-50 transition"
                >
                  {row.cells.map((cell, index) => (
                    <td
                      {...cell.getCellProps()}
                      className={`border-b border-r border-gray-300 p-[10px] text-desktop-p-m ${
                        index === row.cells.length - 1 ? "border-r-0" : ""
                      }`}
                      style={{
                        overflow: "auto",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <CustomBlackBtn
        text="+ Add row"
        variant="sm"
        filled={false}
        border="sm"
        onClick={addRow}
      />
    </div>
  );
};

export default CustomEditableTable;
