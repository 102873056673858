import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MobileRedirect from "../../../Assets/Images/signup/mobileRedirect.svg";
import Header from "../../LandingPage/sections/header";
import frontSectionBg from "../../../Assets/Images/LandingPage/frontSectionBg.svg";

const MobileRedirectPage = () => {
  const Navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) {
        Navigate(-1);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header color="black" type="founder" />
      <div
        className="mobileRedirect-section"
        style={{
          backgroundImage: `url(${frontSectionBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <img
          src={MobileRedirect}
          style={{
            width: "300px",
          }}
        />
        <div
          className="MobileRedrect-textbox"
          style={{
            width: "350px",
          }}
        >
          <p className="text-neutral-12 md:text-desktop-p-xl text-mobile-p-l mt-10 font-bold text-center">
            To ensure optimal performance and usability for the upcoming
            processes, please switch to the desktop view
          </p>
        </div>
      </div>
    </>
  );
};

export default MobileRedirectPage;
