import React, { forwardRef } from "react";
import { MdEdit } from "react-icons/md";
import Sort from "../../../Assets/Images/icons/sort.svg";

const SectionContainer = forwardRef(
  ({ children, title, action, className = "", actionFunction }, ref) => {
    return (
      <div
        ref={ref}
        className={`rounded-100 border-neutral-5 bg-neutral-1 bg-custom flex flex-col w-full border ${className}`}
      >
        <div className="border-neutral-5 rounded-t-100 bg-neutral-3 text-desktop-p-m flex items-center justify-between px-6 py-3 font-medium border-b">
          <div className="text-neutral-9">{title}</div>
          {action === "edit" && (
            <button
              className="text-brand-blue flex items-center gap-2"
              onClick={actionFunction}
            >
              <MdEdit />
              <span>Edit</span>
            </button>
          )}
          {action === "Filter" && (
            <button
              className="flex items-center gap-2"
              onClick={actionFunction}
              id="toggleButton"
            >
              <img src={Sort} alt={"sort"} />
            </button>
          )}
        </div>
        <div className="gap-300 flex flex-col p-6">{children}</div>
      </div>
    );
  }
);

export default SectionContainer;
