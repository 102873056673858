import React, { useState, useEffect } from "react";
import { downloadStartupDocumentsCSV } from "../../../endpoints/startup";
import { handleFileDownload } from "../../Common/functions";
import {
  extractTimestamp,
  fetchBlobName,
  fetchFullFileName,
} from "../../Common/functions";
import { fetchStartupDetailsById } from "../../../endpoints/startup";
import CustomTooltip from "../../Common/components/custom/customToolTip";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useLoader } from "../../Common/LoaderProvider";
import { getDownloadUrl } from "../../../endpoints/common";
import { RiFolderDownloadLine } from "react-icons/ri";
import dayjs from "dayjs";
import { ToastContainer } from "react-toastify";
import Toast from "../../Common/Toast";

import FileImg from "../../../Assets/Images/signup/file.svg";
import { GrDocumentCsv } from "react-icons/gr";
const CompanyUploadedFiles = () => {
  const loader = useLoader();
  const location = useLocation();
  const { startupId } = location.state || {};
  const token = localStorage.getItem("token");
  const [fileInfo, setFileInfo] = useState({});

  const stringFields = [
    "pitchDeck",
    "videoPitch",
    "financialDocuments",
    "invoiceDetails",
    "bankStatements",
    "corporateDocuments",
    "teamDetails",
    "productTechnology",
    "marketClientInfo",
    "legalComplianceDetails",
    "financialPlanning",
  ];

  const documentsDictionary = {
    pitchDeck: "Pitch Deck",
    videoPitch: "Video Pitch",
    financialDocuments: "Financial Documents",
    invoiceDetails: "Invoice Details",
    bankStatements: "Bank Statements",
    corporateDocuments: "Corporate Documents",
    teamDetails: "Team Details",
    productTechnology: "Product & Technology",
    marketClientInfo: "Market & Client Information",
    legalComplianceDetails: "Legal & Compliance Details",
    financialPlanning: "Financial Planning",
  };

  const fetchDetails = async () => {
    if (!startupId) return;
    let response = null;
    try {
      loader.start();
      response = await fetchStartupDetailsById(startupId, token);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
    if (response?.data !== null) {
      const data = response.data;
      const fields = stringFields.map((field) => data[field]);

      const newFileInfo = {};
      stringFields.forEach((field, index) => {
        if (fields[index]) {
          const urls = [];
          let latestTime = null;

          fields[index].length > 0 &&
            fields[index].forEach((file) => {
              const fileTime = extractTimestamp(file);
              const newFileInfoEntry = {
                filename: fetchBlobName(file),
                fullName: fetchFullFileName(file),
                size: "NA",
                fileURL: file,
                time: fileTime,
              };
              urls.push(newFileInfoEntry);

              // Update the latest time
              if (!latestTime || dayjs(fileTime).isAfter(latestTime)) {
                latestTime = dayjs(fileTime);
              }
            });

          newFileInfo[field] = {
            files: urls, // Store array of file info objects for each field
            latestTime: latestTime
              ? latestTime.format("DD MMM YYYY, HH:mm")
              : null, // Store the most recent time
          };
        }
      });
      setFileInfo((prevState) => ({
        ...prevState,
        ...newFileInfo,
      }));
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [startupId]);

  const handleZipDownload = async () => {
    const query = {
      downloadPath: "/startup/downloadStartupDocumentsZip",
      startupId: startupId,
    };
    try {
      loader.start("Preparing download...");
      const data = await getDownloadUrl(query, token);
      window.location.href = data?.downloadUrl;
      loader.stop();
    } catch (error) {
      loader.stop();
      Toast("Error in downloading files", "error", "companyUploadedFiles");
      console.error("Failed to download ZIP:", error.message);
    }
  };
  const handleCSVDownload = async () => {
    try {
      loader.start("Preparing download...");
      const data = await downloadStartupDocumentsCSV(startupId, token);
      // window.location.href = data?.downloadUrl;
      loader.stop();
    } catch (error) {
      loader.stop();
      Toast("Error in downloading files", "error", "companyUploadedFiles");
      console.error("Failed to download ZIP:", error.message);
    }
  };

  const isRecentlyUpdated = (fileTime) => {
    if (!fileTime) return false;
    const now = dayjs();
    const updatedTime = dayjs(fileTime);
    return now.diff(updatedTime, "hour") <= 3;
  };

  return (
    <div className="documents">
      <ToastContainer
        position="top-center"
        containerId={"companyUploadedFiles"}
      />
      <div className="flex items-center gap-4">
        <p className="investorDetails-subheading">Documents</p>
        <CustomTooltip title="Download Zip" position="bottom" theme="black">
          <div className="mt-[20px]">
            <RiFolderDownloadLine
              onClick={handleZipDownload}
              style={{
                fontSize: "30px",
                cursor: "pointer",
                border: "1px solid #000",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </div>
        </CustomTooltip>
        <CustomTooltip title="Download CSV" position="bottom" theme="black">
          <div className="mt-[20px]">
            <GrDocumentCsv
              onClick={handleCSVDownload}
              style={{
                fontSize: "30px",
                cursor: "pointer",
                border: "1px solid #000",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </div>
        </CustomTooltip>
      </div>
      <div className="Documents-box">
        {Object.keys(fileInfo).length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      width: "30%",
                      fontFamily: "karla,sans-serif",
                      fontWeight: 600,
                    }}
                  >
                    Document Type
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "20%",
                      fontFamily: "karla,sans-serif",
                      fontWeight: 600,
                    }}
                  >
                    Last Updated Time
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "50%",
                      fontFamily: "karla,sans-serif",
                      fontWeight: 600,
                    }}
                  >
                    Files
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stringFields.map((field) => {
                  if (fileInfo[field] && fileInfo[field].files.length > 0) {
                    const { files, latestTime } = fileInfo[field];
                    return (
                      <TableRow key={field}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ fontFamily: "Karla, sans-serif" }}
                        >
                          {documentsDictionary[field]}
                        </TableCell>
                        <TableCell>{latestTime || "NA"}</TableCell>
                        <TableCell>
                          <div className="pitch-review-files">
                            {files.map((file, fileIndex) => (
                              <div
                                key={fileIndex}
                                className="fileUploads"
                                style={{
                                  width: "fit-content",
                                  backgroundColor: isRecentlyUpdated(file.time)
                                    ? "#d1e7dd" // Highlighted background color
                                    : "transparent",
                                }}
                              >
                                <img
                                  src={FileImg}
                                  className="FileImg"
                                  alt="File Icon"
                                />
                                <div className="fileUploads-div-2">
                                  <div
                                    className="fileUploads-div-3"
                                    style={{
                                      width: "fit-content",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleFileDownload(file.fileURL)
                                    }
                                  >
                                    {file.filename}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div>No Documents Attached</div>
        )}
      </div>
    </div>
  );
};

export default CompanyUploadedFiles;
