import React, { useEffect } from "react";
import FinancialStatementSmallComparisonTable from "./AnalyticsPlots/FinancialStatementSmallComparisonTable";
import PlotTemplate from "./AnalyticsPlots/PlotTemplate";
import TopCustomerTable from "./AnalyticsPlots/TopCustomerTable";
import SmallCardGrid from "./AnalyticsPlots/SmallCardGrid";
import BalanceSheetSummaryTable from "./AnalyticsPlots/BalanceSheetSummaryTable";
import { dumpInvestorInteraction } from "../../endpoints/common";
import AnalyticsPageTitle from "./AnalyticsPlots/AnalyticsPageTitle";
import { isEmptyObject } from "jquery";
import { isEmptyArray } from "formik";
import { customerPlotsColors } from "../Common/functions";
import BankStatementPlot from "./AnalyticsPlots/bankStatementPlot";
import CashFlowSmallTable from "./AnalyticsPlots/CashFlowSmallTable";

const AnalyticsOverview = ({ deal, data, setAnalytics, stage, mapper }) => {
  const token = localStorage.getItem("token");
  useEffect(() => {
    const dumpInteraction = async () => {
      try {
        await dumpInvestorInteraction(
          {
            dealId: deal.dealId,
            type: "view",
            page: "analytics",
            stageOfDeal: stage,
            element: "page",
            pageSection: "summary",
          },
          token
        );
      } catch (error) {
        console.error("Error dumping interaction", error);
      }
    };
    dumpInteraction();
  }, []);
  return (
    <div>
      <SmallCardGrid data={mapper?.fiveCards} title={`Analytics Summary`} />

      <div>
        <AnalyticsPageTitle
          title={`Sales Analysis`}
          viewMore={true}
          handleClick={() => setAnalytics(`sales`)}
        />
        <div className="grid grid-cols-2 gap-250">
          {!isEmptyArray(data?.customerTapeData?.plotData?.revenueByCategory) &&
            mapper?.plots?.revenueByCategoryPlot?.isVisible && (
              <div className="bg-neutral-1 rounded-100 border border-neutral-5 p-200 h-[465px]">
                <PlotTemplate
                  data={{
                    ...mapper?.plots?.revenueByCategoryPlot,
                    data: {
                      xLabels: data?.customerTapeData?.plotData?.xLabels,
                      values:
                        data?.customerTapeData?.plotData?.revenueByCategory,
                    },
                  }}
                  colors={customerPlotsColors["revenueByCategory"]}
                />
              </div>
            )}
          {!isEmptyObject(data?.customerTapeData?.tableData) &&
            mapper?.tables?.topCustomerTable?.isVisible && (
              <div className="bg-neutral-1 rounded-100 border border-neutral-5 p-200">
                <TopCustomerTable
                  data={{
                    ...mapper?.tables?.topCustomerTable,
                    data: data?.customerTapeData?.tableData,
                  }}
                />
              </div>
            )}
          {!isEmptyObject(data?.customerTapeData?.customerGrowth) &&
            mapper?.plots?.activeCustomers?.isVisible && (
              <div className="bg-neutral-1 rounded-100 border h-[465px] border-neutral-5 p-200">
                <PlotTemplate
                  data={{
                    ...mapper?.plots?.activeCustomers,
                    data: {
                      xLabels: data?.customerTapeData?.plotData?.xLabels,
                      values: {
                        ...data?.customerTapeData?.customerGrowth,
                        data: { type: "bar" },
                      },
                    },
                  }}
                  colors={customerPlotsColors["revenue"]}
                />
              </div>
            )}
          <div></div>
        </div>
      </div>
      <div>
        <AnalyticsPageTitle
          title={`Financial Analysis`}
          viewMore={true}
          handleClick={() => setAnalytics(`financials`)}
        />
        <div className="gap-250 grid grid-cols-2">
          {mapper?.tables?.financialSmallTable?.isVisible &&
            !isEmptyObject(data?.financialData?.tableData || {}) && (
              <div className="bg-neutral-1 rounded-100 border border-neutral-5 p-200 h-[465px]">
                <FinancialStatementSmallComparisonTable
                  data={{
                    ...mapper?.tables?.financialSmallTable,
                    data: data?.financialData?.tableData,
                  }}
                />
              </div>
            )}
          {mapper?.tables?.balanceSheetSummary?.isVisible &&
            !isEmptyObject(data?.balanceSheetData || {}) && (
              <div className="bg-neutral-1 rounded-100 border border-neutral-5 p-200 h-[465px]">
                <BalanceSheetSummaryTable
                  data={{
                    ...mapper?.tables?.balanceSheetSummary,
                    data: data?.balanceSheetData,
                  }}
                />
              </div>
            )}
        </div>
      </div>
      <div>
        <AnalyticsPageTitle
          title={`Cash Flow & Liquidity`}
          handleClick={() => ""}
        />
        <div className="gap-250 grid grid-cols-2">
          <div className="bg-neutral-1 rounded-100 p-200 border border-neutral-5 h-[465px]">
            {data?.bankStatementData && (
              <BankStatementPlot
                data={{
                  ...mapper?.plots?.bankStatementPlot,
                  data: data?.bankStatementData,
                }}
              />
            )}
          </div>
          <div className="bg-neutral-1 rounded-100 border border-neutral-5 p-200 h-[465px]">
            {data?.cashFlowData && (
              <CashFlowSmallTable
                data={{
                  ...mapper?.tables?.cashFlowSummary,
                  data: data?.cashFlowData,
                }}
              />
            )}
          </div>
        </div>
      </div>
      {/* <div>
          <AnalyticsPageTitle
            title={`Business Forecast`}
            handleClick={() => ""}
          />
        </div> */}
    </div>
  );
};

export default AnalyticsOverview;
