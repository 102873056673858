import React, { useEffect, useState } from "react";
import Topbar from "../../Company/Deal/topbar";
import { Helmet } from "react-helmet";
import { getDealDetailsByDealId } from "../../../../endpoints/deal";
import Analytics from "../../../analytics/analytics";
import { useLocation } from "react-router-dom";
import Sidebar from "../../../Common/components/sidebar";
import Navbar from "../../../Common/components/navbar";

const AdminAnalytics = () => {
  const location = useLocation();
  const { dealId, startupId } = location.state;
  const [deal, setDeal] = useState(null);
  const token = localStorage.getItem("token");
  const [analytics, setAnalytics] = useState("summary");
  const handleChange = (analytics) => {
    setAnalytics(analytics);
  };
  const navItems = [
    {
      label: "Summary",
      isActive: analytics === "summary",
      onClick: () => handleChange("summary"),
    },
    {
      label: "Sales",
      isActive: analytics === "sales",
      onClick: () => handleChange("sales"),
    },
    {
      label: "Financials",
      isActive: analytics === "financials",
      onClick: () => handleChange("financials"),
    },
  ];
  useEffect(() => {
    const fetchDeal = async () => {
      const response = await getDealDetailsByDealId(dealId, token);
      setDeal(response.data);
    };
    fetchDeal();
  }, [dealId, token]);
  useEffect(() => {}, [deal]);
  return (
    <>
      <div className="InvestorSide">
        <Helmet>
          <title>{`${deal?.companyName} - Analytics`}</title>
        </Helmet>
        <Sidebar active="myProfile" />
        <Navbar navItems={navItems} title={`Analytics`} isBack={false} />

        <div
          className="custom-container h-[calc(100vh-70px)] overflow-y-scroll"
          style={{ overflowX: "hidden" }}
        >
          <div
            className="grid grid-cols-12 gap-200"
            style={{ overflowX: "hidden" }}
          >
            <div className="col-span-12">
              <div className="analytics-page">
                <Analytics
                  deal={deal}
                  analytics={analytics}
                  setAnalytics={setAnalytics}
                  startupId={startupId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAnalytics;
