import { useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({
  value,
  setValue,
  style = {},
  rows = 5,
  paddingNone = false,
  placeholder = "Enter details",
}) => {
  const quillRef = useRef(); // Reference to ReactQuill

  const handleChange = (content) => {
    setValue(content);
    adjustEditorHeight(); // Adjust height whenever content changes
  };
  const Link = Quill.import("formats/link");
  class CustomLink extends Link {
    static sanitize(url) {
      const sanitizedUrl = super.sanitize(url);
      if (sanitizedUrl) {
        // Ensure the URL starts with a valid protocol
        return sanitizedUrl.startsWith("http://") ||
          sanitizedUrl.startsWith("https://")
          ? sanitizedUrl
          : `https://${sanitizedUrl}`;
      }
      return sanitizedUrl;
    }
  }
  Quill.register(CustomLink, true);
  const adjustEditorHeight = () => {
    // Access the .ql-editor element inside ReactQuill
    const editor = quillRef.current?.getEditor?.();
    const editorElement = editor?.root; // .ql-editor is the root of the editor content
    if (editorElement) {
      const contentHeight = editorElement.scrollHeight; // Content height
      const minHeight = 20 * rows;
      let calculatedHeight;
      if (contentHeight < minHeight) {
        calculatedHeight = minHeight;
      } else {
        calculatedHeight = Math.min(contentHeight, minHeight);
      }
      editorElement.style.minHeight = `${calculatedHeight}px`; // Apply height dynamically
    }
  };
  const removePadding = () => {
    const editor = quillRef.current?.getEditor?.();
    const editorElement = editor?.root; // .ql-editor is the root of the editor content
    if (editorElement) {
      editorElement.style.paddingBottom = `2px`;
    }
  };
  if (paddingNone) {
    removePadding();
  }
  useEffect(() => {
    adjustEditorHeight(); // Adjust height on initial load
  }, [value]); // Adjust height whenever the value changes
  return (
    <>
      <style>
        {`
          /* Custom styles for the ReactQuill container */
          .custom-text-editor .ql-container {
            font-family: Arial, sans-serif; /* Change font */
            font-size: 14px; /* Adjust font size */
            color: #333; /* Text color */
            border-radius: 0px 0px 6px 6px; /* Rounded corners */
            border: 1px solid #8692A6; /* Border styling */
            overflow-y: auto; /* Allow scrolling if content exceeds height */
          }

          .custom-text-editor .ql-toolbar {
            background-color: #fff; /* Toolbar background */
            border-radius: 6px 6px 0 0; /* Rounded corners for toolbar */
            border: 1px solid #8692A6; /* Border styling */
          }

          .custom-text-editor .ql-toolbar .ql-picker-label {
            color: #555; /* Adjust dropdown text color */
          }

          .custom-text-editor .ql-toolbar button {
            border: none; /* Remove button borders */
          }

          .custom-text-editor .ql-toolbar button:hover {
            background-color: #e6e6e6; /* Hover effect */
          }
          .custom-text-editor .ql-editor::before {
            font-style:normal;
            color: var(--Secondary-Text-Color); /* Change placeholder text color */
            font-size: 14px; /* Adjust font size */
            pointer-events: none; /* Prevent interaction with the placeholder */
            font-weight:300;
          }
        `}
      </style>
      <ReactQuill
        ref={quillRef}
        className="custom-text-editor"
        theme="snow"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        style={{
          ...style,
        }}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            ["clean"],
          ],
        }}
      />
    </>
  );
};

export default TextEditor;
