import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomTooltip = styled(
  ({ className, position, arrow = true, offset = [0, 0], ...props }) => (
    <Tooltip
      {...props}
      placement={position}
      classes={{ popper: className }}
      arrow={arrow} // Conditionally include the arrow
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset, // Set the offset
            },
          },
        ],
      }}
    />
  )
)(
  ({
    theme,
    fontSize = "14px",
    fontWeight = "500",
    paddingX = "10px",
    display = "block",
  }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: theme === "black" ? "#002132" : "#fff",
      color: theme === "black" ? "#fff" : "#002132",
      fontFamily: "Roboto",
      fontSize: fontSize,
      fontWeight: fontWeight,
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: paddingX,
      paddingRight: paddingX,
      borderRadius: "4px",
      border: theme === "white" && "1px solid #D9D9D9",
      boxShadow: theme === "white" && "var(--box-shadow-1)",
      display: display,
    },
    [`& .MuiTooltip-arrow`]: {
      color: theme === "black" ? "#002132" : "#fff",
    },
  })
);

export default CustomTooltip;
