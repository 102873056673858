import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import StayTuned from "../../../Assets/Images/InvestorPage/Dashboard/stayTuned.svg";
import { useLoader } from "../LoaderProvider";
import { getDummyAnalytics } from "../../../endpoints/common";
import { Helmet } from "react-helmet";
import AnalyticsOverview from "../../analytics/analyticsOverview";
import HistoricalFinancials from "../../analytics/historicalFinancials";
import BusinessAnalytics from "../../analytics/businessAnalytics";
import Sidebar from "./sidebar";
import Navbar from "./navbar";

const DummyAnalytics = () => {
  const [loading, setLoading] = useState(true);
  const [analytics, setAnalytics] = useState(`summary`);
  const [error, setError] = useState(null);
  const loader = useLoader();
  const [analyticsData, setAnalyticsData] = useState(null);
  useEffect(() => {
    getAnalytics();
  }, []);

  const getAnalytics = async () => {
    try {
      const response = await getDummyAnalytics();
      setAnalyticsData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      loader.stop();
      setLoading(false);
    }
  };
  if (loading) {
    loader.start("Fetching data...");
  }

  const handleChange = (analytics) => {
    setAnalytics(analytics);
  };

  if (error) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <p
              style={{
                font: "Karla, sans-serif",
                fontSize: "20px",
                marginBottom: "40px",
              }}
            >
              No analytics available at the moment. Data is being processed.
              Please try again soon.
            </p>
            <img src={StayTuned} style={{ width: "500px" }} alt="stayTuned" />
          </Box>
        </Box>
      </Container>
    );
  }
  const navItems = [
    {
      label: "Summary",
      isActive: analytics === "summary",
      onClick: () => handleChange("summary"),
    },
    {
      label: "Sales",
      isActive: analytics === "sales",
      onClick: () => handleChange("sales"),
    },
    {
      label: "Financials",
      isActive: analytics === "financials",
      onClick: () => handleChange("financials"),
    },
  ];
  return (
    <>
      <Sidebar userRole="company" active={`analytics`} />
      <Navbar navItems={navItems} title={`Analytics`} isBack={false} />
      <div
        className="custom-container h-[calc(100vh-70px)] overflow-y-scroll"
        style={{ overflowX: "hidden" }}
      >
        {/* <div style={{ pointerEvents: "none" }}></div> */}
        <div
          className="grid grid-cols-12 gap-200"
          style={{ overflowX: "hidden" }}
        >
          <div className="col-span-12">
            <div className="analytics-page">
              <Helmet>
                <title>{`Startup | Sample - Analytics`}</title>
              </Helmet>
              <section>
                <div>
                  {analytics === "summary" && analyticsData?.summary && (
                    <AnalyticsOverview
                      data={analyticsData?.data}
                      setAnalytics={setAnalytics}
                      mapper={analyticsData?.summary}
                    />
                  )}
                  {analytics === "sales" && analyticsData?.sales && (
                    <BusinessAnalytics
                      data={analyticsData?.data}
                      mapper={analyticsData?.sales}
                    />
                  )}
                  {analytics === "financials" && analyticsData?.financials && (
                    <HistoricalFinancials
                      data={analyticsData?.data}
                      mapper={analyticsData?.financials}
                    />
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DummyAnalytics;
