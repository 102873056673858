import React, { useState, useEffect } from "react";

import Sidebar from "../../../Common/components/sidebar";
import Topbar from "../Components/topbar";
import SubHeader from "../Components/subHeader";
import DealsFilterBox from "../../../Common/components/DealsFilterBox";
import { useLoader } from "../../../Common/LoaderProvider";
import DealsBox from "./dealsBox";
import NoRecommendedDeals from "../Components/noRecommendedDeals";

import { fetchInvestorDetails } from "../../../../endpoints/investor";
import {
  getAllNoNameDeals,
  getDealsByInvestorID,
  getNoNameDeals,
  getPassedDeals,
} from "../../../../endpoints/deal";

import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { investorState, saveState } from "../investorState";
import "../../../../CSS/InvestorPage/Dashboard/style.css";
import {
  getAllSecondaryDeals,
  getOpenSecondaryDeals,
} from "../../../../endpoints/secondary";
import { ToastContainer } from "react-toastify";
import Navbar from "../../../Common/components/navbar";

const NewDeals = () => {
  const Navigate = useNavigate();
  const loader = useLoader();
  const { type } = useParams();
  const [details, setDetails] = useState(null);
  const token = localStorage.getItem("token");
  const [idealFit, setIdealFit] = useState([]);
  const [moderateFit, setModerateFit] = useState([]);
  const [nonFit, setNonFit] = useState([]);
  const [otherDeals, setOtherDeals] = useState([]);
  const [allFocusOptions, setAllFocusOptions] = useState([]);
  const [state, setState] = useRecoilState(investorState);
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const [passedDeals, setPassedDeals] = useState([]);
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [interactedDeals, setInteractedDeals] = useState([]);
  const [investorID, setInvestorID] = useState();
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [wishlistDealIds, setWishlistDealIds] = useState([]);
  const [wishlistDeals, setWishlistDeals] = useState([]);
  const [noNameDeals, setNoNameDeals] = useState([]);
  const [primaryDeals, setPrimaryDeals] = useState([]);
  const [secondaryDeals, setSecondaryDeals] = useState([]);
  const [recommendedOnly, SetRecommendedOnly] = useState(false);

  const [startups, setStartups] = useState([]);
  const fetchDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetchInvestorDetails(token);
      setDetails(response.data.data);
      setWishlistDealIds(response.data.data.wishlistDeals);
      setProfileCompleted(response.data.data.profileCompleted);
      const newState = {
        ...state,
        investorId: response.data.data.investorID,
      };
      setInvestorID(response.data.data.investorID);
      setState(newState);
      saveState(newState);
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [token]);
  useEffect(() => {
    const getInteractedDeals = async () => {
      try {
        const response = await getDealsByInvestorID(investorID, token);
        setInteractedDeals(response.data);
      } catch (error) {
        loader.stop();
      }
    };
    if (investorID) {
      loader.start("Fetching deals...");
      getInteractedDeals();
    }
  }, [investorID, token]);
  const fetchAllStartupsAndCategorize = async () => {
    let filterDeals = [];
    try {
      const response = await getNoNameDeals(investorID, token);
      const response2 = await getAllSecondaryDeals(investorID, token);
      loader.stop();
      let allDeals = [];
      if (response.data) {
        allDeals = allDeals.concat(response.data);
      }
      if (response2.data) {
        allDeals = allDeals.concat(response2.data);
      }
      if (allDeals.length > 0) {
        for (let index = 0; index < allDeals.length; index++) {
          let element = allDeals[index];
          if (interactedDeals.some((deal) => deal.dealId === element.dealId)) {
          } else {
            element = { ...element, isRecommended: isRecommended(element) };
            filterDeals.push(element);
          }
        }
        setStartups(filterDeals);
        const validResponses = filterDeals.filter((response) =>
          wishlistDealIds.includes(response.dealId)
        );
        setWishlistDeals(validResponses);
      }
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    if (investorID) {
      fetchAllStartupsAndCategorize();
    }
  }, [interactedDeals?.length, wishlistDealIds, idealFit?.length]);

  useEffect(() => {}, [details]);
  const categorizeStartups = () => {
    const ideal = [];
    const moderate = [];
    const non = [];
    if (
      details &&
      details.idealDealIds &&
      details.moderateDealIds &&
      details.nonFitDealIds
    ) {
      // Extract deal IDs from details
      const idealDealIds = details.idealDealIds;
      const moderateDealIds = details.moderateDealIds;
      const nonFitDealIds = details.nonFitDealIds;

      // Categorize startups
      startups &&
        startups.length > 0 &&
        startups.forEach((startup) => {
          //
          if (idealDealIds.includes(startup.dealId)) {
            ideal.push(startup);
          } else if (moderateDealIds.includes(startup.dealId)) {
            moderate.push(startup);
          } else if (nonFitDealIds.includes(startup.dealId)) {
            non.push(startup);
          }
        });
    } else {
      console.error("Details object does not contain the required fields.");
    }

    // Set state variables
    setIdealFit(ideal);
    setModerateFit(moderate);
    setNonFit(non);
    setOtherDeals(moderate.concat(non));
    setAllFocusOptions([...ideal, ...moderate, ...non]);
  };
  useEffect(() => {
    if (details && startups?.length > 0) categorizeStartups();
  }, [startups?.length, details]);

  const isRecommended = (deal) => {
    if (idealFit?.length > 0) {
      return idealFit.some(
        (recommendedDeal) => recommendedDeal.dealId === deal.dealId
      );
    } else {
      return false;
    }
  };

  //Necessary if someone choose some filters
  useEffect(() => {}, [filteredDeals, type]);
  async function getOpenListingDeals() {
    try {
      //loader is not used in this page because there is one gif which will not be properly shown due to loader
      // loader.start();
      const data = await getAllNoNameDeals(token);
      const response = await getOpenSecondaryDeals(token);
      let deals = [];
      if (data.data) {
        deals = deals.concat(data.data);
      }
      if (response.data) {
        deals = deals.concat(response.data);
      }
      if (idealFit?.length > 0) {
        idealFit.forEach((deal) => {
          if (!deals.some((d) => d.dealId === deal.dealId)) {
            deals.push(deal);
          }
        });
      }
      let filterDeals = [];
      const primary = [];
      const secondary = [];
      for (let index = 0; index < deals.length; index++) {
        let element = deals[index];
        if (interactedDeals.some((deal) => deal.dealId === element.dealId)) {
        } else {
          element = { ...element, isRecommended: isRecommended(element) };
          if (element?.dealType === "secondary") {
            secondary.push(element);
          } else {
            primary.push(element);
          }
          filterDeals.push(element);
        }
      }
      setNoNameDeals(filterDeals);
      setPrimaryDeals(primary);
      setSecondaryDeals(secondary);
      loader.stop();
    } catch (error) {
      // Toast("Error getting NoNameDeals", "error", "noNameDeals");
      loader.stop();
    }
  }
  useEffect(() => {
    if (idealFit) getOpenListingDeals();
  }, [token, interactedDeals?.length, idealFit?.length]);
  useEffect(() => {
    const fetchPassedDeals = async () => {
      try {
        const response = await getPassedDeals(investorID, token);
        if (response.data !== null) {
          setPassedDeals(response.data);
        }
      } catch (error) {}
    };
    if (investorID) {
      fetchPassedDeals();
    }
  }, [investorID]);
  function dealsLength(fitType) {
    try {
      // Do not show even count of deals if his profile is not completed yet
      if (
        fitType !== noNameDeals &&
        fitType !== wishlistDeals &&
        !profileCompleted
      ) {
        return "00";
      }
      const length = fitType.length ? fitType.length : 0;
      return length.toString().padStart(2, "0");
    } catch (error) {
      return "00";
    }
  }
  //!Deals get updated in deals Filter box
  const renderDeals = () => {
    switch (type) {
      case "recommended":
        return (
          <>
            {profileCompleted ? (
              <DealsBox
                allDeals={idealFit}
                recommendedDeals={idealFit}
                filterDeals={filteredDeals}
                details={details}
                title="Recommended deals"
                setWishlistDealIds={setWishlistDealIds}
                stage="initialStage"
                SetRecommendedOnly={SetRecommendedOnly}
                recommendedOnly={recommendedOnly}
                wishlistDealIds={wishlistDealIds}
              />
            ) : (
              <NoRecommendedDeals />
            )}
          </>
        );
      case "primary":
        return (
          <>
            {profileCompleted ? (
              <DealsBox
                allDeals={primaryDeals}
                recommendedDeals={idealFit}
                filterDeals={filteredDeals}
                details={details}
                title="Primary deals"
                setWishlistDealIds={setWishlistDealIds}
                stage="initialStage"
                wishlistDealIds={wishlistDealIds}
                SetRecommendedOnly={SetRecommendedOnly}
                recommendedOnly={recommendedOnly}
              />
            ) : (
              <NoRecommendedDeals />
            )}
          </>
        );
      case "secondary":
        return (
          <>
            {profileCompleted ? (
              <DealsBox
                allDeals={secondaryDeals}
                recommendedDeals={idealFit}
                filterDeals={filteredDeals}
                details={details}
                title="Secondary deals"
                setWishlistDealIds={setWishlistDealIds}
                stage="initialStage"
                recommendedOnly={recommendedOnly}
                SetRecommendedOnly={SetRecommendedOnly}
                wishlistDealIds={wishlistDealIds}
              />
            ) : (
              <NoRecommendedDeals />
            )}
          </>
        );
      case "otherDeals":
        return (
          <>
            {profileCompleted ? (
              <DealsBox
                recommendedDeals={idealFit}
                allDeals={otherDeals}
                filterDeals={filteredDeals}
                details={details}
                title="Other deals"
                setWishlistDealIds={setWishlistDealIds}
                stage="initialStage"
                recommendedOnly={recommendedOnly}
                SetRecommendedOnly={SetRecommendedOnly}
                wishlistDealIds={wishlistDealIds}
              />
            ) : (
              <NoRecommendedDeals />
            )}
          </>
        );
      case "allListing":
        return (
          <>
            <DealsBox
              allDeals={noNameDeals}
              recommendedDeals={idealFit}
              filterDeals={filteredDeals}
              details={details}
              title="Open Listing"
              setWishlistDealIds={setWishlistDealIds}
              stage="initialStage"
              recommendedOnly={recommendedOnly}
              SetRecommendedOnly={SetRecommendedOnly}
              wishlistDealIds={wishlistDealIds}
            />
          </>
        );
      case "wishlist":
        return (
          <>
            <DealsBox
              allDeals={wishlistDeals}
              recommendedDeals={idealFit}
              filterDeals={filteredDeals}
              details={details}
              title="Wishlist"
              setWishlistDealIds={setWishlistDealIds}
              stage="initialStage"
              recommendedOnly={recommendedOnly}
              SetRecommendedOnly={SetRecommendedOnly}
              wishlistDealIds={wishlistDealIds}
            />
          </>
        );
      //Only deals will show here that are passed at initial stages
      case "archive":
        return (
          <>
            {profileCompleted ? (
              <DealsBox
                allDeals={passedDeals}
                recommendedDeals={idealFit}
                filterDeals={filteredDeals}
                details={details}
                title="Archive"
                stage="initialStage"
                recommendedOnly={recommendedOnly}
                SetRecommendedOnly={SetRecommendedOnly}
                wishlistDealIds={wishlistDealIds}
                isPassed={true}
              />
            ) : (
              <NoRecommendedDeals />
            )}
          </>
        );
      default:
        return (
          <>
            {profileCompleted ? (
              <DealsBox
                allDeals={[]}
                filterDeals={[]}
                recommendedDeals={[]}
                details={details}
                title="deals"
                fetchDetails={fetchDetails}
                fetchAllStartupsAndCategorize={fetchAllStartupsAndCategorize}
                stage="initialStage"
                recommendedOnly={recommendedOnly}
                SetRecommendedOnly={SetRecommendedOnly}
                wishlistDealIds={wishlistDealIds}
              />
            ) : (
              <NoRecommendedDeals />
            )}
          </>
        );
    }
  };
  useEffect(() => {
    if (!type) {
      Navigate("/investor/newDeals/allListing");
    } else if (
      !["allListing", "primary", "secondary", "wishlist", "archive"].includes(
        type
      )
    ) {
      Navigate("/investor/newDeals/allListing");
    }
  });
  const navItems = [
    {
      label: "",
      isActive: false,
      onClick: () => "",
    },
  ];
  return (
    <div className="InvestorSide newDeals-page">
      <Sidebar active="opportunities" userRole="investor" />
      {/* <Topbar title="Opportunities" /> */}
      <Navbar navItems={[]} title={"New Deals"} isBack={false} />
      <ToastContainer containerId={"newDeals"} position="top-center" />
      <div className="w-[1081px] max-w-full mx-auto">
        <SubHeader
          recommendedDealsCount={dealsLength(idealFit)}
          wishListCount={dealsLength(wishlistDeals)}
          noNameDealCount={dealsLength(noNameDeals)}
          otherDealsCount={dealsLength(otherDeals)}
          archiveDealsCount={dealsLength(passedDeals)}
          primaryDealsCount={dealsLength(primaryDeals)}
          secondaryDealsCount={dealsLength(secondaryDeals)}
          filterBoxOpen={filterBoxOpen}
          setFilterBoxOpen={setFilterBoxOpen}
          recommendedOnly={recommendedOnly}
          SetRecommendedOnly={SetRecommendedOnly}
        />
      </div>
      <div className="!mt-[0px] !overflow-y-scroll !h-[calc(100vh-140px)] pb-[20px]">
        <div className="InvestorSide-content relative">
          <DealsFilterBox
            deals={
              type === "recommended"
                ? idealFit
                : type === "otherDeals"
                ? otherDeals
                : type === "allListing"
                ? noNameDeals
                : type === "wishlist"
                ? wishlistDeals
                : type === "archive"
                ? passedDeals
                : type === "primary"
                ? primaryDeals
                : type === "secondary"
                ? secondaryDeals
                : noNameDeals
            }
            filterBoxOpen={filterBoxOpen}
            setFilterBoxOpen={setFilterBoxOpen}
            setFilteredDeals={setFilteredDeals}
            shouldUpdateUrl={false}
            recommendedOnly={recommendedOnly}
            className={"top-[-20px] right-48"}
          />
          <div className="w-[1081px] mx-auto">{renderDeals()}</div>
        </div>
      </div>
    </div>
  );
};

export default NewDeals;
