import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  investorState,
  saveState as investorSaveState,
  useInvestorLogout,
} from "../../Investor/Dashboard/investorState";
import {
  companyState,
  saveState as companySaveState,
  useCompanyLogout,
} from "../../Founders/Dashboard/companyState";
import {
  fetchInvestorMemberDetails,
  getProfileCompletionPercentage as investorProfilePercentage,
} from "../../../endpoints/investor";

import {
  fetchStartupMemberDetails,
  getProfileCompletionPercentage as companyProfilePercentage,
} from "../../../endpoints/startup";

import fundrevLogoSmall from "../../../Assets/Images/InvestorPage/Dashboard/fundrevLogoSmall.svg";
import verifiedIcon from "../../../Assets/Images/InvestorPage/Dashboard/verified.svg";
import featureRequestSidebar from "../../../Assets/Images/InvestorPage/Dashboard/featureRequestSidebar.svg";

import sidebarOpen from "../../../Assets/Images/icons/sidebar/sidebarOpen.svg";
import sidebarClose from "../../../Assets/Images/icons/sidebar/sidebarClose.svg";
import newDeals from "../../../Assets/Images/icons/sidebar/newDeals.svg";
import dealFlow from "../../../Assets/Images/icons/sidebar/dealFlow.svg";
import ongoingDeals from "../../../Assets/Images/icons/sidebar/ongoingDeals.svg";
import deadDeals from "../../../Assets/Images/icons/sidebar/deadDeals.svg";
import watchlistDeals from "../../../Assets/Images/icons/sidebar/watchlistDeals.svg";
import investorIcon from "../../../Assets/Images/icons/sidebar/investorProfile.svg";
import overview from "../../../Assets/Images/icons/sidebar/overview.svg";
import fundraising from "../../../Assets/Images/icons/sidebar/fundraising.svg";
import activeInvestors from "../../../Assets/Images/icons/sidebar/activeInvestors.svg";
import inactiveInvestors from "../../../Assets/Images/icons/sidebar/inactiveInvestors.svg";
import watchlistInvestors from "../../../Assets/Images/icons/sidebar/watchlistInvestors.svg";

import account from "../../../Assets/Images/icons/sidebar/account.svg";
import logoutIcon from "../../../Assets/Images/icons/sidebar/logout.svg";
// import investors from "../../../Assets/Images/icons/sidebar/investors.svg";
// import analytics from "../../../Assets/Images/icons/sidebar/analytics.svg";

import userProfile from "../../../Assets/Images/InvestorPage/Dashboard/userProfile.svg";
import fundrevBlackText from "../../../Assets/Images/icons/fundrevBlackText.svg";
import Dialog from "@mui/material/Dialog";

import "../../../CSS/InvestorPage/Dashboard/newSidebar.css";
import { FaAngleRight } from "react-icons/fa6";
import CustomTooltip from "./custom/customToolTip";
import { Box, Modal } from "@mui/joy";
import CustomBlackBtn from "./custom/customBlackBtn";
import CustomDialog from "./custom/customDialog";

const Sidebar = ({ active, type, userRole = "company" }) => {
  const sidebarRef = useRef(null);
  const Navigate = useNavigate();
  const companyLogout = useCompanyLogout();
  const investorLogout = useInvestorLogout();

  const logout = userRole === "company" ? companyLogout : investorLogout;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFullyExpanded, setIsFullyExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isDealFlowOpen, setIsDealFlowOpen] = useState(false);
  const [state, setState] = useRecoilState(
    userRole === "investor" ? investorState : companyState
  );
  const [verified, setVerified] = useState(false);
  const [percentComplete, setPercentComplete] = useState(undefined);
  const [dealsModal, setDealsModal] = useState(false);
  const [fundraisingModal, setFundraisingModal] = useState(false);
  const [accountModal, setAccountModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const [expandedMenuId, setExpandedMenuId] = useState(null);

  const toggleMenu = (id) => {
    setExpandedMenuId(expandedMenuId === id ? null : id);
  };

  const handleSidebarClose = () => {
    setExpandedMenuId(null); // Reset all open menu items
    setIsExpanded(false); // Close the sidebar
  };
  const handleLogout = () => {
    logout();
    localStorage.removeItem("token");
    localStorage.removeItem("onBoardingComplete");
    localStorage.removeItem("role");
    Navigate("/signin", {
      state: {
        type: userRole === "investor" ? "Investor" : "Startup",
      },
    });
  };
  const [profile, setProfile] = useState("");
  useEffect(() => {
    const fetchDetails = async () => {
      if (token) {
        let response;
        if (userRole === "investor") {
          response = await fetchInvestorMemberDetails(token);
          if (response && response.data !== null) {
            const { name, memberImg, investorID, investorMemberID } =
              response.data.data;
            const newState = {
              ...state,
              name,
              memberImg,
              investorId: investorID,
              investorMemberId: investorMemberID,
            };
            setProfile(memberImg);
            setState(newState);
            investorSaveState(newState);
          }
        } else {
          response = await fetchStartupMemberDetails(token);
          if (response && response.data !== null) {
            const { name, memberImg, startupId, startupMemberID } =
              response.data.data;
            const newState = {
              ...state,
              startupId,
              name,
              memberImg,
              startupMemberID: startupMemberID,
            };
            setProfile(memberImg);
            setState(newState);
            companySaveState(newState);
          }
        }
      }
    };

    fetchDetails();
  }, [token]);

  useEffect(() => {
    const activeTab = document.getElementById(active);
    if (activeTab) {
      activeTab.classList.add("activeTab");
    }
  }, [active]);
  useEffect(() => {
    if (isExpanded) {
      const timer = setTimeout(() => setIsFullyExpanded(true), 100);
      return () => clearTimeout(timer);
    } else {
      setIsFullyExpanded(false);
    }
  }, [isExpanded]);

  const profileCompletionPercentage = async () => {
    try {
      let response;
      if (userRole === "investor") {
        response = await investorProfilePercentage(token);
      } else {
        response = await companyProfilePercentage(token);
      }
      if (response && response.data) {
        const percent = response.data.percentage;
        setPercentComplete(percent);
        setVerified(response.data.verified);
        const newState = {
          ...state,
          percentComplete: percent,
          verified: response.data.verified,
        };
        setState(newState);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    profileCompletionPercentage();
  }, []);
  useEffect(() => {
    if (active === "ongoingDeals") {
      setIsDealFlowOpen(true);
    }
  }, [active]);
  const openInNewTab = () => {
    const a = document.createElement("a");
    a.href = "https://fundrev.frill.co";
    a.target = "_blank";
    a.rel = "noopener noreferrer"; // Ensure security
    a.click();
  };
  const menuItems = {
    investor: [
      {
        id: "opportunities",
        img: newDeals,
        label: "New Deals",
        closedAction: () => Navigate("/investor/newDeals"),
        openAction: () => Navigate("/investor/newDeals"),
      },
      {
        id: "ongoingDeals",
        img: dealFlow,
        label: "Deal Flow",
        children: [
          {
            label: "Current Deals",
            img: ongoingDeals,
            openAction: () => Navigate("/investor/ongoingDeals/current"),
            isActive: active === "ongoingDeals" && type === "current",
          },
          {
            label: "Dead Deals",
            img: deadDeals,
            openAction: () => Navigate("/investor/ongoingDeals/dead"),
            isActive: active === "ongoingDeals" && type === "dead",
          },
          {
            label: "Watchlist",
            img: watchlistDeals,
            openAction: () => Navigate("/investor/ongoingDeals/watchlist"),
            isActive: active === "ongoingDeals" && type === "watchlist",
          },
        ],
        closedAction: () => setDealsModal(true),
        openAction: () => {
          toggleMenu("ongoingDeals");
        },
      },
      {
        id: "profile",
        img: investorIcon,
        label: "Profile",
        profilePercentage: percentComplete,
        isVerified: verified,
        closedAction: () => Navigate("/investor/profile"),
        openAction: () => Navigate("/investor/profile"),
      },
    ],
    company: [
      {
        id: "overview",
        img: overview,
        label: "Overview",
        closedAction: () => Navigate("/company/dashboard"),
        openAction: () => Navigate("/company/dashboard"),
      },

      {
        id: "fundraising",
        img: fundraising,
        label: "Fundraising",
        children: [
          {
            label: "Active investors",
            img: activeInvestors,
            openAction: () => Navigate("/company/ongoingDeals/current"),
            isActive: active === "ongoingDeals" && type === "current",
          },
          {
            label: "Inactive Investors",
            img: inactiveInvestors,
            openAction: () => Navigate("/company/ongoingDeals/dead"),
            isActive: active === "ongoingDeals" && type === "dead",
          },
          {
            label: "Watchlist Investors",
            img: watchlistInvestors,
            openAction: () => Navigate("/company/ongoingDeals/watchlist"),
            isActive: active === "ongoingDeals" && type === "watchlist",
          },
        ],
        closedAction: () => setFundraisingModal(true),
        openAction: () => {
          toggleMenu("fundraising");
        },
      },
      // {
      //   id: "investors",
      //   img: investors,
      //   label: "Investors",
      //   profilePercentage: percentComplete,
      //   isVerified: verified,
      //   closedAction: () => Navigate("/company/ongoingDeals"),
      //   openAction: () => {
      //     Navigate("/company/ongoingDeals");
      //   },
      // },
      // {
      //   id: "analytics",
      //   img: analytics,
      //   label: "Analytics",
      //   closedAction: () => Navigate("/investor/newDeals"),
      //   openAction: () => Navigate("/investor/newDeals"),
      // },
      {
        id: "profile",
        img: investorIcon,
        label: "Company Profile",
        closedAction: () => Navigate("/company/editProfile"),
        openAction: () => Navigate("/company/editProfile"),
        profilePercentage: percentComplete,
        isVerified: verified,
      },
    ],
  };
  const featureRequestItems = [
    {
      id: "featureRequest",
      img: featureRequestSidebar,
      label: "Feature Request",
      closedAction: openInNewTab,
      openAction: openInNewTab,
    },
  ];
  const dealsItems = [
    {
      id: "current",
      img: ongoingDeals,
      label: "Current Deals",
      openAction: () => {
        Navigate("/investor/ongoingDeals/current");
        setDealsModal(false);
      },
    },
    {
      id: "dead",
      img: deadDeals,
      label: "Dead Deals",
      openAction: () => {
        Navigate("/investor/ongoingDeals/dead");
        setDealsModal(false);
      },
    },
    {
      id: "watchlist",
      img: watchlistDeals,
      label: "Watchlist",
      openAction: () => {
        Navigate("/investor/ongoingDeals/watchlist");
        setDealsModal(false);
      },
    },
  ];
  const fundraisingItems = [
    {
      id: "current",
      img: activeInvestors,
      label: "Active Investors",
      openAction: () => {
        Navigate("/company/ongoingDeals/current");
        setFundraisingModal(false);
      },
    },
    {
      id: "dead",
      img: inactiveInvestors,
      label: "Inactive Investors",
      openAction: () => {
        Navigate("/company/ongoingDeals/dead");
        setFundraisingModal(false);
      },
    },
    {
      id: "watchlist",
      img: watchlistInvestors,
      label: "Watchlist Investors",
      openAction: () => {
        Navigate("/company/ongoingDeals/watchlist");
        setFundraisingModal(false);
      },
    },
  ];
  const accountItems = [
    {
      id: "account",
      img: account,
      label: "Account",
      openAction:
        userRole === "investor"
          ? () => Navigate("/investor/account")
          : () => Navigate("/company/account"),
    },
    {
      id: "logout",
      img: logoutIcon,
      label: "Logout",
      openAction: () => {
        setAccountModal(false);
        setLogoutModal(true);
      },
    },
  ];
  const ParentItem = ({ item, isFullyExpanded, isExpanded }) => {
    return (
      <div className="w-full">
        <div
          className={`flex cursor-pointer items-center h-[48px] w-full py-0.5 gap-4 text-base font-semibold text-neutral-12 transition-all duration-300`}
        >
          {/* type === item.id for dealItems */}
          <div
            className={`mx-2 p-2 ${
              active === item.id || type === item.id
                ? "border-r bg-[#d2dcff]"
                : "bg-white hover:bg-[#F4F6FF]"
            } transition-all duration-300 rounded w-full`}
            onClick={isExpanded ? item.openAction : item.closedAction}
          >
            <div className="flex items-center gap-4">
              {/* Icon */}
              {isExpanded ? (
                <div
                  style={{
                    width: "24px",
                    height: "24px",
                    backgroundColor: `${
                      active === item.id
                        ? "var(--Primary-Text-Color)"
                        : "#696F79"
                    }`,
                    mask: `url(${item.img}) no-repeat center`,
                  }}
                />
              ) : (
                <CustomTooltip
                  title={item.label}
                  position="right"
                  theme="white"
                  arrow={false}
                  offset={[0, 15]}
                >
                  <div
                    style={{
                      width: "24px",
                      height: "24px",
                      backgroundColor: `${
                        active === item.id
                          ? "var(--Primary-Text-Color)"
                          : "#696F79"
                      }`,
                      mask: `url(${item.img}) no-repeat center`,
                    }}
                  />
                </CustomTooltip>
              )}

              {/* Label */}
              <span
                className={`absolute text-desktop-p-mb left-16 text-neutral-12 whitespace-nowrap overflow-hidden transition-opacity duration-300 ${
                  isFullyExpanded
                    ? "opacity-100 visible"
                    : "opacity-0 invisible "
                } ${active === item.id ? "font-semibold" : "font-medium"}`}
              >
                {item.label}
              </span>

              {item.children && isExpanded && (
                <span
                  className={`absolute right-6 ml-auto transform transition-all duration-500 ${
                    expandedMenuId === item.id ? "rotate-90" : ""
                  } ${
                    isFullyExpanded
                      ? "opacity-100 visible"
                      : "opacity-0 invisible"
                  }`}
                >
                  <FaAngleRight />
                </span>
              )}
              {item.id === "profile" &&
                isExpanded &&
                (item.isVerified ? (
                  <img
                    src={verifiedIcon}
                    alt="Verified"
                    className={`right-4 absolute transition-opacity duration-300 ${
                      isFullyExpanded
                        ? "opacity-100 visible"
                        : "opacity-0 invisible"
                    }`}
                  />
                ) : (
                  <p
                    className="right-4 absolute px-2 py-0.5 bg-[#FF8D8D] rounded-2xl text-[10px]"
                    style={{
                      backgroundColor:
                        item.profilePercentage < 50
                          ? "#FF8D8D"
                          : item.profilePercentage < 100
                          ? "#FFDD8D"
                          : "#A2FF8D",
                    }}
                  >
                    {item.profilePercentage}%
                  </p>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setExpandedMenuId(null);
        setTimeout(() => setIsExpanded(false), [200]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Dialog
        open={userRole === "investor" ? dealsModal : fundraisingModal}
        onClose={
          userRole === "investor"
            ? () => setDealsModal(false)
            : () => setFundraisingModal(false)
        }
        BackdropProps={{
          style: {
            display: "none", // Prevents the backdrop from being displayed
          },
        }}
        PaperProps={{
          component: "form",
          sx: {
            borderRadius: "8px",
            position: "absolute", // Make dialog position absolute
            top: "10%", // Center vertically
            left: "3%", // Center horizontally
            padding: "4px 2px",
            width: "200px",
            boxShadow: "var(--box-shadow-1)",
            border: "1px solid var(--border)",
          },
        }}
      >
        <div>
          {(userRole === "investor" ? dealsItems : fundraisingItems).map(
            (item) => (
              <ParentItem
                key={item.id}
                item={item}
                isExpanded
                isFullyExpanded
              />
            )
          )}
        </div>
      </Dialog>
      <Dialog
        open={accountModal}
        onClose={() => setAccountModal(false)}
        BackdropProps={{
          style: {
            display: "none", // Prevents the backdrop from being displayed
          },
        }}
        PaperProps={{
          component: "form",
          sx: {
            borderRadius: "8px",
            position: "absolute",
            bottom: "0%",
            left: "3%",
            padding: "4px 2px",
            width: "150px",
            boxShadow: "var(--box-shadow-1)",
            border: "1px solid var(--border)",
          },
        }}
      >
        <div>
          {accountItems.map((item) => (
            <ParentItem
              key={item.id}
              item={item}
              isExpanded={true}
              isFullyExpanded={true}
            />
          ))}
        </div>
      </Dialog>
      <CustomDialog open={logoutModal} onClose={() => setLogoutModal(false)}>
        <div className="p-500 flex flex-col gap-8">
          <p className="text-desktop-p-l font-bold">
            Are you sure you want to logout?
          </p>
          <div className="gap-150 flex justify-center">
            <CustomBlackBtn
              text={"Cancel"}
              onClick={() => setLogoutModal(false)}
              filled={false}
              border="sm"
            />
            <CustomBlackBtn
              text={"Logout"}
              onClick={handleLogout}
              border="sm"
              mode="danger"
            />
          </div>
        </div>
      </CustomDialog>
      {/* <Modal
        open={logoutModal}
        onClose={() => setLogoutModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
      </Modal> */}
      <div
        ref={sidebarRef}
        className={`absolute top-0 z-[70] flex h-full ${
          isExpanded ? "w-[240px]" : "w-[56px]"
        } flex-col font-karla font-normal transition-all duration-300 ease-in-out shadow-lg`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="relative h-full w-full flex flex-col bg-white shadow-[--box-shadow-1]">
          {/* Logo and Collapse Button */}
          <div className=" bg-white h-[60px] px-5 border-neutral-5 border-b-[1px]">
            <div className="flex items-center justify-between w-full h-full">
              <Link to="/investor/newDeals" className="flex items-center gap-4">
                <img
                  loading="lazy"
                  src={fundrevLogoSmall}
                  className="cursor-pointer"
                />
                {/* Text beside logo when expanded */}
                {isExpanded && (
                  <img alt="Fundrev" src={fundrevBlackText} className="" />
                )}
              </Link>
              {isExpanded && (
                <img
                  src={sidebarClose}
                  alt="open"
                  className={`transition-all duration-300 cursor-pointer ${
                    isFullyExpanded
                      ? "opacity-100 visible"
                      : "opacity-0 invisible"
                  }`}
                  onClick={handleSidebarClose}
                />
              )}
            </div>
          </div>

          {/* Expand Button (middle of the sidebar, only when collapsed) */}
          {/* {!isExpanded && isHovered && (
            <img
              src={sidebarOpen}
              alt="open"
              className="absolute right-[-15px] top-1/2 -translate-y-1/2 transition-all duration-300 cursor-pointer"
             
            />
          )} */}

          {/* Sidebar Menu Items */}
          <div className="flex flex-col justify-between h-[calc(100%-74px)]">
            <div className="flex flex-col justify-start w-full pt-5">
              {menuItems[userRole].map((item) => (
                <div key={item.id} className="w-full">
                  <ParentItem
                    key={item.id}
                    item={item}
                    isExpanded={isExpanded}
                    isFullyExpanded={isFullyExpanded}
                  />

                  {item.children && (
                    <div
                      className={`ml-6 overflow-hidden transition-all duration-500 ${
                        expandedMenuId === item.id ? "max-h-screen" : "max-h-0"
                      }`}
                      style={{ transitionTimingFunction: "ease-in-out" }}
                    >
                      {item.children.map((child, index) => (
                        <div
                          key={index}
                          className={`flex cursor-pointer items-center h-[48px] w-full px-2 py-0.5 gap-4 text-base font-semibold text-neutral-12 transition-all duration-300`}
                          onClick={child.openAction}
                        >
                          <div
                            className={` ${
                              child.isActive
                                ? "border-r bg-[#d2dcff]"
                                : "bg-white hover:bg-[#F4F6FF]"
                            } transition-all duration-300 rounded w-full py-100 pl-100 flex items-center`}
                          >
                            <div className="flex items-center gap-4">
                              <div
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  backgroundColor: `${
                                    active === item.id
                                      ? "var(--Primary-Text-Color)"
                                      : "#696F79"
                                  }`,
                                  mask: `url(${child.img}) no-repeat center`,
                                }}
                              />

                              {isExpanded && (
                                <span className="text-neutral-12 whitespace-nowrap text-desktop-p-mb overflow-hidden font-medium">
                                  {child.label}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex flex-col justify-start w-full">
              {!isExpanded && (
                <CustomTooltip
                  title={"Toggle Sidebar"}
                  position="right"
                  theme="white"
                  arrow={false}
                  offset={[0, 15]}
                >
                  <img
                    src={sidebarOpen}
                    className={`w-[40px] p-2 cursor-pointer rounded-050 ml-[8px] mb-1 ${
                      isHovered && "bg-blue-50"
                    } `}
                    alt="Toggle Sidebar"
                    onClick={() => setIsExpanded(true)}
                  />
                </CustomTooltip>
              )}
              {featureRequestItems.map((item) => (
                <ParentItem
                  key={item.id}
                  item={item}
                  isExpanded={isExpanded}
                  isFullyExpanded={isFullyExpanded}
                />
              ))}
              <div className="flex cursor-pointer items-center h-[64px] w-full m-auto pl-2 py-0.5 gap-4 text-base font-semibold text-neutral-12 transition-all duration-300">
                {/* <CornerProfileBox modal={modal} setModal={setModal} /> */}
                <div
                  className=" flex items-center gap-4"
                  onClick={() => setAccountModal(!accountModal)}
                >
                  <img
                    loading="lazy"
                    srcSet={profile ? profile : userProfile}
                    className="sidebar-profile self-start object-cover object-center w-10 h-10 rounded-full"
                  />
                  {isExpanded && (
                    <div>
                      <p className="text-neutral-12 whitespace-nowrap text-base font-medium">
                        {state.name || "User"}
                      </p>
                      <p className="text-neutral-8 text-xs">{role || "role"}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
