import React, { useEffect, useState } from "react";
import Toast from "../../../Common/Toast";
import Profile from "../../../Common/components/company/profile";
import Navbar from "../../../Common/components/navbar";
import Sidebar from "../../../Common/components/sidebar";
import ScheduleMeeting from "./scheduleMeeting";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import PassDealModal from "../../../Common/modals/passDealModal";
import { CustomDialogWithContent } from "../../../Common/components/custom/customDialog";
import { useLoader } from "../../../Common/LoaderProvider";
import { navItems } from "./navItems";

import { getSecondaryDealById } from "../../../../endpoints/secondary";
import { getScheduleMeetingByDealId } from "../../../../endpoints/investor";
import { dumpInvestorInteraction } from "../../../../endpoints/common";
import {
  getAllDealInteractions,
  getDealByStage,
  postStageData,
} from "../../../../endpoints/deal";

import { investorState } from "../investorState";
import { Modal, Box } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

import closeIcon from "../../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import illustration1 from "../../../../Assets/Images/InvestorPage/Dashboard/illustration1.svg";
import illustration2 from "../../../../Assets/Images/InvestorPage/Dashboard/illustration2.svg";
import illustration3 from "../../../../Assets/Images/InvestorPage/Dashboard/illustration3.svg";

const Overview = ({}) => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const navigate = useNavigate();
  const { dealId } = useParams();
  const { firstVisit } = location.state || {};
  const [stage, setStage] = useState("interested");
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [acceptanceModal, setAcceptanceModal] = useState(false);
  const [firstVisitModalOpen, setFirstVisitModalOpen] = useState(firstVisit);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [scheduleMeetingModal, setScheduleMeetingModal] = useState(false);
  const [requestAccessModalOpen, setRequestAccessModalOpen] = useState(false);
  const { investorId } = useRecoilValue(investorState);
  const [deal, setDeal] = useState("");
  const [meetingScheduled, setMeetingScheduled] = useState("Not Scheduled");
  const fetchAllInteractions = async () => {
    try {
      const response = await getAllDealInteractions(
        deal?.dealId,
        investorId,
        token
      );
      loader.stop();
      if (response.data) {
        const lastResponse = response.data[response.data.length - 1];
        setStage(lastResponse.Stage);
      }
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    if (deal && investorId) fetchAllInteractions();
  }, [deal, investorId]);
  useEffect(() => {
    const dumpInteraction = async () => {
      try {
        await dumpInvestorInteraction(
          {
            dealId: dealId,
            type: "view",
            page: "companyOverview",
            stageOfDeal: stage,
            element: "page",
          },
          token
        );
      } catch (error) {
        console.error("Error dumping interaction", error);
      }
    };
    dumpInteraction();
  }, []);
  const handleRequestToStartup = async () => {
    const updatedFormData = {
      dealId: dealId,
      investorID: investorId,
      IsInterested: true,
      Stage: "requestSent",
      dealType: deal?.dealType,
    };
    let response;
    try {
      loader.start("Sending request to startup...");
      response = await postStageData(
        updatedFormData,
        token,
        deal?.dealType === "secondary"
      );
      loader.stop();
      if (response?.error?.status === 403) {
        Toast(
          "You do not have access to send the request",
          "error",
          "companyDetailsBeforeNDASigned"
        );
        loader.stop();
        setStage("interested");
        setAcceptanceModal(false);
        return;
      } else {
        loader.stop();
        setStage("requestSent");
      }
    } catch (error) {
      setStage("interested");
      loader.stop();
    }
    setAcceptanceModal(false);
  };
  function handleCheckProfileComplete(type) {
    if (type === "reason") {
      setOpenReasonModal(true);
    } else {
      setAcceptanceModal(true);
    }
  }
  useEffect(() => {
    const fetchDealDetails = async () => {
      let response;
      try {
        loader.start("Fetching deal details");
        if (dealId.slice(0, 1) === "S") {
          response = await getSecondaryDealById(dealId, token, investorId);
        } else {
          response = await getDealByStage(dealId, token);
        }
        loader.stop();
        if (response.data) {
          setDeal(response.data);
        } else {
          Toast(
            "Error fetching deal details. Please try again later",
            "error",
            "companyDetailsShowInterest"
          );
        }
      } catch (error) {
        Toast(
          "Error fetching deal details. Please try again later",
          "error",
          "companyDetailsShowInterest"
        );
      }
    };
    if (dealId) {
      fetchDealDetails();
    }
  }, [dealId, investorId]);

  const checkMeetingScheduled = async () => {
    try {
      if (investorId && dealId) {
        const response = await getScheduleMeetingByDealId(investorId, dealId);
        if (response?.data?.timings) {
          console.log(response.data.timings);
          if (dayjs().isAfter(dayjs(response.data.timings.start))) {
            setMeetingScheduled("Passed");
          } else {
            setMeetingScheduled("Scheduled");
          }
        } else {
          setMeetingScheduled("Not Scheduled");
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (dealId && investorId) checkMeetingScheduled();
  }, [dealId, investorId, scheduleMeetingModal]);
  return (
    <div>
      <Modal
        open={acceptanceModal}
        onClose={() => setAcceptanceModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box reason-box"
          style={{ width: "600px", height: "fit-content" }}
        >
          <img
            className="reason-box-closeicon"
            src={closeIcon}
            style={{
              cursor: "pointer",
              position: "fixed",
              right: "20px",
              top: "20px",
            }}
            alt="close"
            onClick={() => setAcceptanceModal(false)}
          ></img>
          <div className="text-box">
            <div className="heading">
              <p className="heading">Send Request</p>
            </div>
            <div className="subheading">
              <p>Do you want to proceed with sending the request?</p>
            </div>
          </div>

          <div className="btn-box">
            <CustomBlackBtn
              text={`Cancel`}
              filled={false}
              onClick={() => setAcceptanceModal(false)}
            />
            <CustomBlackBtn
              text={`Send Request`}
              onClick={handleRequestToStartup}
            />
          </div>
        </Box>
      </Modal>
      <PassDealModal
        openReasonModal={openReasonModal}
        setOpenReasonModal={setOpenReasonModal}
        stage={"initialStage"}
        dealId={dealId}
        investorID={investorId}
        type={stage === "requestSent" ? "withdraw" : null}
        toastContainer={"companyDetailsShowInterest"}
        dealType={deal?.dealType}
      />
      <CustomDialogWithContent
        open={firstVisitModalOpen}
        onClose={() => setFirstVisitModalOpen(false)}
        heading={"Deal Added to Your Ongoing Deals"}
        primaryAction={() => setFirstVisitModalOpen(false)}
        primaryActionText={"Got it"}
        subheading={
          "You can now track and manage this deal in the Ongoing Deals section.Stay updated and take the next steps to move forward."
        }
        illustration={illustration1}
      />
      <CustomDialogWithContent
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        heading={"Confirm Your Next Steps"}
        subheading={
          "Please confirm if you've already connected with this company or a Fundrev analyst regarding this deal, or proceed to schedule a call."
        }
        secondaryActionText={"I've already connected!"}
        secondaryAction={() => {
          setRequestAccessModalOpen(true);
          setConfirmModalOpen(false);
        }}
        primaryActionText={"Schedule a Call"}
        primaryAction={() => {
          setScheduleMeetingModal(true);
          setConfirmModalOpen(false);
        }}
        illustration={illustration2}
      />
      <CustomDialogWithContent
        open={requestAccessModalOpen}
        onClose={() => setRequestAccessModalOpen(false)}
        heading={"Request Access to Data Room and Analytics"}
        subheading={
          meetingScheduled === "Passed"
            ? "You can now request access to the data room and analytics for further evaluation."
            : `Since you've already connected with ${deal.companyName}, you can now request access to the data room and analytics for further evaluation.`
        }
        secondaryActionText={"Cancel"}
        secondaryAction={() => setRequestAccessModalOpen(false)}
        primaryAction={() => {
          handleRequestToStartup();
          setRequestAccessModalOpen(false);
        }}
        primaryActionText={"Request Access"}
        illustration={illustration3}
      />

      <Navbar
        navItems={navItems(navigate, dealId, stage)}
        title={"Company Profile"}
      >
        <ScheduleMeeting
          deal={deal}
          modal={scheduleMeetingModal}
          setModal={setScheduleMeetingModal}
          stage={stage}
        />
        <CustomBlackBtn
          text={
            stage === "interested" ? (
              <p className="text-desktop-p-s">Not Interested</p>
            ) : (
              <p className="text-desktop-p-s">Withdraw Request</p>
            )
          }
          mode="danger"
          variant="sm"
          border="sm"
          filled={false}
          additionalClasses="px-300"
          onClick={() => handleCheckProfileComplete("reason")}
        />
        {stage === "interested" && meetingScheduled === "Not Scheduled" && (
          <CustomBlackBtn
            text="Get Connected"
            mode="blue"
            variant="sm"
            border="sm"
            additionalClasses="px-300"
            onClick={() => setConfirmModalOpen(true)}
          />
        )}
        {stage === "interested" && meetingScheduled === "Scheduled" && (
          <CustomBlackBtn
            text="Meeting Scheduled"
            disabled
            variant="sm"
            border="sm"
            additionalClasses="px-300"
            onClick={() => setScheduleMeetingModal(true)}
          />
        )}
        {stage === "interested" && meetingScheduled === "Passed" && (
          <CustomBlackBtn
            text="Request Access"
            variant="sm"
            border="sm"
            additionalClasses="px-300"
            mode="blue"
            onClick={() => setRequestAccessModalOpen(true)}
          />
        )}
        {stage === "requestSent" && (
          <CustomBlackBtn
            text="Access Request Sent"
            disabled
            variant="sm"
            border="sm"
            additionalClasses="px-300"
            onClick={() => setScheduleMeetingModal(true)}
          />
        )}
      </Navbar>
      <Sidebar userRole="investor" type={"current"} active={"ongoingDeals"} />
      <div className="custom-container pt-1 mx-auto">
        <div className="gap-150 flex flex-col w-full h-[calc(100vh-80px)]">
          <Profile deal={deal} passedStage={stage} dealType={deal?.dealType} />
        </div>
      </div>
    </div>
  );
};

export default Overview;
