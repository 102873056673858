import React, { useState, useEffect } from "react";
import FrontSectionCarousel from "./frontSectionCarousel";
import { WordFadeIn } from "../../Common/animations/wordFadeIn";
import { NumberTicker } from "../../Common/animations/numberTicker";
import { ShinyButton } from "../../Common/animations/shinyButton";

import { useNavigate } from "react-router-dom";

import whiteArrow from "../../../Assets/Images/icons/whiteArrow.svg";
import blackGradientW from "../../../Assets/Images/LandingPage/blackGradientW.png";

import "../../../CSS/LandingPage/front.css";
const stats = [
  {
    value: 400,
    label: "in Deal pipeline",
    color: "text-white",
    secondColor: "text-white",
    border: "border border-solid border-neutral-300",
    startDecorator: "Rs. ",
    endDecorator: "+ Cr",
    backgroundImage: `url(${blackGradientW})`,
    width: "w-[200px] md:w-[170px] 2xl:w-[210px]",
  },

  {
    value: 30,
    label: "Active Deals",
    background: "bg-white",
    color: "text-var(--Primary-Text-Color)",
    secondColor: "text-[#646464]",
    border: "border border-solid border-neutral-300",
    endDecorator: "+",
    width: "w-[170px] md:w-[140px] 2xl:w-[170px]",
  },
  {
    value: 50,
    label: "Investors Network",
    background: "bg-white",
    color: "text-var(--Primary-Text-Color)",
    secondColor: "text-[#646464]",
    border: "border border-solid border-neutral-300",
    endDecorator: "+",
    width: "w-[170px] md:w-[140px] 2xl:w-[170px]",
  },
];

const StatCard = ({
  background,
  backgroundImage,
  border,
  value,
  label,
  color,
  secondColor,
  startDecorator,
  endDecorator,
  width,
}) => {
  return (
    <div
      style={{
        backgroundImage: backgroundImage,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      }}
      className={`${width} h-[79px] flex justify-center flex-col items-start px-5 py-5 rounded-lg ${background} ${border} ${color}`}
    >
      <div>
        {/* <div className=" text-xl font-bold">{value}</div> */}
        <NumberTicker
          value={value}
          className={"2xl:text-[25px] text-xl font-bold "}
          startDecorator={startDecorator}
          endDecorator={endDecorator}
        />
        <div className={`mt-2 text-xs ${secondColor}`}>{label}</div>
      </div>
    </div>
  );
};
function Front() {
  const Navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const heading =
    windowWidth > 991
      ? "The premier network for private markets, streamlining deal-making and investor management. Fundraise, connect with investors, explore M&A opportunities, and accelerate deals like never before. Say goodbye to information asymmetry in private markets—welcome to Fundrev!"
      : "The premier private markets platform to fundraise, manage investors, explore M&A, and accelerate deals—welcome to Fundrev!";
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="2xl:mt-[40px] flex lg:flex-col items-center  px-16 pt-24 2xl:pt-[12vh] pb-20 w-full max-md:px-5 max-md:pt-24 background: url('../../../Assets/Images/LandingPage/frontSectionBg.svg')">
      <div className="flex gap-10 lg:gap-[150px] 2xl:gap-[50px] w-full lg:flex-row flex-col">
        <div className="flex flex-col w-[90%] m-auto lg:w-[57%] ">
          <div className="flex flex-col mt-3.5 w-full max-md:mt-10 max-md:max-w-full items-center lg:items-start ">
            <WordFadeIn
              className="text-[32px] md:text-[40px] lg:text-h1 2xl:text-res-h1 font-medium text-[var(--Primary-Text-Color)] max-md:mr-2.5 max-md:max-w-full max-md:text-4xl lg:text-left text-center"
              words={"Capital Stack for Private Markets from Idea to IPO"}
              wholeSentence={true}
              delay={0.2}
            />

            <p className="mt-[30px] lg:mt-[42px] 2xl:text-xl text-sm lg:text-base text-[#646464] max-md:mt-10 max-md:max-w-full lg:text-left text-center">
              {heading}
            </p>
            {/* <CustomBlackBtn
              text={
                <>
                  <p>Get Started</p>
                  <img src={whiteArrow} alt="arrow" />
                </>
              }
              mode="blue"
              border="sm"
              style={{ fontWeight: 500, marginTop: "49px" }}
              onClick={() => Navigate("/signup")}
              additionalClasses="2xl:px-[30px] 2xl:h-[60px]  2xl:text-res-base"
            /> */}
            <ShinyButton
              upperCase={false}
              color="#407BFF"
              className="md:text-base text-base flex h-12 px-6 py-3 font-medium mt-[49px] 2xl:px-[30px] 2xl:h-[60px] 2xl:py-[18px]"
              onClick={() => Navigate("/signup")}
              text="text-base 2xl:text-res-base"
            >
              <div className="flex gap-2">
                <p>Get Started</p>
                <img src={whiteArrow} alt="arrow" />
              </div>
            </ShinyButton>
            <div className="max-md:mt-10 laptop-design flex flex-wrap justify-center max-w-full gap-3 mt-16">
              {stats.map((stat, index) => (
                <StatCard key={index} {...stat} />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-full lg:w-[600px] 2xl:w-[600px] max-md:ml-0 max-md:w-full">
          <FrontSectionCarousel />
        </div>
        <div className="max-md:mt-10 phone-design flex flex-wrap justify-center max-w-full gap-3 mt-16">
          {stats.map((stat, index) => (
            <StatCard key={index} {...stat} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Front;
