import React, { useState } from "react";
import UploadFiles from "../components/uploadFiles";
import { MdLockOutline } from "react-icons/md";

const AnalyticsPreparation = ({
  fetchDetails,
  validationSchema,
  setSchema,
  setIsUploading,
  uploadFileData,
  prepareFormData,
}) => {
  const [lastHoveredIndex, setLastHoveredIndex] = useState(null);
  return (
    <div className="AnalyticsPreparation fundrev-card onboardingForm-card relative">
      <p className="heading">Let's Prepare Your Analytics</p>
      <p className="subheading">
        Upload documents from the past 3 years or since your company's inception
      </p>
      <p className="flex flex-row gap-[8px] text-[14px] color-[#696F79] bg-zinc-50 p-[4px] rounded-[4px] items-center justify-center absolute top-[16px] right-[16px]">
        <MdLockOutline />
        Your info is secured with us
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <UploadFiles
          filename="Financial Documents"
          name="financialDocuments"
          filesArray={"financialDocumentsFiles"}
          documentsRequiredText="Your income statement, Balance sheet, and cash flow statement OR trial balance goes here"
          index={1}
          lastHoveredIndex={lastHoveredIndex}
          setLastHoveredIndex={setLastHoveredIndex}
          fetchDetails={fetchDetails}
          setSchema={setSchema}
          validationSchema={validationSchema}
          setIsUploading={setIsUploading}
          uploadFileData={uploadFileData}
          prepareFormData={prepareFormData}
        />
        <UploadFiles
          filename="Invoice Details"
          name="invoiceDetails"
          filesArray={"invoiceDetailsFiles"}
          documentsRequiredText="Revenue details include Invoice Date, Customer ID, Product ID, Sale Price, Cost, etc."
          index={2}
          lastHoveredIndex={lastHoveredIndex}
          setLastHoveredIndex={setLastHoveredIndex}
          fetchDetails={fetchDetails}
          setSchema={setSchema}
          validationSchema={validationSchema}
          setIsUploading={setIsUploading}
          uploadFileData={uploadFileData}
          prepareFormData={prepareFormData}
        />
        <UploadFiles
          filename="Bank Statements"
          name="bankStatements"
          filesArray={"bankStatementsFiles"}
          documentsRequiredText="Upload company bank statement here"
          index={3}
          lastHoveredIndex={lastHoveredIndex}
          setLastHoveredIndex={setLastHoveredIndex}
          fetchDetails={fetchDetails}
          setSchema={setSchema}
          validationSchema={validationSchema}
          setIsUploading={setIsUploading}
          uploadFileData={uploadFileData}
          prepareFormData={prepareFormData}
        />
      </div>
    </div>
  );
};

export default AnalyticsPreparation;
