import React, { useState, useEffect, useRef, useMemo } from "react";
import SectionContainer from "../SectionContainer";
import parse from "html-react-parser";
import {
  SectionTitle,
  SectionDescription,
  StatCard,
  ProfileHeader,
} from "../investor/profileHelpers";
import { TagGroup } from "../investor/profileHelpers";
import greenTick from "../../../../Assets/Images/icons/greenTick.svg";
import redMark from "../../../../Assets/Images/InvestorPage/Dashboard/redMark.svg";
import { TeamMemberCard } from "../investor/profileHelpers";
import LinearProgressBar from "../linearProgressBar";
import CustomTable from "../custom/customTable";
import moment from "moment";
import { getFundrevAnalystDetailsById } from "../../../../endpoints/fundrevAnalyst";
import Navigation from "../navigation";
import { useLoader } from "../../LoaderProvider";
import { ToastContainer } from "react-toastify";
import { useRecoilValue } from "recoil";
import { investorState } from "../../../Investor/Dashboard/investorState";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";

const Profile = ({ deal }) => {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [investmentData, setInvestmentData] = useState(["-"]);

  const [cardTags, setCardTags] = useState([]);
  const [percentageFundingRaised, setPercentageFundingRaised] = useState(0);
  const [fundrevAnalystDetails, setFundrevAnalystDetails] = useState("");
  const [stages, setStages] = useState([]);
  const fundrevAnalystID = "FN123456";
  const [activeIndex, setActiveIndex] = useState(0);
  const { investorId } = useRecoilValue(investorState);

  const refs = {
    "Company Overview": useRef(null),
    "Performance Metrics": useRef(null),
    "Key Highlights": useRef(null),
    "Deal Details": useRef(null),
    "Funding History": useRef(null),
    "Investment Highlights": useRef(null),
    "Purpose Of Fundraising": useRef(null),
    "Founding Team": useRef(null),
  };
  const primaryStages = [
    "Company Overview",
    "Performance Metrics",
    "Key Highlights",
    "Investment Highlights",
    "Purpose Of Fundraising",
    "Founding Team",
    "Deal Details",
    "Funding History",
  ];
  const secondaryStages = [
    "Company Overview",
    "Performance Metrics",
    "Key Highlights",
    "Investment Highlights",
    "Deal Details",
    "Funding History",
  ];
  const isValidObject = (obj) =>
    obj &&
    typeof obj === "object" &&
    !Array.isArray(obj) &&
    Object.keys(obj).length > 0;
  const columns = [
    "Date",
    "Round",
    "Raised (₹cr)",
    "% Stake",
    "Valuation (₹cr)",
  ];
  useEffect(() => {
    let invData = [];
    if (deal?.previousRounds) {
      deal?.previousRounds.map((data) => {
        const array = []; // Create a new array for each iteration
        array.push(data.date ? moment(data.date).format("DD-MM-YYYY") : "-");
        array.push(data.round);
        array.push(data.raised);
        array.push(data.stake);
        array.push(data.valuation);
        invData.push(array);
      });
      setInvestmentData(invData);
    }
  }, [deal]);
  const fetchAnalystDetails = async () => {
    if (!token) return;
    try {
      // If the user is fundrev analyst then fetch the details of the fundrev analyst
      if (fundrevAnalystID) {
        const response = await getFundrevAnalystDetailsById({
          fundrevAnalystID: fundrevAnalystID,
        });
        if (response.data !== null) {
          const responseData = response.data;
          const { data } = responseData;
          setFundrevAnalystDetails(data);
        }
        return response;
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchAnalystDetails();
  }, [fundrevAnalystID]);
  const companyStats = (company) => {
    return {
      overview: [
        {
          icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/founded.svg",
          label: "Founded",
          value: company?.foundedYear,
        },
        {
          icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/headquarters.svg",
          label: "Headquarters",
          value: company?.headquarters,
        },
        {
          icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/employees.svg",
          label: "Employees",
          value: company?.numEmployees || "-",
        },
        {
          icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/noOfInvesments.svg",
          label: "Backing",
          value: company?.backing || "",
        },
      ],
    };
  };
  const handleScroll = (stage, index) => {
    setActiveIndex(index);
    refs[stage].current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    deal?.dealType === "secondary"
      ? setStages(secondaryStages)
      : setStages(primaryStages);
  }, [deal]);
  useEffect(() => {
    const computeCardTags = () => {
      const tags = [];

      // Handle industryVerticals parsing
      try {
        if (deal?.industryVerticals.length > 0) {
          if (Array.isArray(deal?.industryVerticals))
            tags.push(...deal?.industryVerticals);
        }
      } catch (error) {
        console.error("Error parsing Industry Verticals:", error);
      }
      try {
        if (deal?.subIndustryVerticals.length > 0) {
          if (Array.isArray(deal?.subIndustryVerticals))
            tags.push(...deal?.subIndustryVerticals);
        }
      } catch (error) {
        console.error("Error parsing sub Industry Verticals:", error);
      }
      // Handle endMarket parsing
      try {
        if (deal?.endMarket?.length > 0) {
          if (Array.isArray(deal?.endMarket)) tags.push(...deal?.endMarket);
        }
      } catch (error) {
        console.error("Error parsing End Market Focus:", error);
        tags.push("_"); // Fallback value
      }
      try {
        if (deal?.backing?.length > 0) {
          const fundingTypes = [deal?.backing];
          if (Array.isArray(fundingTypes)) tags.push(...deal?.fundingTypes);
        }
      } catch (error) {
        console.error("Error parsing Backing:", error);
      }
      return tags;
    };

    // Set computed card tags
    setCardTags(computeCardTags());
  }, [deal]);
  useEffect(() => {
    try {
      if (!deal?.fundingRaised) {
        setPercentageFundingRaised(0);
        return;
      }
      const percentage = (deal?.fundingRaised / deal?.fundingRequired) * 100;
      setPercentageFundingRaised(percentage.toFixed(2));
    } catch (error) {
      setPercentageFundingRaised(0);
    }
  }, [deal?.fundingRaised, deal?.fundingRequired]);

  return (
    <>
      <ToastContainer
        containerId={"companyDetailsShowInterest"}
        position="top-center"
      />
      <div className="gap-x-200 grid h-full grid-cols-12 pb-4">
        <div className="p-200 border-neutral-5 h-fit rounded-100 col-span-2 border">
          <Navigation
            stages={stages}
            handleClick={handleScroll}
            activeIndex={activeIndex}
          />
        </div>
        <div className="gap-200 flex flex-col h-full col-span-7 overflow-x-hidden overflow-y-scroll">
          <SectionContainer
            title={"Company Overview"}
            ref={refs["Company Overview"]}
          >
            <ProfileHeader data={deal} role="company" />
            <TagGroup tags={cardTags} />
            <div className="gap-150 flex flex-col">
              <SectionTitle>Company Description</SectionTitle>
              <SectionDescription>
                <p className="setInnerHTML ql-editor">
                  {parse(deal?.description || "")}
                </p>
              </SectionDescription>
            </div>
            <div className="grid w-full grid-cols-4 gap-6">
              {companyStats(deal)?.overview?.map((stat, index) => (
                <StatCard
                  key={index}
                  icon={stat.icon}
                  label={stat.label}
                  value={stat.value}
                />
              ))}
            </div>
          </SectionContainer>
          <SectionContainer
            title={"Performance Metrics"}
            ref={refs["Performance Metrics"]}
          >
            <div className="gap-150 flex flex-col">
              <SectionTitle>Financials</SectionTitle>
              <SectionDescription>
                <div className="grid w-full grid-cols-4 gap-6">
                  {isValidObject(deal?.financialMetrics) ? (
                    Object.entries(deal?.financialMetrics).map(
                      ([key, value]) => (
                        <StatCard key={key} label={key} value={value} />
                      )
                    )
                  ) : (
                    <div className="text-gray-500">No valid data available</div>
                  )}
                </div>
              </SectionDescription>
            </div>
            {deal?.dealType !== "secondary" && (
              <div className="gap-150 flex flex-col">
                <SectionTitle>Key Metrics</SectionTitle>
                <SectionDescription>
                  <div className="grid w-full grid-cols-4 gap-6">
                    {isValidObject(deal?.keyMetrics) ? (
                      Object.entries(deal?.keyMetrics).map(([key, value]) => (
                        <StatCard key={key} label={key} value={value} />
                      ))
                    ) : (
                      <div className="text-gray-500">
                        No valid data available
                      </div>
                    )}
                  </div>
                </SectionDescription>
              </div>
            )}

            <div className="gap-150 flex flex-col">
              <SectionTitle>Other Information</SectionTitle>
              <SectionDescription>
                <div className="gap-300 flex">
                  {deal?.dataAvailabilityWithFundrev &&
                    Object.entries(deal?.dataAvailabilityWithFundrev).map(
                      ([key, value]) => {
                        if (key.toLowerCase() === "financial") return null;
                        return (
                          <div key={key} className="gap-100 flex items-center">
                            <img
                              loading="lazy"
                              src={value === true ? greenTick : redMark}
                              alt={`${key}-status`}
                            />
                            <div>
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </SectionDescription>
            </div>
          </SectionContainer>
          <SectionContainer
            title={"Key Highlights"}
            ref={refs["Key Highlights"]}
          >
            <p className="setInnerHTML ql-editor">
              {parse(deal?.keyHighlights || "")}
            </p>
          </SectionContainer>
          {deal?.dealType === "secondary" ? (
            <SectionContainer
              title={"Investment Highlights"}
              ref={refs["Investment Highlights"]}
            >
              {" "}
              <SectionDescription>
                <div className="grid w-full grid-cols-4 gap-6">
                  {isValidObject(deal?.investmentHighlights) ? (
                    Object.entries(deal?.investmentHighlights).map(
                      ([key, value]) => (
                        <StatCard key={key} label={key} value={value} />
                      )
                    )
                  ) : (
                    <div className="text-gray-500">No valid data available</div>
                  )}
                </div>
              </SectionDescription>
              <p className="setInnerHTML ql-editor">
                {parse(deal?.investmentHighlightsDescription || "")}
              </p>
            </SectionContainer>
          ) : (
            <>
              <SectionContainer
                title={"Investment Highlights"}
                ref={refs["Investment Highlights"]}
              >
                <p className="setInnerHTML ql-editor">
                  {parse(deal?.investmentHighlights || "")}
                </p>
              </SectionContainer>
              <SectionContainer
                title={"Purpose Of Fundraising"}
                ref={refs["Purpose Of Fundraising"]}
              >
                <p className="setInnerHTML ql-editor">
                  {parse(deal?.noNamePurposeOfFundraising || "")}
                </p>
              </SectionContainer>
              <SectionContainer
                title={"Founding Team"}
                ref={refs["Founding Team"]}
              >
                <div className="gap-150 grid grid-cols-2">
                  {deal?.foundingMember?.map((member) => {
                    return <TeamMemberCard member={member} />;
                  })}
                </div>
              </SectionContainer>
            </>
          )}
          <SectionContainer title={"Deal Details"} ref={refs["Deal Details"]}>
            <div className="flex flex-col w-full">
              <div className="text-neutral-12 text-desktop-p-mb">
                {deal?.dealType === "secondary" ? "Secondary" : deal?.roundType}
              </div>
              <div className="flex flex-col py-0.5 mt-3 w-full rounded-none ">
                <LinearProgressBar value={percentageFundingRaised} />
                <div className="flex justify-between mt-2.5 text-desktop-p-xs font-medium text-neutral-12">
                  <p>
                    ₹{deal?.fundingRaised || 0}Cr ({percentageFundingRaised}%
                    raised)
                  </p>
                  <p>₹{deal?.fundingRequired}Cr</p>
                </div>
              </div>
            </div>
            <div>
              <p className="setInnerHTML ql-editor">
                {parse(deal?.dealDetails || "")}
              </p>
            </div>
          </SectionContainer>
          <SectionContainer
            title={"Funding History"}
            ref={refs["Funding History"]}
          >
            <div className="gap-150 flex flex-col">
              <SectionTitle>Key Investors</SectionTitle>
              <SectionDescription>
                <div className="flex flex-wrap gap-2">
                  {deal?.keyInvestors && deal?.keyInvestors.trim() ? (
                    deal?.keyInvestors.split(",").map((investor, index) => (
                      <div
                        key={index}
                        className="text-neutral-12 bg-neutral-3 border border-neutral-5 py-[6px] px-[16px] rounded text-desktop-p-s font-normal"
                      >
                        {investor.trim()}
                      </div>
                    ))
                  ) : (
                    <div className="text-neutral-12 text-desktop-p-s font-normal">
                      No Previous Investors
                    </div>
                  )}
                </div>
              </SectionDescription>
            </div>
            <div className="gap-150 flex flex-col">
              <SectionTitle>Fundraising History</SectionTitle>
              <SectionDescription>
                <CustomTable
                  columns={columns}
                  data={investmentData}
                  equalWidth
                />
              </SectionDescription>
            </div>
          </SectionContainer>
        </div>
        <div className="p-300 border-neutral-5 rounded-100 h-fit gap-250 flex flex-col col-span-3 border">
          <p className="text-neutral-12 text-desktop-p-l font-bold">
            Have Questions?
          </p>
          <p className="text-desktop-p-mb text-wrap font-normal">
            For any queries or further details about this deal, connect directly
            with your Fundrev deal analyst.
          </p>
          <div className="gap-200 flex items-center">
            <img
              src={fundrevAnalystDetails.profile}
              alt="User profile"
              className="w-12 h-12 rounded-[4px]"
            />
            <div className="flex flex-col">
              <p className="text-desktop-p-l text-neutral-12 font-bold">
                {fundrevAnalystDetails.name}
              </p>
              <p className="text-neutral-8 text-desktop-p-mb">
                {fundrevAnalystDetails.title}
              </p>
            </div>
          </div>
          <div className="gap-300 flex">
            <div className="gap-150 flex items-center">
              <PhoneIcon fontSize="12px" />
              <p className="text-desktop-p-s">
                {fundrevAnalystDetails?.phoneNumber}
              </p>
            </div>
            <div className="gap-150 flex items-center">
              <MailIcon fontSize="12px" />
              <a
                href={`mailto:${fundrevAnalystDetails?.email}`}
                className="text-desktop-p-s text-blue-500"
              >
                {fundrevAnalystDetails?.email}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
