import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const CustomTable = ({ columns, data, equalWidth = false }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "4px", // Set border radius to 4px
        boxShadow: "none", // Remove box-shadow
        border: "none", // Remove border
        border: "1px solid #D9D9D9",
      }}
    >
      <Table
        aria-label="custom table"
        sx={{
          borderCollapse: "collapse", // Ensure borders align seamlessly
        }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                className="text-desktop-p-m !text-neutral-12 !p-3 !font-satoshi font-medium !leading-none pro"
                sx={{
                  borderRight:
                    index < columns.length - 1 ? "1px solid #D9D9D9" : "none", // Vertical borders
                  width: equalWidth ? `${100 / columns.length}%` : "auto", // Equal width logic
                }}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, colIndex) => (
                <TableCell
                  key={colIndex}
                  className="!text-desktop-p-s !font-satoshi !text-neutral-12 !p-3 leading-none"
                  sx={{
                    borderRight:
                      colIndex < columns.length - 1
                        ? "1px solid #D9D9D9"
                        : "none",
                    borderBottom:
                      rowIndex < data.length - 1 ? "1px solid #D9D9D9" : "none",
                  }}
                >
                  {row[colIndex] || "-"}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
