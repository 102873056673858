import React from "react";
import {
  StyledFormControl,
  StyledFormLabel,
  investmentStageOptions,
  industryVerticalsOptions,
  endMarketFocusOptions,
  StyledFormLabelOptional,
  financialCriteriaOptions,
} from "../../../../Common/functions";
import { useFormikContext } from "formik";
import CustomInput from "../../../../Common/components/custom/customInput";
import CustomSelect from "../../../../Common/components/custom/customSelect";
import CustomCheckboxGroup from "../../../../Common/components/custom/customCheckboxGroup";
const InvestmentCriteria = () => {
  const { values, handleChange, handleBlur, touched, errors } =
    useFormikContext();
  return (
    <div className=" relative flex flex-col w-full gap-10">
      <div className="gap-y-300 gap-x-600 grid grid-cols-3">
        <StyledFormControl fullWidth>
          <StyledFormLabel>Stage of Investment</StyledFormLabel>
          <CustomSelect
            label="Stage of Investment"
            name="investmentStage"
            multiple={true}
            value={values.investmentStage}
            onChange={handleChange}
            options={investmentStageOptions}
            placeholder="Select Stage of Investment"
            error={Boolean(touched.investmentStage && errors.investmentStage)}
            helperText={touched.investmentStage && errors.investmentStage}
          />
        </StyledFormControl>

        <StyledFormControl fullWidth>
          <StyledFormLabelOptional>Financial Criteria</StyledFormLabelOptional>
          <CustomSelect
            placeholder="Select Financial Criteria"
            name={`financialCriteria`}
            multiple={true}
            value={values.financialCriteria}
            onChange={handleChange}
            onBlur={handleBlur}
            options={financialCriteriaOptions}
            error={Boolean(
              touched.financialCriteria && errors.financialCriteria
            )}
            helperText={touched.financialCriteria && errors.financialCriteria}
          />
        </StyledFormControl>
        <StyledFormControl fullWidth></StyledFormControl>
        <StyledFormControl
          fullWidth
          sx={{
            gridColumn: "1/-1",
          }}
        >
          <CustomCheckboxGroup
            required
            label="Industry Vertical"
            name={"industryVerticalOptions"}
            options={industryVerticalsOptions} // Array of objects: [{ value: 'tech', label: 'Technology' }, ...]
            value={values.industryVerticalOptions} // Current selected values (array)
            onChange={handleChange} // Formik's handleChange or any other custom handler
            error={Boolean(
              touched.industryVerticalOptions && errors.industryVerticalOptions
            )}
            helperText={
              touched.industryVerticalOptions && errors.industryVerticalOptions
            }
          />
        </StyledFormControl>
        <StyledFormControl
          fullWidth
          sx={{
            gridColumn: "1/-1",
          }}
        >
          <CustomCheckboxGroup
            required
            label="End Market Focus"
            name="endMarketFocus"
            options={endMarketFocusOptions} // Array of objects: [{ value: 'tech', label: 'Technology' }, ...]
            value={values.endMarketFocus} // Current selected values (array)
            onChange={handleChange} // Formik's handleChange or any other custom handler
            error={Boolean(touched.endMarketFocus && errors.endMarketFocus)}
            helperText={touched.endMarketFocus && errors.endMarketFocus}
          />
        </StyledFormControl>
        <StyledFormControl
          fullWidth
          sx={{
            gridColumn: "1/-1",
          }}
        >
          <StyledFormLabelOptional>Other Criteria</StyledFormLabelOptional>
          <CustomInput
            placeholder="Enter Other Criteria"
            name={`otherCriteria`}
            value={values.otherCriteria}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.otherCriteria && errors.otherCriteria)}
            helperText={touched.otherCriteria && errors.otherCriteria}
            multiline
            minRows={3}
          />
        </StyledFormControl>
      </div>
    </div>
  );
};

export default InvestmentCriteria;
