import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidebar from "../../../Common/components/sidebar";
import Topbar from "../Components/topbar";
import Timeline from "../Components/Timeline";
import TimelineComponent from "../../../Common/components/timeline";
import Overview from "./overview";
import Activity from "./activity";
import { companyState } from "../companyState";
import PassDealModal from "../../../Common/modals/passDealModal";
import { postStageData } from "../../../../endpoints/deal";
import UploadDocumentModal from "../../../Common/modals/uploadDocumentModal";
import Toast from "../../../Common/Toast";
import { getAllDealInteractions } from "../../../../endpoints/deal";

import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import { useRecoilValue } from "recoil";
import { useLoader } from "../../../Common/LoaderProvider";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import CustomModal from "../../../Common/components/custom/customModal";
import GreenTickModal from "../../../Common/components/custom/greenTickModal";

const InvestorDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [type, setType] = useState("current");
  const { activeBtn } = useRecoilValue(companyState);
  const loader = useLoader();
  const location = useLocation();
  const Navigate = useNavigate();
  const {
    investor,
    isDealLive,
    watchlistAccepted,
    passedStage,
    dealId,
    investorID,
    latestStage,
  } = location.state || {};
  const [title, setTitle] = useState(
    investor && investor?.companyName
      ? investor?.companyName
      : "Company Overview"
  );
  const [greenTickModal, setGreenTickModal] = useState(false);
  const [stage, setStage] = useState(passedStage ? passedStage : "");
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [SPAModalOpen, setSPAModalOpen] = useState(false);
  const [spaFile, setSpaFile] = useState(null);
  const [requestAcceptModal, setRequestAcceptModal] = useState(false);
  const [watchlistModal, setWatchlistModal] = useState(false);
  const [watchlistAccept, setWatchlistAccept] = useState(true);
  const spaFileChange = (file) => {
    setSpaFile(file);
  };
  const handleCancelSPA = () => {
    setSPAModalOpen(false);
    setSpaFile();
  };
  const handleSPASubmit = async () => {
    try {
      if (!spaFile) {
        Toast("Please upload a file", "error", "investorDetails");
        return;
      }
      const formData = new FormData();
      formData.append("dealId", dealId);
      formData.append("Stage", "spaNegotiation");
      formData.append("IsInterested", true);
      formData.append("investorID", investor.investorID);
      formData.append("SPA", spaFile[0]);
      loader.start("Uploading SPA...");
      const response = await postStageData(formData, token);
      loader.stop();
      if (response.data !== null) {
        setSPAModalOpen(false);
        setSpaFile();
        Navigate("/company/spaNegotiation", {
          state: {
            stage: "spaNegotiation",
            dealId: dealId,
            investorID: investor.investorID,
            companyName: investor.companyName,
          },
        });
      } else if (response.error.status === 403) {
        Toast(
          "You do not have access to interact with this deal",
          "error",
          "investorDetails"
        );
        setRequestAcceptModal(false);
      }
    } catch (error) {
      loader.stop();
      Toast(
        "Failed to upload SPA due to some internal errors",
        "error",
        "investorDetails"
      );
    }
  };
  const handleAccept = async () => {
    loader.start(`Loading...`);
    let stageAccept;
    if (stage === "requestSent") {
      stageAccept = "requestAccepted";
    } else if (stage === "ioiSubmitted") {
      stageAccept = "ioiAccepted";
    } else if (stage === "loiSubmitted") {
      stageAccept = "loiAccepted";
    }
    const updatedFormData = {
      dealId: dealId,
      investorID: investorID,
      IsInterested: true,
      Stage: stageAccept,
    };
    try {
      const response = await postStageData(updatedFormData, token);
      loader.stop();

      if (response.data !== null) {
        setStage(stageAccept);
        setRequestAcceptModal(false);
        setGreenTickModal(true);
      } else if (response.error.status === 403) {
        Toast(
          "You do not have access to interact with this deal",
          "error",
          "investorDetails"
        );
        setRequestAcceptModal(false);
      }
    } catch (error) {
      loader.stop();
    }
  };

  const redirectToSPA = () => {
    Navigate("/company/spaNegotiation", {
      state: {
        stage: "spaNegotiation",
        dealId: dealId,
        investorID: investor.investorID,
        companyName: investor.companyName,
      },
    });
  };

  const redirectToIOIBid = () => {
    Navigate("/company/termSheet", {
      state: {
        stage: "ioiSubmitted",
        dealId: dealId,
        investorID: investor?.investorID,
        companyName: investor?.companyName,
      },
    });
  };
  const redirectToLOIAgreement = () => {
    Navigate("/company/dueDiligence", {
      state: {
        stage: "loiSubmitted",
        dealId: dealId,
        investorID: investor.investorID,
        companyName: investor.companyName,
      },
    });
  };
  const handleWatchListRequest = async () => {
    let formData;
    if (watchlistAccept) {
      formData = {
        dealId: dealId,
        investorID: investorID,
        IsInterested: false,
        IsRejected: false,
        Stage: "watchlist",
        watchlistAccepted: true,
      };
    } else {
      formData = {
        dealId: dealId,
        investorID: investorID,
        IsRejected: true,
        Stage: latestStage,
        watchlistAccepted: false,
      };
    }
    try {
      loader.start("Processing request...");
      const response = await postStageData(formData, token);
      loader.stop();
      setWatchlistModal(false);
      if (response.data) {
        if (watchlistAccept) {
          Navigate("/company/ongoingDeals/watchlist");
        } else {
          Navigate("/company/ongoingDeals/dead");
        }
      } else if (response.error.status === 403) {
        Toast(
          "You do not have access to interact with this deal",
          "error",
          "investorDetails"
        );
      }
    } catch (error) {
      Toast(
        "Error in processing request. Please try again later",
        "error",
        "investorDetails"
      );
    }
  };
  const fetchAllInteractions = async () => {
    try {
      const response = await getAllDealInteractions(
        dealId,
        investor.investorID,
        token
      );
      setStage(response.data[response.data.length - 1].Stage);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchAllInteractions();
  }, [dealId, investor]);
  useEffect(() => {
    if (!investor || !passedStage || !dealId) {
      Navigate("/company/dashboard");
    }
  }, [investor, passedStage, dealId]);
  const btnTitleMap = {
    1: "Overview",
    2: "Activity",
  };
  useEffect(() => {
    setTitle(`${investor?.companyName} - ${btnTitleMap[activeBtn]}`);
  }, [activeBtn]);
  useEffect(() => {
    if (stage === "watchlist") {
      setType("watchlist");
    } else if (!isDealLive) {
      setType("dead");
    } else {
      setType("current");
    }
  });
  return (
    <>
      {isLoading ? (
        <>Loading...</>
      ) : (
        <div className="InvestorSide">
          <ToastContainer position="top-center" containerId="investorDetails" />
          <Sidebar active="ongoingDeals" type={type} userRole="company" />
          <Topbar title={title} isBack={true} />
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <GreenTickModal
            open={greenTickModal}
            title={`Request Accepted Successfully!`}
            onClose={() => setGreenTickModal(false)}
          />
          <CustomModal
            open={requestAcceptModal}
            onClose={() => setRequestAcceptModal(false)}
            secondaryAction={() => setRequestAcceptModal(false)}
            primaryAction={handleAccept}
            title={`Request Acceptance`}
            primaryText="Accept"
            description={`Do you want to accept request sent by ${investor?.companyName}? `}
            customStyles={{
              width: "600px",
            }}
          />
          <CustomModal
            open={watchlistModal}
            onClose={() => setWatchlistModal(false)}
            secondaryAction={() => setWatchlistModal(false)}
            primaryAction={handleWatchListRequest}
            title={
              watchlistAccept ? `Watchlist Acceptance` : "Reject Watchlist"
            }
            primaryText={watchlistAccept ? "Accept" : "Reject"}
            description={
              watchlistAccept
                ? `Do you want to accept watchlist request by ${investor?.companyName}? `
                : `Are you sure you want to reject watchlist request by ${investor?.companyName}? `
            }
            customStyles={{
              width: "600px",
            }}
          />
          <PassDealModal
            openReasonModal={openReasonModal}
            setOpenReasonModal={setOpenReasonModal}
            stage={stage}
            isCompany={true}
            dealId={dealId}
            investorID={investorID}
            toastContainer="investorDetails"
          />
          <UploadDocumentModal
            DocumentUploadModalOpen={SPAModalOpen}
            setDocumentUploadModalOpen={setSPAModalOpen}
            DocumentUploadFile={spaFile}
            DocumentUploadFileChange={spaFileChange}
            handleCancelDocumentUpload={handleCancelSPA}
            handleDocumentUploadSubmit={handleSPASubmit}
          />
          <div
            className="InvestorSide-box"
            style={{
              overflowX: "hidden",
              display: "flex",
              flexDirection: "row-reverse",
              overflowY: "scroll",
            }}
          >
            <div className="InvestorSide-content" style={{ flex: 5 }}>
              <div className="cdetails-div">
                {stage !== "requestSent" && (
                  <Header
                    activeButton={1}
                    stage={stage}
                    isDealLive={isDealLive}
                  />
                )}
                <div className="flex gap-3 mt-4">
                  <TimelineComponent
                    passedStage={stage === "watchlist" ? latestStage : stage} //stage is not passed here as stage can be watchlist. But latestStage will be stage before watchlist
                    dealId={dealId}
                    investorID={investor?.investorID}
                    companyName={investor?.companyName}
                    role="company"
                  />
                  {activeBtn === 1 && <Overview investor={investor} />}
                  {activeBtn === 2 && (
                    <Activity
                      dealId={dealId}
                      investorID={investor?.investorID}
                      isDealLive={isDealLive}
                    />
                  )}
                </div>
              </div>

              {isDealLive && stage === "requestSent" && (
                <div className="showInterestButtons-div">
                  <div className="btns-insideContent">
                    <div className="showInterestButtons-right">
                      <CustomBlackBtn
                        mode="danger"
                        onClick={() => setOpenReasonModal(true)}
                        text="Decline Offer"
                        filled={false}
                      />
                    </div>
                    <div className="showInterestButtons-right">
                      <CustomBlackBtn
                        onClick={() => setRequestAcceptModal(true)}
                        text="Accept Request"
                      />
                    </div>
                  </div>
                </div>
              )}

              {isDealLive && stage !== "requestSent" && (
                <div className="showInterestButtons-div">
                  <div className="btns-insideContent">
                    {stage !== "dealClosed" &&
                      stage !== "requestSent" &&
                      stage !== "watchlist" && (
                        <CustomBlackBtn
                          mode="danger"
                          onClick={() => setOpenReasonModal(true)}
                          text="Pass Deal"
                          filled={false}
                        />
                      )}
                    <div className="showInterestButtons-right">
                      {stage === "requestAccepted" ||
                        stage === "ioiSubmitted" ||
                        stage === "ioiAccepted" ||
                        stage === "loiSubmitted" ||
                        stage === "spaNegotiation" ||
                        stage === "loiAccepted" ||
                        stage === "watchlist"}

                      {stage === "ioiSubmitted" && (
                        <CustomBlackBtn
                          onClick={redirectToIOIBid}
                          text="View Term Sheet"
                        />
                      )}
                      {stage === "ioiAccepted" && (
                        <CustomBlackBtn
                          onClick={redirectToIOIBid}
                          text="View Term Sheet"
                        />
                      )}
                      {stage === "loiSubmitted" && (
                        <CustomBlackBtn
                          onClick={redirectToLOIAgreement}
                          text="View Final Bid"
                        />
                      )}
                      {stage === "loiAccepted" && (
                        <CustomBlackBtn
                          onClick={() => setSPAModalOpen(true)}
                          text="Attach SPA"
                        />
                      )}
                      {stage === "spaNegotiation" && (
                        <CustomBlackBtn
                          onClick={redirectToSPA}
                          text="View SPA Negotiation"
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}

              {stage === "watchlist" && !watchlistAccepted && (
                <div className="showInterestButtons-div">
                  <div className="btns-insideContent">
                    <div className="showInterestButtons-right">
                      <CustomBlackBtn
                        text="Reject Watchlist"
                        filled={false}
                        mode="danger"
                        onClick={() => {
                          setWatchlistAccept(false);
                          setWatchlistModal(true);
                        }}
                      />
                      <CustomBlackBtn
                        text="Accept Watchlist"
                        onClick={() => {
                          setWatchlistAccept(true);
                          setWatchlistModal(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvestorDetails;
