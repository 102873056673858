import React, { useState, useEffect } from "react";
import Header from "../../Common/components/header";
import Topbar from "../../Founders/Dashboard/Components/topbar";
import Sidebar from "../../Common/components/sidebar";
import CommentBox from "./commentBox";
import DetailedCardHeader from "./detailedCardHeader";
import {
  getDealIdbyStartupId,
  getDealDetailsByDealId,
  publishStartup,
} from "../../../endpoints/deal";
import { ToastContainer } from "react-toastify";
import Overview from "../../Investor/Dashboard/companyDetails/overview";
import Dataroom from "../../Investor/Dashboard/companyDetails/dataroom";
import NoNamePreview from "../../Common/components/NoNamePreview";
import { toggleButtonStyles } from "../../Common/functions";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { companyOnBoardingState, saveState } from "./companyOnBoardingState";

import previous from "../../../Assets/Images/signup/previous.svg";
import Next from "../../../Assets/Images/signup/next.svg";
import reqEdit from "../../../Assets/Images/FounderPage/reqedit.svg";
import commentIcon from "../../../Assets/Images/FounderPage/commentIcon.svg";
import { useLoader } from "../../Common/LoaderProvider";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import Analytics from "../../analytics/analytics";
import { getStartupDetails } from "../../../endpoints/admin";
import { fetchStartupDetails } from "../../../endpoints/startup";
import { getSecondaryDealById } from "../../../endpoints/secondary";
const ProfilePreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { startupId, token, analystID, hideBtn, dealType } =
    location.state || {};
  const { numMessages } = useRecoilValue(companyOnBoardingState);
  const [dealId, setDealId] = useState(location?.state?.dealId || null);
  const [deal, setDeal] = useState(null);
  const [cardType, setCardType] = useState("noNameCard");
  const [step, setStep] = useState(1);
  const { activeBtn } = useRecoilValue(companyOnBoardingState);
  const [state, setState] = useRecoilState(companyOnBoardingState);
  const [analytics, setAnalytics] = useState("summary");
  const loader = useLoader();
  const [Messages, setMessages] = useState([]);
  const handleActiveBtn = (activeBtn) => {
    const newState = { ...state, activeBtn: activeBtn };
    setState(newState);
    saveState(newState);
  };

  useEffect(() => {
    if (cardType && activeBtn) {
      if (cardType === "noNameCard") {
        setStep(1);
        handleActiveBtn(1);
      } else if (cardType === "detailedCard" && activeBtn === 1) {
        setStep(2);
      } else if (cardType === "detailedCard" && activeBtn === 2) {
        setStep(3);
      } else if (cardType === "detailedCard" && activeBtn === 3) {
        setStep(4);
      } else {
        setStep(1); // Default to step 1 if conditions are not met
        handleActiveBtn(1);
      }
    }
  }, [cardType, activeBtn]);
  const handleStep = (step) => {
    switch (step) {
      case 1:
        setCardType("noNameCard");
        handleActiveBtn(1);
        break;
      case 2:
        setCardType("detailedCard");
        handleActiveBtn(1);
        break;
      case 3:
        setCardType("detailedCard");
        handleActiveBtn(2);
        break;
      case 4:
        setCardType("detailedCard");
        handleActiveBtn(3);
        break;
      default:
        setCardType("noNameCard");
        handleActiveBtn(1);
    }
  };

  const handleStepChange = (direction) => {
    if (direction === "prev") {
      handleStep(step - 1);
      setStep(step - 1);
    } else {
      if (step === 4) {
        navigate("/company/onboarding", {
          state: {
            isReviewedByCompany: true,
          },
        });
      } else {
        handleStep(step + 1);
        setStep(step + 1);
      }
    }
  };
  const handleOpen = () => {
    const newState = { ...state, notificationBarOpen: true };
    setState(newState);
    saveState(newState); // Save to local storage
  };

  const handleCardTypeChange = (event, newCardType) => {
    if (newCardType !== null) setCardType(newCardType);
  };

  const getDealId = async () => {
    try {
      if (startupId && !dealType === "secondary") {
        const response = await getDealIdbyStartupId(startupId, token);
        setDealId(response.data.dealId);
      }
    } catch (error) {}
  };
  const getDealDetails = async () => {
    try {
      let response;
      if (dealType === "secondary") {
        response = await getSecondaryDealById(dealId);
      } else {
        response = await getDealDetailsByDealId(dealId, token);
      }
      setDeal((prev) => {
        return {
          ...prev,
          ...response.data,
        };
      });
      loader.stop();
    } catch (error) {
      loader.stop();
    }
  };
  const getStartupData = async () => {
    if (dealType !== "secondary") {
      if (analystID) {
        try {
          const response = await getStartupDetails(startupId, token);
          const data = response?.data || false;
          if (data) {
            setDeal((prev) => {
              return {
                ...prev,
                ...data,
              };
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const response = await fetchStartupDetails(token);
          const data = response?.data?.data || false;
          if (data) {
            setDeal((prev) => ({
              ...prev,
              ...data,
            }));
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  useEffect(() => {
    loader.start();
    getDealId();
  }, [startupId]);
  useEffect(() => {
    if (dealId) {
      getDealDetails();
    } else {
      loader.stop();
    }
    if (startupId) {
      getStartupData();
    }
  }, [dealId, startupId]);
  return (
    <div className="company-profile-preview investorDetailsPage">
      <div className="InvestorSide">
        <ToastContainer position="top-center" containerId="profilePreview" />
        {/* <Header color="black" token="Startuptoken" type="Startup" /> */}
        <Topbar title="Deal Preview" />
        <Sidebar active="overview" useRole="company" />
        <CommentBox
          dealId={dealId}
          token={token}
          analystID={analystID}
          Messages={Messages}
          setMessages={setMessages}
        />
        <div className="InvestorSide-box">
          <div className="InvestorSide-content">
            <div
              className="cdetails-div"
              // style={{ marginBottom: hideBtn && "100px" }}
            >
              <div className="Preview-div">
                <div className="cdetails-first-div">
                  <ToggleButtonGroup
                    color="primary"
                    value={cardType}
                    exclusive
                    onChange={handleCardTypeChange}
                    aria-label="card-type"
                    sx={{
                      backgroundColor: "#D2DCFF",
                      padding: "4px 8px",
                      borderRadius: "8px",
                      width: "fit-content",
                    }}
                  >
                    <ToggleButton value="noNameCard" sx={toggleButtonStyles}>
                      No Name Card
                    </ToggleButton>
                    <ToggleButton value="detailedCard" sx={toggleButtonStyles}>
                      Detailed Card
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <div className="flex gap-4">
                    {deal && deal?.msa && deal?.termsOfUse && (
                      <CustomBlackBtn
                        text={`View T&C`}
                        border="sm"
                        variant="sm"
                        onClick={() => {
                          // Download Terms of Use
                          const termsLink = document.createElement("a");
                          termsLink.href = deal.termsOfUse;
                          termsLink.download = "TermsOfUse.pdf"; // Adjust filename
                          termsLink.style.display = "none";
                          document.body.appendChild(termsLink);
                          termsLink.click();
                          document.body.removeChild(termsLink);
                        }}
                      />
                    )}
                    {Messages?.length > 0 ? (
                      <CustomBlackBtn
                        onClick={handleOpen}
                        border="sm"
                        variant="sm"
                        filled={false}
                        text={
                          <>
                            <img
                              src={commentIcon}
                              className="comments-div-img"
                              alt="comment"
                            />
                            {Messages.length} comments{" "}
                          </>
                        }
                      />
                    ) : (
                      <CustomBlackBtn
                        onClick={handleOpen}
                        border="sm"
                        variant="sm"
                        filled={false}
                        text={
                          <>
                            <img
                              src={reqEdit}
                              className="comments-div-img"
                              alt="comment"
                            />
                            No comments yet
                          </>
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              {cardType === "noNameCard" ? (
                <NoNamePreview deal={deal} stage={"companyOnBoarding"} />
              ) : (
                <div className="detailed-card">
                  <DetailedCardHeader
                    activeBtn={1}
                    from={"companyOnBoarding"}
                    dealId={dealId}
                    token={token}
                    analystID={analystID}
                  />
                  {activeBtn === 1 && (
                    <Overview deal={deal} stage={"companyReview"} />
                  )}
                  {activeBtn === 2 && startupId && (
                    <Analytics
                      setAnalytics={setAnalytics}
                      analytics={analytics}
                      deal={deal}
                      startupId={startupId}
                      stage={`companyOnBoarding`}
                    />
                  )}
                  {activeBtn === 3 && (
                    <Dataroom
                      page="profilePreview"
                      deal={deal}
                      role={analystID ? "fundrevAnalyst" : "company"}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {analystID
          ? ""
          : hideBtn && (
              <div className="showInterestButtons-div">
                <div className="btns-insideContent">
                  {step > 1 && (
                    <div>
                      <CustomBlackBtn
                        filled={false}
                        text="Previous Step"
                        type="button"
                        onClick={() => handleStepChange("prev")}
                      />
                    </div>
                  )}

                  <div>
                    <CustomBlackBtn
                      text={step === 4 ? `Complete Review` : `Next Step`}
                      type="button"
                      onClick={() => handleStepChange("next")}
                    />
                  </div>
                </div>
              </div>
            )}
      </div>
    </div>
  );
};

export default ProfilePreview;
