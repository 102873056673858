import React, { useState, useEffect } from "react";
import Toast from "../../../Common/Toast";
import {
  isValidLinkedInUrl,
  StyledFormControl,
  StyledFormLabel,
} from "../../../Common/functions";
import {
  fetchInvestorMemberDetails,
  memberUpdate,
} from "../../../../endpoints/investor";
import ChangePasswordModal from "../../../Common/modals/changePasswordModal";
import { useLoader } from "../../../Common/LoaderProvider";

import { matchIsValidTel } from "mui-tel-input";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import editImg from "../../../../Assets/Images/signup/edit-img.svg";
import uploadImg from "../../../../Assets/Images/signup/upload-img.svg";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { Form, Formik } from "formik";
import CustomInput from "../../../Common/components/custom/customInput";
import CustomPhoneInput from "../../../Common/components/custom/customPhoneInput";
import SectionContainer from "../../../Common/components/SectionContainer";

import AccountHeader from "./accountHeader";
import Sidebar from "../../../Common/components/sidebar";
import InvestorProfileSubHeader from "./editInvestorProfileSubHeader";
const Account = () => {
  const loader = useLoader();
  const [editMode, setEditMode] = useState(false);
  const token = localStorage.getItem("token");
  const [userDetails, setUserDetails] = useState({});
  const [email, setEmail] = useState("");
  const [investorID, setInvestorID] = useState("");
  const [memberImgFile, setMemberImgFile] = useState("");
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [companyLogoPreview, setCompanyLogoPreview] = useState(uploadImg);

  const initialValues = {
    name: userDetails?.name || "",
    title: userDetails?.title || "",
    email: userDetails.email || "",
    linkedIn: userDetails?.linkedIn || "",
    phoneNumber: userDetails?.phoneNumber || "",
    biography: userDetails.biography || "",
    memberImg: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    title: Yup.string().required("Title is required"),
    linkedIn: Yup.string()
      .required("LinkedIn Profile is required")
      .test("invalid", "Please enter a valid LinkedIn profile URL", (value) =>
        isValidLinkedInUrl(value)
      ),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .test("invalid", "Please enter a valid phone number", (value) =>
        matchIsValidTel(value)
      ),
    biography: Yup.string().required("Biography is required"),
  });

  const fetchDetails = async () => {
    let response;
    if (token) {
      try {
        loader.start("Fetching investor details...");
        response = await fetchInvestorMemberDetails(token);
        loader.stop();
      } catch (error) {
        loader.stop();
      }
    }
    if (token) {
      if (response && response.data !== null) {
        setUserDetails(response.data.data);
        setEmail(response.data.data.email);
        setInvestorID(response.data.data.investorID);

        // Set the image preview if the logo is available
        if (response.data.data.memberImg) {
          setCompanyLogoPreview(response.data.data.memberImg);
        }
      } else {
        loader.stop();
      }
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [token]);
  const handleFormSubmit = async (values) => {
    for (const key in values) {
      if (
        values.hasOwnProperty(key) &&
        key !== "memberImg" &&
        key !== "biography" &&
        key !== "pushNotifications" &&
        key !== "emailNotifications" &&
        key !== "linkedIn"
      ) {
        if (!values[key]) {
          Toast("Please fill all the fields", "warning", "investorAccount");
          return;
        }
      }
    }

    const data = {
      ...values,
      memberImg: memberImgFile,
    };
    const formDataInput = new FormData();
    for (const key in data) {
      if (data[key] === true || data[key] === false) {
        formDataInput.append(key, data[key]);
      } else if (data.hasOwnProperty(key)) {
        if (data[key]) {
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            formDataInput.append(key, JSON.stringify(data[key]));
          } else {
            formDataInput.append(key, data[key]);
          }
        }
      }
    }
    if (!data.investorID) {
      formDataInput.append("investorID", investorID);
    }
    loader.start("Updating member details...");
    const response = await memberUpdate(formDataInput, token);
    loader.stop();
    if (response.data !== null) {
      if (response.data.status === 200) {
        Toast(
          "Member details updated successfully",
          "success",
          "investorAccount"
        );
      } else {
        loader.stop();

        Toast("Failed to update member details", "error", "investorAccount");
      }
    } else {
      loader.stop();
      console.error("Error updating member details:", response.error);
    }
    fetchDetails();
  };

  return (
    <>
      <Helmet>
        <title>Investor | Account</title>
      </Helmet>
      <InvestorProfileSubHeader colsCount={editMode ? 10 : 8} />
      <Sidebar userRole="investor" active={"profile"} />
      <div className="custom-container h-[calc(100vh-70px)] overflow-y-scroll">
        <ChangePasswordModal
          passwordModalOpen={passwordModalOpen}
          setPasswordModalOpen={setPasswordModalOpen}
          token={token}
          containerId="investorAccount"
        />
        {/* <CustomDialogWithActions
          open={passwordModalOpen}
          onClose={() => setPasswordModalOpen(false)}
          className="w-[600px] h-[200px]"
        >

        </CustomDialogWithActions> */}
        {!editMode ? (
          <div className="gap-200 grid w-full grid-cols-12">
            <div className="col-span-2"></div>
            <div className="pt-300 col-span-8">
              <SectionContainer
                title={"Basic Details"}
                action={"edit"}
                actionFunction={() => setEditMode(true)}
              >
                <AccountHeader details={userDetails} />
              </SectionContainer>
              <div className="gap-200 flex flex-col mt-10">
                <p className="text-neutral-12 text-desktop-p-xl">
                  Password and Authentication
                </p>

                <CustomBlackBtn
                  text="Change Password"
                  variant="sm"
                  border="sm"
                  filled={false}
                  onClick={() => setPasswordModalOpen(true)}
                />
              </div>
              <div className="gap-200 flex flex-col mt-10">
                <p className="text-neutral-12 text-desktop-p-xl">
                  Delete Account
                </p>
                <p className="text-neutral-9">
                  If you like to delete your Fundrev account linked with:{" "}
                  <span className="text-blue-500">{email},</span> <br />
                  <span>
                    Please write us on{" "}
                    <a
                      href="mailto:admin@fundrev.ai"
                      className="text-blue-500"
                      style={{
                        textDecoration: "None",
                      }}
                    >
                      admin@fundrev.ai
                    </a>
                    .
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleFormSubmit}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
                handleSubmit,
                setTouched,
                validateForm,
              }) => (
                <div className="gap-200 pt-300 grid grid-cols-12">
                  <div className="col-span-1" />
                  <SectionContainer
                    title={"Basic Details"}
                    className="col-span-10"
                  >
                    <Form className="flex flex-col gap-6">
                      <div
                        className="upload-btn-wrapper"
                        style={{ marginTop: "0px" }}
                      >
                        <button
                          type="button"
                          onClick={() =>
                            document.getElementById("memberImg").click()
                          }
                        >
                          <img
                            src={
                              memberImgFile
                                ? URL.createObjectURL(memberImgFile)
                                : companyLogoPreview
                            }
                            style={{
                              cursor: "pointer",
                              border: "1px dashed #BFBFBF",
                              borderRadius: "6px",
                              objectFit: "contain",
                              padding:
                                companyLogoPreview !== uploadImg
                                  ? "19px 29px"
                                  : "0px",
                            }}
                            className="w-[130px] h-[110px]"
                            alt="UploadProfile"
                          />
                        </button>
                        {(memberImgFile instanceof File ||
                          companyLogoPreview !== uploadImg) && (
                          <label
                            htmlFor="memberImg"
                            className="relative -top-[85px] cursor-pointer ml-[10px]"
                          >
                            <img
                              src={editImg}
                              className="inline align-baseline w-[65px]"
                              alt="Edit"
                            />
                          </label>
                        )}
                        <input
                          id="memberImg"
                          type="file"
                          name="memberImg"
                          accept="image/png, image/jpeg" // Accept only JPEG and PNG
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              setFieldValue("memberImg", file);
                              setMemberImgFile(file);
                              setCompanyLogoPreview(URL.createObjectURL(file));
                            }
                          }}
                          style={{ display: "none" }}
                        />
                        {touched.memberImg && errors.memberImg && (
                          <div className="error">{errors.memberImg}</div>
                        )}
                      </div>
                      <div className=" gap-x-600 gap-y-300 grid grid-cols-3">
                        <StyledFormControl>
                          <StyledFormLabel>Full Name</StyledFormLabel>
                          <CustomInput
                            name={`name`}
                            placeholder={`Full Name`}
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        </StyledFormControl>
                        {/* Title */}
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Title</StyledFormLabel>
                          <CustomInput
                            placeholder="Title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                          />
                        </StyledFormControl>

                        {/* LinkedIn Profile */}

                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Email</StyledFormLabel>
                          <CustomInput
                            placeholder="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            disabled
                          />
                        </StyledFormControl>
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>LinkedIn Profile</StyledFormLabel>
                          <CustomInput
                            placeholder="LinkedIn Profile"
                            name="linkedIn"
                            value={values.linkedIn}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.linkedIn && errors.linkedIn)}
                            helperText={touched.linkedIn && errors.linkedIn}
                          />
                        </StyledFormControl>
                        {/* Phone Number */}
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Phone Number</StyledFormLabel>
                          <CustomPhoneInput
                            defaultCountry="IN"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            onChange={(phoneNumber) =>
                              setFieldValue("phoneNumber", phoneNumber)
                            }
                            error={Boolean(
                              touched.phoneNumber && errors.phoneNumber
                            )}
                            helperText={
                              touched.phoneNumber && errors.phoneNumber
                            }
                          />
                        </StyledFormControl>

                        {/* Biography */}
                        <StyledFormControl></StyledFormControl>
                        <div
                          style={{
                            gridColumn: "1/-2",
                          }}
                        >
                          <StyledFormControl fullWidth>
                            <StyledFormLabel>Biography</StyledFormLabel>
                            <CustomInput
                              placeholder="Biography"
                              name="biography"
                              value={values.biography}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(
                                touched.biography && errors.biography
                              )}
                              helperText={touched.biography && errors.biography}
                              multiline={true}
                              minRows={3}
                            />
                          </StyledFormControl>
                        </div>
                      </div>
                      <div className="showInterestButtons-div">
                        <div className="btns-insideContent">
                          <CustomBlackBtn
                            mode="danger"
                            filled={false}
                            text="Discard"
                            onClick={() => setEditMode(false)}
                          />

                          <CustomBlackBtn
                            text={"Save"}
                            type="button"
                            onClick={() => {
                              if (Object.keys(errors).length > 0) {
                                // Trigger Formik's validation to show errors
                                validateForm();
                                setTouched(
                                  Object.keys(errors).reduce(
                                    (acc, field) => ({ ...acc, [field]: true }),
                                    {}
                                  )
                                );
                              } else {
                                handleSubmit();
                                setEditMode(false);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Form>
                  </SectionContainer>
                </div>
              )}
            </Formik>
          </>
        )}
      </div>
    </>
  );
};

export default Account;
