import React, { useEffect } from "react";
import AnalyticsPageTitle from "./AnalyticsPlots/AnalyticsPageTitle";
import CashFlowStatementTable from "./AnalyticsPlots/CashFlowStatementTable";
import CashFlowSmallTable from "./AnalyticsPlots/CashFlowSmallTable";
import BalanceSheetTable from "./AnalyticsPlots/BalanceSheetTable";
import BalanceSheetSummaryTable from "./AnalyticsPlots/BalanceSheetSummaryTable";
import { dumpInvestorInteraction } from "../../endpoints/common";
import BankStatementPlot from "./AnalyticsPlots/bankStatementPlot";
import FinancialStatementTable from "./AnalyticsPlots/FinancialStatementTable";
import FinancialStatementBigComparisonTable from "./AnalyticsPlots/FinancialStatementBigComparisonTable";
import SmallCardGrid from "./AnalyticsPlots/SmallCardGrid";
import { isEmptyObject } from "jquery";
import FinancialPlot from "./AnalyticsPlots/FinancialPlot";
import SGAPiePlot from "./AnalyticsPlots/SGAPiePlot";

const HistoricalFinancials = ({ data, deal, stage, mapper }) => {
  const token = localStorage.getItem("token");
  useEffect(() => {
    const dumpInteraction = async () => {
      try {
        await dumpInvestorInteraction(
          {
            dealId: deal.dealId,
            type: "view",
            page: "analytics",
            stageOfDeal: stage,
            element: "page",
            pageSection: "historicalFinancials",
          },
          token
        );
      } catch (error) {
        console.error("Error dumping interaction", error);
      }
    };
    dumpInteraction();
  }, []);
  return (
    <div>
      <SmallCardGrid data={mapper?.fiveCards} title={`Financial Analysis`} />
      <div className="grid grid-cols-2 gap-250 mt-200">
        {!isEmptyObject(data?.financialData?.plotData || {}) &&
          mapper?.plots?.financialPlot?.isVisible && (
            <div className="bg-neutral-1 rounded-100 p-200 border border-neutral-5 h-[465px]">
              <FinancialPlot
                data={{
                  ...mapper?.plots?.financialPlot,
                  data: data?.financialData?.plotData,
                }}
              />
            </div>
          )}
        <div className="bg-neutral-1 rounded-100 p-200 border border-neutral-5 h-[465px]">
          <SGAPiePlot
            data={{
              ...mapper?.plots?.expenseBreakDown,
              data: data?.financialData?.tableData,
            }}
          />
        </div>
        <div className="bg-neutral-1 rounded-100 p-200 border border-neutral-5 col-span-2">
          <FinancialStatementBigComparisonTable
            data={{
              ...mapper?.tables?.financialBigCompTable,
              ...data?.financialData?.tableData,
            }}
          />
        </div>
        <div className="bg-neutral-1 rounded-100 border border-neutral-5 p-200 col-span-2">
          <FinancialStatementTable
            data={{
              ...mapper?.tables?.financialTable,
              data: data?.financialData?.tableData,
            }}
          />
        </div>
        <div className="bg-neutral-1 rounded-100 border border-neutral-5 p-200 col-span-2">
          <BalanceSheetTable
            data={{
              ...mapper?.tables?.balanceSheetTable,
              data: data?.balanceSheetData,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HistoricalFinancials;
