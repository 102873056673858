import React from "react";
import CustomBlackBtn from "../components/custom/customBlackBtn";
import CustomBlackBtnPhone from "../components/custom/customBlackBtnPhone";
import { Modal } from "@mui/joy";
const welcomeInvestor = ({
  open,
  values,
  navigateToInvestorProfile,
  handleClose,
}) => {
  return (
    <>
      <Modal
        className="laptop-design"
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="laptop-design flex flex-col items-center px-6 py-6 bg-white rounded-3xl max-w-[650px] shadow-[4px_4px_16px_rgba(161,161,161,0.12)] max-md:px-5 m-auto absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
          <div className="max-md:max-w-full flex flex-col items-center">
            <div className="max-md:max-w-full flex flex-col items-center text-xl text-gray-500">
              <div className="font-light leading-9 text-right">
                Welcome {values?.name},
              </div>
              <div className="text-zinc-900 mt-2">
                Building Strong Partnerships Starts Here
              </div>
              <div className="mt-2 text-base leading-7 text-center w-[80%]">
                As your trusted deal scouts, we value long-term relationships.
                Please ensure to:
              </div>
            </div>
            <div className="max-md:max-w-full flex flex-col items-center mt-6">
              <div className="max-md:max-w-full flex flex-wrap items-start gap-10">
                <div className="flex flex-col justify-center items-center px-4 py-3 rounded-lg border-blue-700 border-dashed shadow-sm border-[0.5px] w-[169px]">
                  <div className="flex flex-col items-center">
                    <div className="flex gap-2.5 justify-center items-center px-3 rounded-2xl border border-white border-solid bg-slate-50 h-[60px] min-h-[60px] w-[60px]">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/eac1be993afe5a1db8c1bac08f4d73bdcee084a194947421a924db1b9d2c70d0?placeholderIfAbsent=true&apiKey=73d3a062790540ae90ec23e7560be34f"
                        className="aspect-square self-stretch object-contain w-8 my-auto"
                      />
                    </div>
                    <div className="flex flex-col items-end mt-3 text-base text-center text-gray-500">
                      <div>Maintain Confidentiality</div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center px-4 py-3 rounded-lg border-blue-700 border-dashed shadow-sm border-[0.5px] w-[169px]">
                  <div className="flex flex-col items-center w-[92px]">
                    <div className="flex gap-2.5 justify-center items-center px-3 rounded-2xl border border-white border-solid bg-slate-50 h-[60px] min-h-[60px] w-[60px]">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9667b88470c0db2df748d6dda77bb56cd2daf8c5e55f000079334f3e7f8d4661?placeholderIfAbsent=true&apiKey=73d3a062790540ae90ec23e7560be34f"
                        className="w-9 aspect-square self-stretch object-contain my-auto"
                      />
                    </div>
                    <div className="flex flex-col items-end w-full mt-3 text-base text-center text-gray-500">
                      <div className="w-full">
                        Refrain from
                        <br />
                        Solicitation
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center px-4 py-3 rounded-lg border-blue-700 border-dashed shadow-sm border-[0.5px]">
                  <div className="flex flex-col items-center">
                    <div className="flex gap-2.5 justify-center items-center px-3 rounded-2xl border border-white border-solid bg-slate-50 h-[60px] min-h-[60px] w-[60px]">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f6b4831fa5df5fbd5c367d95e7d128d33f50b23d6a413a51fb2852dccae05e63?placeholderIfAbsent=true&apiKey=73d3a062790540ae90ec23e7560be34f"
                        className="aspect-square self-stretch object-contain w-8 my-auto"
                      />
                    </div>
                    <div className="flex flex-col items-end mt-3 text-base text-center text-gray-500">
                      <div>
                        Adhere to Platform
                        <br />
                        Terms of Use
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="max-md:max-w-full mt-2 text-base leading-7 text-gray-500">
                Together, let's discover exceptional investment opportunities.
              </div>
            </div>
          </div>
          <div className="py-5" onClick={navigateToInvestorProfile}>
            <CustomBlackBtn type="button" text="Explore Opportunities" />
          </div>
        </div>
      </Modal>
      <Modal
        className="phone-design"
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="phone-design flex flex-col items-center py-6 px-4 bg-white rounded-3xl width-[400px] shadow-[4px_4px_16px_rgba(161,161,161,0.12)] absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
          <div className="flex flex-col items-center w-full">
            <div className="flex flex-col items-center max-w-full text-gray-500 w-[326px]">
              <div className="text-xl font-light leading-9 text-right">
                Welcome {values?.name},
              </div>
              <div className="text-zinc-900 mt-2 text-sm">
                Building Strong Partnerships Starts Here
              </div>
              <div className="self-stretch mt-2 text-xs leading-5 text-center">
                As your trusted deal scouts, we value long-term relationships.
                Please ensure to:
              </div>
            </div>
            <div className="flex flex-col items-center mt-4 max-w-full w-[326px]">
              <div className="flex items-start gap-1">
                <div className="flex flex-col justify-center items-center px-1 py-2 rounded-lg border-blue-700 border-dashed shadow-sm border-[0.5px] w-[108px]">
                  <div className="flex flex-col items-center">
                    <div className="flex gap-2.5 justify-center items-center px-2 w-9 h-9 rounded-lg border border-white border-solid bg-slate-50">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f804220c05f4abcd8cc4072dde70b58c3de86ab0831f46e93362f2c3f4d29dba?placeholderIfAbsent=true&apiKey=73d3a062790540ae90ec23e7560be34f"
                        className="aspect-square self-stretch object-contain w-5 my-auto"
                      />
                    </div>
                    <div className="flex flex-col items-end mt-3 text-xs font-light text-center text-gray-500">
                      <div>Maintain Confidentiality</div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center px-1 py-2 rounded-lg border-blue-700 border-dashed shadow-sm border-[0.5px] w-[108px]">
                  <div className="flex flex-col items-center w-[67px]">
                    <div className="flex gap-2.5 justify-center items-center px-2 w-9 h-9 rounded-lg border border-white border-solid bg-slate-50">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7293bc0a05623380663ad01103fc829c4b97ed04771fe7d61aae118d61cfff08?placeholderIfAbsent=true&apiKey=73d3a062790540ae90ec23e7560be34f"
                        className="aspect-square self-stretch object-contain w-5 my-auto"
                      />
                    </div>
                    <div className="flex flex-col items-end w-full mt-3 text-xs font-light text-center text-gray-500">
                      <div className="w-full">Refrain from Solicitation</div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center px-1 py-2 rounded-lg border-blue-700 border-dashed shadow-sm border-[0.5px]">
                  <div className="flex flex-col items-center">
                    <div className="flex gap-2.5 justify-center items-center px-2 w-9 h-9 rounded-lg border border-white border-solid bg-slate-50">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3c9fd22488f3751e708f0073a6a180c8aa5a13de36310ba1c83a6b4a01427cbd?placeholderIfAbsent=true&apiKey=73d3a062790540ae90ec23e7560be34f"
                        className="aspect-square self-stretch object-contain w-5 my-auto"
                      />
                    </div>
                    <div className="flex flex-col items-end mt-3 text-xs font-light text-center text-gray-500">
                      <div>Adhere to Platform TnC</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 text-xs leading-7 text-gray-500">
                Together, let's discover exceptional investment opportunities.
              </div>
            </div>
          </div>
          <div
            className="w-full py-5 text-center"
            onClick={navigateToInvestorProfile}
          >
            <CustomBlackBtnPhone
              type="button"
              text="Explore Opportunities"
              className="w-full max-sm:w-[80%]"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default welcomeInvestor;
