import React, { useState } from "react";
import Plot from "react-plotly.js";
import { calculateDtick } from "./PlotUtilityFunctions";
import TimePeriodButton from "./TimePeriodButton";

const BankStatementPlot = ({ data }) => {
  const [type, setType] = useState("m");
  const xValues = Object?.keys(data.data[type] || {});

  const plotData = [
    {
      x: xValues,
      y: xValues.map((key) => data.data[type][key].inflow), // Map inflow values for each x
      type: "bar",
      name: "Inflow",
      marker: { color: "#20BDA6" },
      hovertemplate: `<b>Inflow</b>: %{y:,.0f}` + "<extra></extra>",
    },
    {
      x: xValues,
      y: xValues.map((key) => data.data[type][key].outflow), // Map outflow values for each x
      type: "bar",
      name: "Outflow",
      marker: { color: "#FFAC68" },
      hovertemplate: `<b>Outflow</b>: %{y:,.0f}` + "<extra></extra>",
    },
    {
      x: xValues,
      y: xValues.map((key) => data.data[type][key].balance), // Map balance values for each x
      type: "scatter",
      name: "Balance",
      line: { shape: "spline" },
      mode: "lines",
      marker: { color: "#0476B2" },
      hovertemplate: `<b>Balance</b>: %{y:,.0f}` + "<extra></extra>",
    },
  ];

  return (
    <>
      <TimePeriodButton type={type} title={data.name} setType={setType} />

      <div
        style={{ width: "100%", height: "100%", position: "relative" }}
        className="mt-200"
      >
        <Plot
          data={plotData}
          layout={{
            barmode: "group",
            barcornerradius: 2,
            margin: {
              l: 40,
              r: 60,
              t: 20,
              b: 105,
            },
            xaxis: {
              zeroline: true,
              zerolinewidth: 2,
              dtick: calculateDtick(type, Object?.keys(data.data[type] || {})),
              tickangle: 0,
              range: type === "m" &&
                xValues?.length > 12 && [
                  xValues.length - 12,
                  xValues.length - 1,
                ],
            },
            paper_bgcolor: "transparent",
            plot_bgcolor: "transparent",
            modebar: {
              activecolor: "#00A6FB",
              bgcolor: "transparent",
              color: "grey",
              orientation: "v",
            },
            hovermode: "x unified",
            legend: {
              orientation: "h",
              x: 0.5,
              y: -0.1,
              xanchor: "center",
              yanchor: "top",
            },
            hoverlabel: {
              bordercolor: "transparent",
              bgcolor: "#FFF",
            },
          }}
          config={{
            locale: "en-UK",
            displaylogo: false,
            responsive: true,
            modeBarButtonsToRemove: [
              "autoScale2d",
              "lasso2d",
              "select2d",
              "zoomIn2d",
              "zoomOut2d",
            ],
          }}
          useResizeHandler={true}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </>
  );
};

export default BankStatementPlot;
