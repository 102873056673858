import { parse, subMonths, subYears, format } from "date-fns";
import { useEffect, useState } from "react";

// Parse key to date, handling both MMM YY and Year YY formats
const parseKey = (key) => {
  const cleanKey = key?.replace(/\*/g, "").trim();
  // Check if key is in Year YY format
  if (cleanKey?.toLowerCase()?.startsWith("year")) {
    const yearPart = cleanKey?.slice(-2);
    return parse(yearPart, "yy", new Date());
  }
  return parse(cleanKey, "MMM yy", new Date());
};

// Format key based on the option type
const formatTargetDate = (date, option) => {
  if (option === "previousy") {
    return `Year ${format(date, "yy")}`;
  }
  return format(date, "MMM yy");
};

// Get the ideal key based on date and option
const getIdealKey = (currentDate, option) => {
  let targetDate;
  switch (option) {
    case "previousm":
      targetDate = subMonths(currentDate, 1);
      break;
    case "lastm":
      targetDate = subYears(currentDate, 1);
      break;
    case "previousq":
      targetDate = subMonths(currentDate, 3);
      break;
    case "lastq":
      targetDate = subYears(currentDate, 1);
      break;
    case "previousltm":
      targetDate = subMonths(currentDate, 12);
      break;
    case "previousy":
      targetDate = subYears(currentDate, 1);
      break;
    default:
      return format(currentDate, "MMM yy");
  }

  return option === "previousy"
    ? `Year ${format(targetDate, "yy")}`
    : format(targetDate, "MMM yy");
};

// Get relative key based on reference key and option
const getRelativeKey = (keys, currentKey, option, includeIdeal = true) => {
  // Convert all keys to dates for comparison while keeping original keys
  const keyDates = keys.map((key) => ({
    originalKey: key,
    date: parseKey(key),
  }));

  // Sort by date
  const currentDate = parseKey(currentKey);

  // Get the ideal target key
  const idealKey = getIdealKey(currentDate, option);

  // If we don't want ideal keys or we have an exact match, proceed with existing logic
  const targetDate = parseKey(idealKey);
  const targetFormat = formatTargetDate(targetDate, option);

  // Find exact match
  const match = keyDates.find((k) => {
    const kFormat =
      option === "previousy"
        ? `Year ${format(k.date, "yy")}`
        : format(k.date, "MMM yy");
    return kFormat === targetFormat;
  });
  // If match found, check if original key had asterisk
  if (match) {
    const hasAsterisk = currentKey.includes("*");
    const matchingKeys = keys.filter((k) => {
      const kFormat =
        option === "previousy"
          ? `Year ${format(parseKey(k), "yy")}`
          : format(parseKey(k), "MMM yy");
      return kFormat === targetFormat;
    });

    if (hasAsterisk) {
      const asteriskMatch = matchingKeys.find((k) => k.includes("*"));
      if (asteriskMatch) return { key: asteriskMatch, isIdeal: false };
    }

    return { key: match.originalKey, isIdeal: false };
  }

  // If no match found and includeIdeal is true, return the ideal key
  if (includeIdeal) {
    // If current key has asterisk, add it to ideal key
    return {
      key: currentKey.includes("*") ? `${idealKey}*` : idealKey,
      isIdeal: true,
    };
  }

  // If no match and not including ideal keys, return most recent
  return { key: null, isIdeal: false };
};

// Main hook for key selection
const useKeySelection = (data, type, option, keys, includeIdeal = true) => {
  const [key, setKey] = useState("");
  const [ckey, setCkey] = useState("");
  const [isIdeal, setIsIdeal] = useState(false);

  // Set current key (most recent by default)
  useEffect(() => {
    if (keys.length > 0) {
      setKey(keys[keys.length - 1]);
    }
  }, [type, data, keys]);

  // Set comparison key based on option
  useEffect(() => {
    if (keys.length > 0 && key) {
      const result = getRelativeKey(keys, key, option, includeIdeal);
      setCkey(result.key);
      setIsIdeal(result.isIdeal);
    }
  }, [data, key, option, keys, includeIdeal]);

  return { key, ckey, isIdeal };
};

export { useKeySelection, getRelativeKey };
