import React, { useEffect, useState } from "react";
import {
  StyledFormControl,
  StyledFormLabel,
  StyledFormLabelOptional,
  getCompanyLogo,
  investorTypeOptions,
} from "../../../../Common/functions";
import { useFormikContext } from "formik";
import CustomInput from "../../../../Common/components/custom/customInput";
import CustomSelect from "../../../../Common/components/custom/customSelect";
import uploadImg from "../../../../../Assets/Images/signup/upload-img.svg";
import editImg from "../../../../../Assets/Images/signup/edit-img.svg";

const InvestmentFirmDetails = ({
  investorLogoPreview,
  setInvestorLogoPreview,
  investor,
}) => {
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } =
    useFormikContext();
  useEffect(() => {
    try {
      if (investor.investorLogo === "" || !investor.investorLogo) {
        setFieldValue("investorLogo", getCompanyLogo(values.investorWebsite));
        setInvestorLogoPreview(getCompanyLogo(values.investorWebsite));
      }
    } catch (error) {}
  }, [values.investorWebsite]);
  return (
    <div className="gap-300 relative flex flex-col flex-1 w-full">
      <div className="gap-100 relative flex flex-col overflow-hidden w-[130px]">
        <StyledFormLabel>Logo</StyledFormLabel>
        <button
          type="button"
          className="cursor-pointer"
          onClick={() => document.getElementById("investorLogo").click()}
        >
          <img
            src={investorLogoPreview}
            alt="UploadImage"
            style={{
              width: values.investorLogo !== uploadImg ? "130px" : "130px",
              height: values.investorLogo !== uploadImg ? "110px" : "110px",
              border: "1px dashed #BFBFBF",
              borderRadius: "6px",
              objectFit: "contain",
              padding: values.investorLogo !== uploadImg ? "19px 29px" : "0px",
            }}
            onError={(e) => {
              e.target.src = uploadImg; // Fallback to uploadImg if investorLogoPreview fails
            }}
          />
        </button>
        {/* {hovered && investorLogoPreview !== uploadImg && (
            <div className="absolute top-[50px] left-[30px] flex items-center justify-center w-[70px] h-[62px] bg-black bg-opacity-50 rounded-md">
              <span
                className="font-bold text-white cursor-pointer"
                style={{
                  pointerEvents: "auto", // Allow clicking on the delete button
                }}
                onClick={() => {
                  // Reset the uploaded image
                  setInvestorLogoPreview(uploadImg);
                }}
              >
                Delete
              </span>
            </div>
          )} */}
        <input
          id="investorLogo"
          type="file"
          name="investorLogo"
          accept="image/png, image/jpeg"
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              setFieldValue("investorLogo", file);
              const reader = new FileReader();
              reader.onloadend = () => {
                setInvestorLogoPreview(reader.result);
              };
              reader.readAsDataURL(file);
            }
          }}
          className="hidden"
        />
        {errors.investorLogo && (
          <div className="text-xs text-red-600">{errors.investorLogo}</div>
        )}
      </div>
      {investorLogoPreview !== uploadImg && (
        <label
          htmlFor="memberImg"
          className="absolute cursor-pointer ml-[10px] left-[130px] top-[30px]"
        >
          <img
            src={editImg}
            className="inline align-baseline w-[65px]"
            alt="Edit"
            onClick={() => document.getElementById("investorLogo").click()}
          />
        </label>
      )}
      <div className="gap-y-300 gap-x-600 grid grid-cols-3">
        <StyledFormControl fullWidth>
          <StyledFormLabel>Investor Firm Name</StyledFormLabel>
          <CustomInput
            placeholder="Enter Company Name"
            name="companyName"
            value={values.companyName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.companyName && errors.companyName)}
            helperText={touched.companyName && errors.companyName}
          />
        </StyledFormControl>
        <StyledFormControl fullWidth>
          <StyledFormLabelOptional>
            Headquarters Location
          </StyledFormLabelOptional>
          <CustomInput
            placeholder="Enter Headquarters"
            name="headquarters"
            value={values.headquarters}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.headquarters && errors.headquarters)}
            helperText={touched.headquarters && errors.headquarters}
          />
        </StyledFormControl>
        <StyledFormControl fullWidth>
          <StyledFormLabelOptional>Founded Year</StyledFormLabelOptional>
          <CustomInput
            placeholder="Enter Founded Year"
            name="foundedYear"
            type="number"
            value={values.foundedYear}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.foundedYear && errors.foundedYear)}
            helperText={touched.foundedYear && errors.foundedYear}
          />
        </StyledFormControl>
        <StyledFormControl fullWidth>
          <StyledFormLabelOptional>Number of Employees</StyledFormLabelOptional>
          <CustomInput
            placeholder="Enter no. of Employees"
            name="employeeCount"
            type="number"
            value={values.employeeCount}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.employeeCount && errors.employeeCount)}
            helperText={touched.employeeCount && errors.employeeCount}
          />
        </StyledFormControl>
        <StyledFormControl fullWidth>
          <StyledFormLabel>Investor Type</StyledFormLabel>
          <CustomSelect
            label="Investor Type"
            name="investorType"
            multiple={false}
            value={values.investorType}
            onChange={handleChange}
            options={investorTypeOptions}
            placeholder="Select Investor Type"
            error={Boolean(touched.investorType && errors.investorType)}
            helperText={touched.investorType && errors.investorType}
          />
        </StyledFormControl>
        <StyledFormControl fullWidth>
          <StyledFormLabel>Website</StyledFormLabel>
          <CustomInput
            placeholder="Enter Website"
            name="investorWebsite"
            value={values.investorWebsite}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.investorWebsite && errors.investorWebsite)}
            helperText={touched.investorWebsite && errors.investorWebsite}
          />
        </StyledFormControl>

        <StyledFormControl
          fullWidth
          sx={{
            gridColumn: "1/-2",
          }}
        >
          <StyledFormLabel>Firm Description</StyledFormLabel>
          <CustomInput
            placeholder="Enter Firm Description"
            name={`investorDescription`}
            value={values.investorDescription}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(
              touched.investorDescription && errors.investorDescription
            )}
            helperText={
              touched.investorDescription && errors.investorDescription
            }
            multiline
            minRows={3}
          />
        </StyledFormControl>
      </div>
    </div>
  );
};

export default InvestmentFirmDetails;
