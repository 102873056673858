import React, { useEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import supportIcon from "../../../Assets/Images/icons/supportIcon.svg";
import CustomTooltip from "./custom/customToolTip";
import {
  ClickAwayListener,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from "@mui/material";
import cross from "../../../Assets/Images/signup/cross.svg";
import FundrevAnalystDetails from "./fundrevAnalystDetails";
import CustomBlackBtn from "./custom/customBlackBtn";
import { StyledFormControl, StyledFormLabel } from "../functions";
import CustomInput from "./custom/customInput";
import CustomPhoneInput from "./custom/customPhoneInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { matchIsValidTel } from "mui-tel-input";
import {
  getAnalystCalendarFreeSlots,
  postSupportMessage,
  scheduleMeetingWithAnalyst,
} from "../../../endpoints/common";
import timezone from "dayjs/plugin/timezone";
import Toast from "../Toast";
import { ToastContainer } from "react-toastify";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useLoader } from "../LoaderProvider";
import CalendarIcon from "../../../Assets/Images/icons/calendarIcon.svg";
dayjs.extend(timezone);

const SupportButton = () => {
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem(`token`);
  const [step, setStep] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [freeSlots, setFreeSlots] = useState([]);
  const [data, setData] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      margin: theme.spacing(0.5),
      borderRadius: "4px",
      height: "42px",
      border: "1px solid #1a1a1a",
      color: "#1a1a1a",
      width: "100px",
      '&[aria-pressed="true"]': {
        color: "white",
        background: "var(--brand-color)",
        borderColor: "var(--brand-color)",
        border: "1px solid var(--brand-color)",
        "&:hover": {
          backgroundColor: "var(--brand-color)",
        },
      },
    },
  }));
  const loader = useLoader();
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedSlot(null);
  };
  useEffect(() => {
    if (selectedDate && data?.length > 0) {
      // Get the current time and add the IST offset (5 hours 30 minutes)
      const currentTime = dayjs().tz(`Asia/Kolkata`);

      // Filter the slots based on the selected date and time
      const filteredSlots = data.filter((slot) => {
        // Parse the start and end times as dayjs objects, assuming they are in IST
        const slotStart = dayjs(slot.start).tz(`Asia/Kolkata`);
        // Check if the slot's date matches the selectedDate
        const isSameDate = slotStart.isSame(selectedDate, "day");

        // Ensure the slot's start time is greater than the current time
        const isAfterCurrentTime = slotStart.isAfter(currentTime);
        return isSameDate && isAfterCurrentTime;
      });
      // Set the filtered slots to the state
      setFreeSlots(filteredSlots);
    }
  }, [data, selectedDate]);
  const shouldDisableDate = (date) => {
    if (data?.length > 0) {
      // Get the current time with IST timezone
      const currentTime = dayjs().tz("Asia/Kolkata");

      // Filter slots based on selected date and time
      const availableSlots = data?.filter((slot) => {
        // Parse the slot's start time as a dayjs object in IST
        const slotStart = dayjs(slot.start).tz("Asia/Kolkata");
        // Check if the slot's date matches the selected date
        const isSameDate = slotStart.isSame(date, "day");
        // Ensure the slot's start time is after the current time
        const isAfterCurrentTime = slotStart.isAfter(currentTime);
        return isSameDate && isAfterCurrentTime;
      });

      // Disable the date if there are no available slots
      return availableSlots.length === 0;
    } else {
      return true;
    }
  };
  const handleClose = () => {
    if (open) {
      setStep(1);
      setOpen(false);
    }
  };
  const handleSchedule = async () => {
    if (selectedSlot) {
      loader.start(`Scheduling meeting ...`);
      const response = await scheduleMeetingWithAnalyst(
        {},
        selectedSlot,
        token
      );
      loader.stop();
      if (response) {
        Toast("Meeting scheduled successfully", "success", "supportMessage");
        handleClose();
      }
    } else {
      Toast(`Please select a slot.`, `info`, `supportMessage`);
    }
  };
  const handleSlotClick = (e) => {
    setSelectedSlot(e.target.value);
  };
  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    supportMessage: "",
  };

  const fetchFreeSlots = async () => {
    if (step === 2) {
      loader.start(`Fetching Slots`);
      const response = await getAnalystCalendarFreeSlots();
      loader.stop();
      setData(response);
    }
  };
  useEffect(() => {
    fetchFreeSlots();
  }, [step]);

  useEffect(() => {}, [step, token]);
  const validationSchema1 = Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .test("invalid", "Please enter a valid phone number", (value) =>
        matchIsValidTel(value)
      ),
    email: Yup.string().email("Invalid email").required("Email is required"),
    supportMessage: Yup.string().required("Message is required"),
  });

  const validationSchema2 = Yup.object().shape({
    supportMessage: Yup.string().required("Message is required"),
  });

  const handleSubmit = async (values) => {
    const response = await postSupportMessage(values, token);
    if (response?.data) {
      setOpen(false);
      setStep(1);
      Toast("Support message sent successfully!", `success`, `supportMessage`);
    } else {
      Toast("Failed to send support message!", `error`, `supportMessage`);
    }
  };
  return (
    <>
      <ToastContainer containerId={`supportMessage`} position="top-center" />
      {/* Tooltip and Floating Button */}
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        className="bottom-10 right-10 hover:scale-105 fixed z-30 flex items-center justify-center w-12 h-12 text-white transition-transform transform bg-blue-500 rounded-full shadow-lg cursor-pointer"
      >
        <img src={supportIcon} alt="Support" />
      </button>

      <ClickAwayListener
        onClickAway={() => {
          if (open) handleClose();
        }}
      >
        <div>
          <Slide direction="left" in={open} mountOnEnter unmountOnExit>
            <div
              className="fixed top-0 right-0 min-w-80 w-[380px] h-full bg-white shadow-lg z-[70] overflow-y-auto"
              onClick={(e) => e.stopPropagation()} // Prevent click-away on drawer content
            >
              <div className="flex items-center justify-between p-4">
                <p className="text-xl font-semibold">Need Help?</p>
                <button onClick={handleClose}>
                  <img src={cross} alt="cross" className="w-3 text-black" />
                </button>
              </div>
              <div className="h-[1px] bg-[#EDEDED] w-[100%]" />
              {step === 1 && (
                <div className="px-4 mt-4">
                  <div className="bg-white border border-[#d4d4d4] p-4 rounded-lg">
                    <FundrevAnalystDetails analystID={`FN123456`} />
                    <div className="mt-2">
                      <CustomBlackBtn
                        text={
                          <div className="flex items-center gap-2">
                            <img
                              alt="cal"
                              src={CalendarIcon}
                              className="mb-0.5"
                            />{" "}
                            Schedule a Call
                          </div>
                        }
                        filled={false}
                        border="sm"
                        variant="sm"
                        width="100%"
                        type="button"
                        style={{
                          borderRadius: "4px",
                        }}
                        onClick={() => {
                          if (token) {
                            setStep(2);
                          } else {
                            window.open(
                              "https://calendly.com/hello-fundrev",
                              "_blank"
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {step === 1 && (
                <div className="p-4 mt-4">
                  <div className="text-base font-semibold text-[var(--Primary-Text-Color)] mb-4">
                    Write Us a Message
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={
                      token ? validationSchema2 : validationSchema1
                    }
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(true);
                      handleSubmit(values); // Pass values to your custom submit handler
                      setSubmitting(false);
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      values,
                      setFieldValue,
                      handleSubmit,
                    }) => (
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault(); // Prevent default form submission
                          handleSubmit(e); // Call Formik's submit handler
                        }}
                      >
                        <div className="flex flex-col gap-4">
                          {!token && (
                            <StyledFormControl fullWidth>
                              <StyledFormLabel>Name</StyledFormLabel>
                              <CustomInput
                                name="name"
                                placeholder="Enter Name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                              />
                            </StyledFormControl>
                          )}
                          {!token && (
                            <StyledFormControl fullWidth>
                              <StyledFormLabel>Phone Number</StyledFormLabel>
                              <CustomPhoneInput
                                name="phoneNumber"
                                placeholder="Enter Phone Number"
                                value={values.phoneNumber}
                                onChange={(e) =>
                                  setFieldValue("phoneNumber", e)
                                }
                                error={Boolean(
                                  touched.phoneNumber && errors.phoneNumber
                                )}
                                helperText={
                                  touched.phoneNumber && errors.phoneNumber
                                }
                              />
                            </StyledFormControl>
                          )}
                          {!token && (
                            <StyledFormControl fullWidth>
                              <StyledFormLabel>Email</StyledFormLabel>
                              <CustomInput
                                name="email"
                                placeholder="Enter Email Address"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                              />
                            </StyledFormControl>
                          )}
                          <StyledFormControl fullWidth>
                            <StyledFormLabel>Your Message</StyledFormLabel>
                            <CustomInput
                              name="supportMessage"
                              multiline
                              minRows={token ? 5 : 2}
                              placeholder="Please enter your message"
                              value={values.supportMessage}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(
                                touched.supportMessage && errors.supportMessage
                              )}
                              helperText={
                                touched.supportMessage && errors.supportMessage
                              }
                            />
                          </StyledFormControl>
                        </div>
                        <div className="flex justify-end w-full mt-2">
                          <CustomBlackBtn
                            type="submit"
                            variant="sm"
                            text="Submit"
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
              {step === 2 && (
                <div className="p-4">
                  <div className="flex items-center gap-4 mb-4">
                    <ArrowLeftIcon onClick={() => setStep(1)} />
                    <p className="text-base font-semibold">Schedule a Call</p>
                  </div>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        disablePast
                        showDaysOutsideCurrentMonth
                        views={["day"]}
                        value={selectedDate}
                        onChange={handleDateChange}
                        shouldDisableDate={shouldDisableDate}
                        minDate={dayjs().tz(`Asia/Kolkata`).add(24, "hours")}
                        maxDate={dayjs()
                          .tz(`Asia/Kolkata`)
                          .add(9, "day")
                          .add(24, "hours")}
                        sx={{
                          width: 348,
                          margin: 0,
                          "& .Mui-selected": {
                            backgroundColor: "var(--brand-color)", // Set selected date background
                            color: "white", // Optionally set the text color for selected date
                          },
                          "& .Mui-selected:hover": {
                            backgroundColor: "var(--brand-color)", // Maintain the color on hover
                          },
                          "& .Mui-selected:focus": {
                            backgroundColor: "var(--brand-color)", // Maintain the color on focus
                          },
                        }}
                      />
                    </LocalizationProvider>
                    <StyledToggleButtonGroup
                      exclusive
                      aria-label="Period"
                      value={selectedSlot}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: freeSlots?.length === 0 && "center",
                        flexWrap: "wrap",
                      }}
                      onChange={handleSlotClick}
                    >
                      {freeSlots?.length > 0
                        ? freeSlots.map((freeSlot, index) => (
                            <ToggleButton key={index} value={freeSlot.start}>
                              {dayjs(freeSlot.start).format("HH:mm")}
                            </ToggleButton>
                          ))
                        : freeSlots?.length === 0 && (
                            <div>
                              {selectedDate ? (
                                <div>No slots available.</div>
                              ) : (
                                <div>Please select a date</div>
                              )}
                            </div>
                          )}
                    </StyledToggleButtonGroup>
                  </div>
                  <div className="flex justify-center w-full mt-4">
                    <CustomBlackBtn
                      text={`Schedule`}
                      variant="sm"
                      type="button"
                      disabled={!selectedSlot}
                      onClick={handleSchedule}
                      additionalClasses="disabled:opacity-85"
                    />
                  </div>
                </div>
              )}
            </div>
          </Slide>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default SupportButton;
