import React, { useEffect, useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import { formatToLakhs } from "../../../utilities/numberFormatter.js";
import { isEmptyObject } from "jquery";

const BalanceSheetTable = ({ data }) => {
  const [type, setType] = useState("m");
  useEffect(() => {}, [type, data]);
  const isLTMVisible = () => {
    if (
      !isEmptyObject(data?.data?.["ltm"]?.["Assets"]?.values) &&
      !isEmptyObject(data?.data?.["ltm"]?.["Equity and Liability"]?.values)
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <div>
        <TimePeriodButton
          type={type}
          setType={setType}
          ltm={isLTMVisible()}
          title={data.name}
          paddingRight={`0px`}
        />
        {data && (
          <div className="overflow-x-auto mt-200">
            <table aria-label="Balance Sheet Table">
              <thead>
                {[
                  "Balance Sheet",
                  ...(Object.keys(
                    data?.data?.[type]?.[
                      Object.keys(data?.data?.[type] || {})[0]
                    ]?.values || {}
                  ) || []),
                ].map((column, index) => (
                  <td
                    key={index}
                    align={index === 0 ? "left" : "right"}
                    className={`p-150 bg-neutral-3 rounded-050 text-desktop-p-s sticky font-medium text-neutral-8 text-nowrap !uppercase ${
                      index === 0 ? "text-left left-0 z-10" : "text-right"
                    }`}
                  >
                    {column}
                  </td>
                ))}
              </thead>
              <tbody>
                {Object?.entries(data?.data?.[type] || {})?.map(
                  ([key, row], index) => {
                    return (
                      <>
                        {/* For empty title */}
                        <tr key={key + `-empty`}>
                          <td
                            className={`text-desktop-p-s sticky p-150 !border-b-neutral-4 bg-neutral-1 text-left z-10 left-0 text-nowrap`}
                            align="left"
                          >
                            {row.name}
                          </td>
                          {Object.keys(row.emptyValues).map((key, index) => (
                            <td
                              className="p-150 bg-neutral-1 !border-b-neutral-4 text-right text-desktop-p-s"
                              key={index}
                            >
                              {" "}
                            </td>
                          ))}
                        </tr>
                        {row.mappers &&
                          row.mappers.map((mapper) => {
                            const mapperKey = Object.keys(mapper)[0];
                            const keys = Object.keys(mapper[mapperKey]);
                            return keys && keys.length > 1 ? (
                              <>
                                {/* For  mapper empty title */}
                                <tr>
                                  <td
                                    className={`text-desktop-p-s sticky p-150 pl-250 !border-b-neutral-4 bg-neutral-1 text-left z-10 left-0 text-nowrap`}
                                    align="left"
                                  >
                                    {mapperKey}
                                  </td>
                                  {Object.keys(row.emptyValues).map(
                                    (key, index) => (
                                      <td
                                        className="p-150 bg-neutral-1 !border-b-neutral-4 text-right text-desktop-p-s"
                                        key={index}
                                      >
                                        {" "}
                                      </td>
                                    )
                                  )}
                                </tr>
                                {/* Detailed mapper with values */}
                                {Object.entries(mapper[mapperKey]).map(
                                  ([key, values], index) => (
                                    <tr>
                                      <td
                                        className={`text-desktop-p-s sticky p-150 pl-400 !border-b-neutral-4 bg-neutral-1 text-left z-10 left-0 text-nowrap`}
                                        align="left"
                                      >
                                        {key}
                                      </td>
                                      {Object?.values(values).map(
                                        (value, key) => (
                                          <td className="p-150 bg-neutral-1 !border-b-neutral-4 text-right text-desktop-p-s">
                                            {formatToLakhs(value) || `-`}
                                          </td>
                                        )
                                      )}
                                    </tr>
                                  )
                                )}
                              </>
                            ) : (
                              <tr>
                                <td
                                  className={`text-desktop-p-s sticky p-150 pl-250 !border-b-neutral-4 bg-neutral-1 text-left z-10 left-0 text-nowrap`}
                                  align="left"
                                >
                                  {mapperKey}
                                </td>
                                {Object.values(
                                  mapper[mapperKey][mapperKey]
                                ).map((value, index) => (
                                  <td
                                    className="p-150 bg-neutral-1 !border-b-neutral-4 text-right text-desktop-p-s"
                                    key={index}
                                  >
                                    {formatToLakhs(value) || `-`}
                                  </td>
                                ))}
                              </tr>
                            );
                          })}
                        {/* Title with Values */}
                        {row.name !== "" && (
                          <tr key={key + `-values`}>
                            <td
                              className={`text-desktop-p-s sticky p-150 !border-b-neutral-4 text-left z-10 left-0 text-nowrap !border-t-[1.5px] bg-aero-blue-50  !border-t-aero-blue-700 font-bold`}
                              align="left"
                            >
                              {`Total ` + row?.name}
                            </td>
                            {row.values &&
                              Object.keys(row?.values).map((key, index) => (
                                <td
                                  key={index}
                                  className="p-150 !border-b-neutral-4 text-right text-desktop-p-s !border-t-[1.5px] bg-aero-blue-50  !border-t-aero-blue-700 font-bold"
                                >
                                  {formatToLakhs(row.values[key]) || `-`}
                                </td>
                              ))}
                          </tr>
                        )}
                      </>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default BalanceSheetTable;
