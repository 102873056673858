import React from "react";

import { addHTTPS } from "../../../Common/functions";

import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import { ImLinkedin2 } from "react-icons/im";
import defaultPerson from "../../../../Assets/Images/icons/defaultPerson.svg";
import ReadMore from "../../../Common/components/readMore";

export const AccountHeader = ({ details }) => {
  return (
    <div className="gap-300 flex flex-col">
      <div className="flex flex-row justify-between">
        <div className="gap-200 flex items-center">
          <img
            src={details.memberImg || details.profile || defaultPerson}
            alt="User profile"
            className="w-16 h-16 rounded-[4px]"
          />
          <div className="flex flex-col">
            <p className="text-desktop-p-xl text-neutral-12">{details.name}</p>
            <p className="text-neutral-8 text-desktop-p-l">{details.title}</p>
          </div>
        </div>
        <div className="gap-300 flex">
          <div className="gap-150 flex items-center">
            <PhoneIcon fontSize="12px" />
            <p className="text-desktop-p-s">{details.phoneNumber}</p>
          </div>
          <div className="gap-150 flex items-center">
            <MailIcon fontSize="12px" />
            <a
              href={`mailto:${details.email}`}
              className="text-desktop-p-s text-blue-500"
            >
              {details.email}
            </a>
          </div>
          <div className="gap-150 flex items-center">
            {details.linkedIn && (
              <>
                <ImLinkedin2 fontSize="12px" />
                <a
                  href={addHTTPS(details.linkedIn)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-desktop-p-s text-blue-500"
                >
                  {details.name}
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        <p className="text-desktop-p-mb text-neutral-12 font-normal">
          <ReadMore text={details.biography} maxLines={3} />
        </p>
      </div>
    </div>
  );
};

export default AccountHeader;
