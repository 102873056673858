import React, { useState } from "react";
import { changePassword } from "../../../endpoints/common";
import Toast from "../Toast";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import { Box, DialogContent } from "@mui/material";
import { StyledFormControl, StyledFormLabel } from "../functions";
import CustomPasswordInput from "../components/custom/customPasswordInput";
import { Form, Formik } from "formik";
import CustomBlackBtn from "../components/custom/customBlackBtn";
import { CustomDialogWithActions } from "../components/custom/customDialog";

const ChangePasswordModal = ({
  passwordModalOpen,
  setPasswordModalOpen,
  token,
  containerId,
}) => {
  const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };
  const [allStates, setAllStates] = useState({
    lowerValidated: false,
    upperValidated: false,
    numberValidated: false,
    specialValidated: false,
    lengthValidated: false,
  });

  const receiveStatesFromChild = (states) => {
    setAllStates(states);
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Current Password is required"),
    password: Yup.string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/(?=.*[0-9])/, "Password must contain at least one number")
      .matches(
        /(?=.*[!@#$%^&*])/,
        "Password must contain at least one special character"
      )
      .notOneOf(
        [Yup.ref("oldPassword")],
        "New password cannot be the same as the old password"
      ), // custom rule to ensure oldPassword !== newPassword
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const handleSubmit = async (values) => {
    try {
      const body = {
        password: values.oldPassword,
        newPassword: values.password,
      };

      const response = await changePassword(body, token);
      if (response.error) {
        const statusCode = response.error.response.status;
        if (statusCode === 400) {
          Toast("Password not provided", "error", containerId);
        } else if (statusCode === 401) {
          Toast("Incorrect password", "error", containerId);
        } else if (statusCode === 404) {
          Toast("Member not found", "success", containerId);
        }
      } else if (response.response.status === 200) {
        Toast("Password updated successfully", "success", containerId);
        setPasswordModalOpen(false);
      }
    } catch (e) {
      Toast("Internal server error", "error", containerId);
    }
  };
  return (
    <CustomDialogWithActions
      open={passwordModalOpen}
      onClose={() => setPasswordModalOpen(false)}
      className="w-[400px] justify-start"
    >
      <div className="px-100 flex flex-col w-full">
        <p className="text-desktop-p-xl text-neutral-12 mt-200 w-full font-bold text-left">
          Reset Password
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleChange, values }) => (
            <Form className="gap-300 py-200 flex flex-col items-center justify-center w-full">
              <StyledFormControl fullWidth>
                <StyledFormLabel>Enter Current Password</StyledFormLabel>
                <CustomPasswordInput
                  placeholder="Enter current password"
                  name="oldPassword"
                  value={values.oldPassword}
                  onChange={handleChange}
                  error={Boolean(touched.oldPassword && errors.oldPassword)}
                  helperText={touched.oldPassword && errors.oldPassword}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth>
                <StyledFormLabel>Create New Password</StyledFormLabel>
                <CustomPasswordInput
                  placeholder="Enter new password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  sendStatesToParent={receiveStatesFromChild}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth>
                <StyledFormLabel>Confirm New Password</StyledFormLabel>
                <CustomPasswordInput
                  placeholder="Re-enter new password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  sendStatesToParent={receiveStatesFromChild}
                />
              </StyledFormControl>
              <CustomBlackBtn
                type="submit"
                text="Submit"
                mode="blue"
                border="sm"
              />
            </Form>
          )}
        </Formik>
      </div>
    </CustomDialogWithActions>
  );
};

export default ChangePasswordModal;
