const calculateDtick = (type, ticksLength) => {
  let dtick;
  switch (type) {
    case "m":
      dtick = Math.ceil(ticksLength / 10);
      break;
    case "q":
      dtick = Math.ceil(ticksLength / 8);
      break;
    case "y":
      dtick = Math.ceil(ticksLength / 6);
      break;
    case "ltm":
      dtick = Math.ceil(ticksLength / 10);
      break;
    default:
      dtick = 1; // Default to show every tick
  }
  return dtick;
};

const calculateTotals = (data, type) => {
  const totals = [];
  const lengthOfLists = data?.[Object?.keys(data || {})[0]][type].length;

  for (let i = 0; i < lengthOfLists; i++) {
    let totalAtIndex = 0;
    for (let category in data) {
      totalAtIndex += data[category][type][i];
    }
    totals.push(totalAtIndex);
  }

  return totals;
};

export { calculateDtick, calculateTotals };
