import React, { useEffect, useState } from "react";

import CustomInput from "../../../Common/components/custom/customInput";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import CustomPhoneInput from "../../../Common/components/custom/customPhoneInput";
import { isProfileComplete } from "./profileCompletion";

import {
  StyledFormControl,
  StyledFormLabel,
  StyledFormLabelOptional,
} from "../../../Common/functions";

import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useLoader } from "../../../Common/LoaderProvider";
import Toast from "../../../Common/Toast";

import uploadImg from "../../../../Assets/Images/signup/upload-img.svg";
import {
  fetchStartupDetails,
  getProfileCompletionPercentage,
  PostDetails,
} from "../../../../endpoints/startup";
import { matchIsValidTel } from "mui-tel-input";
import { GoPlus } from "react-icons/go";
import { useRecoilState } from "recoil";
import { companyState } from "../companyState";
import { useLocation } from "react-router-dom";
import { getStartupDetails } from "../../../../endpoints/admin";

const FoundingTeamProfile = ({ isAdmin }) => {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const location = useLocation();
  const [startupId, setStartupId] = useState(
    location?.state?.startupId || null
  );
  const { dealId } = location.state || {};
  const [state, setState] = useRecoilState(companyState);
  const [profileImagePreview, setProfileImagePreview] = useState([uploadImg]);
  const [foundingMember, setFoundingMember] = useState([]);
  const [companyDetails, setCompanyDetails] = useState("");
  const [initialValues, setInitialValues] = useState({
    foundingMember: [
      {
        name: "",
        title: "",
        email: "",
        linkedin: "",
        phoneNumber: "",
        biography: "",
        foundingTeamProfile: null,
      },
    ],
    isApproved: false,
  });

  const validationSchema = Yup.object().shape({
    foundingMember: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Full Name is required"),
        title: Yup.string().required("Title is required"),
        email: Yup.string()
          .email("Enter a valid Email")
          .required("Email is required"),
        // linkedin: Yup.string()
        //   .url("Enter a valid LinkedIn URL")
        //   .required("LinkedIn is required"),
        phoneNumber: Yup.string()
          .required("Phone Number is required")
          .test("invalid", "Please enter a valid phone number", (value) =>
            matchIsValidTel(value)
          ),
        biography: Yup.string().required("Biography is required"),
        foundingTeamProfile: Yup.mixed().required("Profile Image is required"),
      })
    ),
  });

  const fetchDetails = async () => {
    if (!token) return;

    try {
      loader.start();
      const response = await fetchStartupDetails(token);
      loader.stop();

      if (response?.data) {
        const data = response.data.data;

        // Handle initial values
        setInitialValues({
          foundingMember: data.foundingMember?.length
            ? data.foundingMember // Directly use the array of objects
            : [
                {
                  name: "",
                  title: "",
                  email: "",
                  linkedin: "",
                  phoneNumber: "",
                  biography: "",
                  foundingTeamProfile: null,
                },
              ],
          isApproved: data.isApproved,
        });

        // Set company details
        setCompanyDetails(data);

        // Set founding members
        setFoundingMember(data.foundingMember || []);

        // Set profile image previews
        if (data.foundingMember?.length) {
          setProfileImagePreview(
            data.foundingMember.map(
              (member) => member.foundingTeamProfile || uploadImg
            )
          );
        } else {
          setProfileImagePreview([uploadImg]);
        }

        // Set Startup ID
        setStartupId(data.startupId);
      }
    } catch (error) {
      loader.stop();
      console.error("Error fetching startup details:", error);
    }
  };

  const getStartupData = async () => {
    try {
      const response = await getStartupDetails(startupId, token);
      const data = response?.data || false;
      if (data) {
        // Handle initial values
        setInitialValues({
          foundingMember: data.foundingMember?.length
            ? data.foundingMember // Directly use the array of objects
            : [
                {
                  name: "",
                  title: "",
                  email: "",
                  linkedin: "",
                  phoneNumber: "",
                  biography: "",
                  foundingTeamProfile: null,
                },
              ],
          isApproved: data.isApproved,
        });

        // Set company details
        setCompanyDetails(data);

        // Set founding members
        setFoundingMember(data.foundingMember || []);

        // Set profile image previews
        if (data.foundingMember?.length) {
          setProfileImagePreview(
            data.foundingMember.map(
              (member) => member.foundingTeamProfile || uploadImg
            )
          );
        } else {
          setProfileImagePreview([uploadImg]);
        }

        // Set Startup ID
        setStartupId(data.startupId);
      } else {
        Toast(`Error fetching startup details`, `info`, `dealDetailsCompanies`);
      }
    } catch (error) {
      Toast(`Error fetching startup Data`, `info`, `dealDetailsCompanies`);
    }
  };

  const profileCompletionPercentage = async () => {
    try {
      const response = await getProfileCompletionPercentage(token);
      const percent = response.data.percentage;

      const newState = {
        ...state,
        percentage: percent,
      };
      setState(newState);
    } catch (error) {}
  };
  useEffect(() => {
    fetchDetails();
    if (startupId && isAdmin) {
      getStartupData();
    }
  }, [token]);
  const postFormData = async (data) => {
    const formDataInput = new FormData();
    const changedIndices = [];

    // Check profile completion directly with the array of founding members
    data.profileCompleted = isProfileComplete(
      companyDetails,
      data.foundingMember
    );

    // Loop through each key in the data object and append it to FormData
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key]) {
          // Handle array or object properties
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            if (key === "foundingMember") {
              data[key].forEach((item, index) => {
                if (item.foundingTeamProfile instanceof File) {
                  formDataInput.append(
                    `foundingTeamProfile`,
                    item.foundingTeamProfile
                  );
                  changedIndices.push(index);
                }
              });
              // Append the foundingMember array directly as JSON
              formDataInput.append(key, JSON.stringify(data[key]));
            } else {
              // For other objects, stringify and append
              formDataInput.append(key, JSON.stringify(data[key]));
            }
          } else {
            // For other data types, append directly
            formDataInput.append(key, data[key]);
          }
        }
      }
    }

    // Append changed indices if any profiles were updated
    if (changedIndices.length > 0) {
      formDataInput.append("changedIndices", JSON.stringify(changedIndices));
    }

    // Add startup ID if not already present
    if (!data.startupId) {
      formDataInput.append("startupId", startupId);
    }

    try {
      loader.start();
      const response = await PostDetails(formDataInput, token);

      if (response.data) {
        Toast(
          "Founding team details successfully updated",
          "success",
          !isAdmin ? "editCompanyProfile" : "dealDetailsCompanies"
        );
        profileCompletionPercentage();
      }
      loader.stop();
    } catch (error) {
      Toast("Error updating team details", "error", "editCompanyProfile");
      loader.stop();
    }
  };

  return (
    <div className="FoundingTeamProfile fundrev-card">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={postFormData}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
            }}
          >
            <FieldArray name="foundingMember">
              {({ push, remove }) => (
                <>
                  {values.foundingMember.map((member, index) => (
                    <div key={index} className="editTeamProfile-form">
                      <div className="editTeamProfile-form-upload-btn-wrapper">
                        <button
                          type="button"
                          onClick={() =>
                            document
                              .getElementById(`foundingTeamProfile-${index}`)
                              .click()
                          }
                        >
                          <img
                            src={profileImagePreview[index] || uploadImg}
                            className="InvestorLogo"
                            alt="profile"
                            style={{
                              cursor: "pointer",
                              width: isAdmin && "100%",
                            }}
                          />
                        </button>

                        <input
                          id={`foundingTeamProfile-${index}`}
                          type="file"
                          name={`foundingMember[${index}].foundingTeamProfile`}
                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              setFieldValue(
                                `foundingMember[${index}].foundingTeamProfile`,
                                file
                              );
                              const reader = new FileReader();
                              reader.onloadend = () => {
                                setProfileImagePreview((prev) => {
                                  const newPreviews = [...prev];
                                  newPreviews[index] = reader.result;
                                  return newPreviews;
                                });
                              };
                              reader.readAsDataURL(file);
                            }
                          }}
                          style={{ display: "none" }}
                        />
                        {errors.foundingMember?.[index]
                          ?.foundingTeamProfile && (
                          <div className="error text-[#d32f2f] text-xs ">
                            {errors.foundingMember[index].foundingTeamProfile}
                          </div>
                        )}
                      </div>
                      <div className="team-profile-input-fields">
                        <div className="Add-member-button">
                          {index > 0 && (
                            <CustomBlackBtn
                              text="Remove Member"
                              border="sm"
                              mode="danger"
                              filled={false}
                              onClick={() => remove(index)}
                            />
                          )}
                          {index === 0 && (
                            <CustomBlackBtn
                              text={
                                <>
                                  <GoPlus />
                                  Add Member
                                </>
                              }
                              border="sm"
                              filled={false}
                              onClick={() =>
                                push({
                                  name: "",
                                  title: "",
                                  email: "",
                                  linkedin: "",
                                  phoneNumber: "",
                                  biography: "",
                                  foundingTeamProfile: null,
                                })
                              }
                            />
                          )}
                        </div>
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Full Name</StyledFormLabel>
                          <CustomInput
                            placeholder="Enter Full Name"
                            name={`foundingMember[${index}].name`}
                            value={member.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.foundingMember?.[index]?.name &&
                                errors.foundingMember?.[index]?.name
                            )}
                            helperText={
                              touched.foundingMember?.[index]?.name &&
                              errors.foundingMember?.[index]?.name
                            }
                          />
                        </StyledFormControl>

                        {/* Title */}
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Designation</StyledFormLabel>
                          <CustomInput
                            placeholder="Enter Designation"
                            name={`foundingMember[${index}].title`}
                            value={member.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.foundingMember?.[index]?.title &&
                                errors.foundingMember?.[index]?.title
                            )}
                            helperText={
                              touched.foundingMember?.[index]?.title &&
                              errors.foundingMember?.[index]?.title
                            }
                          />
                        </StyledFormControl>

                        {/* Email */}
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Email</StyledFormLabel>
                          <CustomInput
                            placeholder="Enter Email"
                            name={`foundingMember[${index}].email`}
                            type="email"
                            value={member.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.foundingMember?.[index]?.email &&
                                errors.foundingMember?.[index]?.email
                            )}
                            helperText={
                              touched.foundingMember?.[index]?.email &&
                              errors.foundingMember?.[index]?.email
                            }
                          />
                        </StyledFormControl>

                        {/* LinkedIn */}
                        <StyledFormControl fullWidth>
                          <StyledFormLabelOptional>
                            LinkedIn
                          </StyledFormLabelOptional>
                          <CustomInput
                            placeholder="Enter LinkedIn Profile URL"
                            name={`foundingMember[${index}].linkedin`}
                            type="url"
                            value={member.linkedin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.foundingMember?.[index]?.linkedin &&
                                errors.foundingMember?.[index]?.linkedin
                            )}
                            helperText={
                              touched.foundingMember?.[index]?.linkedin &&
                              errors.foundingMember?.[index]?.linkedin
                            }
                          />
                        </StyledFormControl>

                        {/* Phone Number */}
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Phone Number</StyledFormLabel>

                          <CustomPhoneInput
                            value={member.phoneNumber}
                            onChange={(value) =>
                              setFieldValue(
                                `foundingMember[${index}].phoneNumber`,
                                value
                              )
                            }
                            error={Boolean(
                              touched.foundingMember?.[index]?.phoneNumber &&
                                errors.foundingMember?.[index]?.phoneNumber
                            )}
                            helperText={
                              touched.foundingMember?.[index]?.phoneNumber &&
                              errors.foundingMember?.[index]?.phoneNumber
                            }
                          />
                        </StyledFormControl>

                        {/* Biography */}
                        <StyledFormControl></StyledFormControl>
                        <div
                          style={{
                            gridColumn: "1/-1",
                          }}
                        >
                          <StyledFormControl fullWidth>
                            <StyledFormLabel>Biography</StyledFormLabel>
                            <CustomInput
                              placeholder="Enter Biography"
                              name={`foundingMember[${index}].biography`}
                              value={member.biography}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(
                                touched.foundingMember?.[index]?.biography &&
                                  errors.foundingMember?.[index]?.biography
                              )}
                              helperText={
                                touched.foundingMember?.[index]?.biography &&
                                errors.foundingMember?.[index]?.biography
                              }
                              multiline
                              minRows={3}
                            />
                          </StyledFormControl>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </FieldArray>
            <div className="showInterestButtons-div">
              <div className="btns-insideContent">
                <CustomBlackBtn type="submit" text="Save" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FoundingTeamProfile;
