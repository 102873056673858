import React, { useEffect, useState, useRef } from "react";
import { Folder, Tree } from "./filetree";
import { downloadMarkdown } from "../../../endpoints/dataroom";
import { useLoader } from "../LoaderProvider";
import { MdOutlineFileDownload } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";
import { MdOutlineFolderZip } from "react-icons/md";
import markdownFolder from "../../../Assets/Images/icons/markdownFolder.svg";

function TreeNode({
  node,
  index,
  parentIndex = "",
  folderClick,
  activePath,
  isAccessible,
}) {
  // Calculate the full index for the current folder
  const currentIndex = parentIndex
    ? `${parentIndex}.${index + 1}`
    : `${index + 1}`;

  if (node.isFolder) {
    return (
      <div
        onClick={(event) => {
          event.stopPropagation();
          folderClick(node.path, currentIndex, true);
        }}
        style={{
          opacity: isAccessible(node.type) ? 1 : 0.5,
        }}
      >
        <Folder value={node.id} element={node.name}>
          {node.children.map((child, childIndex) => (
            <TreeNode
              key={child.id}
              node={child}
              index={childIndex}
              parentIndex={currentIndex} // Pass the current folder's index to children
              folderClick={folderClick}
              activePath={activePath}
              isAccessible={isAccessible}
            />
          ))}
        </Folder>
      </div>
    );
  } else {
    // Ignore files completely
    return null;
  }
}
function getAllOpenFolderIds(nodes) {
  const folderIds = [];
  function traverse(node) {
    if (node.isFolder && node.children && node.children.length > 0) {
      // It's a folder
      folderIds.push(node.id);
      node.children.forEach(traverse);
    }
  }
  nodes.forEach(traverse);
  return folderIds;
}

function FileTree({
  filesData,
  folderClick,
  activePath,
  isAccessible,
  dealId,
  downloadAZip,
}) {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [tree, setTree] = useState([]);
  const [initialExpandedItems, setInitialExpandedItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close the dropdown if clicked outside
    }
  };

  useEffect(() => {
    // Attach the event listener to detect clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setInitialExpandedItems(getAllOpenFolderIds(tree));
  }, [tree]);
  const getAllPaths = () => {
    let arr = [];
    const doc = {
      isFolder: true,
      path: "/home",
      type: "preliminary",
    };

    // Create the array with filtered and mapped filesData
    const filesArray = filesData
      .filter((file) => file.isFolder === true)
      .map((file) => ({
        path: "/home" + file.path,
        isFolder: file.isFolder,
        type: file.type,
      }));

    // Add `doc` at the start of the array
    arr = [doc, ...filesArray];

    buildTree(arr);
  };

  function buildTree(paths) {
    const tree = [];

    paths.forEach(({ path, isFolder, type }) => {
      const segments = path.split("/").filter(Boolean);
      addNode(tree, segments, "", path, isFolder, type);
    });
    setTree(tree);
  }

  // Recursive function to add nodes to the tree
  function addNode(nodes, segments, parentPath, path, isFolder, type) {
    if (segments.length === 0) return;

    const segment = segments[0];
    const currentPath = `${parentPath}/${segment}`;

    let node = nodes.find((n) => n.id === currentPath);

    if (!node) {
      node = {
        id: currentPath,
        name: segment,
        path: path,
        isFolder: isFolder,
        type: type,
        children: [],
      };
      nodes.push(node);
    }

    if (segments.length > 1) {
      addNode(
        node.children,
        segments.slice(1),
        currentPath,
        path,
        isFolder,
        type
      );
    }
  }
  useEffect(() => {
    getAllPaths();
  }, [filesData]);
  const handleMarkdownDownload = async () => {
    try {
      loader.start("Fetching data...");
      const formData = {
        dealId: dealId,
      };
      const response = await downloadMarkdown(formData, token);
      loader.stop();
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "markdown.txt");
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        return { success: true };
      } else {
      }
    } catch (error) {}
  };
  return (
    <div className="relative flex h-full w-[100%] p-300 gap-2 flex-col overflow-hidden bg-background bg-[#fff] rounded-100">
      <div className="flex items-center justify-between">
        <p className="text-neutral-12 text-desktop-p-xl font-semibold">
          Dataroom
        </p>

        <div
          ref={dropdownRef}
          className="border-neutral-5 rounded-050  px-150 py-100 flex items-center border w-[170px] justify-between cursor-pointer relative"
          onClick={toggleDropdown}
        >
          <div className="gap-100 flex items-center">
            <MdOutlineFileDownload
              fontSize={24}
              style={{ cursor: "pointer" }}
            />
            <p className="text-neutral-12 text-desktop-p-m font-bold">
              Download
            </p>
          </div>
          <FaChevronDown
            fontSize={"12px"}
            className={`transition-transform ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
          />

          {isOpen && (
            <div className="border-neutral-5 rounded-050 p-100 gap-050 absolute flex flex-col border top-[50px] w-[170px] left-[0px] z-10 bg-white">
              <div
                className="p-100 flex gap-[10px] hover:bg-blue-50 rounded-050"
                onClick={(e) => {
                  downloadAZip();
                  e.stopPropagation();
                }}
              >
                <MdOutlineFolderZip />
                <p className="text-neutral-12 text-desktop-p-s font-medium">
                  Download Zip
                </p>
              </div>
              <div
                className="p-100 flex gap-[10px] hover:bg-blue-50 rounded-050"
                onClick={(e) => {
                  handleMarkdownDownload();
                  e.stopPropagation();
                }}
              >
                <img src={markdownFolder} alt="Markdown Folder" />
                <p className="text-neutral-12 text-desktop-p-s text-nowrap font-medium">
                  Download Markdown
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <Tree
        key={initialExpandedItems.join(",")}
        className="bg-background p-2 overflow-hidden rounded-md"
        initialSelectedId="7"
        initialExpandedItems={initialExpandedItems}
        elements={tree}
      >
        {tree.map((node, index) => (
          <TreeNode
            key={node.id}
            node={node}
            folderClick={folderClick}
            index={index}
            activePath={activePath}
            isAccessible={isAccessible}
          />
        ))}
      </Tree>
    </div>
  );
}

export default FileTree;
