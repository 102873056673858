import React from "react";
import { TextField, InputAdornment, endAdornment } from "@mui/material";

// Custom Input Component
const CustomInput = ({
  placeholder,
  name,
  value,
  onChange,
  error,
  helperText,
  type = "text", // Default input type
  multiline = false, // Add this prop to control multiline
  minRows = 1, // Number of rows for multiline text
  rows = undefined,
  startDecorator, // New prop to pass start adornment like rupee symbol
  endDecorator,
  handleKeyDown,
  placeholderColor,
  ...rest
}) => {
  return (
    <TextField
      fullWidth
      type={type}
      variant="outlined"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      multiline={multiline} // Apply multiline prop
      minRows={rows ? undefined : minRows} // Apply rows prop for multiline
      rows={rows}
      onKeyDown={handleKeyDown ? handleKeyDown : undefined}
      InputProps={{
        startAdornment: startDecorator ? (
          <InputAdornment position="start">{startDecorator}</InputAdornment>
        ) : null,
        endAdornment: endDecorator ? (
          <endAdornment position="end">{endDecorator}</endAdornment>
        ) : null,
      }} // Apply startAdornment for fields like revenue
      {...rest}
      sx={{
        "& .MuiFormHelperText-root": {
          color: "#FF5454",
          marginLeft: "0px",
          marginTop: "4px",
        },

        "& .MuiOutlinedInput-root": {
          borderRadius: "4px",
          color: "#141414",
          "& fieldset": {
            borderWidth: "1px",
            borderColor: error ? "#FF5454 !important" : "#BFBFBF", // Custom border color
          },
          "&:hover fieldset": {
            borderColor: "#114FEE", // Color on hover
          },
          "&.Mui-focused fieldset": {
            borderWidth: "1.5px",
            borderColor: "#114FEE", // Color when focused
          },
          "& input": {
            padding: endDecorator ? "16px 16px" : "12px 16px",
            fontFamily: "Satoshi, sans-serif",
            width: endDecorator && "50%",
            fontSize: "14px",
          },
          "& textarea": {
            fontSize: "14px",
            fontFamily: "Satoshi, sans-serif",
            fontSize: "14px",
          },
          "& input::placeholder, & textarea::placeholder": {
            fontSize: "14px",
            color: placeholderColor ? placeholderColor : "#BFBFBF",
            opacity: 1,
            fontWeight: 300,
          },
        },
      }}
    />
  );
};

export default CustomInput;
