import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/components/sidebar";
import Topbar from "../Dashboard/Components/topbar";
import { Helmet } from "react-helmet";
import FiveCards from "./FiveCards";
import { getDealSummaryPageData } from "../../../endpoints/deal";
import MeetingTable from "./MeetingTable";
import ActiveInvestorTable from "./ActiveInvestorTable";
import PendingRequestsTable from "./PendingRequestsTable";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoader } from "../../Common/LoaderProvider";

const CompanySummaryPage = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};
  const loader = useLoader();
  const { deal } = state;
  const [data, setData] = useState(null);
  const getData = async () => {
    const response = await getDealSummaryPageData(deal?.dealId);
    loader.stop();
    if (response) {
      setData(response);
    }
  };
  useEffect(() => {
    loader.start();
    getData();
  }, []);
  useEffect(() => {
    if (!deal) Navigate("/company/dashboard");
  }, [deal]);
  return (
    <>
      <div className="InvestorSide onGoingDeals-section">
        <Sidebar active="fundraising" userRole="company" />
        <Topbar title="Fundraising" />
        <Helmet>
          <title>Company | Overview</title>
        </Helmet>
        <div className="InvestorSide-box">
          <div className="InvestorSide-content">
            <Box
              sx={{
                marginBottom: "24px",
                width: "100%",
              }}
            >
              {data && (
                <FiveCards
                  data={data}
                  fundingRequired={deal?.fundingRequired}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              {/* Left Section: Active Investors Table */}
              <Box sx={{ flex: 2, marginRight: 2 }}>
                {data && <ActiveInvestorTable data={data} />}
              </Box>

              {/* Right Section: Request Table and Investor Meeting Table */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                {/* Request Table */}
                <Box>
                  {data && <PendingRequestsTable data={data} deal={deal} />}
                </Box>

                {/* Investor Meeting Table */}
                <Box>{data && <MeetingTable data={data} />}</Box>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanySummaryPage;
