import { IconButton, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { formatToLakhs } from "../../../utilities/numberFormatter";
import arrowUp from "../../../Assets/Images/icons/analytics/ArrowUp.svg";
import arrowDown from "../../../Assets/Images/icons/analytics/ArrowDown.svg";

const FinancialStatementBigComparisonTable = ({ data }) => {
  const keys = Object.keys(data["m"]?.["Revenue"]);
  const [option, setOption] = useState(keys.slice(-1)[0]);
  const [key, setKey] = useState(keys.slice(-1)[0]);
  const [ckey, setCKey] = useState(keys.slice(-13)[0]);
  const [open, setOpen] = useState(true);

  const percentageRows = [
    "Gross Profit %",
    "EBITDA %",
    "Net Profit Margin",
    "EBIT %",
  ];
  const IMPRows = [
    "Gross Profit",
    "EBITDA",
    "Net Profit",
    "EBIT",
    "Profit (Before Tax)",
  ];
  const table_header = [
    "Particulars",
    key,
    ckey,
    `Variance (%)`,
    key,
    ckey,
    `Variance (%)`,
    key,
    ckey,
    `Variance (%)`,
  ];

  useEffect(() => {}, [data, option]);
  function getVariance(type, row, sga) {
    const validNumber = (num) =>
      typeof num === "number" && !isNaN(num) && isFinite(num);

    let a, b;
    if (
      row !== "Gross Profit %" &&
      row !== "EBITDA %" &&
      row !== "SG&A" &&
      row !== "EBIT %" &&
      row !== "Net Profit Margin"
    ) {
      a = data[type][row][key];
      b = data[type][row][ckey];
    } else if (row === "SG&A") {
      a = data[type][row][sga][key];
      b = data[type][row][sga][ckey];
    } else {
      return null; // or any other default value
    }

    if (validNumber(a) && validNumber(b) && b !== 0) {
      if (a === 0 || b === 0 || !a || !b) {
        return 0;
      }
      return (((a - b) / Math.abs(b)) * 100).toFixed(1);
    }

    return 0; // or any other default value to indicate an invalid calculation
  }

  function CollapsibleRow(props) {
    const { row } = props;
    return (
      <>
        <tr sx={{ "& > *": { borderBottom: "unset" } }}>
          <td
            className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-left text-nowrap`}
            align="left"
          >
            {row}
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              sx={{
                paddingY: 0,
              }}
            >
              {open ? (
                <KeyboardArrowUpIcon fontSize="3px" />
              ) : (
                <KeyboardArrowDownIcon fontSize="3px" />
              )}
            </IconButton>
          </td>
          <td
            className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap border-l border-l-neutral-5`}
          >
            {formatToLakhs(data["m"][row]["Total SG&A Expenses"][key])}
          </td>
          <td
            className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap`}
          >
            {formatToLakhs(data["m"][row]["Total SG&A Expenses"][ckey])}
          </td>
          <td
            className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
              getVariance("m", row, "Total SG&A Expenses")
                ? getVariance("m", row, "Total SG&A Expenses") > 0
                  ? "text-[#20BD7E]"
                  : "text-[#FF5454]"
                : ""
            }`}
          >
            <div className="flex items-center gap-100 justify-end">
              {getVariance("m", row, "Total SG&A Expenses")
                ? getVariance("m", row, "Total SG&A Expenses") + `%`
                : `-`}
              {getVariance("m", row, "Total SG&A Expenses") ? (
                getVariance("m", row, "Total SG&A Expenses") > 0 ? (
                  <img src={arrowUp} alt="up" />
                ) : (
                  <img src={arrowDown} alt="down" />
                )
              ) : (
                ``
              )}
            </div>
          </td>
          <td
            className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap border-l border-l-neutral-5`}
          >
            {formatToLakhs(data["ytd"][row]["Total SG&A Expenses"][key])}
          </td>
          <td
            className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap`}
          >
            {formatToLakhs(data["ytd"][row]["Total SG&A Expenses"][ckey])}
          </td>
          <td
            className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
              getVariance("ytd", row, "Total SG&A Expenses")
                ? getVariance("ytd", row, "Total SG&A Expenses") > 0
                  ? "text-[#20BD7E]"
                  : "text-[#FF5454]"
                : ""
            }`}
            align="right"
          >
            <div className="flex items-center gap-100 justify-end">
              {getVariance("ytd", row, "Total SG&A Expenses")
                ? getVariance("ytd", row, "Total SG&A Expenses") + `%`
                : `-`}
              {getVariance("ytd", row, "Total SG&A Expenses") ? (
                getVariance("ytd", row, "Total SG&A Expenses") > 0 ? (
                  <img src={arrowUp} alt="up" />
                ) : (
                  <img src={arrowDown} alt="down" />
                )
              ) : (
                ``
              )}
            </div>
          </td>
          <td
            className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap border-l border-l-neutral-5`}
          >
            {formatToLakhs(data["ltm"][row]["Total SG&A Expenses"][key])}
          </td>
          <td
            className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap`}
          >
            {formatToLakhs(data["ltm"][row]["Total SG&A Expenses"][ckey])}
          </td>
          <td
            className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
              getVariance("ltm", row, "Total SG&A Expenses")
                ? getVariance("ltm", row, "Total SG&A Expenses") > 0
                  ? "text-[#20BD7E]"
                  : "text-[#FF5454]"
                : ""
            }`}
            align="right"
          >
            <div className="flex items-center gap-100 justify-end">
              {getVariance("ltm", row, "Total SG&A Expenses")
                ? getVariance("ltm", row, "Total SG&A Expenses") + `%`
                : `-`}
              {getVariance("ltm", row, "Total SG&A Expenses") ? (
                getVariance("ltm", row, "Total SG&A Expenses") > 0 ? (
                  <img src={arrowUp} alt="up" />
                ) : (
                  <img src={arrowDown} alt="down" />
                )
              ) : (
                ``
              )}
            </div>
          </td>
        </tr>
        {Object.keys(data["m"][row]).map((inside_row, index) => {
          return (
            inside_row !== "Total SG&A Expenses" &&
            Object.values(data["m"][row][inside_row]) && (
              <>
                <style>
                  {`
                    @keyframes fadeIn {
                      0% {
                        opacity: 0;
                        transform: translateY(-10px);
                      }
                      100% {
                        opacity: 1;
                        transform: translateY(0);
                      }
                    }

                    @keyframes fadeOut {
                      0% {
                        opacity: 1;
                        transform: translateY(0);
                      }
                      100% {
                        opacity: 0;
                        transform: translateY(-10px);
                      }
                    }
                  `}
                </style>

                <tr
                  key={index}
                  style={{
                    display: open ? "table-row" : "none",
                    animation: open
                      ? "fadeIn 0.5s ease-in-out forwards"
                      : "fadeOut 0.5s ease-in-out forwards",
                  }}
                >
                  <td
                    className={`text-desktop-p-s p-150 pl-250 !border-b-neutral-4 bg-neutral-1 text-left text-nowrap`}
                    align="left"
                  >
                    {inside_row}
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap border-l border-l-neutral-5`}
                    align="right"
                  >
                    {formatToLakhs(data["m"][row][inside_row][key])}
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap`}
                  >
                    {formatToLakhs(data["m"][row][inside_row][ckey])}
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
                      getVariance("m", row, inside_row)
                        ? getVariance("m", row, inside_row) > 0
                          ? "text-[#20BD7E]"
                          : "text-[#FF5454]"
                        : ""
                    }`}
                    align="right"
                  >
                    <div className="flex items-center gap-100 justify-end">
                      {getVariance("m", row, inside_row)
                        ? getVariance("m", row, inside_row) + `%`
                        : `-`}
                      {getVariance("m", row, inside_row) ? (
                        getVariance("m", row, inside_row) > 0 ? (
                          <img src={arrowUp} alt="up" />
                        ) : (
                          <img src={arrowDown} alt="down" />
                        )
                      ) : (
                        ``
                      )}
                    </div>
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap border-l border-l-neutral-5`}
                  >
                    {formatToLakhs(data["ytd"][row][inside_row][key])}
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap`}
                  >
                    {formatToLakhs(data["ytd"][row][inside_row][ckey])}
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
                      getVariance("ytd", row, inside_row)
                        ? getVariance("ytd", row, inside_row) > 0
                          ? "text-[#20BD7E]"
                          : "text-[#FF5454]"
                        : ""
                    }`}
                    align="right"
                  >
                    <div className="flex items-center gap-100 justify-end">
                      {getVariance("ytd", row, inside_row)
                        ? getVariance("ytd", row, inside_row) + `%`
                        : `-`}
                      {getVariance("ytd", row, inside_row) ? (
                        getVariance("ytd", row, inside_row) > 0 ? (
                          <img src={arrowUp} alt="up" />
                        ) : (
                          <img src={arrowDown} alt="down" />
                        )
                      ) : (
                        ``
                      )}
                    </div>
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap border-l border-l-neutral-5`}
                  >
                    {formatToLakhs(data["ltm"][row][inside_row][key])}
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap`}
                  >
                    {formatToLakhs(data["ltm"][row][inside_row][ckey])}
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
                      getVariance("ltm", row, inside_row)
                        ? getVariance("ltm", row, inside_row) > 0
                          ? "text-[#20BD7E]"
                          : "text-[#FF5454]"
                        : ""
                    }`}
                    align="right"
                  >
                    <div className="flex items-center gap-100 justify-end">
                      {getVariance("ltm", row, inside_row)
                        ? getVariance("ltm", row, inside_row) + `%`
                        : `-`}
                      {getVariance("ltm", row, inside_row) ? (
                        getVariance("ltm", row, inside_row) > 0 ? (
                          <img src={arrowUp} alt="up" />
                        ) : (
                          <img src={arrowDown} alt="down" />
                        )
                      ) : (
                        ``
                      )}
                    </div>
                  </td>
                </tr>
              </>
            )
          );
        })}
      </>
    );
  }
  const handleSelect = (event) => {
    const month = event.target.value;
    setOption(month);
    setKey(month);
    const [m, y] = month.split(" ");
    setCKey(`${m} ${y - 1}`);
  };
  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <p className="text-desktop-p-lb">
            {data.name}
            <p className="text-desktop-p-xs">In lakhs</p>
          </p>
          <div>
            <Select
              value={option}
              onChange={handleSelect}
              sx={{
                width: "133px",
                height: "28px",
                borderRadius: "4px",
                fontSize: "14px",
                color: "var(--Secondary-Text-Color)",
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: "200px",
                  },
                },
              }}
            >
              {keys
                .slice(
                  keys.findIndex(
                    (m) => m === Object.keys(data["ltm"]["Revenue"])[0]
                  ) + 12
                )
                .reverse()
                .map((val, index) => (
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      color: "var(--Secondary-Text-Color)",
                    }}
                    value={val}
                    key={index}
                  >
                    {val}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
        <div className="mt-200">
          <table>
            <thead>
              <tr>
                <th
                  align={"left"}
                  className={`p-150 bg-neutral-3 rounded-050 text-desktop-p-s  font-medium text-neutral-8 text-nowrap !uppercase text-left border-0`}
                ></th>
                <th
                  className={`p-150 bg-neutral-3 rounded-050 text-desktop-p-s  font-medium text-neutral-8 text-nowrap !uppercase text-center border-l border-l-neutral-5`}
                  colSpan={3}
                  align="center"
                >
                  Monthly Comparison
                </th>
                <th
                  className={`p-150 bg-neutral-3 rounded-050 text-desktop-p-s  font-medium text-neutral-8 text-nowrap !uppercase text-center border-l border-l-neutral-5`}
                  colSpan={3}
                  align="center"
                >
                  YTD Comparison
                </th>
                <th
                  className={`p-150 bg-neutral-3 rounded-050 text-desktop-p-s  font-medium text-neutral-8 text-nowrap !uppercase text-center border-l border-l-neutral-5`}
                  colSpan={3}
                  align="center"
                >
                  LTM Comparison
                </th>
              </tr>
              <tr>
                {table_header.map((column, index) => (
                  <th
                    key={index}
                    align={"left"}
                    className={`p-150 bg-neutral-3 rounded-050 text-desktop-p-s  font-medium text-neutral-8 text-nowrap !uppercase ${
                      index === 0 ? "text-left" : "text-right"
                    } ${index % 3 === 1 && "border-l border-l-neutral-5"}`}
                  >
                    {index === 0 ? (
                      <div className="relative bottom-5">{column}</div>
                    ) : (
                      column
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(data["m"]).map((row, index) =>
                row === "SG&A" ? (
                  <CollapsibleRow row={row} key={index} />
                ) : (
                  <tr key={index}>
                    {row !== "SG&A" && (
                      <td
                        className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-left text-nowrap ${
                          IMPRows.includes(row) &&
                          "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                        }`}
                        align="left"
                      >
                        {row}
                      </td>
                    )}
                    {row !== "SG&A" && (
                      <td
                        className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap border-l border-l-neutral-5 ${
                          IMPRows.includes(row) &&
                          "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                        }`}
                      >
                        {formatToLakhs(data["m"][row][key])}
                      </td>
                    )}
                    {row !== "SG&A" && (
                      <td
                        className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
                          IMPRows.includes(row) &&
                          "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                        }`}
                      >
                        {formatToLakhs(data["m"][row][ckey])}
                      </td>
                    )}
                    {row !== "SG&A" && (
                      <td
                        className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
                          IMPRows.includes(row) &&
                          "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                        } ${
                          getVariance("m", row)
                            ? getVariance("m", row) > 0
                              ? "text-[#20BD7E]"
                              : "text-[#FF5454]"
                            : ""
                        }`}
                        align="right"
                      >
                        <div className="flex items-center gap-100 justify-end">
                          {getVariance("m", row)
                            ? getVariance("m", row) + `%`
                            : `-`}
                          {getVariance("m", row) ? (
                            getVariance("m", row) > 0 ? (
                              <img src={arrowUp} alt="up" />
                            ) : (
                              <img src={arrowDown} alt="down" />
                            )
                          ) : (
                            ``
                          )}
                        </div>
                      </td>
                    )}
                    {row !== "SG&A" && (
                      <td
                        className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap border-l border-l-neutral-5 ${
                          IMPRows.includes(row) &&
                          "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                        }`}
                      >
                        {formatToLakhs(data["ytd"][row][key])}
                      </td>
                    )}
                    {row !== "SG&A" && (
                      <td
                        className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
                          IMPRows.includes(row) &&
                          "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                        }`}
                      >
                        {formatToLakhs(data["ytd"][row][ckey])}
                      </td>
                    )}
                    {row !== "SG&A" && (
                      <td
                        className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
                          IMPRows.includes(row) &&
                          "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                        } ${
                          getVariance("m", row)
                            ? getVariance("m", row) > 0
                              ? "text-[#20BD7E]"
                              : "text-[#FF5454]"
                            : ""
                        }`}
                        align="right"
                      >
                        <div className="flex items-center gap-100 justify-end">
                          {getVariance("ytd", row)
                            ? getVariance("ytd", row) + `%`
                            : `-`}
                          {getVariance("ytd", row) ? (
                            getVariance("ytd", row) > 0 ? (
                              <img src={arrowUp} alt="up" />
                            ) : (
                              <img src={arrowDown} alt="down" />
                            )
                          ) : (
                            ``
                          )}
                        </div>
                      </td>
                    )}
                    {row !== "SG&A" && (
                      <td
                        className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap border-l border-l-neutral-5 ${
                          IMPRows.includes(row) &&
                          "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                        }`}
                      >
                        {formatToLakhs(data["ltm"][row][key])}
                      </td>
                    )}
                    {row !== "SG&A" && (
                      <td
                        className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
                          IMPRows.includes(row) &&
                          "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                        }`}
                      >
                        {formatToLakhs(data["ltm"][row][ckey])}
                      </td>
                    )}
                    {row !== "SG&A" && (
                      <td
                        className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
                          IMPRows.includes(row) &&
                          "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                        } ${
                          getVariance("m", row)
                            ? getVariance("m", row) > 0
                              ? "text-[#20BD7E]"
                              : "text-[#FF5454]"
                            : ""
                        }`}
                        align="right"
                      >
                        <div className="flex items-center gap-100 justify-end">
                          {getVariance("ltm", row)
                            ? getVariance("ltm", row) + `%`
                            : `-`}
                          {getVariance("ltm", row) ? (
                            getVariance("ltm", row) > 0 ? (
                              <img src={arrowUp} alt="up" />
                            ) : (
                              <img src={arrowDown} alt="down" />
                            )
                          ) : (
                            ``
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FinancialStatementBigComparisonTable;
