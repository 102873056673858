import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { investorState, saveState } from "../investorState";
import AskQuestionModal from "../../../Common/modals/askQuestionModal";
import ReferInvestorModal from "../../../Common/modals/referInvestorModal";

import { GoCrossReference } from "react-icons/go";
import { GoQuestion } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../../../Common/components/custom/customToolTip";
import { updateHeaderDealStage } from "../../../Common/functions";

function CompanyDetailsSubheader({
  activeButton,
  stage,
  page,
  deal,
  isDealLive = false,
}) {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [askQuestionModal, setAskQuestionModal] = useState(false);
  const [newQuestionAsked, setNewQuestionAsked] = useState(false);
  const [openReferModal, setOpenReferModal] = useState(false);

  const [activeBtn, setActiveBtn] = useState(activeButton);
  const [state, setState] = useRecoilState(investorState);
  const isSecondary = deal?.dealType === "secondary";
  const [text, setText] = useState();
  const [color, setColor] = useState();
  const [bgcolor, setBgcolor] = useState();
  const { investorId } = useRecoilValue(investorState);
  const handleActiveBtn = (activeBtn) => {
    const newState = { ...state, activeBtn: activeBtn };
    setState(newState);
    saveState(newState);
    if (page === "investorAgreement") {
      navigate("/investor/companyDetails/requestAccepted", {
        state: {
          deal: deal,
        },
      });
      return;
    }
  };
  useEffect(() => {
    setActiveBtn(state.activeBtn);
  }, [state.activeBtn]);
  useEffect(() => {
    updateHeaderDealStage(stage, isDealLive, setText, setColor, setBgcolor);
  }, [stage, isDealLive]);
  // const { type } = useParams();

  // const isActive = (section) => section === type;

  // const getCircleBgColor = (section) =>
  //   isActive(section) ? "bg-blue-700" : "bg-gray-500";

  return (
    <>
      <AskQuestionModal
        askQuestionModal={askQuestionModal}
        setAskQuestionModal={setAskQuestionModal}
        token={token}
        dealId={deal ? deal.dealId : ""}
        investorID={investorId}
        roundType={stage}
        setNewQuestionAsked={setNewQuestionAsked}
      />
      <ReferInvestorModal
        openReferModal={openReferModal}
        setOpenReferModal={setOpenReferModal}
        dealId={deal ? deal.dealId : ""}
      />
      <div
        className="flex flex-col pt-2 px-4 bg-slate-50 max-md:px-5 border-color-[#EBF0FA] border-b-2"
        style={{
          borderWidth: stage === "requestSent" && "0px",
        }}
      >
        <div className="max-md:max-w-full flex flex-wrap items-end justify-between w-full gap-10">
          <div
            className="flex flex-col min-w-[240px] max-md:max-w-full"
            style={{
              visibility: stage === "requestSent" && "hidden",
            }}
          >
            <div className="flex flex-wrap gap-6 items-center text-sm min-h-[32px] max-md:max-w-full">
              <div
                className="whitespace-nowrap flex items-center self-stretch gap-1 my-auto"
                onClick={() => {
                  setActiveBtn(1);
                  handleActiveBtn(1);
                }}
              >
                <div
                  className={`self-stretch my-auto font-bold cursor-pointer ${
                    activeBtn === 1 ? "text-blue-700" : "text-gray-500"
                  }`}
                >
                  Overview
                </div>
              </div>
              {(stage === "watchlist" || isDealLive) && (
                <div
                  className="flex items-center self-stretch gap-1 my-auto"
                  onClick={() => {
                    setActiveBtn(2);
                    handleActiveBtn(2);
                  }}
                >
                  <div
                    className={`self-stretch my-auto font-bold cursor-pointer ${
                      activeBtn === 2 ? "text-blue-700" : "text-gray-500"
                    }`}
                  >
                    Analytics
                  </div>
                </div>
              )}

              {stage !== "watchlist" && isDealLive && (
                <div
                  className="flex items-center self-stretch gap-1 my-auto font-semibold"
                  onClick={() => {
                    setActiveBtn(3);
                    handleActiveBtn(3);
                  }}
                >
                  <div
                    className={`self-stretch my-auto font-bold cursor-pointer ${
                      activeBtn === 3 ? "text-blue-700" : "text-gray-500"
                    }`}
                  >
                    Dataroom
                  </div>
                </div>
              )}
              {deal?.dealType !== "secondary" && (
                <div
                  className="whitespace-nowrap flex items-center self-stretch gap-1 my-auto"
                  onClick={() => {
                    setActiveBtn(4);
                    handleActiveBtn(4);
                  }}
                >
                  <div
                    className={`self-stretch my-auto font-bold cursor-pointer ${
                      activeBtn === 4 ? "text-blue-700" : "text-gray-500"
                    }`}
                  >
                    Activity
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center gap-[16px]">
            <div
              className="px-[8px] py-[6px] mb-[4px] justify-center items-center rounded-[8px] border-[1px] border-[#AEB3C1]"
              style={{
                color: color,
                backgroundColor: bgcolor,
              }}
            >
              {text}
            </div>

            <CustomTooltip
              title="Refer a investor"
              theme="black"
              position="bottom"
            >
              <div>
                <GoCrossReference
                  style={{
                    fontSize: "26px",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenReferModal(true)}
                />
              </div>
            </CustomTooltip>
            {stage !== "watchlist" &&
              deal?.dealType !== "secondary" &&
              isDealLive &&
              stage !== "requestSent" && (
                <CustomTooltip
                  title="Ask a question"
                  theme="black"
                  position="bottom"
                >
                  <div>
                    <GoQuestion
                      style={{
                        fontSize: "26px",
                        cursor: "pointer",
                      }}
                      onClick={() => setAskQuestionModal(true)}
                    />
                  </div>
                </CustomTooltip>
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyDetailsSubheader;
