import React, { useEffect, useState } from "react";
import SmallAreaPlot from "./SmallAreaPlot";
import { formatToLakhs } from "../../../utilities/numberFormatter";
import AnalyticsPageTitle from "./AnalyticsPageTitle";
import ArrowUp from "../../../Assets/Images/icons/analytics/ArrowUp.svg";
import ArrowDown from "../../../Assets/Images/icons/analytics/ArrowDown.svg";

const SmallCardGrid = ({ data, title }) => {
  function calculateRelative(x, y) {
    return ((x - y) / Math.abs(y)) * 100 || 0;
  }
  const [isWide, setIsWide] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const parentWidth =
        document.querySelector(".smallCardGrid-parent-div")?.offsetWidth || 0;
      setIsWide(parentWidth > 235);
    };

    // Run on initial render
    handleResize();

    // Attach resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <AnalyticsPageTitle title={title} />
      <div className="grid grid-cols-5 gap-200">
        {data?.map((item, index) => {
          const comparedPercent = !item.percentage
            ? calculateRelative(item?.value, item?.compareValue)
            : null;
          const flag = item.type === "text";
          return (
            <div className="h-24 relative rounded-050 border border-neutral-5 bg-neutral-1 pl-150 smallCardGrid-parent-div">
              {!flag && isWide && (
                <div
                  className="absolute left-[98px] w-[39%] h-full z-10"
                  style={{
                    background:
                      "linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)",
                  }}
                ></div>
              )}
              <div className="absolute right-0 h-full w-[59%]">
                {!flag && (
                  <SmallAreaPlot
                    data={item?.plotData}
                    compareData={item?.compareData}
                    color={comparedPercent > 0 ? "#20BD7E" : "#FF6969"}
                    isNegative={comparedPercent < 0}
                  />
                )}
              </div>
              <div className="text-[--Secondary-Text-Color] mt-2 relative text-desktop-p-mb z-20">
                {item?.name}
              </div>
              <div className="text-desktop-p-2xl mt-150 leading-6">
                {item?.rounded
                  ? item?.value?.toFixed(2)
                  : formatToLakhs(
                      item?.value,
                      item.format,
                      item.currency,
                      item.currency && item.format,
                      item.percentage
                    )}
              </div>
              <div className="flex items-center gap-050 mb-100 text-[--Secondary-Text-Color] mt-1 text-xl-p-xs">
                {comparedPercent ? (
                  comparedPercent > 0 ? (
                    <img alt="up" src={ArrowUp} />
                  ) : (
                    <img alt="down" src={ArrowDown} />
                  )
                ) : (
                  <p></p>
                )}
                <div>
                  {comparedPercent
                    ? formatToLakhs(comparedPercent, false, false, false, true)
                    : ``}
                </div>
              </div>
            </div>
            // <Grid item xs="4" md="4" lg="4" key={index}>
            //   <Box
            //     sx={{
            //       width: "100%",
            //       height: "176px",
            //       backgroundColor: "white",
            //       borderRadius: "4px",
            //       padding: "8px",
            //       display: "flex",
            //       flexDirection: "column",
            //       justifyContent: "space-between",
            //       gap: "16px",
            //     }}
            //   >
            //     <Box
            //       sx={{
            //         display: !flag && "flex",
            //         justifyContent: "space-between",
            //         alignItems: "center",
            //       }}
            //     >
            //       <Box>
            //         <Typography
            //           sx={{
            //             fontFamily: "Karla, sans-serif",
            //             color: "var(--Secondary-Text-Color)",
            //             fontSize: "14px",
            //             fontWeight: 300,
            //           }}
            //         >
            //           {item?.name}
            //         </Typography>
            //         {!flag && (
            //           <Typography
            //             sx={{
            //               fontFamily: "Karla, sans-serif",
            //               fontSize: "16px",
            //               fontWeight: 500,
            //               color: "var(--Primary-Text-Color)",
            //             }}
            //           >
            //             {formatToLakhs(
            //               item?.value,
            //               item.format,
            //               item.currency,
            //               item.currency && item.format,
            //               item.percentage
            //             )}
            //           </Typography>
            //         )}
            //       </Box>
            //       {flag && (
            //         <Box
            //           sx={{
            //             width: "100%",
            //             backgroundColor: "#FAFAFA",
            //             marginTop: "24px",
            //             borderRadius: "4px",
            //           }}
            //         >
            //           <Box
            //             sx={{
            //               textAlign: "center",
            //               fontFamily: "Karla, sans-serif",
            //               fontSize: "16px",
            //               fontWeight: 500,
            //               color: "var(--Primary-Text-Color)",
            //             }}
            //           >
            //             {formatToLakhs(
            //               item?.value,
            //               item.format,
            //               item.currency,
            //               item.currency && item.format,
            //               item.percentage
            //             )}
            //           </Box>
            //           <Box
            //             sx={{
            //               textAlign: "center",
            //               fontFamily: "Karla, sans-serif",
            //               color: "var(--Secondary-Text-Color)",
            //               fontSize: "14px",
            //               fontWeight: 300,
            //             }}
            //           >
            //             {item?.compareValue}
            //           </Box>
            //         </Box>
            //       )}

            //       {!flag && (
            //         <Box>
            //           <Box
            //             sx={{
            //               display: "flex",
            //               justifyContent: "flex-end",
            //             }}
            //           >
            //             {comparedPercent ? (
            //               comparedPercent > 0 ? (
            //                 <TrendingUp
            //                   sx={{
            //                     color: "#05C168",
            //                     fontSize: "20px",
            //                   }}
            //                 />
            //               ) : (
            //                 <TrendingDown
            //                   sx={{
            //                     color: "#D32F2F",
            //                     fontSize: "20px",
            //                   }}
            //                 />
            //               )
            //             ) : (
            //               <p></p>
            //             )}
            //             <Typography
            //               sx={{
            //                 font: "Karla, sans-serif",
            //                 fontWeight: 300,
            //                 color: comparedPercent > 0 ? "#05C168" : "#D32F2F",
            //                 fontSize: "14px",
            //               }}
            //             >
            //               {comparedPercent
            //                 ? formatToLakhs(
            //                     comparedPercent,
            //                     false,
            //                     false,
            //                     false,
            //                     true
            //                   )
            //                 : `-`}
            //               &nbsp;
            //             </Typography>
            //           </Box>
            //           <Typography
            //             sx={{
            //               font: "Karla, sans-serif",
            //               fontWeight: 300,
            //               color: `var(--Secondary-Text-Color)`,
            //               fontSize: "14px",
            //             }}
            //           >
            //             vs{" "}
            //             {["-", "-%", "NaN%", "0.0%"].includes(
            //               formatToLakhs(
            //                 item?.compareValue,
            //                 item.format,
            //                 item.currency,
            //                 item.currency && item.format,
            //                 item.percentage
            //               )
            //             )
            //               ? `NA`
            //               : formatToLakhs(
            //                   item?.compareValue,
            //                   item.format,
            //                   item.currency,
            //                   item.currency && item.format,
            //                   item.percentage
            //                 )}
            //           </Typography>
            //         </Box>
            //       )}
            //     </Box>

            //     {!flag && (
            //       <SmallAreaPlot
            //         data={item?.plotData}
            //         compareData={item?.compareData}
            //       />
            //     )}
            //   </Box>
            // </Grid>
          );
        })}
      </div>
    </>
  );
};

export default SmallCardGrid;
