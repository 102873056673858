import React from "react";
import { formatToLakhs } from "../../../utilities/numberFormatter";

const ChurnTable = ({ data }) => {
  const rows = [
    "Opening",
    "+ Addition",
    "- Churn",
    "Closing Customer",
    "Churn Rate",
    "Retention Rate",
  ];
  const keys = [
    "opening",
    "addition",
    "churn",
    "closing",
    "churnRate",
    "retentionRate",
  ];
  return (
    <>
      <div>
        <div>
          <div className="text-desktop-p-lb">
            {data.name}
            <p className="text-desktop-p-xs">In lakhs</p>
          </div>
        </div>
        <div className="overflow-x-auto mt-200">
          <table size="small">
            <thead>
              <td
                key="header"
                className={`p-150 bg-neutral-3 rounded-050 text-desktop-p-s sticky font-medium text-neutral-8 text-nowrap !uppercase text-left left-0 z-10`}
                align="left"
              >
                Particulars
              </td>
              {Object?.keys(data?.data).map((key, index) => (
                <td
                  key={index}
                  className={`p-150 bg-neutral-3 rounded-050 text-desktop-p-s sticky font-medium text-neutral-8 text-nowrap !uppercase text-right`}
                  align="right"
                >
                  {key}
                </td>
              ))}
            </thead>
            <tbody>
              {keys.map((key, index) => (
                <tr key={index}>
                  <td
                    className={`text-desktop-p-s sticky p-150 !border-b-neutral-4 bg-neutral-1 text-left z-10 left-0 text-nowrap ${
                      (index === 3 || index === 5) &&
                      "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                    }`}
                    align="left"
                  >
                    {rows[index]}
                  </td>
                  {Object?.values(data?.data).map((value, idx) => (
                    <td
                      key={index}
                      className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
                        (index === 3 || index === 5) &&
                        "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                      }`}
                    >
                      {formatToLakhs(
                        value[key],
                        true,
                        false,
                        false,
                        index > 3,
                        true
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ChurnTable;
