const stepFields = {
  "Basic Details": [
    "investorLogo",
    "companyName",
    "investorType",
    "investorWebsite",
    "investorDescription",
  ],
  "Investment Details": [],
  "Investment Focus": [
    "investmentThesis",
    "assetClass",
    "coInvestment",
    "ticketSizevalue",
    "totalExposure",
    "stakeAcquired",
  ],
  Criteria: ["investmentStage", "industryVerticalOptions", "endMarketFocus"],
  "Profile Complete": ["profileCompleted"],
};
export const getUnfilledFields = (fields, values) => {
  return fields.filter((field) => {
    const value = values && values[field];
    if (field === "profileCompleted") {
      return value === false;
    }
    return (
      value === "" ||
      value === null ||
      (Array.isArray(value) && value.length === 0)
    );
  });
};
// Function to check if a specific stage is completed based on conditions
export const isStageCompleted = (stageName, values) => {
  const requiredFields = stepFields[stageName] || [];

  const unfilledFields = getUnfilledFields(requiredFields, values);
  if (unfilledFields.length === 0) {
    return true; // All required fields are filled
  } else {
    return false; // Not all required fields are filled
  }
};
export const isEntireProfileCompleted = (values) => {
  const stages = Object.keys(stepFields); // Get all stage names
  // Check each stage for completion
  return stages
    .slice(0, stages.length - 1)
    .every((stage) => isStageCompleted(stage, values));
};
export const calculateProfileCompletion = (values) => {
  // Get all step names
  try {
    const steps = Object.keys(stepFields);

    // Initialize counters and unfilled fields array
    let totalFields = 0;
    let filledFields = 0;
    let unfilledFields = []; // Store unfilled fields here

    // Iterate over each step and its required fields
    steps.slice(0, steps.length - 1).forEach((step) => {
      let requiredFields = stepFields[step]; // Get required fields for each step dynamically
      totalFields += requiredFields.length; // Add to total fields

      // Calculate filled fields in the current step
      requiredFields.forEach((field) => {
        const value = values[field];

        // Check if the field is filled based on its type and add to filledFields
        const isFilled =
          field === "profileCompleted"
            ? value === true
            : value !== "" &&
              value !== null &&
              !(Array.isArray(value) && value.length === 0);

        if (isFilled) {
          filledFields += 1;
        } else {
          unfilledFields.push(field); // Add unfilled field to the list
        }
      });
    });

    const completionPercentage =
      totalFields > 0 ? (filledFields / totalFields) * 100 : 0;

    // Log unfilled fields
    return completionPercentage.toFixed(0);
  } catch {
    return 0;
  }
};
