import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/components/sidebar";
import Topbar from "./Components/topbar";

import CompleteProfile from "./dashboardStates/completeProfile";
import VerificationIncomplete from "./dashboardStates/verficationIncomplete";
import CompleteDealDetails from "./dashboardStates/completeDealDetails";
import DealUnderReview from "./dashboardStates/dealUnderReview";
import MakeDealLive from "./dashboardStates/makeDealLive";
import CreateDeal from "./createDeal";

import { Helmet } from "react-helmet";
import { fetchStartupDetails, PostDetails } from "../../../endpoints/startup";
import { useLoader } from "../../Common/LoaderProvider";
import { companyState, saveState } from "./companyState";
import { useRecoilState } from "recoil";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";

import { GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import {
  createNewDeal,
  getDealDetailsByDealId,
  getDealIdbyStartupId,
} from "../../../endpoints/deal";
import Toast from "../../Common/Toast";
import { ToastContainer } from "react-toastify";
import { checkDealExist } from "../../../endpoints/admin";
import LiveDeal from "./dashboardStates/liveDeal";
import DetailedDashboard from "./detailedDashboard";

const Dashboard = () => {
  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [state, setState] = useRecoilState(companyState);
  const [startupId, setStartupId] = useState("");
  const [deal, setDeal] = useState({});
  const [dealId, setDealId] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [isApplicationReviewed, setIsApplicationReviewed] = useState(false);
  const [dealCreated, setDealCreated] = useState(false);
  const [details, setDetails] = useState([]);
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [onBoardingCompleted, setOnBoardingCompleted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dealClosed, setDealClosed] = useState(false);
  const fetchDetails = async () => {
    try {
      loader.start("Fetching details");
      const responseData = await fetchStartupDetails(token);
      loader.stop();
      if (responseData.data) {
        const response = responseData.data;
        const newState = {
          ...state,
          startupId: response.data.startupId,
        };
        setStartupId(response.data.startupId);
        setDetails(response.data);
        setIsApproved(response.data.isApproved);
        setIsApplicationReviewed(response.data.isApplicationReviewed);
        setProfileCompleted(response.data.profileCompleted);
        setOnBoardingCompleted(response.data.onBoardingComplete);
        setState(newState);
        saveState(newState);
      } else {
        Toast(
          "Error fetching details, Please try again later",
          "error",
          "companyDashboard"
        );
      }
    } catch (error) {}
  };
  const getDealId = async () => {
    try {
      const response = await getDealIdbyStartupId(startupId, token);
      if (response) {
        const newState = {
          ...state,
          dealId: response.data.dealId,
        };
        if (newState?.dealId) {
          setDealCreated(true);
        }
        setDealId(response.data.dealId);
        setState(newState);
        saveState(newState);
      }
    } catch (error) {}
  };

  const fetchDealDetails = async () => {
    try {
      const response = await getDealDetailsByDealId(dealId, token);
      if (!response) {
        Toast("failed to fetch details", "error", "companyDashboard");
      }
      setDeal(response.data);
    } catch (error) {}
  };
  const handleCreateDealClick = async () => {
    if (dealCreated) {
      Toast(
        "The deal has already been initiated. Please wait until the current deal concludes before proceeding further.",
        "error",
        "companyDashboard"
      );
      return;
    } else if (!dealCreated) {
      loader.start("Creating deal");
      const response = await createNewDeal(startupId, token);
      loader.stop();
      if (response.data) {
        Navigate("/company/onBoarding");
      } else {
        Toast(
          "Error in creating deal. Please try again later",
          "error",
          "companyDashboard"
        );
      }
    } else {
      Navigate("/company/onBoarding");
    }
  };
  function anyConditionTrue(objects) {
    return objects.some((obj) => obj.condition === true);
  }
  const isDealOpen = async () => {
    try {
      const response = await checkDealExist(startupId, token);
      setIsOpen(response.data.dealsExist);
      setDealClosed(response.data.dealClosed);
    } catch (error) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [token]);
  useEffect(() => {
    if (startupId) getDealId();
    if (startupId && isApplicationReviewed) {
      isDealOpen();
    }
  }, [startupId]);
  useEffect(() => {
    if (dealId) fetchDealDetails();
  }, [dealId]);
  const states_1 = [
    // state 1: profile is incomplete
    { condition: !profileCompleted, component: <CompleteProfile /> },

    // state 2: profile completed but not verified
    {
      condition: profileCompleted && !isApproved,
      component: <VerificationIncomplete />,
    },

    // state 3: profile completed, verified but user has not created the deal
    {
      condition: profileCompleted && isApproved && !dealCreated,
      component: (
        <CreateDeal
          handleCreateDealClick={handleCreateDealClick}
          companyName={details?.companyName}
        />
      ),
    },
  ];
  const states_2 = [
    // state 4: profile completed, verified, deal created but deal details are remaining
    {
      condition:
        profileCompleted && isApproved && dealCreated && !onBoardingCompleted,
      component: <CompleteDealDetails deal={deal} details={details} />,
    },

    // state 5: profile completed, verified, deal created, deal details filled but fundrev analyst has not reviewed yet
    {
      condition:
        profileCompleted &&
        isApproved &&
        dealCreated &&
        onBoardingCompleted &&
        !isApplicationReviewed,
      component: <DealUnderReview deal={deal} />,
    },

    // state 6: profile completed, verified, deal created, deal details filled, fundrev analyst approved, user has yet to approve
    {
      condition:
        profileCompleted &&
        isApproved &&
        dealCreated &&
        onBoardingCompleted &&
        isApplicationReviewed &&
        !isOpen,
      component: (
        <MakeDealLive
          dealId={dealId}
          deal={deal}
          startupId={details.startupId}
        />
      ),
    },

    // state 7: profile completed, verified, deal created, deal details filled, fundrev analyst approved, user approved, deal live, deal is ongoing
    {
      condition:
        profileCompleted &&
        isApproved &&
        dealCreated &&
        onBoardingCompleted &&
        isApplicationReviewed &&
        isOpen &&
        !dealClosed,
      component: <LiveDeal deal={deal} status="Live" />,
    },

    // state 8: profile completed, verified, deal created, deal details filled, fundrev analyst approved, user approved, deal live, and deal completed
    {
      condition:
        profileCompleted &&
        isApproved &&
        dealCreated &&
        onBoardingCompleted &&
        isApplicationReviewed &&
        isOpen &&
        dealClosed,
      component: <LiveDeal deal={deal} status="Completed" />,
    },
  ];

  return (
    <div className="InvestorSide company-dashboard">
      <Helmet>
        <title>Fundrev | Company Dashboard</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="companyDashboard" />
      <Sidebar active="overview" userRole="company" />
      <Topbar title="Overview" />
      <div className="InvestorSide-box">
        <div className="InvestorSide-content relative h-full">
          {anyConditionTrue(states_1) && (
            <div className="absolute right-[8px] top-[0px]">
              <CustomBlackBtn
                text={
                  <>
                    <GoPlus />
                    {}
                    Create a deal
                  </>
                }
                border="sm"
                filled={false}
                disabled={!(isApproved && profileCompleted)}
                onClick={handleCreateDealClick}
              />
            </div>
          )}
          {anyConditionTrue(states_1) && (
            <div className="mt-[28px]">
              {states_1.find(({ condition }) => condition)?.component}
            </div>
          )}
          {deal?.dealId && anyConditionTrue(states_2) && (
            <DetailedDashboard deal={deal} states={states_2} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
