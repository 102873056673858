import React from "react";
import Chart from "react-apexcharts";

const SmallAreaPlot = ({ data, compareData, color, isNegative }) => {
  // Split data into positive and negative values
  isNegative = data && data?.length && data?.every((value) => value < 0);
  const options = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: [1, 1, 1], // Increase the width for the dashed line
      dashArray: [0, 0, 0], // Keep the dash pattern for second series
      colors: [color, "#BFBFBF"], // Stroke colors
    },
    grid: {
      show: false,
    },
    markers: {
      size: [0, 0],
    },
    fill: {
      colors: [color, "#BFBFBF"], // Base colors for the plots
      type: ["gradient", "gradient", "solid"], // Specify fill types for each plot
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: [color, "#BFBFBF"], // Ending colors for gradients
        opacityFrom: [isNegative ? 0 : 0.7, 1], // Opacity start values for each plot
        opacityTo: [isNegative ? 0.7 : 0, 1], // Opacity end values for each plot (solid remains 1)
        stops: [0, 100], // Gradient stops (applied to gradients only)
      },
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      min: Math.min(data)[0],
    },
  };

  const series = [
    {
      name: "+ve Area Data",
      type: "area",
      data: data,
    },
    compareData?.length > 0 && {
      name: "Compare Data",
      type: "line",
      data: compareData,
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="line" height={`94`} />
    </div>
  );
};

export default SmallAreaPlot;
