import * as React from "react";
import { IoIosClose } from "react-icons/io";
import CustomBlackBtn from "../custom/customBlackBtn";
import whiteArrow from "../../../../Assets/Images/icons/whiteArrow.svg";
import LinearProgressBar from "../linearProgressBar";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { TagGroup } from "../investor/profileHelpers";
import { useRecoilValue } from "recoil";
import { investorState } from "../../../Investor/Dashboard/investorState";
import { postStageData } from "../../../../endpoints/deal";
import { useLoader } from "../../LoaderProvider";
import Toast from "../../Toast";
import WishlistButton from "./wishlistButton";
const MetricsCard = ({ title, metrics, variant = "secondary" }) => {
  const bgColor = variant === "primary" ? "bg-aero-blue-50" : "bg-white";
  const titleColor =
    variant === "primary" ? "text-aero-blue-700" : "text-neutral-7";
  const borderColor =
    variant === "primary" ? "border-aero-blue-100" : "border-neutral-4";

  return (
    <div
      className={`flex flex-col grow shrink gap-150 self-stretch p-150 my-auto ${bgColor} rounded-075 min-w-[240px] w-[204px] border border-solid ${
        variant === "secondary" ? "border-neutral-5" : `border-aero-blue-50`
      }`}
    >
      <div
        className={`text-desktop-p-xs tracking-[0.5px] uppercase ${titleColor}`}
      >
        {title}
      </div>
      <div className="text-neutral-12 flex flex-col w-full">
        {(metrics && typeof metrics === "object" && !Array.isArray(metrics)
          ? Object.entries(metrics).slice(0, 4)
          : Array(4).fill(["-", "-"])
        ).map(([key, value], index, array) => (
          <div
            key={key || index}
            className={`flex gap-10 justify-between items-center ${
              index !== array.length - 1
                ? `border-b border-solid ${borderColor}  pb-150`
                : "pb-050"
            } ${index !== 0 ? "mt-150" : ""} w-full`}
          >
            <div className="text-desktop-p-s text-nowrap self-stretch my-auto">
              {key || "-"}
            </div>
            <div className="text-desktop-p-mb self-stretch my-auto font-bold">
              {value || "-"}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
const NoNameProfile = ({
  profileOpen,
  setProfileOpen,
  deal,
  isRecommended,
  cardTags,
  percentageFundingRaised,
  isStarFilled,
  handleStarClick,
  isPassed,
}) => {
  const navigate = useNavigate();
  const { investorId } = useRecoilValue(investorState);
  const token = localStorage.getItem("token");
  const loader = useLoader();

  const handleShowInterest = async () => {
    const updatedFormData = {
      dealId: deal?.dealId,
      investorID: investorId,
      IsInterested: true,
      Stage: "interested",
      dealType: deal?.dealType,
    };
    let response;
    try {
      loader.start("Sending request to startup...");
      response = await postStageData(
        updatedFormData,
        token,
        deal?.dealType === "secondary"
      );
      loader.stop();
      if (response?.error?.status === 403) {
        Toast(
          "You do not have access to send the request",
          "error",
          "newDeals"
        );
        loader.stop();
        return;
      } else {
        navigate(`/investor/company/overview/${deal?.dealId}`, {
          state: {
            firstVisit: true,
          },
        });
        loader.stop();
      }
    } catch (error) {
      loader.stop();
    }
  };
  return (
    <div
      className={`w-full h-fit m-0 noNameProfile border-neutral-5 max-md:px-5 gap-300 p-300 rounded-100 flex flex-col items-end bg-white border border-solid shadow-sm ${
        profileOpen ? "flex" : "hidden"
      }`}
    >
      <div className="flex flex-col w-full max-w-[848px] max-md:max-w-full gap-200">
        <div className="max-md:max-w-full flex items-center justify-between w-full gap-10">
          <div className="flex items-center self-stretch gap-2 my-auto text-xs font-bold">
            <div className="bg-aero-blue-50 text-aero-blue-700 px-200 py-150 text-desktop-p-s self-stretch my-auto font-bold rounded">
              {deal?.dealType === "secondary" ? "Secondary" : deal?.roundType}
            </div>
            {deal?.dealTags && deal?.dealTags.length > 0 && (
              <div className="text-desktop-p-s px-200 py-150 self-stretch font-bold text-blue-700 border border-blue-400 rounded">
                {deal?.dealTags}
              </div>
            )}
          </div>
          <div className="flex items-center self-stretch w-10 gap-2 my-auto">
            <button
              className="flex gap-1.5 justify-center items-center self-stretch  my-auto w-10 h-10 bg-white rounded border border-solid border-neutral-5 min-h-[40px]"
              onClick={() => setProfileOpen(false)}
            >
              <IoIosClose fontSize={"25px"} />
            </button>
          </div>
        </div>
        <div className="max-md:max-w-full gap-200 flex flex-row items-start">
          <div className="flex flex-col min-w-[240px] w-[306px] gap-150">
            <div className="border-neutral-5 p-150 rounded-075 flex flex-col w-full gap-3 text-xs border border-solid">
              <div className="text-neutral-400 text-desktop-p-xs tracking-[0.5px] uppercase">
                Company Overview
              </div>
              <div className="text-neutral-12 text-base font-bold">
                {deal?.noNameTitle}
              </div>
              <div className="text-neutral-8 leading-5">
                {deal?.noNameSubHeading}
              </div>
              <TagGroup tags={cardTags} isRecommended={isRecommended} />
            </div>
            <div className="flex flex-col gap-4 justify-center p-150 w-full rounded-075 border border-solid border-neutral-5 min-h-[95px]">
              <div className="flex flex-col w-full">
                <div className="text-neutral-400 text-desktop-p-xs tracking-[0.5px] uppercase">
                  Current round
                </div>
                <div className="flex flex-col py-0.5 mt-3 w-full rounded-none max-w-[282px]">
                  <LinearProgressBar value={percentageFundingRaised} />
                  <div className="flex justify-between mt-2.5 text-desktop-p-xs font-medium text-neutral-12">
                    <p>
                      ₹{deal?.fundingRaised || 0}Cr ({percentageFundingRaised}%
                      raised)
                    </p>
                    <p>₹{deal?.fundingRequired}Cr</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col min-w-[240px] w-[526px] max-md:max-w-full gap-200">
            <div className="border-neutral-5 max-md:max-w-full gap-150 p-150 rounded-075 flex flex-col w-full border border-solid">
              <div className="text-neutral-7 max-md:max-w-full text-desktop-p-xs tracking-[0.5px] uppercase">
                INVESTMENT HIGHLIGHTS
              </div>
              <div
                className="text-neutral-900 max-md:max-w-full text-desktop-p-s leading-5"
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 7,
                  textOverflow: "ellipsis",
                }}
              >
                {deal?.dealType === "secondary"
                  ? parse(deal?.investmentHighlightsDescription || "")
                  : parse(deal?.investmentHighlights || "")}
              </div>
            </div>
            <div className="max-md:max-w-full gap-200 flex flex-wrap items-center w-full">
              <MetricsCard
                title="Financials"
                metrics={deal?.financialMetrics}
                variant="primary"
              />

              <MetricsCard
                title="Key Metrics"
                metrics={
                  deal?.dealType === "secondary"
                    ? deal?.investmentHighlights
                    : deal?.keyMetrics
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start gap-3">
        {!isPassed && (
          <WishlistButton filled={isStarFilled} onClick={handleStarClick} />
        )}

        {!isPassed && (
          <CustomBlackBtn
            text={
              <div className="gap-150 flex items-center">
                <p className="text-desktop-p-s font-semibold">Show Interest</p>
                <img src={whiteArrow} alt="arrow" />
              </div>
            }
            mode="blue"
            variant="sm"
            border="sm"
            additionalClasses="h-[42px] px-[25px] bg-blue-400"
            onClick={handleShowInterest}
          />
        )}
      </div>
    </div>
  );
};
export default NoNameProfile;
