import { CurrencyRupee } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import electricity from "../../../../Assets/Images/icons/electricity.svg";
import emptyState from "../../../../Assets/Images/icons/emptyStateFolder.svg";

const DashboardGrid1 = ({ data, deal, states }) => {
  const fundingRequired = deal?.fundingRequired;
  const navigate = useNavigate();

  const [topInvestors, setTopInvestors] = useState([]);
  const [displayCount, setDisplayCount] = useState(0);

  // Refs to measure heights
  const containerRef = useRef(null);
  const topSectionRef = useRef(null);
  const overviewRef = useRef(null);

  const stages = [
    "dealClosed",
    "requestSent",
    "requestAccepted",
    "ioiSubmitted",
    "ioiAccepted",
    "loiSubmitted",
    "loiAccepted",
    "spaNegotiation",
  ];

  const getInvestmentType = (investor) =>
    investor?.Stage === "ioiSubmitted" || investor?.Stage === "ioiAccepted"
      ? investor.IOI_Bid_Details.investmentType
      : investor?.Stage === "loiSubmitted" || investor?.Stage === "loiAccepted"
      ? investor.LOI_Bid_Details.investmentType
      : "";

  const getIntent = (intent) => {
    if (intent === 100) {
      return (
        <p className="investor-card-tag text-[var(--dark-green-color)] bg-[#DCEDEA]">
          Highest <img src={electricity} alt="high" className="w-[6px]" />
        </p>
      );
    } else if (intent > 75) {
      return (
        <p className="investor-card-tag text-[var(--dark-green-color)] bg-[#DCEDEA]">
          High
        </p>
      );
    } else if (intent > 50) {
      return (
        <p className="investor-card-tag text-[var(--blue-color)] bg-[var(--light-blue-color)]">
          Medium
        </p>
      );
    } else {
      return <p className="investor-card-tag bg-[var(--pink-color)]">Low</p>;
    }
  };

  const handleTopInvestors = () => {
    const filteredInvestors =
      data?.activeInvestorTable?.length > 0 &&
      data?.activeInvestorTable?.filter(
        (inv) =>
          inv.IsInterested && !inv.IsRejected && inv.Stage !== "watchlist"
      );
    const sortedInvestors =
      filteredInvestors?.length > 0 &&
      filteredInvestors?.sort((a, b) => {
        return stages.indexOf(b.Stage) - stages.indexOf(a.Stage);
      });
    sortedInvestors?.length > 0 && setTopInvestors(sortedInvestors);
  };

  useEffect(() => {
    handleTopInvestors();
  }, [data?.activeInvestorTable?.length]);

  useEffect(() => {
    // After topInvestors is set, measure the layout
    if (
      containerRef.current &&
      topSectionRef.current &&
      overviewRef.current &&
      topInvestors.length > 0
    ) {
      // Measure heights
      const containerHeight = containerRef.current.clientHeight;
      const topHeight = topSectionRef.current.clientHeight;
      const overviewHeight = overviewRef.current.clientHeight;

      // Available height for top investors section
      const availableHeight = containerHeight - topHeight - overviewHeight;

      // Height of each card (including margin)
      // Adjust if needed to match actual rendered height + spacing
      const investorCardHeight = 45 + 8; // card height + top margin if any
      const cardsThatFit = Math.floor(availableHeight / investorCardHeight);

      setDisplayCount(Math.min(cardsThatFit, topInvestors.length));
    } else {
      // If no investors or can't measure yet, show all by default
      setDisplayCount(topInvestors.length);
    }
  }, [topInvestors]);

  return (
    <>
      <style>
        {`@media(max-height: 750px){
          .top-investor-box {
              margin-bottom : 0px;
       }
        }`}
      </style>
      <Box
        ref={containerRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh", // Or any fixed height container you have
          overflow: "hidden",
        }}
      >
        {/* Top (Ongoing Deal) Section */}
        <Box ref={topSectionRef}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <Box
              sx={{
                fontSize: "16px",
                color: "#696F79",
                lineHeight: "22px",
              }}
            >
              Ongoing Deal
            </Box>
            <Box>
              {deal?.dealId && (
                <div className="dealId-div">
                  <div>Deal ID: {deal?.dealId}</div>
                </div>
              )}
            </Box>
          </Box>
          {states.find(({ condition }) => condition)?.component}
        </Box>

        {/* Top Investors Section */}
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            marginY: "16px",
            overflow: "hidden",
          }}
          className="top-investor-box"
        >
          <div className="flex items-center justify-between">
            Top Investors{" "}
            <button
              className="underline text-[var(--blue-color)] text-xs"
              onClick={() => navigate("/company/ongoingDeals/current")}
            >
              View All
            </button>
          </div>
          <Box
            sx={{
              marginTop: "16px",
              overflow: "hidden",
            }}
          >
            {topInvestors?.length > 0 ? (
              topInvestors.slice(0, displayCount).map((investor, index) => (
                <div
                  key={index}
                  className={`h-[45px] p-2 flex justify-between items-center bg-[#E5F5FE] rounded-s ${
                    index !== 0 && `mt-2`
                  }`}
                >
                  <div className="flex justify-center items-center gap-2">
                    <div>
                      <img
                        alt="investorLogo"
                        src={investor?.firmLogo}
                        style={{
                          height: "29px",
                          width: "29px",
                          objectFit: "cover",
                          borderRadius: "6px",
                        }}
                      />
                    </div>
                    <div>
                      <div className="text-xs font-bold">
                        {investor?.firmName}
                      </div>
                      <div className="text-xs text-[#747474]">
                        {investor?.investorType}
                      </div>
                    </div>
                  </div>
                  <div>
                    {["requestSent", "requestAccepted"].includes(
                      investor?.Stage
                    ) ? (
                      <p className="text-[12px]">
                        {getIntent(investor?.investorIntentPercentile)}
                      </p>
                    ) : (
                      ![
                        "requestSent",
                        "requestAccepted",
                        "dealClosed",
                      ].includes(investor?.Stage) &&
                      (getInvestmentType(investor) === "Lead Round" ? (
                        <p className="investor-card-tag text-[var(--dark-green-color)] bg-[var(--light-green-color)]">
                          Lead
                        </p>
                      ) : (
                        <p className="investor-card-tag text-[var(--blue-color)] bg-[var(--light-blue-color)]">
                          {getInvestmentType(investor)}
                        </p>
                      ))
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full flex justify-center mt-3">
                <div className="flex flex-col items-center">
                  <img src={emptyState} alt="emptyState" />
                  <p className="opacity-30 text-[var(--Primary-Text-Color)] mt-2">
                    No Investors
                  </p>
                </div>
              </div>
            )}
          </Box>
        </Box>

        {/* Overview Section */}
        <Box ref={overviewRef}>
          <Box
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              marginBottom: "16px",
            }}
          >
            Overview
          </Box>
          <div className="flex justify-normal gap-2 items-center w-full flex-col">
            <div className="rounded w-full px-4 py-2 border border-dotted rounded-s">
              <div className="flex items-center justify-between">
                <div className="text-sm font-light text-[#696F79]">
                  Profile views
                </div>
                <div className="text-base font-semibold text-[var(--Primary-Text-Color)]">
                  {data?.profileViews}
                </div>
              </div>
            </div>
            <div className="rounded w-full px-4 py-2 border border-dotted rounded-s">
              <div className="flex items-center justify-between">
                <div className="text-sm font-light text-[#696F79]">
                  Investor Screening
                </div>
                <div className="text-base font-semibold text-[var(--Primary-Text-Color)] flex items-center">
                  {data?.noNameRequests}{" "}
                </div>
              </div>
            </div>
            <div className="rounded w-full px-4 py-2 border border-dotted rounded-s">
              <div className="flex justify-between items-center">
                <div className="text-sm font-light text-[#696F79]">
                  Bids Received{" "}
                </div>
                <div className="flex items-center gap-1 text-base font-semibold text-[var(--Primary-Text-Color)]">
                  {fundingRequired && (
                    <div className="text-xs text-[#4FB24A]">
                      {data?.bidsReceived[0]?.totalIOI_Bids +
                      data?.bidsReceived[0]?.totalLOI_Bids
                        ? (
                            ((data?.bidsReceived[0]?.totalIOI_Bids +
                              data?.bidsReceived[0]?.totalLOI_Bids) /
                              Number(fundingRequired)) *
                            100
                          )?.toFixed(0) + `% Round`
                        : ``}
                    </div>
                  )}
                  {data?.bidsReceived[0]?.totalIOI_Bids +
                  data?.bidsReceived[0]?.totalLOI_Bids ? (
                    <div className="flex items-center">
                      <CurrencyRupee fontSize="16px" />{" "}
                      {data?.bidsReceived[0]?.totalIOI_Bids +
                        data?.bidsReceived[0]?.totalLOI_Bids}
                      Cr{" "}
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <CurrencyRupee fontSize="16px" /> 0Cr
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="rounded w-full px-4 py-2 border border-dotted rounded-s">
              <div className="flex justify-between items-center">
                <div className="text-sm font-light text-[#696F79]">
                  Bids Accepted{" "}
                </div>
                <div className="flex justify-between items-center gap-1 text-base font-semibold text-[var(--Primary-Text-Color)]">
                  {fundingRequired && (
                    <div className="text-xs text-[#4FB24A]">
                      {data?.bidsAccepted[0]?.totalIOI_Bids +
                      data?.bidsAccepted[0]?.totalLOI_Bids
                        ? (
                            ((data?.bidsAccepted[0]?.totalIOI_Bids +
                              data?.bidsAccepted[0]?.totalLOI_Bids) /
                              Number(fundingRequired)) *
                            100
                          )?.toFixed(0) + `% Round`
                        : ``}
                    </div>
                  )}
                  {data?.bidsAccepted[0]?.totalIOI_Bids +
                  data?.bidsAccepted[0]?.totalLOI_Bids ? (
                    <div className="flex items-center">
                      <CurrencyRupee fontSize="16px" />{" "}
                      {data?.bidsAccepted[0]?.totalIOI_Bids +
                        data?.bidsAccepted[0]?.totalLOI_Bids}
                      Cr{" "}
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <CurrencyRupee fontSize="16px" /> 0Cr
                    </div>
                  )}{" "}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default DashboardGrid1;
