import React from "react";
import { Dialog } from "@mui/material";
import { IoClose } from "react-icons/io5";
import CustomBlackBtn from "./customBlackBtn";

const CustomDialog = ({ children, onClose, ...props }) => {
  return (
    <Dialog
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(5px)", // Blur effect for the backdrop
            backgroundColor: "rgba(255, 255, 255, 0.4)", // Semi-transparent white
          },
        },
      }}
      PaperProps={{
        sx: {
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", // Custom shadow
          position: "relative",
          zIndex: 40,
          border: "1px solid #D9D9D9", // Border styling
          borderRadius: 2, // Optional rounded corners
          maxWidth: "100%",
        },
      }}
      sx={{
        zIndex: 50,
      }}
      {...props}
    >
      {children}
    </Dialog>
  );
};
export default CustomDialog;

export const CustomDialogWithActions = ({
  children,
  onClose,
  className,
  ...props
}) => {
  return (
    <CustomDialog onClose={onClose} {...props}>
      <div
        className={`bg-white flex flex-col justify-center items-center p-150 rounded-100 ${className}`}
      >
        <div
          className="p-150 border-neutral-5 rounded-050 top-300 right-300 absolute border cursor-pointer"
          onClick={onClose}
        >
          <IoClose />
        </div>
        {children}
      </div>
    </CustomDialog>
  );
};

export const CustomDialogWithContent = ({
  children,
  onClose,
  className,
  heading,
  subheading,
  primaryActionText,
  primaryAction,
  secondaryActionText,
  secondaryAction,
  illustration,
  ...props
}) => {
  return (
    <CustomDialog onClose={onClose} {...props}>
      <div className="relative bg-white w-[504px] flex flex-col justify-center items-center pt-400 pb-300 px-[78px] rounded-100">
        <div
          className="p-150 border-neutral-5 rounded-050 top-150 right-150 absolute border cursor-pointer"
          onClick={onClose}
        >
          <IoClose />
        </div>
        <img src={illustration} alt="illustration" />
        <p className="text-neutral-12 text-desktop-p-l mt-9 font-bold">
          {heading}
        </p>
        <p className="text-desktop-p-mb mt-200 font-normal text-center">
          {subheading}
        </p>
        <div className="gap-150 mt-9 flex">
          {secondaryActionText && (
            <CustomBlackBtn
              text={secondaryActionText}
              onClick={secondaryAction}
              mode="blue"
              border="sm"
              variant="sm"
              additionalClasses="!px-300"
              filled={false}
            />
          )}

          <CustomBlackBtn
            text={primaryActionText}
            onClick={primaryAction}
            mode="blue"
            border="sm"
            variant="sm"
            additionalClasses="!px-300"
          />
        </div>
      </div>
    </CustomDialog>
  );
};
