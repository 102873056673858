import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import emptyState from "../../../Assets/Images/icons/emptyStateFolder.svg";

const PendingRequestsTable = ({ data, deal }) => {
  const navigate = useNavigate();
  const handleClick = (investor) => {
    switch (investor.Stage) {
      case "requestSent":
        navigate("/company/investorDetails", {
          state: {
            passedStage: "requestSent",
            investorID: investor?.investorID,
            dealId: deal?.dealId,
            investor: investor?.investorDetails,
            isDealLive: true,
          },
        });
        break;
      case "ioiSubmitted":
        navigate("/company/termSheet", {
          state: {
            stage: "ioiSubmitted",
            dealId: deal?.dealId,
            investorID: investor?.investorID,
            companyName: investor?.firmName,
            isDealLive: true,
          },
        });
        break;
      case "loiSubmitted":
        navigate("/company/dueDiligence", {
          state: {
            stage: "loiSubmitted",
            dealId: deal?.dealId,
            investorID: investor.investorID,
            companyName: investor.firmName,
            isDealLive: true,
          },
        });
        break;
      case "watchlist":
        // console.log(deal?.dealId, investor.investorID, investor.firmName);
        navigate("/company/investorDetails", {
          state: {
            passedStage: "watchlist",
            dealId: deal?.dealId,
            investorID: investor.investorID,
            investor: investor?.investorDetails,
            isDealLive: true,
          },
        });
        break;
      default:
        return;
    }
  };

  const getStage = (stage) => {
    switch (stage) {
      case "requestSent":
      case "requestAccepted": // Handles both "requestSent" and "requestAccepted"
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#00A6FB20",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#00A6FB",
              fontSize: "10px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            Screening
          </Box>
        );
      case "ioiSubmitted":
      case "ioiAccepted":
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#F0F7F9",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#00A6FB",
              fontSize: "10px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            Term Sheet
          </Box>
        );
      case "loiSubmitted":
      case "loiAccepted":
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#00A6FB20",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#00A6FB",
              fontSize: "10px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            Due Diligence
          </Box>
        );
      case "spaNegotiation":
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#E9FEEA",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#4FB24A",
              fontSize: "10px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            SPA
          </Box>
        );
      case "dealClosed":
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#E9FEEA",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#4FB24A",
              fontSize: "10px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            Close
          </Box>
        );
      default:
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#00A6FB20",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#00A6FB",
              fontSize: "10px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            {String(stage)}
          </Box>
        );
    }
  };
  return (
    <>
      <Box
        sx={{
          height: "calc(50vh - 60px)",
          width: "100%",
          backgroundColor: "white",
          borderRadius: "4px",
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <div className="text-base text-[#696F79]">Request</div>
        </Box>
        <Paper
          sx={{
            boxShadow: 0,
          }}
        >
          <TableContainer
            sx={{
              borderRadius: "4px",
              height: "calc(50vh - 150px)",
            }}
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      height: "40px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight: "300",
                      fontSize: "14px",
                      backgroundColor: "#DADADA",
                    }}
                  >
                    Investor Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontWeight: "300",
                      fontSize: "14px",
                      backgroundColor: "#DADADA",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontWeight: "300",
                      fontSize: "14px",
                      backgroundColor: "#DADADA",
                    }}
                  >
                    Type
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.pendingRequests?.length > 0 &&
                  data?.pendingRequests?.map((request) => (
                    <>
                      <TableRow key={request.investorID}>
                        <TableCell
                          sx={{
                            maxWidth: "200px",
                            fontFamily: "Karla, sans-serif",
                            height: "50px",
                            fontSize: "14px",
                          }}
                        >
                          {request.firmName}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Karla, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {dayjs(request.createdAt)
                            .tz(`Asia/Kolkata`)
                            .format(`DD MMM YYYY`)}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Karla, sans-serif",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClick(request)}
                        >
                          {getStage(request.Stage)}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                {data?.pendingRequests?.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      sx={{
                        border: 0,
                        padding: 0, // Remove default cell padding to avoid extra spacing
                      }}
                      align="right"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          alignItems: "center",
                          flexDirection: "column",
                          height: "calc(50vh - 200px)",
                        }}
                      >
                        <img src={emptyState} alt="emptyState" />
                        <p
                          style={{
                            opacity: 0.3,
                            color: "var(--Primary-Text-Color)",
                            marginTop: "8px",
                          }}
                        >
                          No Requests
                        </p>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default PendingRequestsTable;
