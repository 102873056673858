import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/components/sidebar";
import Topbar from "./Components/topbar";
import OnGoingDealCard from "./Components/onGoingDealCard";
import Card from "../../Common/components/Card";
import { getDealsByInvestorID, getDealByStage } from "../../../endpoints/deal";
import { fetchInvestorMemberDetails } from "../../../endpoints/investor";
import {
  reducedStages,
  stageRenameMap,
  stageMap,
} from "../../Common/functions";

import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment-timezone";
import "../../../CSS/InvestorPage/Dashboard/onGoingDeals.css";
import { useLoader } from "../../Common/LoaderProvider";
import { getSecondaryDealById } from "../../../endpoints/secondary";
import emptyStateFolder from "../../../Assets/Images/icons/emptyStateFolder.svg";
import { calculateDaysAgo } from "../../Common/functions";
import Navbar from "../../Common/components/navbar";

const OngoingDeals = () => {
  const loader = useLoader();
  const Navigate = useNavigate();
  const { type } = useParams();
  const token = localStorage.getItem("token");
  const [name, setName] = useState("");
  const [investorId, setInvestorId] = useState("");
  const [deals, setDeals] = useState([]);
  const [dealDetails, setDealDetails] = useState({});
  const [groupedDeals, setGroupedDeals] = useState([]);
  const [title, setTitle] = useState("");
  const [dealStages] = useState(reducedStages);
  // const open = localStorage.getItem("isApplicationReviewed");
  // useEffect(() => {
  //   if (open === "false") {
  //     Navigate("/investorDetails");
  //   }
  // });
  // const [filteredDeals, setFilteredDeals] = useState([]);
  function dealsLength(type) {
    try {
      const length = type.length ? type.length : 0;
      return length.toString().padStart(2, "0");
    } catch (error) {
      return "00";
    }
  }
  const fetchDetails = async () => {
    const token = localStorage.getItem("token");
    let response;
    if (token) {
      response = await fetchInvestorMemberDetails(token);
    }
    if (token) {
      if (response && response.data !== null) {
        setName(response.data.data.name);
        setInvestorId(response.data.data.investorID);
      } else {
      }
    }
  };
  useEffect(() => {
    loader.start("Fetching deals...");
    fetchDetails();
  }, [token]);

  useEffect(() => {
    const fetchDeals = async () => {
      if (investorId) {
        try {
          loader.start("Fetching deals...");
          const response = await getDealsByInvestorID(investorId, token);
          setDeals(response.data);
          if (response.data.length === 0) {
            loader.stop();
          }
        } catch (error) {
          loader.stop();
        }
      }
    };

    fetchDeals();
  }, [investorId]);

  useEffect(() => {
    const fetchDealDetails = async () => {
      const details = {};
      if (token) {
        for (const deal of deals) {
          try {
            let response;
            if (deal?.dealType === "secondary") {
              response = await getSecondaryDealById(
                deal?.dealId,
                token,
                investorId
              );
            } else {
              response = await getDealByStage(deal.dealId, token);
            }
            details[deal.dealId] = response.data;
          } catch (error) {}
        }
        setDealDetails(details);
      }
      setDealDetails(details);
    };
    if (deals) {
      fetchDealDetails();
    } else if (deals.length === 0 && investorId) {
      loader.stop();
    }
  }, [deals]);

  const renderDealCard = (deal, stage, index) => {
    const details = dealDetails[deal.dealId];

    if (!details) return null;
    // const latestActivity = getFormattedDate(deal.createdAt);
    const latestActivity = moment(deal.createdAt)
      .tz("Asia/Kolkata")
      .format("D MMM YYYY");
    loader.stop();

    return (
      <OnGoingDealCard
        index={index}
        key={deal.dealId}
        requestedDate={
          deal.Stage === "requestSent" ? latestActivity : undefined
        }
        submittedDate={
          deal.Stage === "ioiSubmitted" ||
          deal.Stage === "loiSubmitted" ||
          deal.Stage === "interested"
            ? latestActivity
            : undefined
        }
        daysAgo={calculateDaysAgo(deal.createdAt)}
        acceptedDate={
          deal.Stage === "requestAccepted" ||
          deal.Stage === "ioiAccepted" ||
          deal.Stage === "loiAccepted"
            ? latestActivity
            : undefined
        }
        IOIBidSubmittedAmount={
          deal.Stage === "ioiSubmitted"
            ? deal?.IOI_Bid_Details?.investmentAmount
            : undefined
        }
        IOIBidAcceptedAmount={
          deal.Stage === "ioiAccepted"
            ? deal?.IOI_Bid_Details?.investmentAmount
            : undefined
        }
        LOIBidSubmittedAmount={
          deal.Stage === "loiSubmitted"
            ? deal?.LOI_Bid_Details?.investmentAmount
            : undefined
        }
        LOIBidAcceptedAmount={
          deal.Stage === "loiAccepted"
            ? deal.LOI_Bid_Details.investmentAmount
            : undefined
        }
        closingDate={deal.Stage === "dealClosed" ? latestActivity : undefined}
        deal={details}
        stage={deal.Stage}
        isInterested={deal.IsInterested}
        isRejected={deal.IsRejected}
      />
    );
  };

  // const handleTabClick = (tab) => {
  //   setSelectedTab(tab);
  // };
  const handleGroupedFilteredDeals = () => {
    // Filter deals based on the `type`
    let filteredDeals;
    switch (type) {
      case "current":
        filteredDeals = deals
          ? deals.filter((deal) => deal.IsInterested === true)
          : [];
        break;
      case "dead":
        filteredDeals = deals
          ? deals.filter(
              (deal) =>
                (deal.IsInterested === false || deal.IsRejected === true) &&
                deal.Stage !== "initialStage"
            )
          : [];
        break;
      case "watchlist":
        filteredDeals = deals
          ? deals.filter((deal) => deal.Stage === "watchlist")
          : [];
        break;
      default:
        filteredDeals = deals || [];
    }

    // Now, group the filtered deals by stage
    const groupedDeals = dealStages.reduce((acc, stage) => {
      acc[stage] = filteredDeals.filter(
        (deal) => stageMap[deal.Stage] === stage
      );
      return acc;
    }, {}); // Initialize with an empty object as the accumulator

    // Set the grouped deals
    setGroupedDeals(groupedDeals);
  };

  useEffect(() => {
    handleGroupedFilteredDeals();
  }, [type, deals]);
  useEffect(() => {
    if (!type) {
      Navigate("/investor/ongoingDeals/current");
    }
  });
  useEffect(() => {
    setTitle(
      type === "current"
        ? "Ongoing Deals"
        : type === "dead"
        ? "Dead Deals"
        : type === "watchlist"
        ? "Watchlist"
        : "Ongoing Deals"
    );
  }, [type]);

  const stageTextMap = {
    requestSent:
      "Deals you've shown interest in will appear here. Start exploring opportunities from the listing page.",
    ioiSubmitted:
      "Deals requiring a term sheet will show up here as you progress in the process.",
    loiSubmitted:
      "Once term sheets are accepted, deals will move here for further analysis.",
    dealClosed:
      "Deals in the final stages of agreement will be listed here once the due diligence is completed.",
    watchlist: "Deals you put in watchlist will appear here",
  };
  return (
    <div className="InvestorSide onGoingDeals-section newOngoingDeals !overflow-hidden">
      <Helmet>
        <title>Investor | Ongoing Deals</title>
      </Helmet>
      <Sidebar active="ongoingDeals" type={type} userRole="investor" />
      {/* <Topbar title={title} /> */}
      <Navbar navItems={[]} title={"Ongoing Deals"}></Navbar>
      <div className="InvestorSide-box !mt-0 !overflow-y-hidden !py-300 !h-[calc(100vh-60px)]">
        <div
          className={`max-w-[1500px] flex m-auto h-full ${
            type !== "watchlist" && "justify-center"
          }`}
        >
          <div className="flex !overflow-y-hidden overflow-x-scroll bg-white p-200 gap-200 rounded-100 border border-neutral-5 w-fit">
            {dealStages.map((stage, index) => (
              <div
                key={index}
                id={stage}
                className={`gap-300 flex flex-col items-center  ${
                  stage !== "dealClosed" &&
                  stage !== "watchlist" &&
                  "pr-200 border-r-neutral-5 border-r-[1px]"
                }`}
                style={{
                  display:
                    (type === "watchlist") === (stage === "watchlist")
                      ? "flex"
                      : "none",
                }}
              >
                <div className="flex justify-between w-[305px]">
                  <div className="text-neutral-12 text-desktop-p-xl">
                    {stageRenameMap[stage]}
                  </div>
                  <div className="text-[#9AA5AD] text-desktop-p-xl font-normal">
                    {dealsLength(groupedDeals[stage])}
                  </div>
                </div>
                <div className="flex flex-col gap-200 overflow-y-scroll h-[calc(100vh-180px)] w-[300px]">
                  {groupedDeals &&
                  groupedDeals[stage] &&
                  groupedDeals[stage].length > 0 ? (
                    groupedDeals[stage].map((deal, index) =>
                      renderDealCard(deal, stage, index)
                    )
                  ) : (
                    <div className="flex flex-col items-center justify-center h-full w-[80%] m-auto text-center">
                      <img src={emptyStateFolder} alt="Empty folder" />
                      <p className="mt-300 text-neutral-12 font-bold">
                        No Deals in {stageRenameMap[stage]}
                      </p>
                      <p className="text-desktop-p-s text-neutral-12 mt-200">
                        {type !== "dead"
                          ? stageTextMap[stage]
                          : "Deals that are no longer active will appear here for your records."}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OngoingDeals;
