import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import Topbar from "../Company/Deal/topbar";
import SecondarySidebar from "./secondarySidebar";
import {
  getLatestDealInterestByInvestorDealId,
  getLatestStageInvestorsByDealId,
  getSecondaryDealById,
} from "../../../endpoints/secondary";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import CustomModal from "../../Common/components/custom/customModal";
import { useLoader } from "../../Common/LoaderProvider";
import { postStageData } from "../../../endpoints/deal";
import { ToastContainer } from "react-toastify";
import Toast from "../../Common/Toast";
import SecondaryViewBid from "../../Common/modals/SecondaryViewBid";

const SecondaryDealRequests = () => {
  const location = useLocation();
  const token = localStorage.getItem(`token`);
  const { dealId } = location.state || {};
  const [deal, setDeal] = useState(null);
  const [investors, setInvestors] = useState([]);
  const [acceptModal, SetAcceptModal] = useState(false);
  const [rejectModal, SetRejectModal] = useState(false);
  const [secondaryViewModal, setSecondaryViewModal] = useState(false);
  const [bid, setBid] = useState(null);
  const [bidDate, setBidDate] = useState(null);
  const navigate = useNavigate();
  const loader = useLoader();
  const [selectedInvestorId, setSelectedInvestorId] = useState(null);
  const getDealDetails = async () => {
    const response = await getSecondaryDealById(dealId, token);
    if (response?.data) {
      setDeal(response.data);
    }
  };
  const getInterestedInvestors = async () => {
    const response = await getLatestStageInvestorsByDealId(dealId, token);
    if (response?.data) {
      setInvestors(response.data);
    } else {
      console.log("No interested investors found");
    }
  };
  useEffect(() => {
    getInterestedInvestors();
    getDealDetails();
  }, []);
  const handleAccept = async () => {
    const investor = acceptModal;
    let updatedFormData = {
      dealId: dealId,
      investorID: investor?.investorID,
      IsInterested: true,
      dealType: deal?.dealType,
    };
    let stageAccept;
    if (investor?.Stage === "requestSent") {
      stageAccept = "requestAccepted";
      updatedFormData = {
        ...updatedFormData,
        Stage: stageAccept,
      };
    } else if (investor?.Stage === "ioiSubmitted") {
      stageAccept = "ioiAccepted";
      updatedFormData = {
        ...updatedFormData,
        IOI_Bid_Details: investor?.allIOI_Bids?.[0],
        Stage: stageAccept,
      };
    } else if (investor?.Stage === "loiSubmitted") {
      stageAccept = "loiAccepted";
      updatedFormData = {
        ...updatedFormData,
        LOI_Bid_Details: investor?.allLOI_Agreements?.[0],
        Stage: stageAccept,
      };
    }

    try {
      const response = await postStageData(updatedFormData, token);
      loader.stop();
      if (response.data !== null) {
        Toast(
          `Request Successfully Accepted!`,
          `success`,
          `secondaryDealRequests`
        );
        SetAcceptModal(false);
      }
      getInterestedInvestors();
    } catch (error) {
      Toast(`Something went wrong`, `error`, `secondaryDealRequests`);
      loader.stop();
    }
  };
  useEffect(() => {}, [investors]);
  const handleReject = async () => {
    const investor = rejectModal;
    let updatedFormData = {
      dealId: dealId,
      investorID: investor?.investorID,
      IsRejected: true,
      Stage: investor?.Stage,
      dealType: deal?.dealType,
    };
    if (
      investor?.Stage === "ioiSubmitted" ||
      investor?.Stage === "ioiAccepted"
    ) {
      updatedFormData = {
        ...updatedFormData,
        IOI_Bid_Details: investor?.allIOI_Bids?.[0],
      };
    } else if (
      investor?.Stage === "loiSubmitted" ||
      investor?.Stage === "loiAccepted"
    ) {
      updatedFormData = {
        ...updatedFormData,
        LOI_Bid_Details: investor?.allLOI_Agreements?.[0],
      };
    }
    try {
      const response = await postStageData(updatedFormData, token, true);
      loader.stop();
      if (response.data !== null) {
        Toast(`Deal Rejected!`, `info`, `secondaryDealRequests`);
        SetRejectModal(false);
      }
      getInterestedInvestors();
    } catch (error) {
      Toast(`Something went wrong`, `error`, `secondaryDealRequests`);
      loader.stop();
    }
  };

  const handleViewBid = (investor) => {
    const stage = investor?.Stage;
    if (stage === "ioiSubmitted" || stage === "ioiAccepted") {
      setSelectedInvestorId(investor?.investorID);
      setSecondaryViewModal(true);
    } else if (stage === "loiSubmitted" || stage === "loiAccepted") {
      setSelectedInvestorId(investor?.investorID);
      setSecondaryViewModal(true);
    } else {
      return false;
    }
  };
  const getLatestDealInterest = async () => {
    const response = await getLatestDealInterestByInvestorDealId(
      deal?.dealId,
      selectedInvestorId,
      token
    );
    if (response?.data) {
      const bid =
        response?.data?.IOI_Bid_Details ||
        response?.data?.LOI_Bid_Details ||
        {};
      setBidDate(response?.data?.createdAt);
      setBid(bid);
    }
  };

  useEffect(() => {}, [selectedInvestorId]);

  return (
    <>
      <Helmet>
        <title>Admin Panel - Secondary Deal</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        containerId={`secondaryDealRequests`}
      />
      <Topbar />
      <SecondarySidebar dealId={dealId} active={`dealRequests`} />
      <CustomModal
        open={acceptModal}
        onClose={() => SetAcceptModal(false)}
        title={`Confirmation`}
        description={`Are you sure you want to proceed with accepting the request?`}
        primaryText="Accept"
        secondaryText="Cancel"
        primaryAction={handleAccept}
        secondaryAction={() => SetAcceptModal(false)}
      />
      <SecondaryViewBid
        secondaryViewModal={secondaryViewModal}
        setSecondaryViewModal={setSecondaryViewModal}
        deal={deal}
        investorID={selectedInvestorId}
        page="companyDetailsPage"
        getLatestDealInterest={getLatestDealInterest}
        bid={bid}
        bidDate={bidDate}
      />
      <CustomModal
        open={rejectModal}
        onClose={() => SetRejectModal(false)}
        title={`Confirmation`}
        description={`Are you sure you want to proceed with rejecting the request?`}
        primaryText="Reject"
        secondaryText="Cancel"
        primaryAction={handleReject}
        secondaryAction={() => SetRejectModal(false)}
      />
      <div className="mt-20 h-[90vh] ml-[270px] overflow-scroll">
        <div className="text-2xl font-semibold">Requests</div>
        <div className="mt-4">
          <table>
            <thead>
              <th>Investor Name</th>
              <th>Investor ID</th>
              <th>Stage</th>
              <th>View</th>
              <th>Action</th>
              <th>View Investor</th>
            </thead>
            <tbody>
              {investors?.length > 0 ? (
                investors.map((investor) => (
                  <tr>
                    <td>{investor?.details?.companyName}</td>
                    <td>{investor?.investorID}</td>
                    <td>
                      {investor?.IsInterested === false &&
                        "Deal Passed by investor - "}
                      {investor?.IsRejected === true &&
                        "Deal Passed by admin - "}
                      {investor?.Stage}
                    </td>
                    <td>
                      {!["requestSent", "requestAccepted"].includes(
                        investor?.Stage
                      ) ? (
                        <CustomBlackBtn
                          text={`View Bid`}
                          variant="sm"
                          border="sm"
                          mode="blue"
                          onClick={() => handleViewBid(investor)}
                        />
                      ) : (
                        `-`
                      )}
                    </td>
                    <td>
                      <div className="flex items-center gap-2">
                        {investor?.IsInterested && !investor?.IsRejected && (
                          <>
                            {![
                              "requestAccepted",
                              "ioiAccepted",
                              "loiAccepted",
                            ].includes(investor?.Stage) &&
                              deal && (
                                <CustomBlackBtn
                                  text={`Accept`}
                                  variant="sm"
                                  border="sm"
                                  onClick={() =>
                                    ![
                                      "requestAccepted",
                                      "ioiAccepted",
                                      "loiAccepted",
                                    ].includes(investor?.Stage) &&
                                    deal &&
                                    SetAcceptModal(investor)
                                  }
                                />
                              )}
                            <CustomBlackBtn
                              text={`Reject`}
                              variant="sm"
                              border="sm"
                              mode="danger"
                              filled={false}
                              onClick={() => deal && SetRejectModal(investor)}
                            />
                          </>
                        )}
                      </div>
                    </td>
                    <td>
                      <CustomBlackBtn
                        text={`View`}
                        variant="sm"
                        border="sm"
                        filled={false}
                        onClick={() =>
                          navigate("/admin/investor/preview", {
                            state: {
                              investor: investor?.details,
                            },
                          })
                        }
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={`5`} className="text-center">
                    No Interested Investors
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SecondaryDealRequests;
