import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";

export default function WishlistButton({ filled, onClick = {} }) {
  const [isAdded, setIsAdded] = useState(filled);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = async () => {
    if (isAnimating) return;
    setIsAnimating(true);

    await new Promise((resolve) => setTimeout(resolve, 150));
    setIsAdded((prev) => !prev);

    setTimeout(() => {
      setIsAnimating(false);
    }, 300);
    onClick();
  };

  return (
    <motion.button
      onClick={handleClick}
      className={`flex items-center gap-2 px-6 py-[11.5px] rounded transition-colors border ${
        isAdded
          ? "bg-amber-50 text-amber-900 border-amber-200"
          : "bg-white text-neutral-12 border-neutral-12"
      }`}
      whileTap={{ scale: 0.98 }}
    >
      <motion.div
        animate={{
          scale: isAnimating ? 0.5 : 1,
          rotate: isAnimating ? 180 : 0,
        }}
        transition={{
          duration: 0.15,
          ease: "easeInOut",
        }}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={isAdded ? "filled" : "outlined"}
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.5, opacity: 0 }}
            transition={{ duration: 0.15 }}
          >
            {isAdded ? (
              <AiFillStar className="text-amber-400 w-4 h-4" />
            ) : (
              <AiOutlineStar className="text-amber-400 w-4 h-4" />
            )}
          </motion.div>
        </AnimatePresence>
      </motion.div>

      <AnimatePresence mode="wait">
        <motion.span
          key={isAdded ? "added" : "add"}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.15 }}
        >
          <p className="text-desktop-p-s text-neutral-12 font-medium">
            {isAdded ? "Added to Wishlist" : "Add to Wishlist"}
          </p>
        </motion.span>
      </AnimatePresence>
    </motion.button>
  );
}
