import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import emptyState from "../../../../Assets/Images/icons/emptyStateFolder.svg";

const DashboardGrid3 = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={2}>
          <Grid xs={12}>
            <Box
              sx={{
                background: "white",
                borderRadius: "4px",
                height: "calc(33vh - 43px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="flex flex-col items-center">
                <img src={emptyState} alt="emptyState" />
                <p className="opacity-30 text-[var(--Primary-Text-Color)] mt-2">
                  No investors with dataroom access
                </p>
              </div>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box
              sx={{
                background: "white",
                borderRadius: "4px",
                height: "calc(33vh - 43px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="flex flex-col items-center">
                <img src={emptyState} alt="emptyState" />
                <p className="opacity-30 text-[var(--Primary-Text-Color)] mt-2">
                  No watchlisted investors
                </p>
              </div>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box
              sx={{
                background: "white",
                borderRadius: "4px",
                height: "calc(33vh - 43px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="flex flex-col items-center">
                <img src={emptyState} alt="emptyState" />
                <p className="opacity-30 text-[var(--Primary-Text-Color)] mt-2">
                  No past deals
                </p>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DashboardGrid3;
