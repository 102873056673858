import React, { useRef, useState, useEffect } from "react";

import CustomToolTip from "../../../Common/components/custom/customToolTip";

import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { investorState } from "../investorState";

import defaultCompanyLogo from "../../../../Assets/Images/InvestorPage/Dashboard/defaultCompanyLogo.png";
import Accepted from "../../../../Assets/Images/InvestorPage/Dashboard/accepted.svg";
import Pending from "../../../../Assets/Images/InvestorPage/Dashboard/pending.svg";
import ProgressBar from "../../../Common/components/molecules/progressBar";
import LinearProgressBar from "../../../Common/components/linearProgressBar";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { getScheduleMeetingByDealId } from "../../../../endpoints/investor";
import dayjs from "dayjs";
import moment from "moment";
import { calculateDaysAgo } from "../../../Common/functions";
const OnGoingDealCard = ({ deal, stage, submittedDate, daysAgo }) => {
  const {
    roundType,
    industryVerticals,
    backing,
    endMarket,
    dealTags,
    companyLogo,
    companyName,
    fundingRaised,
    fundingRequired,
  } = deal;
  const [firstTag, setFirstTag] = useState();

  function processFirstTag() {
    try {
      setFirstTag(dealTags);
    } catch (error) {}
  }
  useEffect(() => {
    processFirstTag();
  }, [dealTags]);
  const Navigate = useNavigate();
  const [state, setState] = useRecoilState(investorState);
  const [expand, setExpand] = useState(false);
  const [cardTags, setCardTags] = useState([]);
  const containerRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState([]);
  const [remainingTags, setRemainingTags] = useState(0);
  const [percentageFundingRaised, setPercentageFundingRaised] = useState(0);
  const [meetingScheduled, setMeetingScheduled] = useState("not scheduled");
  const [meetingTime, setMeetingTime] = useState(new Date());
  const { investorId } = useRecoilValue(investorState);
  useEffect(() => {
    try {
      if (!fundingRaised) {
        setPercentageFundingRaised(0);
        return;
      }
      const percentage = (fundingRaised / fundingRequired) * 100;
      setPercentageFundingRaised(percentage.toFixed(2));
    } catch (error) {
      setPercentageFundingRaised(0);
    }
  }, [fundingRaised, fundingRequired]);
  const checkMeetingScheduled = async () => {
    try {
      if (investorId && deal?.dealId) {
        const response = await getScheduleMeetingByDealId(
          investorId,
          deal?.dealId
        );
        if (response.data) {
          if (dayjs().isAfter(dayjs(response?.data?.timings?.start))) {
            setMeetingScheduled("Passed");
          } else {
            setMeetingScheduled("Scheduled");
            setMeetingTime(response.data.timings.start);
          }
        } else {
          setMeetingScheduled("Not Scheduled");
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (deal?.dealId && investorId) checkMeetingScheduled();
  }, [deal?.dealId, investorId]);
  // useEffect(() => {
  //   if (containerRef.current) {
  //     const containerWidth = containerRef.current.offsetWidth;
  //     let totalWidth = 0;
  //     let tagsToShow = [];
  //     let remainingCount = 0;

  //     props.badges.forEach((tag, index) => {
  //       const tagElement = document.createElement("div");
  //       tagElement.style.display = "inline-block";
  //       tagElement.style.visibility = "hidden";
  //       tagElement.style.position = "absolute";
  //       tagElement.className = "Card-div-6";
  //       tagElement.innerText = tag;
  //       document.body.appendChild(tagElement);

  //       const tagWidth = tagElement.offsetWidth;
  //       document.body.removeChild(tagElement);

  //       if (totalWidth + tagWidth <= containerWidth) {
  //         totalWidth += tagWidth;
  //         tagsToShow.push(tag);
  //       } else {
  //         remainingCount = props.badges.length - tagsToShow.length;
  //         return false; // Break out of the loop
  //       }
  //     });

  //     setVisibleTags(tagsToShow);
  //     setRemainingTags(remainingCount);
  //   }
  // }, [props.badges]);
  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth * 0.9;
      let totalWidth = 0;
      let tagsToShow = [];
      let remainingCount = 0;

      cardTags.forEach((tag, index) => {
        const tagElement = document.createElement("div");
        tagElement.style.display = "inline-block";
        tagElement.style.visibility = "hidden";
        tagElement.style.position = "absolute";
        tagElement.className = "Card-div-6";
        tagElement.innerText = tag;
        document.body.appendChild(tagElement);

        const tagWidth = tagElement.offsetWidth;
        document.body.removeChild(tagElement);

        if (totalWidth + tagWidth <= containerWidth) {
          totalWidth += tagWidth;
          tagsToShow.push(tag);
        } else {
          remainingCount = cardTags.length - tagsToShow.length;
          return false; // Break out of the loop
        }
      });

      setVisibleTags(tagsToShow);
      setRemainingTags(remainingCount);
    }
  }, [cardTags]);
  useEffect(() => {
    const computeCardTags = () => {
      const tags = [];

      // Handle industryVerticals parsing
      try {
        if (industryVerticals.length > 0) {
          if (Array.isArray(industryVerticals)) tags.push(...industryVerticals);
        }
      } catch (error) {
        console.error("Error parsing Industry Verticals:", error);
      }
      // Handle endMarket parsing
      try {
        if (endMarket.length > 0) {
          if (Array.isArray(endMarket)) tags.push(...endMarket);
        }
      } catch (error) {
        console.error("Error parsing End Market Focus:", error);
        tags.push("_"); // Fallback value
      }
      try {
        if (backing.length > 0) {
          const backings = [backing];
          if (Array.isArray(backings)) tags.push(...backings);
        }
      } catch (error) {
        console.error("Error parsing End Market Focus:", error);
      }
      return tags;
    };

    // Set computed card tags
    setCardTags(computeCardTags());
  }, [roundType, industryVerticals, endMarket]);
  const handleCardClick = () => {
    const newState = { ...state, activeBtn: 1 };
    setState(newState);
    Navigate(`/investor/company/overview/${deal?.dealId}`);
  };
  const stageDisplayMap = {
    interested: "Send Request",
    requestSent: "Approval Pending",
    requestAccepted: "Request Accepted",
    ioiSubmitted: "Bid Approval Pending",
    ioiAccepted: "Bid Accepted",
    loiSubmitted: "Bid Approval Pending",
    loiAccepted: "Final Bid Accepted",
    dealClosed: "Deal Closed",
  };
  return (
    <div
      className="rounded-100 p-100 border-neutral-5 gap-150 flex flex-col bg-white border cursor-pointer"
      onClick={handleCardClick}
    >
      <div className="p-100 gap-150 flex flex-col">
        <div className="gap-100 flex">
          <img
            src={companyLogo ? companyLogo : defaultCompanyLogo}
            className="w-9 h-9 object-contain"
          />
          <div className="flex flex-col">
            <p className="text-neutral-12 text-desktop-p-m font-bold">
              {companyName}
            </p>
            <p className="text-neutral-8 text-desktop-p-s">
              {deal?.legalEntityName}
            </p>
          </div>
        </div>
        <div className="gap-150 flex" ref={containerRef}>
          {visibleTags.map((tag, index) => (
            <div
              key={index}
              className="text-desktop-p-s px-150 py-075 border-neutral-5 border rounded"
            >
              {tag}
            </div>
          ))}
        </div>
      </div>
      <div className="pl-100 pb-100 flex justify-between">
        {stage === "interested" && meetingScheduled === "Not Scheduled" && (
          <p className="text-neutral-8 text-desktop-p-s">
            Last Interacted: {submittedDate}
          </p>
        )}
        {stage === "interested" && meetingScheduled === "Scheduled" && (
          <p className="text-neutral-8 text-desktop-p-s">
            Scheduled date: {moment(meetingTime).format("D MMM YYYY")}
          </p>
        )}
        <p className="text-neutral-12 text-desktop-p-s bg-blue-50 px-100 rounded-300">
          {stage === "interested" && meetingScheduled === "Scheduled"
            ? calculateDaysAgo(meetingTime)
            : daysAgo}
        </p>
      </div>
      <div className="px-100 pb-100">
        <div className="flex flex-col w-full">
          <div className="text-neutral-12 text-desktop-p-xs font-bold">
            {deal?.dealType === "secondary" ? "Secondary" : deal?.roundType}
          </div>
          <div className="flex flex-col py-0.5 mt-3 w-full rounded-none">
            <LinearProgressBar value={percentageFundingRaised} />
            <div className="flex justify-between mt-2.5 text-desktop-p-xs font-medium text-neutral-12">
              <p>
                ₹{deal?.fundingRaised || 0}Cr ({percentageFundingRaised}%
                raised)
              </p>
              <p>₹{deal?.fundingRequired}Cr</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div></div>
        {stage === "interested" && meetingScheduled === "Scheduled" ? (
          <CustomBlackBtn
            text={<p className="font-bold">Meeting Scheduled</p>}
            disabled
            variant="sm"
            border="sm"
            additionalClasses="!text-desktop-p-s !h-[31px] px-300"
          />
        ) : stage === "interested" && meetingScheduled === "Not Scheduled" ? (
          <CustomBlackBtn
            text={<p className="font-bold">Get Connected</p>}
            mode="blue"
            variant="sm"
            border="sm"
            additionalClasses="!text-desktop-p-s !h-[31px] px-300"
          />
        ) : stage === "interested" && meetingScheduled === "Passed" ? (
          <CustomBlackBtn
            text={<p className="font-bold">Request Access</p>}
            mode="blue"
            variant="sm"
            border="sm"
            additionalClasses="!text-desktop-p-s !h-[31px] px-300"
          />
        ) : stage === "requestSent" ? (
          <CustomBlackBtn
            text={<p className="font-bold">Request Sent</p>}
            variant="sm"
            border="sm"
            disabled
            additionalClasses="!text-desktop-p-s !h-[31px] px-300"
          />
        ) : (
          <></>
        )}
      </div>
      {/* <div className="p-100 flex flex-col">
          <div className="Card-div-3">
            <div className="card-div-img-section">
              <img
                src={companyLogo ? companyLogo : defaultCompanyLogo}
                alt="company Logo"
              />
              <p>{companyName}</p>
            </div>
          </div>

          <div className="Card-div-5" ref={containerRef}>
            {visibleTags.map((tag, index) => (
              <div
                key={index}
                className="Card-div-6 blue-text"
                style={{
                  color: deal?.dealType === "secondary" && "var(--brand-color)",
                }}
              >
                {tag}
              </div>
            ))}
            {remainingTags > 0 && (
              <div
                className="Card-div-7 blue-text"
                style={{
                  color: deal?.dealType === "secondary" && "var(--brand-color)",
                }}
              >
                +{remainingTags}
              </div>
            )}
          </div>
        </div>
        <div className="card-section-3">
          <ProgressBar
            totalFundingRaised={fundingRaised || 0}
            fundingRequired={fundingRequired}
            roundType={roundType}
            DealTag={firstTag}
            dealId={deal.dealId}
            card={"ongoingDealCard"}
            requestedDate={requestedDate}
            submittedDate={submittedDate}
            acceptedDate={acceptedDate}
            IOIBidSubmittedAmount={IOIBidSubmittedAmount}
            IOIBidAcceptedAmount={IOIBidAcceptedAmount}
            LOIBidSubmittedAmount={LOIBidSubmittedAmount}
            LOIBidAcceptedAmount={LOIBidAcceptedAmount}
            closingDate={closingDate}
            stage={stage}
            dealType={deal?.dealType}
          />
        </div> */}
    </div>
  );
};

export default OnGoingDealCard;
