import React, { useState } from "react";
import { Modal } from "@mui/material";
import { Box } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { postStageData } from "../../../endpoints/deal";
import Toast from "../Toast";
import { useLoader } from "../LoaderProvider";

import { StyledFormControl, StyledFormLabel } from "../functions";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import "../../../CSS/Signup/modal.css";
import CustomBlackBtn from "../components/custom/customBlackBtn";
import CustomInput from "../components/custom/customInput";

import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
const PassDealModal = ({
  openReasonModal,
  setOpenReasonModal,
  stage,
  isCompany,
  containerId,
  type,
  dealId,
  investorID,
  setDealPassed,
  dealType,
  toastContainer,
}) => {
  const [watchList, setWatchList] = useState(false);
  const [openPassDealModal, setOpenPassDealModal] = useState(false);
  const containerName = containerId ? containerId : "companyDetails";
  const request = type === "withdraw" ? "withdrawing" : "passing";
  const token = localStorage.getItem("token");
  const Navigate = useNavigate();
  const loader = useLoader();
  const handleNotInterested = async (values) => {
    if (isCompany) {
      if (values.reason !== "") {
        const updatedFormData = {
          Pass_Deal_Reason: values.reason,
          dealId: dealId,
          investorID: investorID,
          IsRejected: true,
          Stage: stage,
        };
        try {
          loader.start("Declining deal...");
          const response = await postStageData(updatedFormData, token);
          if (response.data !== null) {
            setDealPassed(true);
          } else if (response.error.status === 403) {
            Toast(
              "You do not have access to interact with this deal",
              "error",
              toastContainer
            );
            setOpenPassDealModal(false);
            setOpenReasonModal(false);
            loader.stop();
            return;
          }

          loader.stop();
        } catch (error) {
          loader.stop();
        }

        Navigate("/company/ongoingDeals/dead");
        setOpenReasonModal(false);
      } else {
        Toast("Please enter the reason", "error", toastContainer);
      }
    } else {
      if (values.reason !== "") {
        const updatedFormData = {
          dealId: dealId,
          investorID: investorID,
          Not_Interested_Reason: values.reason,
          IsInterested: false,
          Stage: watchList ? "watchlist" : stage,
          dealType: dealType,
        };
        loader.start("Declining deal...");
        try {
          const response = await postStageData(updatedFormData, token);

          if (response.data !== null) {
            if (stage === "initialStage") {
              Navigate("/investor/newDeals/archive");
            } else {
              Navigate(
                watchList
                  ? "/investor/ongoingDeals/watchlist"
                  : "/investor/ongoingDeals/dead"
              );
            }
          } else if (response.error.status === 403) {
            Toast(
              "You do not have access to interact with this deal",
              "error",
              toastContainer
            );
            setOpenPassDealModal(false);
            setOpenReasonModal(false);
            loader.stop();
            return;
          }
          setWatchList(false);
          loader.stop();
        } catch (error) {
          setWatchList(false);
          loader.stop();
        }

        setOpenReasonModal(false);
      } else {
        Toast("Please enter the reason", "error", containerName);
      }
    }
  };

  // const addTowatchlist = async () => {
  //   try {
  //     const updatedFormData = {
  //       dealId: dealId,
  //       investorID: investorID,
  //       IsInterested: true,
  //       IsRejected: false,
  //       Stage: "watchlist",
  //     };
  //     loader.start("Adding deal to watchlist...");
  //     try {
  //       const response = await postStageData(updatedFormData, token);
  //       if (response.data !== null) {
  //         Navigate(
  //           stages.includes(stage)
  //             ? "/investor/newDeals"
  //             : "/investor/ongoingdeals"
  //         );
  //       } else {
  //         Toast(
  //           "Error in adding to watchlist. Please try again later",
  //           "error",
  //           containerName
  //         );
  //       }
  //       loader.stop();
  //     } catch (error) {
  //       loader.stop();
  //     }

  //     setOpenReasonModal(false);
  //     Navigate("/investor/ongoingdeals");
  //   } catch (error) {}
  // };
  const stages = ["initialStage", "requestSent"];
  return (
    <>
      <Modal
        open={
          isCompany || stages.includes(stage) || dealType === "secondary"
            ? openReasonModal
            : openPassDealModal
        }
        onClose={() => setOpenReasonModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box reason-box" style={{ width: "550px" }}>
          <div className="modal-content">
            <div className="modal-right-section">
              <div className="reason-box-first-div">
                <p className="modal-heading">
                  {watchList
                    ? "Enter reason for adding deal to watchlist"
                    : `Enter reason for ${request} the deal:`}
                </p>
                <img
                  className="reason-box-closeicon"
                  src={closeIcon}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    isCompany ||
                    stages.includes(stage) ||
                    dealType === "secondary"
                      ? setOpenReasonModal(false)
                      : setOpenPassDealModal(false);
                    setWatchList(false);
                  }}
                  alt="close"
                />
              </div>
              <div className="reasons-box">
                <Formik
                  initialValues={{ reason: "" }}
                  validationSchema={Yup.object({
                    reason: Yup.string().required("Reason is required"),
                  })}
                  onSubmit={handleNotInterested}
                >
                  {({ errors, touched, handleChange, handleBlur, values }) => (
                    <Form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "36px",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div className="input-fields" style={{ width: "100%" }}>
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>
                            {watchList
                              ? "Reason for watchlist"
                              : "Reason for passing"}
                          </StyledFormLabel>
                          <CustomInput
                            placeholder="Please enter your reason"
                            name="reason"
                            value={values.reason}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.reason && errors.reason)}
                            helperText={touched.reason && errors.reason}
                          />
                        </StyledFormControl>
                      </div>

                      <div className="submit-btn-section">
                        <CustomBlackBtn type="submit" text="Pass" />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={
          isCompany || stages.includes(stage) || dealType === "secondary"
            ? openPassDealModal
            : openReasonModal
        }
        onClose={() => setOpenReasonModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box reason-box p-4"
          sx={{ width: "400px", padding: "16px" }}
        >
          <div className="modal-content">
            <Box
              className="modal-right-section"
              sx={{ display: "flex", flexDirection: "column", gap: "40px" }}
            >
              <p className="modal-heading mt-4">Add to Watchlist instead?</p>
              <img
                className="reason-box-closeicon"
                src={closeIcon}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "16px",
                  top: "16px",
                }}
                onClick={() => setOpenReasonModal(false)}
                alt="close"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <div
                  onClick={() => {
                    setOpenPassDealModal(true);
                    setOpenReasonModal(false);
                  }}
                >
                  <CustomBlackBtn text="No" filled={false} />
                </div>
                <div
                  onClick={() => {
                    setWatchList(true);
                    setOpenPassDealModal(true);
                    setOpenReasonModal(false);
                  }}
                >
                  <CustomBlackBtn text="Yes" />
                </div>
              </div>
            </Box>
            <div className="modal-left-section"></div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PassDealModal;
