import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { calculateDtick } from "./PlotUtilityFunctions";
import TimePeriodButton from "./TimePeriodButton";

const FinancialPlot = ({ data, ltm = true }) => {
  const [type, setType] = useState("m");
  useEffect(() => {}, [type, data]);
  const isLTMVisible = () => {
    if (data?.data?.["ltm"]?.Revenue?.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const plotData = [
    {
      x: data.data[type]?.xLabels,
      y: data.data[type]?.Revenue,
      type: "bar",
      name: "Revenue",
      marker: { color: "#0F48D9" },
      hovertemplate: `<b>Revenue</b>: %{y:,.0f}` + "<extra></extra>",
    },
    {
      x: data.data[type]?.xLabels,
      y: data.data[type]?.GrossProfit,
      type: "bar",
      name: "Gross Profit",
      marker: { color: "#4172F1" },
      hovertemplate: `<b>Gross Profit</b>: %{y:,.0f}` + "<extra></extra>",
    },
    {
      x: data.data[type]?.xLabels,
      y: data.data[type]?.EBITDA,
      type: "bar",
      name: "EBITDA",
      marker: { color: "#92AEF7" },
      hovertemplate: `<b>EBITDA</b>: %{y:,.0f}` + "<extra></extra>",
    },
    {
      x: data.data[type]?.xLabels,
      y: data.data[type]?.GrossProfitPercentage,
      type: "scatter",
      yaxis: "y2",
      name: "Gross Profit %",
      line: { shape: "spline" },
      mode: "lines",
      marker: { color: "#FFA477" },
      hovertemplate:
        `<b>Gross Profit Margin</b>: %{y:,.1f}%` + "<extra></extra>",
    },
    {
      x: data.data[type]?.xLabels,
      y: data.data[type]?.EBITDAPercentage,
      type: "scatter",
      yaxis: "y2",
      name: "EBITDA %",
      line: { shape: "spline" },
      mode: "lines",
      marker: { color: "#35A242" },
      hovertemplate: `<b>EBITDA %</b>: %{y:,.1f}%` + "<extra></extra>",
    },
  ];

  return (
    <div>
      <TimePeriodButton
        type={type}
        setType={setType}
        title={data.name}
        ltm={isLTMVisible()}
      />
      <div
        style={{ width: "100%", height: "100%", position: "relative" }}
        className="mt-200"
      >
        <Plot
          data={plotData}
          layout={{
            barmode: "group",
            margin: {
              l: 40,
              r: 60,
              t: 20,
              b: 150,
            },
            barcornerradius: 2,
            xaxis: {
              zeroline: true,
              zerolinewidth: 2,
              range: ["m", "ltm"].includes(type) &&
                data.data[type]?.xLabels.length > 12 && [
                  data.data[type]?.xLabels.length - 12,
                  data.data[type]?.xLabels.length - 1,
                ],
              dtick: calculateDtick(type, data.data[type]?.xLabels.length),
              tickangle: 0,
              tickfont: {
                family: "Karla, sans-serif",
              },
            },
            yaxis: {
              autorangeoptions: {
                minallowed: 0,
              },
            },
            yaxis2: {
              overlaying: "y",
              side: "right",

              range: [0, 100],
              ticksuffix: "%",
              autorange: false,
              showgrid: false,
            },
            paper_bgcolor: "transparent",
            plot_bgcolor: "transparent",
            modebar: {
              activecolor: "#00A6FB",
              bgcolor: "transparent",
              color: "grey",
              orientation: "v",
            },
            hovermode: "x unified",
            legend: {
              orientation: "h",
              x: 0.5,
              xanchor: "center",
              y: -0.1,
              yanchor: "top",
              itemwidth: 0.2,
            },
            hoverlabel: {
              bordercolor: "transparent",
              bgcolor: "#FFF",
            },
          }}
          config={{
            displaylogo: false,
            responsive: true,
            modeBarButtonsToRemove: [
              "autoScale2d",
              "lasso2d",
              "select2d",
              "zoomIn2d",
              "zoomOut2d",
            ],
          }}
          useResizeHandler={true}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </div>
  );
};

export default FinancialPlot;
