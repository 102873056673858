import React, { useEffect, useState } from "react";
import CustomInput from "../../../Common/components/custom/customInput";
import CustomSelect from "../../../Common/components/custom/customSelect";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
// import PreviousRoundsEditable from "../../../Common/components/previousRoundsEditable";
import CustomEditableTable from "../../../Common/components/custom/customEditable";
import TextEditor from "../../../Common/components/custom/textEditor";

import { isProfileComplete } from "./profileCompletion";

import {
  industryVerticalsOptions,
  endMarketFocusOptions,
  StyledFormControl,
  StyledFormLabel,
  StyledFormLabelOptional,
  getFilteredSubIndustryOptions,
  updateSubIndustryVerticals,
} from "../../../Common/functions";
import { companyState } from "../companyState";
import { Button } from "@mui/material";

import {
  fetchStartupDetails,
  getProfileCompletionPercentage,
  PostDetails,
} from "../../../../endpoints/startup";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useLoader } from "../../../Common/LoaderProvider";
import Toast from "../../../Common/Toast";

import uploadImg from "../../../../Assets/Images/signup/upload-img.svg";
import { useRecoilState } from "recoil";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { randomId } from "@mui/x-data-grid-generator";
import { Box } from "@mui/joy";

const EditCompanyDetails = () => {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [state, setState] = useRecoilState(companyState);
  const [startupId, setStartupId] = useState("");
  const [companyLogoPreview, setCompanyLogoPreview] = useState(uploadImg);
  const [foundingMember, setFoundingMember] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [previousRounds, setPreviousRounds] = useState([]);
  const [initialValues, setInitialValues] = useState({
    companyName: "",
    legalEntityName: "",
    headquarters: "",
    foundedYear: "",
    numEmployees: "",
    website: "",
    industryVerticals: [],
    subIndustryVerticals: [],
    cin: "",
    taxNumber: "",
    endMarket: [],
    erpPlatform: "",
    techstack: "",
    description: "",
    keyInvestors: "",
    companyLogo: null,
    isApproved: false,
    previousRounds: [
      {
        id: randomId(),
        date: "",
        round: "",
        raised: "",
        stake: "",
        valuation: "",
      },
    ],
  });

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company Name is required"),
    legalEntityName: Yup.string().required("Legal Entity Name is required"),
    headquarters: Yup.string().required("Headquarters is required"),
    foundedYear: Yup.number()
      .required("Founded Year is required")
      .min(1800, "Year must be after 1800")
      .max(new Date().getFullYear(), "Year cannot be in the future"),
    numEmployees: Yup.number()
      .required("Number of Employees is required")
      .min(1, "Must have at least 1 employee"),
    website: Yup.string()
      .matches(
        /^(https?:\/\/)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})((\/[\w.-]*)*\/?)?$/,
        "Please enter a valid URL"
      )
      .required("Company Website is required"),
    industryVerticals: Yup.array().required("Industry Verticals is required"),
    subIndustryVerticals: Yup.array().required(
      "Sub industry Verticals is required"
    ),
    cin: Yup.string().required("CIN/LLPIN is required"),
    taxNumber: Yup.string().required("Tax Identification Number is required"),
    endMarket: Yup.array().required("End Market Focus is required"),
    erpPlatform: Yup.string().required("ERP Platform Used is required"),
    // techstack: Yup.string().required("Tech stack is required"),
    description: Yup.string().required("Company description is required"),
    // keyInvestors: Yup.string().required("Key investors are required"),
    companyLogo: Yup.mixed().required("Company Logo is required"),
  });

  const fetchDetails = async () => {
    if (!token) return;
    try {
      loader.start();
      const response = await fetchStartupDetails(token);
      loader.stop();
      if (response?.data !== null) {
        const data = response.data.data;

        setInitialValues({
          companyName: data.companyName || "",
          legalEntityName: data.legalEntityName || "",
          headquarters: data.headquarters || "",
          foundedYear: data.foundedYear || "",
          numEmployees: data.numEmployees || "",
          website: data.website || "",
          industryVerticals: data.industryVerticals || [],
          subIndustryVerticals: data.subIndustryVerticals || [],
          endMarket: data.endMarket || [],
          cin: data.cin || "",
          taxNumber: data.taxNumber || "",
          erpPlatform: data.erpPlatform || "",
          techstack: data.techstack || "",
          description: data.description || "",
          keyInvestors: data.keyInvestors || "",
          companyLogo: data.companyLogo || null, // Ensure this matches the type required
          isApproved: data.isApproved || false,
          previousRounds: data.previousRounds || [
            {
              id: randomId(),
              date: "",
              round: "",
              raised: "",
              stake: "",
              valuation: "",
            },
          ],
        });
        setRows(
          response?.data.data.previousRounds?.length > 0
            ? response?.data.data.previousRounds?.map((row) => ({
                id: randomId(),
                ...row,
              }))
            : [
                {
                  id: randomId(),
                  date: "",
                  round: "",
                  raised: "",
                  stake: "",
                  valuation: "",
                },
              ]
        );
        setPreviousRounds(data?.previousRounds);
        setFoundingMember(data.foundingMember);
        isProfileComplete(data, data?.foundingMember);

        // If companyLogo is present, set it as the preview
        if (data.companyLogo) {
          setCompanyLogoPreview(data.companyLogo);
        }
        setStartupId(data.startupId);
      }
    } catch (error) {
      loader.stop();
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [token]);
  const postFormData = async (data) => {
    //Checking if founding team details are filled or not.
    const foundingMembersJSON = foundingMember;
    data.profileCompleted = isProfileComplete(data, foundingMembersJSON);
    data.previousRounds = previousRounds;
    const formDataInput = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key]) {
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            formDataInput.append(key, JSON.stringify(data[key]));
          } else {
            formDataInput.append(key, data[key]);
          }
          //This is for if the user want to remove those details
        } else {
          formDataInput.append(key, data[key]);
        }
      }
    }
    if (!data.startupId) {
      formDataInput.append("startupId", startupId);
    }
    try {
      loader.start();
      const response = await PostDetails(formDataInput, token);
      loader.stop();
      if (response) {
        //Parent toast container
        Toast("Details saved successfully", "success", "editCompanyProfile");
      }
      profileCompletionPercentage();
    } catch (error) {
      Toast(
        "Error is saving details. Try again later",
        "error",
        "editCompanyProfile"
      );
      loader.stop();
    }
  };
  const profileCompletionPercentage = async () => {
    try {
      const response = await getProfileCompletionPercentage(token);
      const percent = response.data.percentage;

      const newState = {
        ...state,
        percentage: percent,
      };
      setState(newState);
    } catch (error) {}
  };
  // const columns = [
  //   {
  //     field: "date",
  //     headerName: "Date",
  //     width: 160,
  //     headerClassName: "border-t border-r",
  //     editable: true,
  //     sortable: false,
  //   },
  //   {
  //     field: "round",
  //     headerClassName: "border-t border-r",
  //     headerName: "Round",
  //     width: 160,
  //     editable: true,
  //     sortable: false,
  //   },
  //   {
  //     field: "raised",
  //     headerName: "Raised(₹Cr)",
  //     headerClassName: "border-t border-r",
  //     width: 160,
  //     editable: true,
  //     sortable: false,
  //     type: "number",
  //   },
  //   {
  //     field: "stake",
  //     headerName: "Stake (%)",
  //     headerClassName: "border-t border-r",
  //     width: 160,
  //     editable: true,
  //     sortable: false,
  //     type: "number",
  //   },
  //   {
  //     field: "valuation",
  //     headerClassName: "border-t border-r",
  //     headerName: "Valuation (₹Cr)",
  //     width: 160,
  //     editable: true,
  //     sortable: false,
  //     type: "number",
  //   },
  //   {
  //     field: "actions",
  //     type: "actions",
  //     headerClassName: "border-t",
  //     headerName: "Actions",
  //     width: 100,
  //     cellClassName: "actions",
  //     getActions: ({ id }) => {
  //       const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

  //       if (isInEditMode) {
  //         return [
  //           <GridActionsCellItem
  //             icon={<SaveIcon />}
  //             label="Save"
  //             sx={{
  //               color: "primary.main",
  //             }}
  //             onClick={handleSaveClick(id)}
  //           />,
  //           <GridActionsCellItem
  //             icon={<CancelIcon />}
  //             label="Cancel"
  //             className="textPrimary"
  //             onClick={handleCancelClick(id)}
  //             color="inherit"
  //           />,
  //         ];
  //       }

  //       return [
  //         <GridActionsCellItem
  //           icon={<EditIcon />}
  //           label="Edit"
  //           className="textPrimary"
  //           onClick={handleEditClick(id)}
  //           color="inherit"
  //         />,
  //         <GridActionsCellItem
  //           icon={<DeleteIcon />}
  //           label="Delete"
  //           onClick={handleDeleteClick(id)}
  //           color="inherit"
  //         />,
  //       ];
  //     },
  //   },
  // ];
  const columns = [
    {
      Header: "Date",
      accessor: "date",
      placeholder: "Enter Date",
      type: "date",
    },
    {
      Header: "Round",
      accessor: "round",
      placeholder: "Select Round",
      type: "text",
    },
    {
      Header: "Raised",
      accessor: "raised",
      placeholder: "Enter Raised",
      type: "text",
    },
    {
      Header: "% Stake",
      accessor: "stake",
      placeholder: "Enter Stake",
      type: "text",
    },
    {
      Header: "% Valuation",
      accessor: "valuation",
      placeholder: "Enter Valuation",
      type: "text",
    },
  ];
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [
        ...oldRows,
        {
          id,
          date: "",
          round: "",
          raised: "",
          stake: "",
          valuation: "",
          isNew: true,
        },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
      }));
    };

    return (
      <GridToolbarContainer>
        <div className="flex justify-between w-full">
          <p className="mt-2 ml-2">Enter your previous funding rounds</p>
          <Button
            sx={{
              color: "#1A1A1A",
              textTransform: "capitalize",
            }}
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            Add record
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }
  return (
    <div className="editCompanyDetails">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={postFormData}
      >
        {({
          errors,
          touched,
          handleChange: formikHandleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => {
          const handleChange = (e) => {
            formikHandleChange(e);
            //* This function will only allow sub industryVerticals which are under industryVerticals field
            //* It removes the sub industry Verticals which are not in selected industry verticals.
            //* It will be triggered when there is change in industry verticals
            const { name, value } = e.target;
            if (name === "industryVerticals") {
              updateSubIndustryVerticals(value, setFieldValue, values);
            }
          };
          return (
            <Form className="flex flex-col">
              <div className="editCompanyDetails-form fundrev-card">
                <div className="editCompanyDetails-form-upload-btn-wrapper">
                  <button
                    type="button"
                    className="upload-img-btn"
                    onClick={() =>
                      document.getElementById("companyLogo").click()
                    }
                  >
                    <img
                      src={companyLogoPreview}
                      className="InvestorLogo"
                      alt="UploadImage"
                      style={{ cursor: "pointer" }}
                    />
                  </button>

                  {/* Conditionally show the edit button if companyLogoPreview is not the default image */}
                  {/* {companyLogoPreview !== uploadImg && (
                <label htmlFor="companyLogo" className="edit-img-btn">
                  <img src={editImg} className="editImg" alt="Edit" />
                </label>
              )} */}

                  <input
                    id="companyLogo"
                    type="file"
                    name="companyLogo"
                    accept="image/png, image/jpeg" // Accept only JPEG and PNG
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        setFieldValue("companyLogo", file); // Set file in Formik state
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          setCompanyLogoPreview(reader.result); // Set preview image URL
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                    style={{ display: "none" }}
                  />
                  {touched.companyLogo && errors.companyLogo && (
                    <div className="error text-[#d32f2f] text-xs ">
                      {errors.companyLogo}
                    </div>
                  )}
                </div>
                <div className="company-details-input-fields">
                  {/* Company Name */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Company Name</StyledFormLabel>
                    <CustomInput
                      placeholder="Enter Company Name"
                      name="companyName"
                      value={values.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.companyName && errors.companyName)}
                      helperText={touched.companyName && errors.companyName}
                    />
                  </StyledFormControl>

                  {/* Legal Entity Name */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Legal Entity Name</StyledFormLabel>
                    <CustomInput
                      placeholder="Enter Legal Entity Name"
                      name="legalEntityName"
                      value={values.legalEntityName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.legalEntityName && errors.legalEntityName
                      )}
                      helperText={
                        touched.legalEntityName && errors.legalEntityName
                      }
                    />
                  </StyledFormControl>

                  {/* Headquarters */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Headquarters</StyledFormLabel>
                    <CustomInput
                      placeholder="Enter Headquarters"
                      name="headquarters"
                      value={values.headquarters}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.headquarters && errors.headquarters
                      )}
                      helperText={touched.headquarters && errors.headquarters}
                    />
                  </StyledFormControl>

                  {/* Founded Year */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Founded Year</StyledFormLabel>
                    <CustomInput
                      placeholder="Enter Founded Year"
                      name="foundedYear"
                      type="number"
                      value={values.foundedYear}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.foundedYear && errors.foundedYear)}
                      helperText={touched.foundedYear && errors.foundedYear}
                    />
                  </StyledFormControl>

                  {/* No. of Employees */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>No. of Employees</StyledFormLabel>
                    <CustomInput
                      placeholder="Enter Number of Employees"
                      name="numEmployees"
                      type="number"
                      value={values.numEmployees}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.numEmployees && errors.numEmployees
                      )}
                      helperText={touched.numEmployees && errors.numEmployees}
                    />
                  </StyledFormControl>

                  {/* Company Website */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Company Website</StyledFormLabel>
                    <CustomInput
                      placeholder="Enter Company Website"
                      name="website"
                      value={values.website}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.website && errors.website)}
                      helperText={touched.website && errors.website}
                    />
                  </StyledFormControl>

                  {/* Industry Verticals */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Industry Verticals</StyledFormLabel>
                    <CustomSelect
                      label="Industry Verticals"
                      name="industryVerticals"
                      multiple={true}
                      value={values.industryVerticals}
                      onChange={handleChange}
                      options={industryVerticalsOptions}
                      placeholder="Select Industry Verticals"
                      error={Boolean(
                        touched.industryVerticals && errors.industryVerticals
                      )}
                      helperText={
                        touched.industryVerticals && errors.industryVerticals
                      }
                    />
                  </StyledFormControl>
                  <StyledFormControl fullWidth>
                    <StyledFormLabelOptional>
                      Sub Industry Verticals
                    </StyledFormLabelOptional>
                    <CustomSelect
                      label="Sub industry Verticals"
                      name="subIndustryVerticals"
                      multiple={true}
                      value={values.subIndustryVerticals}
                      onChange={handleChange}
                      options={getFilteredSubIndustryOptions(values)}
                      placeholder="Select sub Industry Verticals"
                      error={Boolean(
                        touched.subIndustryVerticals &&
                          errors.subIndustryVerticals
                      )}
                      helperText={
                        touched.subIndustryVerticals &&
                        errors.subIndustryVerticals
                      }
                    />
                  </StyledFormControl>

                  {/* CIN/LLPIN */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>CIN/LLPIN</StyledFormLabel>
                    <CustomInput
                      placeholder="Enter CIN/LLPIN"
                      name="cin"
                      value={values.cin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.cin && errors.cin)}
                      helperText={touched.cin && errors.cin}
                    />
                  </StyledFormControl>

                  {/* Tax Identification Number */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Tax Identification Number</StyledFormLabel>
                    <CustomInput
                      placeholder="Enter Tax Identification Number"
                      name="taxNumber"
                      value={values.taxNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.taxNumber && errors.taxNumber)}
                      helperText={touched.taxNumber && errors.taxNumber}
                    />
                  </StyledFormControl>

                  {/* End Market Focus */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>End Market Focus</StyledFormLabel>
                    <CustomSelect
                      label="End Market Focus"
                      name="endMarket"
                      multiple={true}
                      value={values.endMarket}
                      onChange={handleChange}
                      options={endMarketFocusOptions}
                      placeholder="Select End Market Focus"
                      error={Boolean(touched.endMarket && errors.endMarket)}
                      helperText={touched.endMarket && errors.endMarket}
                    />
                  </StyledFormControl>

                  {/* ERP Platform Used */}
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>ERP Platform Used</StyledFormLabel>
                    <CustomInput
                      placeholder="Enter ERP Platform Used"
                      name="erpPlatform"
                      value={values.erpPlatform}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.erpPlatform && errors.erpPlatform)}
                      helperText={touched.erpPlatform && errors.erpPlatform}
                    />
                  </StyledFormControl>
                  <StyledFormControl fullWidth>
                    <StyledFormLabelOptional>
                      Tech Stack
                    </StyledFormLabelOptional>
                    <CustomInput
                      placeholder="Enter tech stack"
                      name="techstack"
                      value={values.techstack}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.techstack && errors.techstack)}
                      helperText={touched.techstack && errors.techstack}
                    />
                  </StyledFormControl>
                  <StyledFormControl fullWidth></StyledFormControl>
                  <StyledFormControl fullWidth></StyledFormControl>
                  <StyledFormControl fullWidth>
                    <StyledFormLabelOptional>
                      Key Investors
                    </StyledFormLabelOptional>
                    <CustomInput
                      placeholder="Enter key Investors"
                      name="keyInvestors"
                      value={values.keyInvestors}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.keyInvestors && errors.keyInvestors
                      )}
                      helperText={touched.keyInvestors && errors.keyInvestors}
                      multiline
                      minRows={4}
                    />
                  </StyledFormControl>
                  <StyledFormControl
                    fullWidth
                    sx={{
                      gridColumn: "2/-1",
                    }}
                  >
                    <StyledFormLabel>Company Description</StyledFormLabel>
                    <TextEditor
                      placeholder="Enter Company Description"
                      value={values.description}
                      setValue={(content) =>
                        setFieldValue("description", content)
                      }
                      rows={1}
                      style={{
                        marginBottom: "40px",
                      }}
                      paddingNone={true}
                    />
                  </StyledFormControl>
                </div>
              </div>
              <div className="editCompanyDetails fundrev-card">
                <p className="fundrev-card-heading mb-[16px]">
                  Previous Funding Rounds
                </p>
                <div
                  className="editCompanyDetails-form"
                  style={{
                    gridTemplateColumns: "none",
                  }}
                >
                  <div>
                    {/* <Box
                      sx={{
                        height: `min-content`,
                        width: `max-content`, // Fixed width
                        "& .actions": {
                          color: "text.secondary",
                        },
                        "& .textPrimary": {
                          color: "text.primary",
                        },
                      }}
                    > */}
                    {/* <DataGrid
                      rows={rows}
                      columns={columns}
                      editMode="row"
                      rowModesModel={rowModesModel}
                      disableColumnMenu={true}
                      disableColumnResize={true}
                      onRowModesModelChange={handleRowModesModelChange}
                      onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}
                      slots={{
                        toolbar: EditToolbar,
                      }}
                      slotProps={{
                        toolbar: { setRows, setRowModesModel },
                      }}
                      hideFooter
                    /> 
                       */}
                    {/* </Box> */}

                    <CustomEditableTable
                      columns={columns}
                      data={previousRounds}
                      setData={setPreviousRounds}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="editCompanyDetails-submitBtn-section">
              
            </div> */}
              <div className="showInterestButtons-div">
                <div className="btns-insideContent">
                  <CustomBlackBtn type="submit" text="Save" />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditCompanyDetails;
