import React, { useRef, useEffect } from "react";
import Slide from "@mui/material/Slide";
import CheckboxGroup from "./molecules/checkBoxGroup";

const FilterBox = ({
  filterBoxOpen,
  setFilterBoxOpen,
  clearAllFilters,
  filters,
  className,
  clearAll = true,
}) => {
  const filterBoxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const filterBox = document.getElementById("filterBox");
      const toggleButton = document.getElementById("toggleButton");

      if (
        filterBox &&
        !filterBox.contains(event.target) &&
        toggleButton &&
        !toggleButton.contains(event.target) // Exclude the toggle button
      ) {
        setFilterBoxOpen(false);
      }
    };

    if (filterBoxOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterBoxOpen, setFilterBoxOpen]);

  return (
    // <Slide direction="left" in={filterBoxOpen} mountOnEnter unmountOnExit>
    <div
      ref={filterBoxRef}
      style={{
        display: filterBoxOpen ? "flex" : "none",
      }}
      id="filterBox"
      className={`w-[450px] max-w-[90%] shadow-md border-[1px] border-neutral-5 rounded mt-4 flex flex-col absolute bg-white z-30 ${className}`}
    >
      <div className="border-neutral-5 flex justify-between p-4 border-b-[0.5px]">
        <p className="text-neutral-12 text-desktop-p-lb font-medium">Filters</p>
        {/* {clearAll && (
          <button
            className="text-brand-color text-desktop-p-mb font-medium text-blue-500 capitalize"
            onClick={clearAllFilters}
          >
            Clear All
          </button>
        )} */}
      </div>
      {filters?.map(
        (
          { title, options, selectedOptions, setSelectedOptions, isSortFilter },
          index
        ) => (
          <div key={index}>
            <CheckboxGroup
              title={title}
              options={options}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              isSortFilter={isSortFilter}
            />
          </div>
        )
      )}
    </div>
    // </Slide>
  );
};

export default FilterBox;
