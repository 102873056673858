import { stages } from "../../../Common/functions";

export const navItems = (navigate, dealId, stage) => {
  // Get the current pathname
  const currentPath = window.location.pathname;

  return [
    {
      label: "Overview",
      isActive: currentPath === `/investor/company/overview/${dealId}`,
      onClick: () => navigate(`/investor/company/overview/${dealId}`),
    },
    {
      label: "Analytics",
      isActive: currentPath === `/investor/company/pre-analytics/${dealId}`,
      onClick: () => {
        stages.indexOf(stage) > 1
          ? navigate(`/investor/company/pre-analytics/${dealId}`)
          : navigate(`/investor/company/pre-analytics/${dealId}`);
      },
    },
    {
      label: "Dataroom",
      isActive:
        currentPath === `/investor/company/dataroom/${dealId}` ||
        currentPath === `/investor/company/pre-dataroom/${dealId}`,
      onClick: () => {
        stages.indexOf(stage) > 1
          ? navigate(`/investor/company/dataroom/${dealId}`)
          : navigate(`/investor/company/pre-dataroom/${dealId}`);
      },
    },
  ];
};
