import React, { useEffect, useState } from "react";
import {
  addStartuptoWishlist,
  removeStartupFromWishlist,
} from "../../endpoints/investor";
import InputCell from "./components/inputCell";
import folder from "../../Assets/Images/InvestorPage/Dashboard/folder.svg";
import pdfIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/pdfIcon.svg";
import docxIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/docxIcon.svg";
import pptIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/pptIcon.svg";
import jpgIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/jpgIcon.svg";
import mpIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/mpIcon.svg";
import FileImg from "../../Assets/Images/signup/file.svg";
import { FormControl, FormLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { streamFile } from "../../endpoints/common";
import Toast from "./Toast";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { streamDataroomFile } from "../../endpoints/dataroom";
import dayjs from "dayjs";

const fetchBlobName = (url) => {
  try {
    if (url) {
      const str = decodeURIComponent(url.split("/").pop());
      const lastIndex = str.lastIndexOf("_");
      if (lastIndex === -1 || str.indexOf("_") === lastIndex) {
        return str;
      }
      const result = str.replace(/_\d{8}_\d{6}/, "");
      return result;
    } else {
      return "";
    }
  } catch (error) {
    console.error("Error extracting file name:", error);
    return "";
  }
};
const fetchFullFileName = (url) => {
  try {
    const str = decodeURIComponent(url.split("/").pop());
    return str;
  } catch (error) {
    console.error("Error extracting file name:", error);
    return "";
  }
};
const extractTimestamp = (filename) => {
  try {
    const match = filename.match(/_(\d{8}_\d{6})\./);
    const dateTime = dayjs(match[1], "YYYYMMDD_HHmmss");
    return dateTime.format("DD MMMM YY, hh:mm:ss A");
  } catch (error) {
    return "";
  }
};
export const handleVideoStream = async (fileURL) => {
  if (fileURL) {
    const response = await streamFile(fileURL);
    const url = URL.createObjectURL(response);
    return url;
  }
};
export const handleFileDownload = async (
  fileURL,
  fileName,
  loader,
  toastContainerId,
  isDataRoomRequest,
  dealId,
  token
) => {
  loader && loader.start("Downloading file...");
  if (fileURL && !fileURL.startsWith("blob:")) {
    if (isDataRoomRequest) {
      const formData = {
        fileURL: fileURL,
        dealId: dealId,
      };
      const response = await streamDataroomFile(formData, token);
      loader && loader.stop();
      if (response.status && response.status === 403) {
        Toast(
          "The file will be accessible in the Due Diligence stage",
          "info",
          toastContainerId
        );
        return;
      }
      if (response?.data) {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(response?.data);
        downloadLink.download = fileName; // Extract filename
        downloadLink.click();

        // Clean up the URL object to release memory
        window.URL.revokeObjectURL(downloadLink.href);
        return;
      } else {
        Toast(
          `Some error occurred while downloading.`,
          `info`,
          toastContainerId
        );
      }
    } else {
      const response = await streamFile(fileURL);
      loader && loader.stop();
      if (response?.data) {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(response?.data);
        downloadLink.download = fetchFullFileName(fileURL); // Extract filename
        downloadLink.click();

        // Clean up the URL object to release memory
        window.URL.revokeObjectURL(downloadLink.href);
      } else {
        loader && loader.stop();
        Toast(
          `Some error occurred while downloading.`,
          `info`,
          toastContainerId
        );
      }
    }
  } else if (fileURL.startsWith("blob:")) {
    const downloadLink = document.createElement("a");
    downloadLink.href = fileURL;
    downloadLink.download = fileName; // Extract filename
    downloadLink.click();
    // Clean up the URL object to release memory
    window.URL.revokeObjectURL(downloadLink.href);
    loader && loader.stop();
  } else {
    loader && loader.stop();
    Toast(`Some error occurred while downloading.`, `info`, toastContainerId);
  }
};

function isValidEmail(val) {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regEmail.test(val)) {
    return false;
  } else {
    return true;
  }
}

function isValidUrl(url) {
  const regex =
    /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  return regex.test(url);
}

function isValidLinkedInUrl(url) {
  const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;
  return regex.test(url);
}
const timeDiff = (givenTime, hours) => {
  const givenDate = new Date(givenTime);
  const currentDate = new Date();
  const diffInMilliseconds = Math.abs(currentDate - givenDate);
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  return diffInHours < hours;
};
const useZoomEffect = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (windowWidth < 1300) {
      document.getElementsByClassName("InvestorSide")[0].style.zoom = "75%";
      const plots = document.getElementsByClassName("plot");
      for (let i = 0; i < plots.length; i++) {
        plots[i].style.zoom = "133.33%";
      }
      document.getElementsByClassName("InvestorSide-box")[0].style.height =
        "calc(133.33vh - 100px)";
    } else {
      document.getElementsByClassName("InvestorSide")[0].style.zoom = "100%";
      document.getElementsByClassName("InvestorSide-box")[0].style.height =
        "calc(100vh - 100px)";
    }
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};
//Navigating user if he/she has token
export const handleNavigation = (navigate) => {
  const userRole = localStorage.getItem("role");
  const token = localStorage.getItem("token");

  if (userRole === "investor") {
    navigate("/investor/newDeals", {
      state: {
        token: token,
      },
    });
    // } else {
    //   navigate("/investorDetails", {
    //     state: {
    //       tkn: token,
    //     },
    //   });
  } else if (userRole === "company") {
    navigate("/company/dashboard", {
      state: {
        tkn: token,
      },
    });
  }
  window.scrollTo(0, 0);
};

const addDealToWishlist = async (startupId, investorId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await addStartuptoWishlist(startupId, investorId, token);
    if (!response) {
    } else if (response.data !== null) {
      // Toast("Added to wishlist", "success");
    }
  } catch (error) {}
};

const removeDealFromWishlist = async (startupId, investorId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await removeStartupFromWishlist(
      startupId,
      investorId,
      token
    );
    if (!response) {
    } else if (response.data !== null) {
      // Toast("Removed from wishlist", "success");
    }
  } catch (error) {}
};

function calculateTotalAum(investmentDetails) {
  try {
    const totalAum = JSON.parse(investmentDetails).reduce((sum, investment) => {
      const aumValue = Number(investment.aum) || 0;
      return sum + aumValue;
    }, 0);
    return totalAum;
  } catch (error) {
    console.error("Error calculating total AUM:", error);
    return 0;
  }
}

function calculateInvestments(investmentDetails) {
  const totalInvestments = investmentDetails.reduce((sum, investment) => {
    const InvestmentValue = Number(investment.investments) || 0;
    return sum + InvestmentValue;
  }, 0);
  return totalInvestments;
}

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};
const industryVerticalsShort = {
  AT: "AdTech",
  AM: "Advanced Manufacturing",
  AG: "AgTech",
  AI: "AI/ML",
  AU: "Audiotech",
  AR: "AR/VR",
  AC: "Autonomous cars",
  BP: "B2B payments",
  BE: "Beauty",
  BD: "Big Data",
  CA: "Cannabis",
  CS: "Carsharing",
  CL: "Cleantech",
  CT: "Climate tech",
  CC: "Cloudtech",
  CO: "ConstructionTech",
  CN: "Consumer",
  BC: "Blockchain",
  CY: "Cybersecurity",
  DH: "Digital health",
  EC: "Ecommerce",
  ED: "Edtech",
  EP: "Ephemeral content",
  ES: "eSports",
  FT: "Femtech",
  FI: "Fintech",
  FD: "Foodtech",
  GA: "Gaming",
  HT: "Healthtech",
  HR: "HRtech",
  IM: "Impact investing",
  IN: "Industrials",
  IF: "Infrastructure",
  IT: "Insurtech",
  IO: "IOT",
  LT: "Legal tech",
  LS: "Life sciences",
  MF: "Manufacturing",
  MT: "Marketing tech",
  MM: "Micro-mobility",
  MB: "Mobile",
  MC: "Mobile commerce",
  MG: "Mortgage tech",
  NT: "Nanotechnology",
  OG: "Oil and gas",
  ON: "Oncology",
  PT: "Pet tech",
  PR: "PropTech",
  RT: "Restaurant tech",
  RS: "Ridesharing",
  RD: "Robotics and drones",
  SA: "SaaS",
  ST: "Space tech",
  SC: "Supply chain technology",
  TM: "TMT",
  WE: "Wearables",
  WT: "WorkTech",
  "3D": "3D printing",
};

const stages = [
  "interested",
  "requestSent",
  "requestAccepted",
  "ioiSubmitted",
  "ioiAccepted",
  "loiSubmitted",
  "loiAccepted",
  "spaNegotiation",
  "dealClosed",
  "watchlist",
];
const reducedStages = [
  "requestSent",
  "ioiSubmitted",
  "loiSubmitted",
  "dealClosed",
  "watchlist",
];
const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "64px",
    width: "100%",
    fontSize: "14px",
    background: "none",
    border: "1px solid #cdd7e1",
    borderRadius: "6px",
    padding: "15px",
    fontFamily: '"Karla", sans-serif',
    alignItems: "base-line",
  }),
};
const inputCustomStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "64px",
    width: "100%",
    fontSize: "14px",
    background: "none",
    border: "1px solid #8692a6",
    borderRadius: "6px",
    padding: "15px",
    fontFamily: '"Karla", sans-serif',
    alignItems: "base-line",
  }),
};
const inputCustomStylesAssestClass = {
  control: (provided) => ({
    ...provided,
    minHeight: "64px",
    width: "100%",
    fontSize: "14px",
    background: "white",
    border: "1px solid #8692a6",
    borderRadius: "6px",
    padding: "15px",
    fontFamily: '"Karla", sans-serif',
    alignItems: "base-line",
  }),
};
const cols = [
  {
    Header: "Date",
    accessor: "date",
    placeholder: "date",
    Cell: InputCell,
    width: 180,
  },
  {
    Header: "Round",
    accessor: "round",
    placeholder: "round",
    Cell: InputCell,
    width: 180,
  },
  {
    Header: "Raised (₹Cr)",
    accessor: "raised",
    placeholder: "raised",
    Cell: InputCell,
    width: 180,
  },
  {
    Header: "% Stake",
    accessor: "stake",
    placeholder: "stake",
    Cell: InputCell,
    width: 180,
  },
  {
    Header: "Valuation (₹Cr)",
    accessor: "valuation",
    placeholder: "valuation",
    Cell: InputCell,
    width: 180,
  },
];
const folderIcons = {
  folder: folder,
  pdf: pdfIcon,
  docx: docxIcon,
  ppt: pptIcon,
  jpg: jpgIcon,
  mp3: mpIcon,
};
const getIcon = (fileType) => {
  const defaultIcon = FileImg; // Replace with your generic icon
  return folderIcons[fileType] || defaultIcon;
};

const plotColorPallete = [
  "#D2DCFF",
  "#A8B0CC",
  "#525664",
  "#2A2C33",
  "#86A1FF",
  "#FFD2DC",
  "#98A6D4",
  "#114FEE",
  "#98DBFD",
  "#00CD9E",
  "#D3D9EE",
  "#9747FF",
  "#F4BD50",
  "#FF9933",
  "#FF855F",
  "#D32F2F",
  "#B22234",
  "#DD2590",
  "#99005C",
  "#161616",
];

function validateFolderName(folderName) {
  const prohibitedCharacters = /[<>:"\/\\|?*]/;
  const reservedNames = [
    "CON",
    "PRN",
    "AUX",
    "NUL",
    "COM1",
    "COM2",
    "COM3",
    "COM4",
    "COM5",
    "COM6",
    "COM7",
    "COM8",
    "COM9",
    "LPT1",
    "LPT2",
    "LPT3",
    "LPT4",
    "LPT5",
    "LPT6",
    "LPT7",
    "LPT8",
    "LPT9",
  ];
  const minLength = 3;
  const maxLength = 255;
  if (!folderName) {
    return "Folder name cannot be empty.";
  }
  if (prohibitedCharacters.test(folderName)) {
    return "Folder name contains prohibited characters.";
  }
  if (reservedNames.includes(folderName.toUpperCase())) {
    return "Folder name is reserved and cannot be used.";
  }
  if (folderName.length < minLength) {
    return `Folder name must be at least ${minLength} character long.`;
  }
  if (folderName.length > maxLength) {
    return `Folder name must not exceed ${maxLength} characters.`;
  }
  return null;
}
function formatSize(bytes) {
  if (bytes < 1024) {
    return `${bytes} bytes`;
  } else if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(2)} KB`;
  } else {
    return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  }
}
function investorStepClick(stepNumber, isApplicationReviewed) {
  if (isApplicationReviewed) {
    return;
  }
  document.getElementsByClassName("first-step")[0].style.display = "none";
  document.getElementsByClassName("second-step")[0].style.display = "none";
  document.getElementsByClassName("third-step")[0].style.display = "none";
  document.getElementsByClassName("fourth-step")[0].style.display = "none";
  document.getElementsByClassName("fifth-step")[0].style.display = "none";
  document.getElementsByClassName("sixth-step")[0].style.display = "none";

  switch (stepNumber) {
    case 1:
      document.getElementsByClassName("first-step")[0].style.display = "flex";
      break;
    case 2:
      document.getElementsByClassName("second-step")[0].style.display = "flex";
      break;
    case 3:
      document.getElementsByClassName("third-step")[0].style.display = "flex";
      break;
    case 4:
      document.getElementsByClassName("fourth-step")[0].style.display = "flex";
      break;
    case 5:
      document.getElementsByClassName("fifth-step")[0].style.display = "flex";
      break;
    case 6:
      document.getElementsByClassName("sixth-step")[0].style.display = "flex";
      break;
    default:
      break;
  }
}
const StyledFormLabel = styled(FormLabel)`
  font-family: Satoshi;
  color: #141414;
  font-size: 14px;
  font-weight: 500;
  ::after {
    content: " *";
    color: #d32f2f;
  }
`;
const StyledFormLabelOptional = styled(FormLabel)`
  font-family: Satoshi;
  color: #141414;
  font-size: 14px;
  font-weight: 500;
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  gap: 8px;
`;
const financialCriteriaOptions = [
  {
    label: "Revenue+",
    value: "Revenue+",
  },
  {
    label: "EBITDA+",
    value: "EBITDA+",
  },
];
const investmentStageOptions = [
  {
    label: "Seed",
    value: "Seed",
  },
  {
    label: "Early Stage",
    value: "Early Stage",
  },
  {
    label: "Growth",
    value: "Growth",
  },
  {
    label: "Late Stage",
    value: "Late Stage",
  },
];
const assetClassOptions = [
  {
    label: "Equity",
    value: "Equity",
  },
  {
    label: "Venture Debt",
    value: "Venture Debt",
  },
  {
    label: "iSAFE",
    value: "iSAFE",
  },
  {
    label: "CCPs",
    value: "CCPs",
  },
  {
    label: "CCDs",
    value: "CCDs",
  },
  {
    label: "Conventional Debt",
    value: "Conventional Debt",
  },
  {
    label: "Warrants",
    value: "Warrants",
  },
];

const investorTypeOptions = [
  { label: "Private Equity", value: "Private Equity" },
  { label: "Venture Capital", value: "Venture Capital" },
  { label: "Venture Debt", value: "Venture Debt" },
  {
    label: "Family Office",
    value: "Family Office",
  },
  { label: "Corporate", value: "Corporate" },
  { label: "HNI/Angel Investor", value: "HNI/Angel Investor" },
  { label: "Others", value: "Others" },
];

const industryVerticalsOptions = [
  { label: "FinTech", value: "FinTech" },
  { label: "eCommerce", value: "eCommerce" },
  { label: "CleanTech", value: "CleanTech" },
  { label: "TravelTech", value: "TravelTech" },
  { label: "DeepTech", value: "DeepTech" },
  { label: "EdTech", value: "EdTech" },
  { label: "AgriTech", value: "AgriTech" },
  { label: "Consumer", value: "Consumer" },
  { label: "RealTech", value: "RealTech" },
  { label: "HealthTech", value: "HealthTech" },
  { label: "Logistics", value: "Logistics" },
  { label: "Media", value: "Media" },
  { label: "EnterpriseTech", value: "EnterpriseTech" },
];

const subIndustryVerticalOptions = {
  FinTech: [
    { label: "LendingTech", value: "LendingTech" },
    { label: "InvestmentTech", value: "InvestmentTech" },
    { label: "Payments", value: "Payments" },
    { label: "InsurTech", value: "InsurTech" },
    { label: "Banking", value: "Banking" },
  ],
  eCommerce: [
    { label: "Marketplace", value: "Marketplace" },
    { label: "reCommerce", value: "reCommerce" },
    { label: "Roll Ups", value: "Roll Ups" },
    { label: "SocialCommerce", value: "SocialCommerce" },
  ],
  CleanTech: [
    { label: "EV", value: "EV" },
    { label: "Waste Management", value: "Waste Management" },
    { label: "ClimateTech", value: "ClimateTech" },
    { label: "Energy", value: "Energy" },
  ],
  TravelTech: [
    { label: "Marketplace", value: "Marketplace" },
    { label: "Transport", value: "Transport" },
    { label: "Hotels", value: "Hotels" },
  ],
  DeepTech: [
    { label: "IOT", value: "IOT" },
    { label: "SpaceTech", value: "SpaceTech" },
    { label: "Drones", value: "Drones" },
    { label: "Robotics", value: "Robotics" },
    { label: "AR/VR", value: "AR/VR" },
  ],
  EdTech: [
    { label: "K12", value: "K12" },
    { label: "Test Preparation", value: "Test Preparation" },
    { label: "Enterprise", value: "Enterprise" },
    { label: "Online Certification", value: "Online Certification" },
    { label: "Online Discovery", value: "Online Discovery" },
    { label: "Skill Development", value: "Skill Development" },
  ],
  AgriTech: [
    { label: "Marketplace", value: "Marketplace" },
    { label: "BioTech", value: "BioTech" },
    { label: "Farm Advisory", value: "Farm Advisory" },
    { label: "Farm Management", value: "Farm Management" },
  ],
  Consumer: [
    { label: "Hyperlocal Delivery", value: "Hyperlocal Delivery" },
    { label: "Hyperlocal Services", value: "Hyperlocal Services" },
    { label: "Alcohol", value: "Alcohol" },
    { label: "Food & Beverage", value: "Food & Beverage" },
  ],
  RealTech: [
    { label: "Shared Spaces", value: "Shared Spaces" },
    { label: "RealEstate SaaS", value: "RealEstate SaaS" },
    { label: "Listing & Discovery", value: "Listing & Discovery" },
    { label: "Services", value: "Services" },
  ],
  HealthTech: [
    { label: "Pharmacy", value: "Pharmacy" },
    { label: "H&W", value: "H&W" },
    { label: "Telemedicine", value: "Telemedicine" },
    { label: "Diagnostics", value: "Diagnostics" },
    { label: "Services", value: "Services" },
  ],
  Logistics: [
    { label: "SaaS", value: "SaaS" },
    { label: "eCommerce", value: "eCommerce" },
    { label: "Lastmile Delivery", value: "Lastmile Delivery" },
    { label: "Storage Services", value: "Storage Services" },
  ],
  Media: [
    { label: "Social Media", value: "Social Media" },
    { label: "OTT", value: "OTT" },
    { label: "Gaming", value: "Gaming" },
  ],
};
const getFilteredSubIndustryOptions = (values) => {
  return values.industryVerticals.flatMap(
    (industry) => subIndustryVerticalOptions[industry] || []
  );
};
const updateSubIndustryVerticals = (value, setFieldValue, currentValues) => {
  const availableIndustryVerticals = value
    .flatMap((industry) => subIndustryVerticalOptions[industry] || [])
    .map((ind) => ind.value);

  const filteredSubIndustryVerticals =
    currentValues.subIndustryVerticals.filter((industry) =>
      availableIndustryVerticals.includes(industry)
    );

  setFieldValue("subIndustryVerticals", filteredSubIndustryVerticals);
};

const endMarketFocusOptions = [
  { label: "B2B", value: "B2B" },
  { label: "B2B2C", value: "B2B2C" },
  { label: "B2C", value: "B2C" },
  { label: "D2C", value: "D2C" },
];
const stageRenameMap = {
  requestSent: "Screening",
  ioiSubmitted: "Term Sheet",
  loiSubmitted: "Due Diligence",
  dealClosed: "Close",
  watchlist: "Watchlist",
};
const stageMap = {
  interested: "requestSent",
  requestSent: "requestSent",
  requestAccepted: "requestSent",
  ioiSubmitted: "ioiSubmitted",
  ioiAccepted: "ioiSubmitted",
  loiSubmitted: "loiSubmitted",
  loiAccepted: "loiSubmitted",
  spaNegotiation: "dealClosed",
  dealClosed: "dealClosed",
  watchlist: "watchlist",
};
const toggleButtonStyles = {
  textTransform: "capitalize !important",
  color: "#000",
  height: "32px",
  border: "none",
  padding: "0px 8px",
  fontSize: "14px",
  "&.Mui-selected": {
    backgroundColor: "#fff !important",
    borderRadius: "8px !important",
    color: "#000 !important",
    border: "1px solid #AEB3C1",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#115293",
  },
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFF",
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#00A6FB",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));
const BorderLinearProgressBlue = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFF",
    border: "2px solid #DDF3FF",
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#00A6FB",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));
const dealTypeOptions = [
  { value: "Equity", label: "Equity" },
  { value: "Debt", label: "Debt" },
  { value: "Equity + Debt", label: "Equity + Debt" },
];
const preferredInvestorsOptions = [
  { value: "Private Equity", label: "Private Equity" },
  { value: "Venture Capital", label: "Venture Capital" },
  { value: "Venture Debt", label: "Venture Debt" },
  { value: "Family Office", label: "Family Office" },
  { value: "Corporate", label: "Corporate" },
  { value: "HNI/Angel Investor", label: "HNI/Angel Investor" },
  { value: "Others", label: "Others" },
];

const debtTypeOptions = [
  { value: "Conventional Debt", label: "Conventional Debt" },
  { value: "Revenue Based Financing", label: "Revenue Based Financing" },
];
const roundTypeOptions = [
  { value: "Pre Seed", label: "Pre Seed" },
  { value: "Seed", label: "Seed" },
  { value: "Pre-Series A", label: "Pre-Series A" },
  { value: "Series A", label: "Series A" },
  { value: "Series B", label: "Series B" },
  { value: "Series C", label: "Series C" },
  { value: "Series D", label: "Series D" },
  { value: "Series D+", label: "Series D+" },
];
const equityTypeOptions = [
  { value: "Priced", label: "Priced" },
  { value: "Non-Priced", label: "Non-Priced" },
];
const pricedEquityOptions = [
  { value: "Equity", label: "Equity" },
  { value: "Preferred Stock", label: "Preferred Stock" },
];
const nonPricedEquityOptions = [
  { value: "Convertible Debt", label: "Convertible Debt" },
  { value: "iSAFE", label: "iSAFE" },
];

const backingOptions = [
  { value: "VC Backed", label: "VC Backed" },
  { value: "Angel Backed", label: "Angel Backed" },
  { value: "Bootstrapped", label: "Bootstrapped" },
];

// export const parseHTMLString = (html) => {
//   try {
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(html, "text/html");

//     const convertNodeToReact = (node, key) => {
//       if (node.nodeType === Node.TEXT_NODE) {
//         return node.textContent; // Return raw text
//       }

//       // Collect attributes
//       const attributes = {};
//       if (node.attributes) {
//         Array.from(node.attributes).forEach((attr) => {
//           attributes[attr.name] = attr.value;
//         });
//       }

//       // Process child nodes recursively
//       const children = Array.from(node.childNodes).map((child, index) =>
//         convertNodeToReact(child, index)
//       );

//       return React.createElement(
//         node.tagName.toLowerCase(),
//         { key, ...attributes },
//         children
//       );
//     };

//     return Array.from(doc.body.childNodes).map((node, index) =>
//       convertNodeToReact(node, index)
//     );
//   } catch (e) {
//     return;
//   }
// };
const longForms = {
  requestSent: "Approval Pending",
  requestAccepted: "Request Accepted",
  ioiSubmitted: "Term Sheet Submitted",
  ioiAccepted: "Term Sheet Accepted",
  loiSubmitted: "Due Diligence Submitted",
  loiAccepted: "Due Diligence Accepted",
  spaNegotiation: "SPA Negotiation",
  dealClosed: "Deal closed",
  watchlist: "Watchlist",
};
const updateHeaderDealStage = (
  stage,
  isDealLive,
  setText,
  setColor,
  setBgcolor
) => {
  // Handle the 'watchlist' stage
  if (stage === "watchlist") {
    setText("Watchlist");
    setColor("#FF9E2C");
    setBgcolor("#F6ECE0");
    return;
  }

  // Set the text for the stage

  // If deal is not live, set specific colors
  if (!isDealLive) {
    setText("Deal passed");
    setColor("#FF9E2C");
    setBgcolor("#F6ECE0");
    return;
  }
  setText(longForms[stage]);

  // Handle stage-specific color logic
  if (
    stage === "requestSent" ||
    stage === "ioiSubmitted" ||
    stage === "loiSubmitted" ||
    stage === "spaNegotiation"
  ) {
    setColor("#FF9E2C");
    setBgcolor("#F6ECE0");
  } else if (
    stage === "requestAccepted" ||
    stage === "ioiAccepted" ||
    stage === "loiAccepted" ||
    stage === "dealClosed"
  ) {
    setColor("#05C168");
    setBgcolor("#E5FAF1");
  }
};
const addHTTPS = (url) => {
  if (!url) return "#"; // Return placeholder if URL is null or undefined
  return url.startsWith("http://") || url.startsWith("https://")
    ? url
    : `http://${url}`; // Prepend "http://" if no protocol is present
};
const getHostname = (websiteUrl) => {
  try {
    // Prepend 'http://' if the input doesn't already include a scheme
    const urlWithScheme =
      websiteUrl.startsWith("http://") || websiteUrl.startsWith("https://")
        ? websiteUrl
        : `http://${websiteUrl}`;
    const hostname = new URL(urlWithScheme).hostname;
    return hostname;
  } catch (error) {
    console.error("Invalid URL:", error.message);
    return null; // Return null or handle error as needed
  }
};

const getCompanyLogo = (websiteUrl) => {
  try {
    const hostname = getHostname(websiteUrl);

    const logoUrl = `https://logo.clearbit.com/${hostname}`;
    return logoUrl;
    // if (response.ok) {
    //   return logoUrl; // If the response is successful, return the logo URL
    // } else {
    //   throw new Error("Logo not found");
    // }
  } catch (error) {
    console.error("Invalid URL:", error.message);
    return null;
  }
};
function calculateDaysAgo(createdAt) {
  // Convert createdAt to IST using Day.js
  const createdDateIST = dayjs(createdAt).tz("Asia/Kolkata").startOf("day");
  const currentDateIST = dayjs().tz("Asia/Kolkata").startOf("day");

  // Calculate the difference in days
  const daysDifference = createdDateIST.diff(currentDateIST, "day");

  // Handle past, present, and future dates
  if (daysDifference === 0) {
    return "today";
  } else if (daysDifference < 0) {
    const daysAgo = Math.abs(daysDifference);
    return daysAgo === 1 ? "1 day ago" : `${daysAgo} days ago`;
  } else {
    return daysDifference === 1
      ? "1 day remaining"
      : `${daysDifference} days remaining`;
  }
}
const csvParser = (file, data, setData) => {
  if (file) {
    const reader = new FileReader();

    reader.onload = (event) => {
      const text = event.target.result;

      // Parse the CSV
      const rows = text.split("\n").filter((row) => row.trim() !== ""); // Split by line and filter empty rows
      const headers = rows[0].split(","); // Get headers from the first row
      const parsedData = rows.slice(1).map((row) => {
        const values = row.split(",");
        const obj = {};
        headers.forEach((header, index) => {
          let value = values[index]?.trim();
          if (header.trim().toLowerCase() === "date" && value) {
            // Use Day.js for date parsing and formatting
            const date = dayjs(value);
            if (date.isValid()) {
              value = date.format("YYYY-MM-DD"); // Format as YYYY-MM-DD
            }
          }
          obj[header.trim()] = value;
        });
        return obj;
      });

      const combinedData = [...data, ...parsedData];

      setData(combinedData);
    };

    reader.readAsText(file);
  }
};
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const getOrdersPerCustomer = (orders, customers) => {
  const division = (a, b) => b.map((v, i) => (v > 0 ? a[i] / v : 0));
  return {
    m: {
      "Orders/Customers": {
        m: division(orders?.m?.orders?.m, customers?.m?.growth?.m),
      },
    },
    q: {
      "Orders/Customers": {
        q: division(orders?.q?.orders?.q, customers?.q?.growth?.q),
      },
    },
    y: {
      "Orders/Customers": {
        y: division(orders?.y?.orders?.y, customers?.y?.growth?.y),
      },
    },
    isPercentageGraph: false,
    title: "Orders/Customers",
    data: {
      type: "bar",
    },
  };
};

const customerPlotsColors = {
  revenue: ["#05A6FB"],
  orders: ["#20BDA6"],
  averageRevenue: ["#FFA477"],
  revenueByCategory: ["#7C39DC", "#9F5CFF", "#B581FF", "#C49AFF", "#DABFFF"],
  costByCategory: ["#0F48D9", "#4172F1", "#6089F4", "#92AEF7", "#B5C8FA"],
  percentageGMByCategory: [
    "#FFC410",
    "#FFA477",
    "#D32F2F",
    "#05A6FB",
    "#092B83",
  ],
  revenueByCustomerType: ["#4172F1", "#5BE1AD"],
  gradient: [
    { stop: "#4172F170", start: "#4172F100" },
    { stop: "#5BE1AD70", start: "#5BE1AD00" },
  ],
  activeCustomers: ["#FFC410"],
  ordersPerCustomer: ["#9F5CFF"],
};
function getFileExtension(path) {
  const extension = path.split(".").pop();
  return extension === path ? "file" : extension.toUpperCase();
}
export {
  fetchBlobName,
  fetchFullFileName,
  extractTimestamp,
  isValidEmail,
  isValidUrl,
  isValidLinkedInUrl,
  useZoomEffect,
  timeDiff,
  addDealToWishlist,
  removeDealFromWishlist,
  calculateTotalAum,
  truncateText,
  calculateInvestments,
  investorStepClick,
  industryVerticalsShort,
  stages,
  reducedStages,
  customStyles,
  inputCustomStyles,
  cols,
  getIcon,
  validateFolderName,
  formatSize,
  plotColorPallete,
  inputCustomStylesAssestClass,
  StyledFormControl,
  StyledFormLabel,
  StyledFormLabelOptional,
  industryVerticalsOptions,
  endMarketFocusOptions,
  stageRenameMap,
  stageMap,
  toggleButtonStyles,
  BorderLinearProgress,
  BorderLinearProgressBlue,
  investorTypeOptions,
  assetClassOptions,
  investmentStageOptions,
  financialCriteriaOptions,
  dealTypeOptions,
  preferredInvestorsOptions,
  debtTypeOptions,
  roundTypeOptions,
  equityTypeOptions,
  pricedEquityOptions,
  nonPricedEquityOptions,
  backingOptions,
  subIndustryVerticalOptions,
  getFilteredSubIndustryOptions,
  updateSubIndustryVerticals,
  updateHeaderDealStage,
  addHTTPS,
  getCompanyLogo,
  calculateDaysAgo,
  csvParser,
  VisuallyHiddenInput,
  customerPlotsColors,
  getOrdersPerCustomer,
  getFileExtension,
};
