import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Topbar from "../Company/Deal/topbar";
import SecondarySidebar from "./secondarySidebar";
import AnalyticsMapper from "../Company/Deal/AnalyticsMapper";

const SecondaryAnalyticsMapper = () => {
  const location = useLocation();
  const token = localStorage.getItem(`token`);
  const { dealId, startupId } = location.state || {};
  return (
    <>
      <Helmet>
        <title>Admin Panel - Secondary Deal</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        containerId={`secondaryAnalyticsMapper`}
      />
      <Topbar />
      <SecondarySidebar dealId={dealId} active={`dealAnalyticsMapper`} />
      <div className="mt-20 h-[90vh] ml-[270px] overflow-scroll">
        <AnalyticsMapper dealId={dealId} />
      </div>
    </>
  );
};

export default SecondaryAnalyticsMapper;
