import React from "react";

const Navigation = ({ stages, handleClick, activeIndex }) => {
  return (
    <nav className="flex flex-col w-full text-sm leading-6">
      {stages.map((stage, index) => (
        <div
          key={stage}
          onClick={() => handleClick(stage, index)}
          className={`flex overflow-hidden gap-3 items-center px-150 py-100 w-full rounded cursor-pointer ${
            index === activeIndex
              ? "bg-blue-50 text-neutral-12 font-medium"
              : "bg-white text-neutral-8 hover:bg-neutral-3"
          } ${index === 0 ? "mt-0" : "mt-2"}`}
        >
          <div className="flex gap-3 items-center self-stretch my-auto w-[157px]">
            <div
              className={`text-desktop-p-mb self-stretch gap-3 my-auto ${
                index === activeIndex ? "font-medium" : "font-normal"
              }`}
            >
              {stage}
            </div>
          </div>
        </div>
      ))}
    </nav>
  );
};

export default Navigation;
