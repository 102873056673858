import React, { useState, useEffect } from "react";

import {
  getIOIDetails,
  getTotalAmountRaisedByStartup,
} from "../../../../endpoints/deal";

import greenTick from "../../../../Assets/Images/icons/greenTick.svg";
import redMark from "../../../../Assets/Images/InvestorPage/Dashboard/redMark.svg";
const Finanicals = ({ deal, page }) => {
  // console.log(deal.financialMetrics);
  const token = localStorage.getItem("token");
  const [totalAmountRaised, setTotalAmountRaised] = useState(0);
  const isSecondary = deal?.dealType === "secondary";

  const [ioiBids, setIOIBids] = useState([]);
  const getTotalAmountRaised = async () => {
    try {
      const response = await getTotalAmountRaisedByStartup({
        dealId: deal ? deal.dealId : "",
        token: token,
      });
      if (response.data) setTotalAmountRaised(response.data || 0);
    } catch (error) {}
  };
  const getIOIBids = async () => {
    // Call the API to get the Term Sheets for the startup
    try {
      const response = await getIOIDetails({
        dealId: deal ? deal.dealId : "",
        token: token,
      });
      setIOIBids(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    if (deal?.dealId) {
      getTotalAmountRaised();
      getIOIBids();
    }
  }, [deal]);
  return (
    <div className="cdetails-div-18">
      <div className="cdetails-div-19">
        <div className="finanicials">
          <div className="cdetails-div-21">Financials</div>
          <div className="cdetails-div-22">
            <div className="cdetails-div-23">
              {Object.entries(deal?.financialMetrics || {}).map(
                ([key, value], index, array) => (
                  <div
                    key={index}
                    className="cdetails-div-27"
                    style={{
                      paddingTop: index === 0 && "0px",
                      paddingBottom: index === array.length - 1 && "0px",
                      borderBottom:
                        index === array.length - 1 ? "0px" : undefined,
                    }}
                  >
                    <div className="cdetails-div-25">{key}</div>
                    <div className="cdetails-div-26">{value}</div>
                  </div>
                )
              )}
            </div>
          </div>
          {deal && deal.financialSubHeading && (
            <div className="cdetails-div-66">({deal.financialSubHeading})</div>
          )}
        </div>
        {/* <div className="cdetails-div-37">
              <div className="cdetails-div-21" style={{ marginLeft: "20px" }}>
                Current Round
              </div>
              <div
                className="cdetails-div-66"
                style={{
                  visibility: "hidden",
                }}
              >
                ({deal ? deal.financialSubHeading : ""})
              </div>
              <div className="current-scenario">
                <div className="cdetails-circular-progress-bar">
                  <CircularProgressbarWithChildren
                    value={
                      deal
                        ? ((Number(deal.TotalFundingRaised) +
                            totalAmountRaised) /
                            Number(deal.FundingRequired)) *
                          100
                        : ""
                    }
                    circleRatio={0.75}
                    styles={buildStyles({
                      rotation: 1 / 2 + 1 / 8,
                      trailColor: "#eee",
                      pathColor: "#4EF79A",
                    })}
                  >
                    <div className="cdetails-circular-progress-bar2">
                      <CircularProgressbar
                        value={
                          deal
                            ? (Number(deal.TotalFundingRaised || 0) /
                                Number(deal.FundingRequired)) *
                              100
                            : ""
                        }
                        circleRatio={0.75}
                        styles={buildStyles({
                          rotation: 1 / 2 + 1 / 8,
                          trailColor: "transparent",
                          pathColor: "#00A6FB",
                        })}
                      />
                    </div>

                    <div className="progress-bar-div">
                      <div className="progress-bar-div-2">
                        ₹
                        {deal
                          ? Number(deal.TotalFundingRaised || 0) +
                            totalAmountRaised
                          : ""}
                        Cr
                      </div>
                      <div className="progress-bar-div-3">
                        {deal
                          ? (
                              ((Number(deal.TotalFundingRaised || 0) +
                                totalAmountRaised) /
                                Number(deal.FundingRequired || 1)) *
                              100
                            ).toFixed(2)
                          : ""}
                        % Raised
                      </div>
                    </div>
                    <p className="progress-bar-div-4 round-size">
                      ₹{deal ? deal.FundingRequired : ""}Cr
                    </p>
                    <p className="progress-bar-div-4 round-size-text">
                      Round Size
                    </p>
                  </CircularProgressbarWithChildren>
                </div>
                <div
                  style={{
                    marginTop: "40px",
                    marginBottom: "15px",
                    padding: "0px 30px",
                    fontFamily: "Karla, sans-serif",
                    display: "flex",
                    width: "100%",
                    fontSize: "14px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#00A6FB",
                      }}
                    ></div>{" "}
                    Previously raised
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#4EF79A",
                      }}
                    ></div>
                    Raised on Fundrev
                  </div>
                </div>
                <div
                  className="cdetails-div-39"
                  style={{
                    marginTop: "0px",
                  }}
                >
                  <div>
                    <div className="cdetails-div-40">
                      ₹
                      {deal
                        ? (deal.IOIBids || 0) + (ioiBids?.amountRaised || 0)
                        : ""}
                      Cr
                    </div>
                    <div className="cdetails-div-41">Term Sheets</div>
                  </div>
                  <img
                    loading="lazy"
                    src={greenLine}
                    className="cdetails-img-2"
                  />
                  <div className="cdetails-div-42">
                    <div className="cdetails-div-43">
                      {deal
                        ? (deal.InvestorsNum || 0) +
                          (ioiBids?.totalInvestors || 0)
                        : ""}
                    </div>
                    <div className="cdetails-div-44">Investors</div>
                  </div>
                </div>
              </div>
            </div> */}
        <div className="cdetails-div-45">
          <div className="cdetails-div-47">
            <div className="cdetails-div-21">
              {isSecondary ? `Investment Highlights` : `Key Metrics`}
            </div>

            <div className="cdetails-div-22">
              {Object.entries(
                isSecondary
                  ? deal?.investmentHighlights || {}
                  : deal?.keyMetrics || {}
              ).map(([key, value], index, array) => (
                <div
                  key={index}
                  className="cdetails-div-27"
                  style={{
                    paddingTop: index === 0 && "0px",
                    paddingBottom: index === array.length - 1 && "0px",
                    borderBottom:
                      index === array.length - 1 ? "0px" : undefined,
                  }}
                >
                  <div className="cdetails-div-25">{key}</div>
                  <div className="cdetails-div-26">{value}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="cdetails-div-45">
          <div className="cdetails-div-47">
            <div className="cdetails-div-21">Other Information</div>
            <div className="cdetails-div-22">
              <div className="cdetails-div-23">
                <div className="cdetails-div-24" style={{ paddingTop: "0px" }}>
                  <div className="cdetails-div-25">Analytics</div>
                  <div className="cdetails-div-26">
                    <img
                      loading="lazy"
                      src={
                        deal && deal.dataAvailabilityWithFundrev
                          ? deal.dataAvailabilityWithFundrev.analytics === true
                            ? greenTick
                            : redMark
                          : ""
                      }
                      alt="tick"
                      className="cdetails-img-3"
                    />
                  </div>
                </div>
                <div className="cdetails-div-24">
                  <div className="cdetails-div-25">Dataroom</div>
                  <div className="cdetails-div-26">
                    <img
                      loading="lazy"
                      src={
                        deal && deal.dataAvailabilityWithFundrev
                          ? deal.dataAvailabilityWithFundrev.dataroom === true
                            ? greenTick
                            : redMark
                          : ""
                      }
                      alt="tick"
                      className="cdetails-img-3"
                    />
                  </div>
                </div>
                <div className="cdetails-div-24">
                  <div className="cdetails-div-25">Forecast Model</div>
                  <div className="cdetails-div-26">
                    <img
                      loading="lazy"
                      src={
                        deal && deal.dataAvailabilityWithFundrev
                          ? deal.dataAvailabilityWithFundrev.forecastModel ===
                            true
                            ? greenTick
                            : redMark
                          : ""
                      }
                      alt="tick"
                      className="cdetails-img-3"
                    />
                  </div>
                </div>
                <div
                  className="cdetails-div-24"
                  style={{ borderBottom: "0px", paddingBottom: "0px" }}
                >
                  <div className="cdetails-div-25">Management Presentation</div>
                  <div className="cdetails-div-26">
                    <img
                      loading="lazy"
                      src={
                        deal && deal.dataAvailabilityWithFundrev
                          ? deal.dataAvailabilityWithFundrev
                              .managementPresentation === true
                            ? greenTick
                            : redMark
                          : ""
                      }
                      alt="tick"
                      className="cdetails-img-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finanicals;
