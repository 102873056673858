import React, { useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import Plot from "react-plotly.js";
import { customerPlotsColors } from "../../Common/functions";
const SGAPiePlot = ({ data }) => {
  const [type, setType] = useState("m");
  const getValues = () => {
    return Object?.keys(data?.data[type]?.["SG&A"] || {})
      .slice(0, -1)
      ?.map((key) => {
        return Object?.values(data?.data[type]?.["SG&A"]?.[key] || {})?.reduce(
          (acc, val) => acc + val,
          0
        );
      });
  };
  const plotData = [
    {
      labels: Object.keys(data?.data[type]?.["SG&A"] || {}).slice(0, -1),
      values: getValues(),
      type: "pie",
      marker: {
        colors: customerPlotsColors["revenueByCategory"],
      },
      hole: 0.7,
      hoverinfo: "label+percent",
      rotation: 180,
      textinfo: "percent",
    },
  ];
  return (
    <>
      <TimePeriodButton
        type={type}
        setType={setType}
        title={data?.name || "Pie"}
        m={false}
        q={false}
        year={false}
      />
      <div
        style={{ width: "100%", height: "100%", position: "relative" }}
        className="mt-200"
      >
        <Plot
          data={plotData}
          layout={{
            paper_bgcolor: "transparent",
            plot_bgcolor: "transparent",
            modebar: {
              activecolor: "#114fee",
              bgcolor: "transparent",
              color: "grey",
              orientation: "v",
            },
            margin: {
              l: 40,
              r: 60,
              t: 20,
              b: 70,
            },
            showlegend: true,
            // legend: {
            //   orientation: "v",
            //   x: 0.1,
            //   traceorder: "normal",
            //   font: {
            //     family: "Karla, sans-serif",
            //     size: 8,
            //     color: "var(--Primary-Text-Color)",
            //   },
            // },
            // hovermode: "x unified",
            // hoverlabel: {
            //   bordercolor: "transparent",
            //   borderRadius: "10px",
            //   bgcolor: "#FFF",
            //   font: {
            //     family: "Karla, sans-serif",
            //   },
            // },
            ...data?.data?.values?.layout,
          }}
          config={{
            displaylogo: false,
            displayModeBar: true,
            modeBarButtonsToRemove: [
              "autoScale2d",
              "lasso2d",
              "select2d",
              "zoomIn2d",
              "zoomOut2d",
            ],
          }}
          useResizeHandler={true}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </>
  );
};

export default SGAPiePlot;
