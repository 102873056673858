import React from "react";
import Stepper from "@mui/joy/Stepper";
import Step, { stepClasses } from "@mui/joy/Step";
import StepIndicator, { stepIndicatorClasses } from "@mui/joy/StepIndicator";

import AdjustIcon from "@mui/icons-material/Adjust";
import tick from "../../../../Assets/Images/FounderPage/Dashboard/TimelineIcons/Tick.svg";

import "../../../../CSS/FounderPage/Dashboard/timeline.css";

const CustomStepper = ({
  activeStep,
  stages,
  className,
  setActiveStep,
  submitForm,
  validationSchema,
  setValidationSchema,
}) => {
  return (
    <div className={`w-[260px] h-fit ${className}`}>
      <Stepper
        orientation="vertical"
        sx={(theme) => ({
          "--Stepper-verticalGap": "1.4rem",
          "--StepIndicator-size": "1.6rem",
          "--Step-gap": "0.5rem",
          "--Step-connectorInset": "0rem",
          "--Step-connectorThickness": "2px",
          "--joy-palette-success-solidBg": "var(--timeline-color)",
          [`& .${stepClasses.completed}`]: {
            "&::after": { bgcolor: "success.solidBg" },
          },
          [`& .${stepClasses.active}`]: {
            [`& .${stepIndicatorClasses.root}`]: {
              border: "10px solid",
              borderColor: "#fff",
              boxShadow: `0 0 0 1px ${theme.vars.palette.primary[500]}`,
            },
          },
          [`& .${stepClasses.disabled} *`]: {
            color: "neutral.softDisabledColor",
          },
          "&.MuiStepLabel-vertical": { padding: 0 },
        })}
      >
        {stages.map((stage, index) => (
          <Step
            key={index}
            completed={stage?.completed}
            sx={{ "&.MuiStepLabel-vertical": { padding: 0 } }} // Remove padding here
            indicator={
              stage?.completed ? (
                <StepIndicator
                  variant="solid"
                  sx={{
                    color: "var(--timeline-color)",
                    backgroundColor: "#fff",
                  }}
                >
                  <img src={tick} alt="completed" className="w-[48px]" />
                </StepIndicator>
              ) : (
                <StepIndicator
                  sx={{
                    color: "#B9BBBD",
                    backgroundColor: "#fff",
                  }}
                >
                  <AdjustIcon sx={{ fontSize: "2rem" }} />
                </StepIndicator>
              )
            }
          >
            <p
              style={{
                fontSize: "14px",
                fontWeight: index === activeStep - 1 ? "bold" : "normal",
                color: "#141414",
                cursor: "pointer",
              }}
              // onClick={() => {
              //   if (index === 4) return;
              //   setActiveStep(index + 1);
              // }}
              onClick={async () => {
                if (index !== 4) {
                  setActiveStep(index + 1);
                }
                await setValidationSchema(null);
                await submitForm().then(() => {
                  setValidationSchema(validationSchema);
                });
              }}
            >
              {stage.name}
            </p>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default CustomStepper;
