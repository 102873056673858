import {
  atom,
  useResetRecoilState,
  useRecoilTransactionObserver_UNSTABLE,
} from "recoil";
import { useCallback } from "react";

// Centralized default state
const DEFAULT_INVESTOR_STATE = {
  name: "",
  activeBtn: 1,
  accountActiveBtn: 1,
  investorId: "",
  notificationBarOpen: false,
  ProfileBoxOpen: false,
  wishListOpen: false,
  profileCompleted: false,
  firm: {},
};

// Load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("investorState");
    if (serializedState === null) {
      return DEFAULT_INVESTOR_STATE;
    }
    return {
      ...DEFAULT_INVESTOR_STATE,
      ...JSON.parse(serializedState),
    };
  } catch (err) {
    console.error("Error loading state from localStorage:", err);
    return DEFAULT_INVESTOR_STATE;
  }
};

// Save state to localStorage with custom debouncing
let saveTimeout;
export const saveState = (state) => {
  clearTimeout(saveTimeout);
  saveTimeout = setTimeout(() => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem("investorState", serializedState);
    } catch (err) {
      console.error("Error saving state to localStorage:", err);
    }
  }, 300);
};

// Recoil atom definitions
export const investorState = atom({
  key: "investorState",
  default: loadState(),
});

export const interactedDealsState = atom({
  key: "interactedDealsState",
  default: [],
});

// Persist Recoil state changes
export const usePersistInvestorState = () => {
  useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
    const state = snapshot.getLoadable(investorState).contents;
    saveState(state);
  });
};

// Logout hook to clear state
export const useInvestorLogout = () => {
  const resetInvestorState = useResetRecoilState(investorState);
  const resetInteractedDealsState = useResetRecoilState(interactedDealsState);

  return useCallback(() => {
    localStorage.removeItem("investorState");

    // Reset Recoil state
    resetInvestorState();
    resetInteractedDealsState();
  }, [resetInvestorState, resetInteractedDealsState]);
};
