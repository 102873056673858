import React, { useEffect, useState } from "react";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import CustomBlackBtnPhone from "../../Common/components/custom/customBlackBtnPhone";
import Logo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import TnC from "./TnC";
import Toast from "../../Common/Toast";
import { ToastContainer } from "react-toastify";
import { investorSignup, memberUpdate } from "../../../endpoints/investor";

import { Checkbox } from "@mui/joy";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useLoader } from "../../Common/LoaderProvider";
import { Helmet } from "react-helmet";

import "../../../CSS/Signup/tnc.css";

const InvestorTnC = () => {
  const loader = useLoader();
  const location = useLocation();
  const Navigate = useNavigate();
  const [acceptTC, setAcceptTC] = useState(false);
  const token = localStorage.getItem("token");
  const { values, startPoint, email, currentPage, investorID } =
    location.state || {};

  useEffect(() => {
    if (!email && !values) {
      Navigate(`/signin`);
    }
  }, [email, values]);

  async function handleSubmit() {
    if (acceptTC) {
      if (
        currentPage === "investorMemberSignup" ||
        currentPage === "newDeals"
      ) {
        const formData = {
          email: email,
          organizationId: investorID,
          acceptedTC: true,
        };
        const response = await memberUpdate(formData, token);
        const toastMessage =
          currentPage === "investorMemberSignup"
            ? "Congratulations you signed up successfully"
            : "You have accepted the MSA and terms of use redirecting to the investor dashboard.";
        if (response.data.status === 200) {
          Toast(toastMessage, "success", "investorTnC");
          setTimeout(() => {
            Navigate("/signin");
          }, 2000);
        }
      } else {
        const finalFormData = {
          companyName: values.firmName,
          name: values.fullName,
          email: values.email,
          investorType: values.investorType,
          password: values.password,
          phoneNumber: values.phoneNumber,
          acceptTC: true,
        };
        let responseData;
        try {
          loader.start("Signing up...");
          responseData = await investorSignup(finalFormData);
          loader.stop();
        } catch (error) {
          loader.stop();
        }
        const response = responseData?.data;
        if (
          response &&
          response?.data?.message ===
            "Please verify your email with the code sent."
        ) {
          Navigate("/investor/emailVerification", {
            state: {
              values: finalFormData,
              startPoint: startPoint,
              investorToken: response.data.verificationToken,
              role: "investor",
            },
          });
        } else if (responseData.error.response.status === 409) {
          Toast(
            "Alerady Registered with this email. Please sign in.",
            "info",
            "investorTnC"
          );
          setTimeout(() => {
            Navigate("/signIn", {
              state: { sentemail: values.email, type: "Investor" },
            });
          }, 3000);
        } else {
          Toast(
            "Something went wrong. Please try again",
            "error",
            "investorTnC"
          );
        }
      }
    } else {
      Toast("Please accept the terms and conditions", "error", "investorTnC");
    }
  }

  return (
    <div className="Investor-terms-conditions-page">
      <ToastContainer position="top-center" containerId="investorTnC" />
      <Helmet>Investor | Terms & Conditions</Helmet>
      <div className="Investor-terms-conditions-header">
        <Link to="/">
          <img src={Logo} alt="fundrev_logo" />
        </Link>
      </div>
      <div className="tnc-block">
        <div className="heading-block">
          <p className="fundrev-heading tnc-heading">
            Please accept our terms and conditions
          </p>
        </div>
        <div className="middle-block">
          <TnC />
        </div>
        <div className="tnc-text-block laptop-design">
          <Checkbox
            className="checkbox"
            aria-label="Checkbox"
            sx={{
              color: "#00a6fb",
              "&.Mui-checked": {
                color: "#00a6fb",
              },
            }}
            checked={acceptTC}
            onChange={(event) => setAcceptTC(event.target.checked)} // Set directly as a boolean
          />

          <p>
            By clicking this, I hereby acknowledge and confirm that I have read,
            understood, and fully accept the terms and conditions set forth in
            the Master Service Agreement and the Terms of Use
          </p>
        </div>
        <div className="last-block laptop-design">
          <p
            style={{ fontWeight: "600", cursor: "pointer" }}
            onClick={() => Navigate("/signup")}
          >
            Decline
          </p>
          <div onClick={handleSubmit}>
            <CustomBlackBtn text="Accept and Continue" />
          </div>
        </div>
        <div className="tnc-last-section phone-design">
          <div className="tnc-text-block">
            <Checkbox
              className="checkbox"
              aria-label="Checkbox"
              sx={{
                color: "#00a6fb",
                "&.Mui-checked": {
                  color: "#00a6fb",
                },
              }}
              checked={acceptTC}
              onChange={(event) => setAcceptTC(event.target.checked)} // Set directly as a boolean
            />

            <p>
              By clicking this, I hereby acknowledge and confirm that I have
              read, understood, and fully accept the terms and conditions set
              forth in the Master Service Agreement and the Terms of Use
            </p>
          </div>
          <div className="last-block">
            <p
              style={{ fontWeight: "600", cursor: "pointer" }}
              onClick={() => Navigate("/signup")}
            >
              Decline
            </p>
            <div onClick={handleSubmit} style={{ width: "100%" }}>
              <CustomBlackBtnPhone text="Accept and Continue" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorTnC;
