import { Info } from "@mui/icons-material";
import {
  Box,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";

const TimePeriodButton = ({
  type,
  setType,
  title,
  ltm,
  subText = true,
  select,
  year = true,
  option,
  setOption,
  m = true,
  q = true,
  py = true,
  paddingRight,
  tooltip = false,
}) => {
  const handleSelect = (event) => {
    setOption(event.target.value);
  };
  const handleChange = (e, newAlignment) => {
    if (newAlignment !== null) {
      setType(newAlignment);
    }
  };
  const getText = () => {
    switch (type) {
      case "m":
        return `Month`;
      case "q":
        return `Quarter`;
      case "y":
        return `Year`;
      case "ltm":
        return "ltm";
      default:
        return ``;
    }
  };
  useEffect(() => {
    setOption && setOption(`previous${type}`);
  }, [type]);
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      borderRadius: "4px",
      fontFamily: "Satoshi",
      fontSize: "10px",
      maxWidth: "48px",
      color: "#141414",
      fontWeight: 500,
      '&[aria-pressed="true"]': {
        backgroundColor: "white",
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.05)",
        "&:hover": {
          backgroundColor: "white",
        },
      },
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
      {
        marginLeft: "4px",
      },
  }));
  return (
    <>
      <Box
        display={`flex`}
        alignItems={`center`}
        justifyContent={`space-between`}
        sx={{
          paddingRight: paddingRight || "5px",
          height: "28px",
        }}
      >
        <p className="text-desktop-p-lb">
          {title}
          {subText && <p className="text-desktop-p-xs">In lakhs</p>}
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <Info
                fontSize="small"
                sx={{
                  marginLeft: "3px",
                  marginBottom: "3px",
                }}
              />
            </Tooltip>
          )}
        </p>

        <div className="flex items-center gap-100">
          {select && type !== "ltm" && type !== "y" && (
            <StyledToggleButtonGroup
              className="bg-neutral-4 border border-neutral-5 p-050 h-[30px]"
              exclusive
              value={option}
              onChange={handleSelect}
            >
              <ToggleButton
                sx={{
                  border: 0,
                  textTransform: "none",
                  height: "20px",
                  padding: "10px 24px",
                }}
                value={`previous${type}`}
              >
                {/* Previous {getText()} */}
                MoM
              </ToggleButton>

              {py && (
                <ToggleButton
                  sx={{
                    border: 0,
                    textTransform: "none",
                    height: "20px",
                    padding: "10px 24px",
                  }}
                  value={`last${type}`}
                >
                  {/* Previous Year */}
                  PY
                </ToggleButton>
              )}
            </StyledToggleButtonGroup>
          )}
          {(m || q || year || ltm) && (
            <StyledToggleButtonGroup
              className="bg-neutral-4 border border-neutral-5 p-050 h-[30px]"
              exclusive
              aria-label="Period"
              value={type}
              onChange={handleChange}
            >
              {m && (
                <ToggleButton
                  sx={{
                    border: 0,
                    textTransform: "none",
                    height: "20px",
                    padding: "10px 24px",
                  }}
                  value="m"
                >
                  M
                </ToggleButton>
              )}
              {q && (
                <ToggleButton
                  sx={{
                    border: 0,
                    textTransform: "none",
                    height: "20px",
                    padding: "10px 24px",
                  }}
                  value="q"
                >
                  Q
                </ToggleButton>
              )}
              {year && (
                <ToggleButton
                  sx={{
                    border: 0,
                    textTransform: "none",
                    height: "20px",
                    padding: "10px 24px",
                  }}
                  value="y"
                >
                  Y
                </ToggleButton>
              )}
              {ltm && (
                <ToggleButton
                  sx={{
                    border: 0,
                    textTransform: "none",
                    height: "20px",
                    padding: "10px 24px",
                  }}
                  value="ltm"
                >
                  LTM
                </ToggleButton>
              )}
            </StyledToggleButtonGroup>
          )}
        </div>
      </Box>
    </>
  );
};

export default TimePeriodButton;
