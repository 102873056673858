import React, { useMemo, useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import { formatToLakhs } from "../../../utilities/numberFormatter";
import {
  getRelativeKey,
  useKeySelection,
} from "../../../hooks/analytics/getComparisonKey";
import arrowUp from "../../../Assets/Images/icons/analytics/ArrowUp.svg";
import arrowDown from "../../../Assets/Images/icons/analytics/ArrowDown.svg";

const BalanceSheetSummaryTable = ({ data }) => {
  const [type, setType] = useState("m");
  const [option, setOption] = useState(`previous${type}`);
  const keys = useMemo(
    () =>
      Object?.keys(
        data?.data?.[type]?.[Object?.keys(data?.data?.[type] || {})?.[0]]
          ?.values || {}
      ),
    [type, data?.data]
  );
  const { key, ckey } = useKeySelection(data, type, option, keys);
  const rows = [
    "Equity",
    "Non-current liability",
    "Current liability",
    "Total Equity and Liability",
    "Fixed Assets",
    "Current Asset",
    "Cash",
    "Total Assets",
  ];
  const IMPRows = ["Total Equity and Liability", "Total Assets"];
  function getVariance(row) {
    const a = getValue(row)?.[key];
    const b = getValue(row)?.[ckey];

    // Check if either 'a' or 'b' is zero to prevent division by zero
    if (a === 0 || b === 0 || !a || !b) {
      return 0;
    }

    return (((a - b) / Math.abs(b)) * 100).toFixed(1);
  }
  function mergeValues(target, source) {
    for (const key in source) {
      if (typeof source[key] === "number") {
        if (!target[key]) {
          target[key] = 0;
        }
        target[key] += source[key];
      }
    }
    return target;
  }
  function sumValues(mapper) {
    const keys = Object.keys(mapper?.[Object.keys(mapper || {})[0]] || {});
    const result = {};
    keys.forEach(
      (key) =>
        key !== "Cash" &&
        mergeValues(result, mapper[Object.keys(mapper)[0]][key])
    );
    return result;
  }
  function getValue(row) {
    if (row === "Equity") {
      return data?.data[type]?.["Equity and Liability"]?.mappers?.[0]?.[
        "Shareholder's Equity"
      ]?.["Shareholder's Equity"];
    } else if (row === "Non-current liability") {
      return sumValues(data?.data[type]?.["Equity and Liability"].mappers[1]);
    } else if (row === "Current liability") {
      return sumValues(data?.data[type]?.["Equity and Liability"].mappers[2]);
    } else if (row === "Total Equity and Liability") {
      return data?.data[type]?.["Equity and Liability"].values;
    } else if (row === "Fixed Assets") {
      return sumValues(data?.data[type]?.["Assets"].mappers[0]);
    } else if (row === "Current Asset") {
      return sumValues(data?.data[type]?.["Assets"].mappers[1]);
    } else if (row === "Cash") {
      return data?.data[type]?.["Assets"].mappers[1]["Current Assets"]["Cash"];
    } else if (row === "Total Assets") {
      return data?.data[type]?.["Assets"].values;
    } else {
      return data?.data[type]?.["Equity and Liability"].mappers[0][
        "Shareholder's Equity"
      ]["Shareholder's Equity"];
    }
  }

  const isVisible = () => {
    let result = false;
    rows.forEach((row) => {
      if (
        getValue(row)?.[
          getRelativeKey(keys, keys?.[keys?.length - 1], `last${type}`, false)
            .key
        ]
      ) {
        result = true;
      }
    });

    return result;
  };
  const isYearVisible = () => {
    let result = false;
    function getValue(row, type) {
      if (row === "Equity") {
        return data?.data[type]?.["Equity and Liability"]?.mappers?.[0]?.[
          "Shareholder's Equity"
        ]?.["Shareholder's Equity"];
      } else if (row === "Non-current liability") {
        return sumValues(data?.data[type]?.["Equity and Liability"].mappers[1]);
      } else if (row === "Current liability") {
        return sumValues(data?.data[type]?.["Equity and Liability"].mappers[2]);
      } else if (row === "Total Equity and Liability") {
        return data?.data[type]?.["Equity and Liability"].values;
      } else if (row === "Fixed Assets") {
        return sumValues(data?.data[type]?.["Assets"].mappers[0]);
      } else if (row === "Current Asset") {
        return sumValues(data?.data[type]?.["Assets"].mappers[1]);
      } else if (row === "Cash") {
        return data?.data[type]?.["Assets"].mappers[1]["Current Assets"][
          "Cash"
        ];
      } else if (row === "Total Assets") {
        return data?.data[type]?.["Assets"].values;
      } else {
        return data?.data[type]?.["Equity and Liability"].mappers[0][
          "Shareholder's Equity"
        ]["Shareholder's Equity"];
      }
    }
    const keys = Object?.keys(
      data?.data?.[type]?.[Object?.keys(data?.data?.[type] || {})?.[0]]
        ?.values || {}
    );
    rows.forEach((row) => {
      if (
        getValue(row, "y")?.[
          getRelativeKey(keys, keys?.[keys?.length - 1], "previousy", false)
            ?.key
        ]
      ) {
        result = true;
      }
    });

    return result;
  };
  return (
    <>
      <TimePeriodButton
        title={data?.name}
        type={type}
        setType={setType}
        select={true}
        year={isYearVisible()}
        option={option}
        setOption={setOption}
        py={isVisible()}
      />
      <div className="mt-200">
        <table aria-label="balance sheet summary table">
          <thead>
            {["Summary", ckey, key, "%Change"].map((column, index) => (
              <th
                key={index}
                align={index === 0 ? "left" : "right"}
                className={`p-150 bg-neutral-3 rounded-050 text-desktop-p-s font-medium text-neutral-8 !uppercase ${
                  index === 0 ? "text-left" : "text-right"
                }`}
              >
                {column}
              </th>
            ))}
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td
                  className={`text-desktop-p-s p-150 !border-b-neutral-4 text-left ${
                    IMPRows.includes(row) &&
                    "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                  }`}
                  align="left"
                >
                  {row}
                </td>
                <td
                  className={`text-desktop-p-s p-150 !border-b-neutral-4 text-right ${
                    IMPRows.includes(row) &&
                    "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                  }`}
                  align="right"
                >
                  {formatToLakhs(getValue(row)?.[ckey]) || `-`}
                </td>
                <td
                  className={`text-desktop-p-s p-150 !border-b-neutral-4 text-right ${
                    IMPRows.includes(row) &&
                    "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                  }`}
                  align="right"
                >
                  {formatToLakhs(getValue(row)?.[key]) || `-`}
                </td>
                <td
                  className={`text-desktop-p-s p-150 !border-b-neutral-4  text-right ${
                    IMPRows.includes(row) &&
                    "bg-aero-blue-50 !border-t-[1.5px]  !border-t-aero-blue-700 font-bold"
                  } ${
                    getVariance(row)
                      ? getVariance(row) > 0
                        ? "text-[#20BD7E]"
                        : "text-[#FF5454]"
                      : ""
                  }`}
                  align="right"
                >
                  <div className="flex items-center gap-100 justify-end">
                    {getVariance(row) ? (
                      getVariance(row) > 0 ? (
                        <img src={arrowUp} alt="up" />
                      ) : (
                        <img src={arrowDown} alt="down" />
                      )
                    ) : (
                      ``
                    )}
                    {getVariance(row) ? getVariance(row) + `%` : `-`}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BalanceSheetSummaryTable;
