import React from "react";
import { Link, useParams } from "react-router-dom";
import { MdFilterList } from "react-icons/md";
import { Checkbox } from "@mui/joy";

const NavItem = ({ to, label, count, isActive }) => (
  <Link to={to}>
    <div
      className={`flex items-center self-stretch my-auto  rounded py-100 pl-150 pr-100 gap-300 text-desktop-p-s  border  ${
        isActive
          ? "border-aero-blue-500 bg-aero-blue-50 font-bold"
          : "border-neutral-5 bg-white font-medium"
      }`}
    >
      <div
        className={`self-stretch my-auto ${
          isActive ? "text-aero-blue-700" : "text-neutral-12"
        }`}
      >
        {label}
      </div>
      <div
        className={`gap-2 self-stretch w-[25px] h-[25px] flex justify-center items-center tracking-wide  text-neutral-12  rounded ${
          isActive
            ? "text-white bg-aero-blue-400"
            : "text-neutral-12 bg-neutral-4"
        }`}
      >
        {count}
      </div>
    </div>
  </Link>
);

function SubHeader({
  recommendedDealsCount,
  wishListCount,
  noNameDealCount,
  otherDealsCount,
  archiveDealsCount,
  primaryDealsCount,
  secondaryDealsCount,
  filterBoxOpen,
  setFilterBoxOpen,
  recommendedOnly,
  SetRecommendedOnly,
}) {
  const { type } = useParams();

  const isActive = (section) => section === type;

  const navItems = [
    {
      to: "/investor/newDeals/allListing",
      label: "All Listing",
      count: noNameDealCount,
      section: "allListing",
    },
    {
      to: "/investor/newDeals/primary",
      label: "Primary",
      count: primaryDealsCount,
      section: "primary",
    },
    {
      to: "/investor/newDeals/secondary",
      label: "Secondary",
      count: secondaryDealsCount,
      section: "secondary",
    },
    {
      to: "/investor/newDeals/wishlist",
      label: "Wishlist",
      count: wishListCount,
      section: "wishlist",
    },
    {
      to: "/investor/newDeals/archive",
      label: "Archive",
      count: archiveDealsCount,
      section: "archive",
    },
  ];

  return (
    <>
      <div className="max-md:px-5 flex flex-col pt-300 pb-2 max-w-[1500px] mx-auto w-full">
        <div className="max-md:max-w-full flex flex-wrap items-end justify-between w-full gap-10">
          <div className="flex flex-col min-w-[240px] max-md:max-w-full">
            <div className="flex flex-wrap gap-3 items-center text-sm min-h-[32px] max-md:max-w-full">
              {navItems.map(({ to, label, count, section }) => (
                <NavItem
                  key={to}
                  to={to}
                  label={label}
                  count={count}
                  isActive={isActive(section)}
                />
              ))}
            </div>
          </div>

          <div className="flex items-center gap-[30px]">
            {type !== "archive" && (
              <Checkbox
                label={"Show Recommended Only"}
                checked={recommendedOnly}
                onChange={(e) => SetRecommendedOnly(e.target.checked)}
                sx={{
                  "& .MuiCheckbox-label": {
                    fontSize: "14px", // Adjust the font size as needed
                  },
                }}
              />
            )}
            <div
              className={`flex gap-2.5 justify-center items-center p-3 rounded border border-neutral-5 cursor-pointer ${
                filterBoxOpen ? "bg-neutral-4" : "bg-white hover:bg-neutral-3"
              }`}
              onClick={() => setFilterBoxOpen(!filterBoxOpen)}
              id="toggleButton"
            >
              <MdFilterList fontSize={"20px"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubHeader;
