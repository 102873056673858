import React, { useState, useEffect } from "react";
import Toast from "../Common/Toast";
import {
  fetchStartupData,
  checkDealExist,
  setToggleApprovalCompany,
  generateCompanyLoginToken,
} from "../../endpoints/admin";
import { getAllEditRequests, toggleOpenListing } from "../../endpoints/deal";
import { getAllQnAs } from "../../endpoints/qnA";

import { Box, Modal, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Verify from "../../Assets/Images/signup/InvestorRegister/Verify.png";
import CustomBlackBtn from "../Common/components/custom/customBlackBtn";
import CustomModal from "../Common/components/custom/customModal";

const Companies = ({ refresh }) => {
  const token = localStorage.getItem("token");
  const fundrevAnalystID = "FN123456";
  const navigate = useNavigate();
  const verificationToken = localStorage.getItem("token");
  const [startups, setStartups] = useState([]);
  const [dealId, setDealId] = useState({});
  const [, setPublishStatus] = useState({});
  const [id, setId] = useState(null);
  const [openListingStatus, setOpenListingStatus] = useState({});
  const [open, setOpen] = useState({});
  const [qnAs, setqnAs] = useState([]);
  const [, setEditRequests] = useState({});
  const [selectedCompanyID, setSelectedCompanyID] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [approveWaitlistModal, setApproveWaitlistModal] = useState(false);
  const [companyLoginModal, setCompanyLoginModal] = useState(false);
  // const [createOrAssignDealModalOpen, setCreateOrAssignDealModalOpen] =
  //   useState(false);
  useEffect(() => {
    fetchData();
  }, [refresh]);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = async () => {
    try {
      const response = await fetchStartupData(token);
      if (response && response.data) {
        setStartups(response.data);
        // Check if deals exist for each startup and update the publish status
        response.data.forEach(async (startup) => {
          const dealsExistResponse = await checkDealExist(
            startup.startupId,
            token
          );

          setPublishStatus((prevState) => ({
            ...prevState,
            [startup.startupId]: dealsExistResponse.data.dealsExist,
          }));
          setOpenListingStatus((prevState) => ({
            ...prevState,
            [startup.startupId]: dealsExistResponse.data.openListing,
          }));
          setDealId((prev) => ({
            ...prev,
            [startup.startupId]: dealsExistResponse.data.dealId,
          }));
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const AssignDeal = async (id) => {
  //   setSelectedCompanyID(id);
  //   setCreateOrAssignDealModalOpen(open);
  // };
  async function toggleStatus(dealId) {
    try {
      await toggleOpenListing(dealId, token);
      fetchData();
    } catch (error) {
      Toast("Error getting NoNameDeals", "error", "adminPanel");
    }
  }
  const fetchEditRequests = async () => {
    try {
      if (verificationToken) {
        const response = await getAllEditRequests(verificationToken);
        if (response && response.data) {
          setEditRequests(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching edit requests:", error);
    }
  };
  const QnACount = ({ startupId }) => {
    const [qnACount, setQnACount] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
      let count = 0;
      if (qnAs) {
        count = qnAs?.filter((doc) => doc.askedBy === startupId).length;
      }
      setQnACount(count);
    }, [startupId]);

    return qnACount !== null ? (
      qnACount > 0 ? (
        <div
          className="toggleStatusbtn Pending-btn"
          onClick={() =>
            navigate("/admin/answerQnAs", {
              state: {
                startupId: startupId,
                fundrevAnalystID: "FN123456",
              },
            })
          }
        >
          <p>&nbsp; {qnACount} questions</p>
        </div>
      ) : (
        <p>No comments</p>
      )
    ) : (
      <p>Loading...</p>
    );
  };

  const fetchAllQnAs = async (fundrevAnalystID) => {
    if (fundrevAnalystID && token) {
      const response = await getAllQnAs(fundrevAnalystID, verificationToken);
      setqnAs(response.data);
      try {
        if (response.response.data === "No qnA found") {
          return;
        }
      } catch (error) {}
    } else {
      return;
    }
  };
  const handleWaitlistApproval = (id) => {
    setId(id);
    setApproveWaitlistModal(true);
  };
  const toggleApproval = async () => {
    try {
      await setToggleApprovalCompany(id, token);
      fetchData();
      setApproveWaitlistModal(false);
    } catch (error) {
      console.error("Error toggling approval:", error);
    }
  };
  const companyLogin = async () => {
    try {
      const response = await generateCompanyLoginToken(
        selectedCompanyID,
        token
      );
      if (response.data) {
        const token = response.data.token;
        localStorage.setItem("token", token);
        localStorage.setItem("role", "company");
        navigate("/company/dashboard");
      } else {
        Toast("Error during login", "error", "adminPanel");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllQnAs(fundrevAnalystID);
  }, [fundrevAnalystID]);
  useEffect(() => {
    fetchEditRequests();
  }, [verificationToken]);
  return (
    <div className="table-container">
      {/* <CreateOrAssignDealModal
        open={createOrAssignDealModalOpen}
        setOpen={setCreateOrAssignDealModalOpen}
        id={selectedCompanyID}
        fetchData={fetchData}
      /> */}
      <CustomModal
        open={approveWaitlistModal}
        onClose={() => setApproveWaitlistModal(false)}
        title="Waitlist Approval"
        description="This will approve company from waitlist"
        primaryAction={toggleApproval}
        secondaryAction={() => setApproveWaitlistModal(false)}
        customStyles={{ width: "500px", height: "fit-content" }}
      />
      <CustomModal
        open={companyLoginModal}
        onClose={() => setCompanyLoginModal(false)}
        title="Admin company login"
        description={`Do you want to login as ${selectedCompanyName}`}
        primaryAction={companyLogin}
        secondaryAction={() => setCompanyLoginModal(false)}
        customStyles={{ width: "500px", height: "fit-content" }}
      />
      <table>
        <thead>
          <tr>
            <th>Startup Name</th>
            {/* <th>Company website</th> */}
            <th>Startup ID</th>
            {/* <th>Profile Completion</th> */}
            <th>Waitlist approval</th>
            <th>Deal ID </th>
            <th>Edit deal</th>
            {/* <th>OnBoarding Completed</th> */}
            {/* <th>Admin Deal details</th> */}
            <th>Open Listing</th>
            <th>Company Login</th>
            {/* <th>Answer QnAs</th> */}
            {/* <th>Investors</th> */}
          </tr>
        </thead>
        <tbody>
          {startups.map((startup) => (
            <>
              <tr key={startup._id}>
                <td>{startup.companyName}</td>
                {/* <td>{startup.website}</td> */}
                <td>{startup.startupId}</td>
                {/* <td>
                  {startup?.profileCompleted ? `Completed` : `Not Completed`}
                </td> */}
                <td>
                  <button
                    className={`toggleStatusbtn ${
                      startup.isApproved ? "Approved-btn" : "Pending-btn"
                    }`}
                    onClick={() => handleWaitlistApproval(startup._id)}
                  >
                    {startup.isApproved ? "Approved" : "Approve"}
                  </button>
                </td>

                <td>
                  {dealId[startup.startupId] ? (
                    dealId[startup.startupId]
                  ) : (
                    <p>Deal not created</p>
                  )}
                </td>

                <td>
                  <CustomBlackBtn
                    text={`Edit`}
                    variant="sm"
                    border={`sm`}
                    onClick={() =>
                      navigate("/admin/dealDetailsEdit", {
                        state: {
                          dealId: dealId[startup.startupId],
                          startupId: startup.startupId,
                        },
                      })
                    }
                  />
                </td>
                {/* <td>
                  {startup.onBoardingComplete
                    ? moment(startup.updatedAt)
                        .tz("Asia/Kolkata")
                        .format("DD/MM/YYYY h:mm A")
                    : "-"}
                </td> */}
                {/* <td>
                  {dealId[startup.startupId] ? (
                    <div
                      className="toggleStatusbtn"
                      style={{ width: "fit-content" }}
                      onClick={() => {
                        navigate("/admin/dealDetailsEdit", {
                          state: {
                            dealId: dealId[startup.startupId],
                            startupId: startup.startupId,
                          },
                        });
                      }}
                    >
                      Edit
                    </div>
                  ) : (
                    <div>Deal not created</div>
                  )}
                </td> */}
                <td>
                  {dealId[startup.startupId] ? (
                    <button
                      className={`toggleStatusbtn ${
                        openListingStatus[startup.startupId]
                          ? "Approved-btn"
                          : "Pending-btn"
                      }`}
                      onClick={() => toggleStatus(dealId[startup.startupId])}
                    >
                      {openListingStatus[startup.startupId]
                        ? "Visible"
                        : "Not Visible"}
                    </button>
                  ) : (
                    "Deal not created"
                  )}
                </td>
                <td>
                  <CustomBlackBtn
                    text="Login"
                    variant="sm"
                    border="sm"
                    mode="blue"
                    onClick={() => {
                      setSelectedCompanyID(startup.startupId);
                      setSelectedCompanyName(startup.companyName);
                      setCompanyLoginModal(true);
                    }}
                  />
                </td>
                {/* <td>
                  {dealId ? <QnACount startupId={startup.startupId} /> : null}
                </td> */}
                {/* <td>
                  {dealId[startup.startupId] ? (
                    <button
                      className="toggleStatusbtn"
                      onClick={() =>
                        navigate("/admin/investorStages", {
                          state: {
                            startupId: startup.startupId,
                            dealId: dealId[startup.startupId],
                            token: verificationToken,
                            fundrevAnalystID: "FN123456",
                            companyName: startup.companyName,
                          },
                        })
                      }
                    >
                      View
                    </button>
                  ) : (
                    "Deal not created yet"
                  )}
                </td> */}
                <Modal
                  open={open[startup.startupId]}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className="modal-box">
                    <img src={Verify} alt="verify" className="verify-image" />
                    <Typography
                      id="modal-modal-title"
                      style={{ textAlign: "center" }}
                      variant="h5"
                    >
                      Do you really want to Unpublish this Deal?
                    </Typography>
                    <Typography id="modal-modal-description">
                      You might have interacted with investors.
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        gap: "50px",
                      }}
                    >
                      <Button className="" variant="outlined">
                        Yes
                      </Button>

                      <Button
                        className=""
                        variant="outlined"
                        color="error"
                        onClick={handleClose}
                      >
                        No
                      </Button>
                    </div>
                  </Box>
                </Modal>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Companies;
