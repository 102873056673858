import React, { useEffect, useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import Plot from "react-plotly.js";
import { customerPlotsColors } from "../../Common/functions";
import { calculateDtick, calculateTotals } from "./PlotUtilityFunctions";

const PlotTemplate = ({
  data,
  m = true,
  q = true,
  y = true,
  tooltip = false,
  precision = `0`,
  colors = [],
  height,
  subText,
}) => {
  const [type, setType] = useState("m");
  useEffect(() => {}, [type, data]);

  // Prepare data for Plotly
  const xLabels = data.data.xLabels[type];
  const totalValues = calculateTotals(data.data.values[type], type);
  const getPercentages = (values) => {
    return values.map((value, index) => (value / totalValues[index]) * 100);
  };

  const isYearVisible = () => {
    let result = false;
    if (
      data?.data?.values?.isPercentageGraph ||
      data?.data?.values?.data?.type !== "bar"
    ) {
      Object.entries(data?.data?.values?.["y"] || {}).forEach(
        ([category, values]) => {
          if (values?.["y"]?.length > 1) {
            result = true;
          }
        }
      );
    } else {
      result = true;
    }
    return result;
  };
  const plotColors = customerPlotsColors[data?.key] || [];

  const plotData = Object?.entries(data.data.values[type] || {}).map(
    ([category, dataValues], index) => {
      // Determine if values are already percentages or if there's only one category
      // const isPercentageGraph = dataValues[type].every((value) => value <= 100);
      const isPercentageGraph = data.data.values.isPercentageGraph || false;
      const isGradient = data.data.values.gradient || false;
      const hasSingleCategory =
        Object.keys(data.data.values[type]).length === 1;
      const percentages = getPercentages(dataValues[type]);
      return {
        x: xLabels,
        y: dataValues[type],
        name: hasSingleCategory ? data?.name : category,
        text: percentages,
        textposition: "none",
        marker: {
          color:
            colors.length > 0
              ? colors[index % colors.length]
              : plotColors[index % plotColors.length],
        },
        hovertemplate:
          `<b>${hasSingleCategory ? data?.name : category}</b>: %{y:,${
            isPercentageGraph ? `.1f` : `.${precision}f`
          }}${isPercentageGraph ? "%" : ""}` +
          (!isPercentageGraph && !hasSingleCategory ? `(%{text:,.1f}%)` : "") +
          "<extra></extra>",
        fill: isGradient && "tonexty", // Fill between this trace and the previous one
        fillgradient: isGradient && {
          type: "vertical", // Linear gradient fill
          colorscale: [
            [0, customerPlotsColors?.gradient?.[index]?.start], // Top of the gradient (start color)
            [1, customerPlotsColors?.gradient?.[index]?.stop], // Bottom of the gradient (end color)
          ],
        },
        ...data?.data?.values?.data,
      };
    }
  );

  return (
    <>
      <TimePeriodButton
        type={type}
        setType={setType}
        title={data?.name}
        m={m}
        q={q}
        year={isYearVisible()}
        tooltip={tooltip}
        subText={subText ?? true}
      />
      <div
        style={{ width: "100%", height: "100%", position: "relative" }}
        className="mt-200"
      >
        <Plot
          data={plotData}
          layout={{
            xaxis: {
              dtick: calculateDtick(type, data.data.xLabels[type]?.length),
              tickangle: 0,
              tickfont: {
                family: "Karla, sans-serif",
              },
            },
            yaxis: {
              gridwidth: 1,
              tickfont: {
                family: "Karla, sans-serif",
                size: "14px",
              },
            },
            barcornerradius: 2,
            paper_bgcolor: "transparent",
            plot_bgcolor: "transparent",
            modebar: {
              activecolor: "#114fee",
              bgcolor: "transparent",
              color: "grey",
              orientation: "v",
            },
            margin: {
              l: 40,
              r: 60,
              t: 20,
              b: 70,
            },
            showlegend: false,
            legend: {
              orientation: "h",
              x: 0.1,
              traceorder: "normal",
              font: {
                family: "Karla, sans-serif",
                size: 8,
                color: "var(--Primary-Text-Color)",
              },
            },
            hovermode: "x unified",
            hoverlabel: {
              bordercolor: "transparent",
              borderRadius: "10px",
              bgcolor: "#FFF",
              font: {
                family: "Karla, sans-serif",
              },
            },
            ...data?.data?.values?.layout,
          }}
          config={{
            displaylogo: false,
            displayModeBar: true,
            modeBarButtonsToRemove: [
              "autoScale2d",
              "lasso2d",
              "select2d",
              "zoomIn2d",
              "zoomOut2d",
            ],
          }}
          useResizeHandler={true}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </>
  );
};
export default PlotTemplate;
