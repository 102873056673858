import React from "react";
import CustomEditable from "../../../../Common/components/custom/customEditable";
import SectionContainer from "../../../../Common/components/SectionContainer";
import {
  StyledFormLabelOptional,
  VisuallyHiddenInput,
  csvParser,
} from "../../../../Common/functions";
import CustomBlackBtn from "../../../../Common/components/custom/customBlackBtn";
import { Button } from "@mui/material";
import { MdOutlineFileDownload } from "react-icons/md";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const InvestmentDetails = ({
  rows,
  setRows,
  setKeyInvestmentRows,
  keyInvestmentRows,
}) => {
  const role = localStorage.getItem("role");

  const keyInvestmentColumns = [
    {
      Header: "Company",
      accessor: "company",
      placeholder: "Company Name",
    },
    {
      Header: "Industry",
      accessor: "industry",
      placeholder: "FinTech",
    },
    {
      Header: "Round",
      accessor: "round",
      placeholder: "Seed",
    },
    {
      Header: "Year",
      accessor: "year",
      placeholder: "2020",
    },
  ];

  if (role === "admin") {
    keyInvestmentColumns.push({
      Header: "Website",
      accessor: "website",
      placeholder: "https://example.com",
    });
  }

  const investmentDetailsColumns = [
    {
      Header: "Fund",
      accessor: "fund",
      placeholder: "Fund",
      editable: true,
    },
    {
      Header: "Vintage Year",
      accessor: "vintage",
      placeholder: "2020",
      type: "number",
    },
    {
      Header: "Investments",
      accessor: "investments",
      placeholder: "5",
      type: "number",
    },
    {
      Header: "AUM (Rs Cr.)",
      accessor: "aum",
      placeholder: "100",
      type: "number",
    },
    {
      Header: "Dry Powder",
      accessor: "dryPowder",
      placeholder: "100",
      type: "number",
    },
  ];

  return (
    <div className=" relative flex flex-col w-full gap-10">
      <div
        className="editCompanyDetails-form"
        style={{
          gridTemplateColumns: "none",
        }}
      >
        <div className="gap-150 flex flex-col">
          <StyledFormLabelOptional>Key Investments</StyledFormLabelOptional>
          <CustomEditable
            columns={keyInvestmentColumns}
            data={keyInvestmentRows}
            setData={setKeyInvestmentRows}
          />
          {role === "admin" && (
            <div className="gap-150 flex">
              <CustomBlackBtn
                text={
                  <a
                    href={
                      "https://fundrevstorage2.blob.core.windows.net/public-downloads/keyInvestments.csv"
                    }
                  >
                    <p className="gap-100 flex items-center">
                      <MdOutlineFileDownload fontSize={"20px"} />
                      Download sample file
                    </p>
                  </a>
                }
                variant="sm"
                border="sm"
              />
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                sx={{
                  width: "150px",
                }}
              >
                Upload CSV
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) =>
                    csvParser(
                      e.target.files[0],
                      keyInvestmentRows,
                      setKeyInvestmentRows
                    )
                  }
                  multiple
                />
              </Button>
            </div>
          )}
        </div>
        <div className="gap-150 flex flex-col">
          <StyledFormLabelOptional>Fund Investments</StyledFormLabelOptional>
          <CustomEditable
            columns={investmentDetailsColumns}
            data={rows}
            setData={setRows}
          />
          {role === "admin" && (
            <div className="gap-150 flex">
              <CustomBlackBtn
                text={
                  <a
                    href={
                      "https://fundrevstorage2.blob.core.windows.net/public-downloads/investmentDetails.csv"
                    }
                  >
                    <p className="gap-100 flex items-center">
                      <MdOutlineFileDownload fontSize={"20px"} />
                      Download sample file
                    </p>
                  </a>
                }
                variant="sm"
                border="sm"
              />
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                sx={{
                  width: "150px",
                }}
              >
                Upload CSV
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => csvParser(e.target.files[0], rows, setRows)}
                  multiple
                />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestmentDetails;
