import React from "react";
import {
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Button,
} from "@mui/material";
import { StyledFormLabel, StyledFormLabelOptional } from "../../functions";

const CustomCheckboxGroup = ({
  label,
  name,
  options,
  value,
  onChange,
  error,
  helperText,
  required = false,
}) => {
  const handleCheckboxChange = (event) => {
    const selectedValues = [...value];
    if (event.target.checked) {
      selectedValues.push(event.target.value);
    } else {
      const index = selectedValues.indexOf(event.target.value);
      if (index > -1) {
        selectedValues.splice(index, 1);
      }
    }
    onChange({
      target: {
        name: name,
        value: selectedValues,
      },
    });
  };

  const handleSelectAll = () => {
    const allValues = options.map((option) => option.value);
    onChange({
      target: {
        name: name,
        value: allValues,
      },
    });
  };

  const handleClearAll = () => {
    onChange({
      target: {
        name: name,
        value: [],
      },
    });
  };

  return (
    <FormControl error={error} component="fieldset" variant="standard">
      <div className="flex items-center justify-between">
        {required ? (
          <StyledFormLabel>{label}</StyledFormLabel>
        ) : (
          <StyledFormLabelOptional>{label}</StyledFormLabelOptional>
        )}

        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            size="small"
            onClick={handleSelectAll}
            className="hover:bg:blue-100 text-blue-500"
            sx={{
              textTransform: "none",
              backgroundColor: "#fff",
            }}
          >
            Select All
          </Button>
          <Button
            size="small"
            onClick={handleClearAll}
            className="hover:bg:blue-100 text-blue-500"
            sx={{
              textTransform: "none",
              backgroundColor: "#fff",
            }}
          >
            Clear
          </Button>
        </div>
      </div>
      <FormGroup className="!flex-row">
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            className="w-[132px]"
            control={
              <Checkbox
                name={name}
                value={option.value}
                checked={value.includes(option.value)}
                onChange={handleCheckboxChange}
                sx={{
                  "&.Mui-checked": {
                    color: "#4172F1",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                  },
                }}
              />
            }
            label={
              <p className="text-neutral-8 text-desktop-p-mb font-medium">
                {option.label}
              </p>
            }
          />
        ))}
      </FormGroup>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CustomCheckboxGroup;
