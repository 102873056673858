import React from "react";

const CardTags = ({ deal, page }) => {
  const parseAndRender = (parsedArray) => {
    try {
      return parsedArray?.map((field, index) => (
        <div
          key={index}
          className="cardTags-div"
          style={{
            color: deal?.dealType === "secondary" && "var(--brand-color)",
          }}
        >
          {field}
        </div>
      ));
    } catch (error) {
      console.error("Parsing error:", error);
      return null;
    }
  };
  //Removed round type as it is shown in progress bar
  return (
    <div className="cardTags-row-div">
      {/* {deal && deal?.roundType && parseAndRender([deal?.roundType])} */}
      {deal &&
        deal?.industryVerticals?.length > 0 &&
        parseAndRender(deal?.industryVerticals)}
      {deal &&
        deal?.subIndustryVerticals?.length > 0 &&
        parseAndRender(deal?.subIndustryVerticals)}
      {deal && deal?.endMarket?.length > 0 && parseAndRender(deal?.endMarket)}
      {deal && deal?.backing && parseAndRender([deal?.backing])}
    </div>
  );
};

export default CardTags;
