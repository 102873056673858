import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import fundrevLogo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO_WHITE.svg";
import whitelogout from "../../../Assets/Images/InvestorPage/Dashboard/whitelogout.svg";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
const SecondarySidebar = ({ active, dealId, deal }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/adminPanel", {
      state: {
        card: "secondaryDeals",
      },
    });
  };
  useEffect(() => {
    const activeTab = document.getElementById(active);
    if (activeTab) {
      activeTab.classList.add("activeTab");
    }
  }, [active]);
  return (
    <div className="opdiv">
      <img
        loading="lazy"
        src={fundrevLogo}
        className="opimg"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
        alt="logo"
      />
      <div className="sidebar-content">
        <div className="opdiv-2">
          <div className="normal-link">
            <div className="opdiv-3" id="editSecondaryDeal">
              <AdminPanelSettingsIcon className="opimg-2" />
              <div
                className="opdiv-4"
                style={{ display: "flex", justifyContent: "center" }}
                onClick={() =>
                  navigate("/admin/secondary/dealEdit", {
                    state: {
                      dealId: dealId,
                      startupId: dealId,
                    },
                  })
                }
              >
                Edit Secondary Deal
              </div>
            </div>
          </div>
          <div className="normal-link">
            <div className="opdiv-3" id="dealPreview">
              <AdminPanelSettingsIcon className="opimg-2" />
              <div
                className="opdiv-4"
                style={{ display: "flex", justifyContent: "center" }}
                onClick={() =>
                  navigate("/admin/secondary/profilePreview", {
                    state: {
                      dealId: dealId,
                      dealType: "secondary",
                      startupId: dealId,
                    },
                  })
                }
              >
                Deal Preview
              </div>
            </div>
          </div>
          <div className="normal-link">
            <div className="opdiv-3" id="dealAnalyticsMapper">
              <AdminPanelSettingsIcon className="opimg-2" />
              <div
                className="opdiv-4"
                style={{ display: "flex", justifyContent: "center" }}
                onClick={() =>
                  navigate("/admin/secondary/analyticsMapper", {
                    state: {
                      dealId: dealId,
                      dealType: "secondary",
                      startupId: dealId,
                    },
                  })
                }
              >
                Analytics Mapper
              </div>
            </div>
          </div>
          <div className="normal-link">
            <div className="opdiv-3" id="dealDataroom">
              <AdminPanelSettingsIcon className="opimg-2" />
              <div
                className="opdiv-4"
                style={{ display: "flex", justifyContent: "center" }}
                onClick={() =>
                  navigate("/admin/secondary/dataroomPrep", {
                    state: {
                      dealId: dealId,
                      dealType: "secondary",
                      startupId: dealId,
                    },
                  })
                }
              >
                Data Room Prep
              </div>
            </div>
          </div>
          <div className="normal-link">
            <div className="opdiv-3" id="dealRequests">
              <AdminPanelSettingsIcon className="opimg-2" />
              <div
                className="opdiv-4"
                style={{ display: "flex", justifyContent: "center" }}
                onClick={() =>
                  navigate("/admin/secondary/dealRequests", {
                    state: {
                      dealId: dealId,
                      startupId: dealId,
                    },
                  })
                }
              >
                Deal Requests
              </div>
            </div>
          </div>
          <div className="normal-link">
            <div className="opdiv-3" id="recommendInvestors">
              <AdminPanelSettingsIcon className="opimg-2" />
              <div
                className="opdiv-4"
                style={{ display: "flex", justifyContent: "center" }}
                onClick={() =>
                  navigate("/admin/secondary/recommendInvestors", {
                    state: {
                      dealId: dealId,
                      startupId: dealId,
                    },
                  })
                }
              >
                Recommend Investors
              </div>
            </div>
          </div>
        </div>

        <div className="opdiv-15" onClick={handleLogout}>
          <img
            loading="lazy"
            src={whitelogout}
            className="opimg-8"
            alt="logout"
          />
          <div className="opdiv-4">Admin Panel</div>
        </div>
      </div>
    </div>
  );
};

export default SecondarySidebar;
