import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { uploadCustomerTape } from "../../../../endpoints/admin";
import Toast from "../../../Common/Toast";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const UploadCustomerTape = ({ startupId }) => {
  const token = localStorage.getItem("token");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!file) return;
    setLoading(true);
    // Simulate file upload process

    const formData = new FormData();
    formData.append("startupId", startupId);
    formData.append("customerTape", file);
    try {
      const response = await uploadCustomerTape(formData, token);
      setLoading(false);
      handleClose();
      response.data &&
        Toast(
          "Customer tape uploaded successfully",
          "success",
          "analyticsMapper"
        );
    } catch (error) {
      Toast("Error in uploading file.", "error", "analyticsMapper");
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<CloudUpload />}
        onClick={handleOpen}
      >
        Upload
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Upload customer tape excel
          </Typography>
          <Input
            type="file"
            inputProps={{ accept: ".xlsx" }}
            onChange={handleFileChange}
          />
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!file}
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default UploadCustomerTape;
