import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import {
  getAllInvestors,
  toggleInvestorModules,
} from "../../endpoints/investor";
import { useNavigate } from "react-router-dom";
import {
  setToggleApprovalInvestor,
  generateInvestorLoginToken,
} from "../../endpoints/admin";
import CustomModal from "../Common/components/custom/customModal";
import CustomBlackBtn from "../Common/components/custom/customBlackBtn";
import { Switch } from "@mui/material";
import Toast from "../Common/Toast";

const Investors = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [investors, setInvestors] = useState([]);
  const token = localStorage.getItem("token");
  const [selectedInvestorID, setSelectedInvestorID] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [investorLoginModal, setInvestorLoginModal] = useState(false);
  const fetchAllInvestors = async () => {
    try {
      const response = await getAllInvestors(token);
      setInvestors(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchAllInvestors();
  }, []);

  const handleToggle = async (investorId, module) => {
    const response = await toggleInvestorModules(investorId, module, token);
    if (response?.data) {
      Toast(`Module updated successfully!`, `success`, `investorPanel`);
      fetchAllInvestors();
    } else {
      Toast("Error updating module", "error", "investorPanel");
    }
  };

  const toggleApproval = async () => {
    try {
      const response = await setToggleApprovalInvestor(id, token);
      fetchAllInvestors();
      setId("");
      setModalOpen(false);
    } catch (error) {
      setId("");
      setModalOpen(false);
      Toast("Error in approving investor", "error", "investorPanel");
      console.error("Error toggling approval:", error);
    }
  };
  const investorLogin = async () => {
    try {
      const response = await generateInvestorLoginToken(
        selectedInvestorID,
        token
      );
      if (response.data) {
        const token = response.data.token;
        localStorage.setItem("token", token);
        localStorage.setItem("role", "investor");
        navigate("/investor/newDeals");
      } else {
        Toast("Error during login", "error", "adminPanel");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="admin-panel-investors">
        <ToastContainer position="top-center" containerId="investorPanel" />
        <CustomModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          title="Waitlist Approval"
          description="This will approve investor from waitlist"
          primaryAction={toggleApproval}
          secondaryAction={() => setModalOpen(false)}
          customStyles={{ width: "500px", height: "fit-content" }}
        />
        <CustomModal
          open={investorLoginModal}
          onClose={() => setInvestorLoginModal(false)}
          title="Admin company login"
          description={`Do you want to login as ${selectedCompanyName}`}
          primaryAction={investorLogin}
          secondaryAction={() => setInvestorLoginModal(false)}
          customStyles={{ width: "500px", height: "fit-content" }}
        />
        <div className="InvestorSide-box table-container">
          <table>
            <thead>
              <tr>
                <th>Investor Firm Name</th>
                <th>Investor ID</th>
                <th>Profile Completed</th>
                <th>Preview</th>
                <th>Approve</th>
                <th>Investor Login</th>
                <th>Primary</th>
                <th>Secondary</th>
              </tr>
            </thead>
            <tbody>
              {investors?.map((investor) => (
                <tr key={investor.investorId}>
                  <td>{investor.companyName}</td>

                  <td>{investor.investorID}</td>
                  <td>
                    {investor.profileCompleted ? "Completed" : "Not Completed"}
                  </td>
                  <td>
                    <button
                      className="toggleStatusbtn"
                      onClick={() =>
                        navigate("/admin/investor/preview", {
                          state: {
                            investor,
                            state: "investors",
                          },
                        })
                      }
                    >
                      Preview
                    </button>
                  </td>
                  <td>
                    <button
                      className={`toggleStatusbtn ${
                        investor.isApplicationReviewed
                          ? "Approved-btn"
                          : "Pending-btn"
                      }`}
                      onClick={() => {
                        setId(investor._id);
                        setModalOpen(true);
                      }}
                    >
                      {investor.isApplicationReviewed ? "Approved" : "Pending"}
                    </button>
                  </td>
                  <td>
                    <CustomBlackBtn
                      text="Login"
                      variant="sm"
                      border="sm"
                      mode="blue"
                      onClick={() => {
                        setSelectedInvestorID(investor.investorID);
                        setSelectedCompanyName(investor.companyName);
                        setInvestorLoginModal(true);
                      }}
                    />
                  </td>
                  <td>
                    <Switch
                      checked={investor?.modules?.primary}
                      onChange={(e) =>
                        handleToggle(investor?.investorID, "primary")
                      }
                    />
                  </td>
                  <td>
                    <Switch
                      checked={investor?.modules?.secondary}
                      onChange={(e) =>
                        handleToggle(investor?.investorID, "secondary")
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Investors;
