import React, { useState, useEffect } from "react";
import {
  StyledFormControl,
  StyledFormLabelOptional,
  industryVerticalsOptions,
  endMarketFocusOptions,
  backingOptions,
  dealTypeOptions,
  roundTypeOptions,
  preferredInvestorsOptions,
  equityTypeOptions,
  pricedEquityOptions,
  nonPricedEquityOptions,
  debtTypeOptions,
  getFilteredSubIndustryOptions,
  updateSubIndustryVerticals,
  csvParser,
  VisuallyHiddenInput,
} from "../../../Common/functions";
import Toast from "../../../Common/Toast";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import CustomInput from "../../../Common/components/custom/customInput";
import CustomSelect from "../../../Common/components/custom/customSelect";
import CustomDateInput from "../../../Common/components/custom/customDateInput";
import CustomModal from "../../../Common/components/custom/customModal";
import { isProfileComplete } from "../../../Founders/Dashboard/editProfile.jsx/profileCompletion";
import {
  getDealDetailsByDealId,
  updateDeal,
  sendForReview,
} from "../../../../endpoints/deal";
import { PostDetails } from "../../../../endpoints/startup";
import { getStartupDetails } from "../../../../endpoints/admin";
import { addCompanyAdmin } from "../../../../endpoints/fundrevAnalyst";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormControl, FormLabel, Input } from "@mui/joy";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import dayjs from "dayjs"; // Import dayjs for date handling
import { randomId } from "@mui/x-data-grid-generator";

import editImg from "../../../../Assets/Images/signup/edit-img.svg";
import { AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import uploadImg from "../../../../Assets/Images/signup/upload-img.svg";

import "../../../../CSS/common/setInnerHTML.css";
import TextEditor from "../../../Common/components/custom/textEditor";
import CustomEditableTable from "../../../Common/components/custom/customEditable";
import { MdOutlineFileDownload } from "react-icons/md";

const DealDetailsForm = () => {
  const location = useLocation();
  const { dealId, startupId } = location.state || {};
  const [fileUrl, setFileUrl] = useState();
  const [companyLogoFile, setCompanyLogoFile] = useState("");
  const token = localStorage.getItem("token");
  const [newtag, setNewTag] = useState();
  const [isApplicationReviewed, setIsApplicationReviewed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [field, setField] = useState("");
  const [secondField, setSecondField] = useState("");
  const [sendForReviewModal, setSendForReviewModal] = useState(false);
  const [inviteAdminModal, setInviteAdminModal] = useState(false);
  const [onBoardingComplete, setOnBoardingComplete] = useState(false);
  const [foundingMember, setFoundingMember] = useState([]);
  const [companyProfileCompleted, setCompanyProfileCompleted] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [previousRounds, setPreviousRounds] = useState([]);
  const [initialValues, setInitialValues] = useState({
    dealId: dealId || "",
    organizationId: startupId || "",
    companyLogo: "",
    companyName: "",
    website: "",
    headquarters: "",
    description: "",
    legalEntityName: "",
    foundedYear: "",
    numEmployees: "",
    purposeOfFundraising: "",
    keyInvestors: "",
    cin: "",
    taxNumber: "",
    endMarket: [],
    backing: "",
    industryVerticals: [],
    subIndustryVerticals: [],
    noNameTitle: "",
    noNameSubHeading: "",
    noNameDescription: "",
    noNamePurposeOfFundraising: "",
    keyHighlights: "",
    investmentHighlights: "",
    dealDetails: "",
    financialSubHeading: "",
    techstack: "",
    keyMetrics: {},
    financialMetrics: {},
    dealTags: [],
    erpPlatform: "",
    dealType: "",
    timeline: "",
    fundingRequired: "",
    debtFundingRequired: "",
    roundType: "",
    debtType: "",
    term: "",
    preferredInvestors: [],
    equityType: "",
    pricedEquityType: "",
    stakeOffered: "",
    minPostMoneyValuation: "",
    nonPricedEquityType: "",
    interestRate: "",
    valuationCap: "",
    discountRate: "",

    softCommitments: false,
    fundingRaised: "",
    debtRaised: "",
    coInvestors: "",
    contactedInvestorNames: "",
    videoPitch: "",
    dataAvailabilityWithFundrev: {
      analytics: false,
      financial: false,
      dataroom: false,
      forecastModel: false,
      managementPresentation: false,
    },
    previousRounds: [
      {
        id: randomId(),
        date: "",
        round: "",
        raised: "",
        stake: "",
        valuation: "",
      },
    ],
  });
  const columns = [
    {
      Header: "Date",
      accessor: "date",
      placeholder: "Enter Date",
      type: "date",
    },
    {
      Header: "Round",
      accessor: "round",
      placeholder: "Select Round",
      type: "text",
    },
    {
      Header: "Raised",
      accessor: "raised",
      placeholder: "Enter Raised",
      type: "text",
    },
    {
      Header: "% Stake",
      accessor: "stake",
      placeholder: "Enter Stake",
      type: "text",
    },
    {
      Header: "% Valuation",
      accessor: "valuation",
      placeholder: "Enter Valuation",
      type: "text",
    },
  ];

  const validationSchema = Yup.object().shape();
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Standard message (some browsers may not display the custom message)
      const message =
        "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // Required for some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleKeyDown = (event, type, values, setFieldValue) => {
    if (event.key === "Enter") {
      addFieldToMetrics(type, values, setFieldValue);
    }
  };

  function handleImageUpload(e) {
    const file = e.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      const fileURL = URL.createObjectURL(file);
      setFileUrl(fileURL);
      setCompanyLogoFile(file);
    } else {
      Toast(
        "Please select an image file (PNG or JPG format only).",
        "info",
        "dealDetailsEdit"
      );
    }
  }
  async function fetchDealDetails() {
    try {
      const response = await getStartupDetails(startupId, token);
      const data = response?.data || false;
      if (data) {
        setInitialValues((prev) => ({
          ...prev,
          companyLogo: data?.companyLogo,
          companyName: data?.companyName,
          website: data?.website,
          headquarters: data?.headquarters,
          description: data?.description,
          legalEntityName: data?.legalEntityName,
          foundedYear: data?.foundedYear,
          numEmployees: data?.numEmployees,
          keyInvestors: data?.keyInvestors,
          cin: data?.cin,
          taxNumber: data?.taxNumber,
          endMarket: data?.endMarket,
          backing: data?.backing,
          industryVerticals: data?.industryVerticals,
          subIndustryVerticals: data?.subIndustryVerticals,
          erpPlatform: data?.erpPlatform,
          techstack: data?.techstack,
          previousRounds: data?.previousRounds,
        }));
        setPreviousRounds(data?.previousRounds);
        setFoundingMember(data?.foundingMember);
        setOnBoardingComplete(data?.onBoardingComplete);
        setCompanyProfileCompleted(data?.profileCompleted);
        setIsApproved(data?.isApproved);
      }
    } catch (error) {
      Toast(`Failed to fetch startup details`, `error`, `dealDetailsEdit`);
    }
    if (dealId) {
      try {
        const response = await getDealDetailsByDealId(dealId, token);
        const data = response.data || false;
        if (data) {
          setInitialValues((prev) => ({
            ...prev,
            dealType: data?.dealType,
            timeline: data?.timeline,
            fundingRequired: data?.fundingRequired,
            debtFundingRequired: data?.debtFundingRequired,
            roundType: data?.roundType,
            debtType: data?.debtType,
            term: data?.term,
            preferredInvestors: data?.preferredInvestors,
            equityType: data?.equityType,
            pricedEquityType: data?.pricedEquityType,
            stakeOffered: data?.stakeOffered,
            minPostMoneyValuation: data?.minPostMoneyValuation,
            nonPricedEquityType: data?.nonPricedEquityType,
            interestRate: data?.interestRate,
            valuationCap: data?.valuationCap,
            discountRate: data?.discountRate,
            purposeOfFundraising: data?.purposeOfFundraising,
            softCommitments: data?.softCommitments,
            fundingRaised: data?.fundingRaised,
            debtRaised: data?.debtRaised,
            coInvestors: data?.coInvestors,
            contactedInvestorNames: data?.contactedInvestorNames,
            videoPitch: data?.videoPitch,
            dataAvailabilityWithFundrev: data?.dataAvailabilityWithFundrev,
            noNameTitle: data?.noNameTitle,
            noNameSubHeading: data?.noNameSubHeading,
            noNameDescription: data?.noNameDescription,
            noNamePurposeOfFundraising: data?.noNamePurposeOfFundraising,
            keyHighlights: data?.keyHighlights,
            investmentHighlights: data?.investmentHighlights,
            dealDetails: data?.dealDetails,
            financialSubHeading: data?.financialSubHeading,
            dealTags: data?.dealTags,
            financialMetrics: data?.financialMetrics,
            keyMetrics: data?.keyMetrics,
          }));
        }
        setIsApplicationReviewed(response.data?.isApplicationReviewed);
        setIsOpen(response.data?.isOpen);
      } catch (err) {
        Toast("Failed to fetch deal details", "error", "dealDetailsEdit");
      }
    }
  }
  useEffect(() => {
    if (token) {
      fetchDealDetails();
    }
  }, [dealId]);

  const handleDealTagsChange = (values, setFieldValue) => {
    const tags = values.dealTags || [];
    tags.push(newtag);
    setFieldValue(`dealTags`, tags);
    setNewTag("");
  };
  const deleteDealTag = (indexToDelete, values, setFieldValue) => {
    const updatedDealTags = values.dealTags.filter(
      (tag, index) => index !== indexToDelete
    );
    setFieldValue(`dealTags`, updatedDealTags);
    return {
      dealTags: updatedDealTags,
    };
  };

  const handleSave = async (values) => {
    const formDataInput = new FormData();
    formDataInput.append(`startupId`, startupId || values.organizationId);
    const isProfileCompleted = companyProfileCompleted
      ? companyProfileCompleted
      : isProfileComplete(values, foundingMember);
    values.previousRounds = previousRounds;
    for (const key in values) {
      if (values.hasOwnProperty(key) && values[key] !== undefined) {
        if (typeof values[key] === "object" && !(values[key] instanceof File)) {
          formDataInput.append(key, JSON.stringify(values[key]));
        } else {
          formDataInput.append(key, values[key]);
        }
      }
    }
    formDataInput.append("profileCompleted", isProfileCompleted);
    if (companyLogoFile) {
      formDataInput.append("companyLogo", companyLogoFile);
    }
    try {
      formDataInput.append(`isApproved`, isApproved);
      formDataInput.append(`isApplicationReviewed`, isApplicationReviewed);
      formDataInput.append(`onBoardingComplete`, onBoardingComplete);
      const response = await PostDetails(formDataInput, token);
      if (response.data) {
        Toast(
          "Startup details successfully updated",
          "success",
          "dealDetailsEdit"
        );
      } else {
        Toast("Failed to save startup data", "error", "dealDetailsEdit");
      }
    } catch (error) {
      console.log(error);
      Toast("Failed to save startup data", "error", "dealDetailsEdit");
    }
    try {
      values.isApplicationReviewed = isApplicationReviewed;
      const response = await updateDeal(values, token);
      //Necessary as otherwise it will get added twice.
      delete values.isApplicationReviewed;
      if (response.data) {
        Toast("Deal Data saved successfully", "success", "dealDetailsEdit");
      } else {
        Toast("Failed to save the deal data", "error", "dealDetailsEdit");
      }
    } catch (err) {
      Toast("Failed to save the deal data", "error", "dealDetailsEdit");
    }
  };

  const addFieldToMetrics = (type, values, setFieldValue) => {
    const inputField =
      type === "keyMetrics" ? field.trim() : secondField.trim();

    if (inputField) {
      setFieldValue(type, { ...values[type], [inputField]: "" });
      setField("");
      setSecondField("");
    }
  };
  const deleteMetric = (type, key, values, setFieldValue) => {
    const metric = values[type];
    delete metric[key];
    setFieldValue(type, metric);
  };
  const sendEmail = async () => {
    try {
      const data = {
        startupId: startupId,
        dealId: dealId,
      };
      const response = await sendForReview(data, token);
      if (response.data.message === "Sent for review") {
        Toast(
          "Data sent for review successfully",
          "success",
          "dealDetailsEdit"
        );
        setIsApplicationReviewed(false);
        setSendForReviewModal(false);
        fetchDealDetails();
      } else {
        Toast("Failed to send data for review", "error", "dealDetailsEdit");
      }
    } catch (err) {
      Toast("Failed to send data for review", "error", "dealDetailsEdit");
    }
  };
  const renderMetricsRows = (values, type, groupSize, setFieldValue) => {
    const metrics = Object.entries(values?.[type] || {});
    const rows = [];
    for (let i = 0; i < metrics.length; i += groupSize) {
      const group = metrics.slice(i, i + groupSize);
      const emptyFieldsCount = groupSize - group.length;

      rows.push(
        <div key={`${type}-row-${i}`} className="input-div">
          {group.map(([key, value], index) => (
            <FormControl
              key={`${type}-input-${index}`}
              className="form-control investorDetails-row-input"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">
                {key}
                <RiDeleteBin6Line
                  className="red-bin"
                  onClick={() => {
                    deleteMetric(type, key, values, setFieldValue);
                  }}
                />
              </FormLabel>

              <Input
                className="input"
                placeholder={`Add value of ${key}`}
                color="neutral"
                name={key}
                type="text"
                value={value}
                onChange={(event) =>
                  setFieldValue(`${type}.${key}`, event.target.value)
                }
                required
              />
            </FormControl>
          ))}
          {Array.from({ length: emptyFieldsCount }).map((_, index) => (
            <FormControl
              key={`${type}-empty-${index}`}
              className="form-control investorDetails-row-input random-input"
            >
              <Input style={{ visibility: "hidden" }} className="input" />
            </FormControl>
          ))}
        </div>
      );
    }

    return rows;
  };
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    startupId: startupId,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const inviteCompanyAdmin = async () => {
    try {
      const response = await addCompanyAdmin(formData, token);
      if (response.data.data) {
        Toast(
          `Successfully sent mail to ${formData.email}`,
          "success",
          "dealDetailsEdit"
        );
        setInviteAdminModal(false);
        setFormData((prev) => ({
          ...prev,
          email: "",
          name: "",
        }));
      } else {
        Toast(
          `Failed to send mail to ${formData.email}`,
          "error",
          "dealDetailsEdit"
        );
      }
    } catch (error) {
      Toast(
        `Failed to send mail to ${formData.email}`,
        "error",
        "dealDetailsEdit"
      );
    }
  };
  return (
    <div
      className="investorDetails dealDetailsEdit"
      style={{
        margin: "0px 0px 0px 0px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 20px)",
      }}
    >
      <ToastContainer position="top-center" containerId="dealDetailsEdit" />
      <CustomModal
        open={sendForReviewModal}
        onClose={() => setSendForReviewModal(false)}
        title="Send for review"
        description="Are you sure you want to send the profile for review? This action can't be reversed."
        primaryAction={sendEmail}
        secondaryAction={() => setSendForReviewModal(false)}
        customStyles={{ width: "500px", height: "fit-content" }}
      />
      <CustomModal
        open={inviteAdminModal}
        onClose={() => setInviteAdminModal(false)}
        title="Invite Company Admin"
        description="Enter details for the company admin"
        primaryAction={inviteCompanyAdmin}
        secondaryAction={() => setInviteAdminModal(false)}
        customStyles={{ width: "500px", height: "fit-content" }}
        showPrimaryOnly
      >
        <form className="flex flex-col items-center w-full gap-4">
          <StyledFormControl fullWidth>
            <StyledFormLabelOptional>Enter name</StyledFormLabelOptional>
            <CustomInput
              placeholder={`Enter Name`}
              name={`name`}
              value={formData.name}
              onChange={handleInputChange}
              variant="outlined"
            />
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledFormLabelOptional>Enter Email</StyledFormLabelOptional>
            <CustomInput
              placeholder={`Enter email`}
              name={`email`}
              value={formData.email}
              onChange={handleInputChange}
              variant="outlined"
            />
          </StyledFormControl>
        </form>
      </CustomModal>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
          enableReinitialize
        >
          {({
            handleBlur,
            values,
            setFieldValue,
            handleChange: formikHandleChange,
          }) => {
            const handleChange = (e) => {
              formikHandleChange(e);
              //* This function will only allow sub industryVerticals which are under industryVerticals field
              //* It removes the sub industry Verticals which are not in selected industry verticals.
              //* It will be triggered when there is change in industry verticals
              const { name, value } = e.target;
              if (name === "industryVerticals") {
                updateSubIndustryVerticals(value, setFieldValue, values);
              }
            };
            return (
              <Form>
                <div className="editCompanyDetails fundrev-card ">
                  <div className="add-member-div">
                    <div>
                      <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] ">
                        Company Details - (From StartupDB)
                      </p>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div className="fundrev-chip blue-chip">
                        Org ID: {values.organizationId}{" "}
                      </div>
                      <div className="fundrev-chip blue-chip">
                        Deal ID: {values.dealId}{" "}
                      </div>
                      <div
                        className={`fundrev-chip ${
                          isProfileComplete(values, foundingMember)
                            ? "success-chip"
                            : "pending-chip"
                        }`}
                      >
                        {isProfileComplete(values, foundingMember)
                          ? "Profile Completed"
                          : "Pending Profile"}
                      </div>
                    </div>
                  </div>
                  <div class="upload-btn-wrapper">
                    <button class="upload-img-btn">
                      <img
                        src={
                          !companyLogoFile
                            ? values.companyLogo
                              ? values.companyLogo
                              : uploadImg
                            : fileUrl
                        }
                        className="InvestorLogo"
                        alt="Upload"
                        style={{ cursor: "pointer" }}
                      />
                    </button>

                    <label htmlFor="company-logo" className="edit-img-btn">
                      <img src={editImg} className="editImg" alt="Edit" />
                    </label>

                    <input
                      id="company-logo"
                      type="file"
                      name="image"
                      accept="image/png, image/jpeg"
                      style={{ cursor: "pointer" }}
                      onChange={(e) => handleImageUpload(e)}
                    />
                  </div>
                  <div className="company-details-input-fields">
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Company Name
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Company Name`}
                        name={`companyName`}
                        value={values.companyName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Legal Entity Name
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Legal Entity Name`}
                        name={`legalEntityName`}
                        value={values.legalEntityName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Company Website
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter website`}
                        name={`website`}
                        value={values.website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>CIN</StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter CIN`}
                        name={`cin`}
                        value={values.cin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Headquarters
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter headquarters`}
                        name={`headquarters`}
                        value={values.headquarters}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Founded Year
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Founded Year`}
                        name={`foundedYear`}
                        type="number"
                        value={values.foundedYear}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Number of Employees
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Number of Employees`}
                        name={`numEmployees`}
                        value={values.numEmployees}
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Tax Number
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Tax Number`}
                        name={`taxNumber`}
                        value={values.taxNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Tech Stack
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Tech Stack`}
                        name={`techstack`}
                        value={values.techstack}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        ERP Platform
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter ERP Platform`}
                        name={`erpPlatform`}
                        value={values.erpPlatform}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Industry Verticals
                      </StyledFormLabelOptional>
                      <CustomSelect
                        label="Industry Verticals"
                        name="industryVerticals"
                        multiple={true}
                        value={values.industryVerticals}
                        onChange={handleChange}
                        options={industryVerticalsOptions}
                        placeholder="Select Industry Verticals"
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Sub Industry Verticals
                      </StyledFormLabelOptional>
                      <CustomSelect
                        label="Sub industry Verticals"
                        name="subIndustryVerticals"
                        multiple={true}
                        value={values.subIndustryVerticals}
                        onChange={handleChange}
                        options={getFilteredSubIndustryOptions(values)}
                        placeholder="Select sub Industry Verticals"
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        End Market Focus
                      </StyledFormLabelOptional>
                      <CustomSelect
                        label="End Market Focus"
                        name="endMarket"
                        multiple={true}
                        value={values.endMarket}
                        onChange={handleChange}
                        options={endMarketFocusOptions}
                        placeholder="Select Industry Verticals"
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>Backing</StyledFormLabelOptional>
                      <CustomSelect
                        label="Backing"
                        name="backing"
                        value={values.backing}
                        onChange={handleChange}
                        options={backingOptions}
                        placeholder="Select Backing"
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Key Investors
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder={`Enter Key Investors`}
                        name={`keyInvestors`}
                        value={values.keyInvestors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth></StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        Company Description
                      </StyledFormLabelOptional>
                      <TextEditor
                        value={values.description}
                        setValue={(content) =>
                          setFieldValue("description", content)
                        }
                        style={{
                          marginBottom: "40px",
                        }}
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        Previous Rounds
                      </StyledFormLabelOptional>

                      <CustomEditableTable
                        columns={columns}
                        data={previousRounds}
                        setData={setPreviousRounds}
                      />
                      <div className="gap-150 flex">
                        <CustomBlackBtn
                          text={
                            <a
                              href={
                                "https://fundrevstorage2.blob.core.windows.net/public-downloads/previousRounds.csv"
                              }
                            >
                              <p className="gap-100 flex items-center">
                                <MdOutlineFileDownload fontSize={"20px"} />
                                Download sample file
                              </p>
                            </a>
                          }
                          variant="sm"
                          border="sm"
                        />
                        <Button
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startIcon={<CloudUploadIcon />}
                          sx={{
                            width: "150px",
                          }}
                        >
                          Upload CSV
                          <VisuallyHiddenInput
                            type="file"
                            onChange={(e) =>
                              csvParser(
                                e.target.files[0],
                                previousRounds,
                                setPreviousRounds
                              )
                            }
                            multiple
                          />
                        </Button>
                      </div>
                    </StyledFormControl>
                  </div>
                </div>
                <div className="editCompanyDetails fundrev-card">
                  <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] mb-4">
                    Deal Details - (From DealDB)
                  </p>
                  <div className="company-details-input-fields">
                    <StyledFormControl>
                      <StyledFormLabelOptional>
                        Deal Type
                      </StyledFormLabelOptional>
                      <CustomSelect
                        label="Deal Type"
                        name="dealType"
                        value={values.dealType}
                        onChange={handleChange}
                        options={dealTypeOptions}
                        placeholder="Select Deal Type"
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Timeline
                      </StyledFormLabelOptional>
                      <CustomDateInput
                        label="Select timeline"
                        value={values.timeline}
                        onChange={(newValue) =>
                          setFieldValue("timeline", newValue)
                        }
                        minDate={dayjs()}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Funding Required (Cr.)
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`fundingRequired`}
                        placeholder={`Enter funding required`}
                        onChange={handleChange}
                        value={values.fundingRequired}
                        onBlur={handleBlur}
                        type="number"
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Debt Required (Cr.)
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`debtFundingRequired`}
                        onChange={handleChange}
                        placeholder={`Enter debt required`}
                        value={values.debtFundingRequired}
                        onBlur={handleBlur}
                        type="number"
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Round Type
                      </StyledFormLabelOptional>
                      <CustomSelect
                        name={`roundType`}
                        onChange={handleChange}
                        value={values.roundType}
                        onBlur={handleBlur}
                        options={roundTypeOptions}
                        placeholder={`Select Round Type`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Debt Type
                      </StyledFormLabelOptional>
                      <CustomSelect
                        name={`debtType`}
                        onChange={handleChange}
                        value={values.debtType}
                        onBlur={handleBlur}
                        options={debtTypeOptions}
                        placeholder={`Select Debt Type`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>Term</StyledFormLabelOptional>
                      <CustomInput
                        name={`term`}
                        placeholder={`Enter Term`}
                        onChange={handleChange}
                        value={values.term}
                        onBlur={handleBlur}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Preferred Investors
                      </StyledFormLabelOptional>
                      <CustomSelect
                        name={`preferredInvestors`}
                        onChange={handleChange}
                        value={values.preferredInvestors}
                        onBlur={handleBlur}
                        options={preferredInvestorsOptions}
                        multiple={true}
                        placeholder={`Select Preferred Investors`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Equity Type
                      </StyledFormLabelOptional>
                      <CustomSelect
                        name={`equityType`}
                        onChange={handleChange}
                        value={values.equityType}
                        onBlur={handleBlur}
                        options={equityTypeOptions}
                        placeholder={`Select Equity Type`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Priced Equity Type
                      </StyledFormLabelOptional>
                      <CustomSelect
                        name={`pricedEquityType`}
                        onChange={handleChange}
                        value={values.pricedEquityType}
                        onBlur={handleBlur}
                        options={pricedEquityOptions}
                        placeholder={`Select Priced Equity Type`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Stake Offered (%)
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`stakeOffered`}
                        onChange={handleChange}
                        value={values.stakeOffered}
                        onBlur={handleBlur}
                        type="number"
                        placeholder={`Enter Stake Offered`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Min. Post Money Valuation
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`minPostMoneyValuation`}
                        onChange={handleChange}
                        value={values.minPostMoneyValuation}
                        onBlur={handleBlur}
                        type="number"
                        placeholder={`Enter Min. Post Money Valuation`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Non Priced Equity Type
                      </StyledFormLabelOptional>
                      <CustomSelect
                        name={`nonPricedEquityType`}
                        onChange={handleChange}
                        value={values.nonPricedEquityType}
                        onBlur={handleBlur}
                        options={nonPricedEquityOptions}
                        placeholder={`Select Non Priced Equity Type`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Interest Rate
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`interestRate`}
                        onChange={handleChange}
                        value={values.interestRate}
                        onBlur={handleBlur}
                        type="number"
                        placeholder={`Enter Interest Rate`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Discount Rate
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`discountRate`}
                        onChange={handleChange}
                        value={values.discountRate}
                        onBlur={handleBlur}
                        type="number"
                        placeholder={`Enter Discount Rate`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Valuation Cap
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`valuationCap`}
                        onChange={handleChange}
                        value={values.valuationCap}
                        onBlur={handleBlur}
                        type="number"
                        placeholder={`Enter Valuation Cap`}
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        Purpose of Fundraising
                      </StyledFormLabelOptional>
                      <TextEditor
                        value={values.purposeOfFundraising}
                        setValue={(content) =>
                          setFieldValue("purposeOfFundraising", content)
                        }
                        style={{
                          marginBottom: "40px",
                        }}
                      />
                    </StyledFormControl>

                    <StyledFormControl component="fieldset">
                      <StyledFormLabelOptional component="legend">
                        Do you have soft commitments?
                      </StyledFormLabelOptional>
                      <div className="flex gap-[24px] mt-[16px]">
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="softCommitments"
                            checked={values.softCommitments === true}
                            onChange={() =>
                              setFieldValue("softCommitments", true)
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          Yes
                        </label>
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="softCommitments"
                            checked={values.softCommitments === false}
                            onChange={() =>
                              setFieldValue("softCommitments", false)
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          No
                        </label>
                      </div>
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Funding Raised
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`fundingRaised`}
                        onChange={handleChange}
                        value={values.fundingRaised}
                        onBlur={handleBlur}
                        placeholder={`Enter Funding Raised`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Debt Raised
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`debtRaised`}
                        onChange={handleChange}
                        value={values.debtRaised}
                        onBlur={handleBlur}
                        placeholder={`Enter Debt Raised`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Co Investors
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`coInvestors`}
                        onChange={handleChange}
                        value={values.coInvestors}
                        onBlur={handleBlur}
                        placeholder={`Enter Co Investors`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Contacted Investor Names
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`contactedInvestorNames`}
                        onChange={handleChange}
                        value={values.contactedInvestorNames}
                        onBlur={handleBlur}
                        placeholder={`Enter Contacted Investor Names`}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Video Pitch
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`videoPitch`}
                        onChange={handleChange}
                        value={values.videoPitch}
                        onBlur={handleBlur}
                        placeholder={`Enter Video Pitch`}
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        No Name Title
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`noNameTitle`}
                        onChange={handleChange}
                        value={values.noNameTitle}
                        onBlur={handleBlur}
                        placeholder={`Enter No Name Title`}
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        No Name SubHeading
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`noNameSubHeading`}
                        onChange={handleChange}
                        value={values.noNameSubHeading}
                        onBlur={handleBlur}
                        placeholder={`Enter No Name Sub Heading`}
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        Key Highlights
                      </StyledFormLabelOptional>
                      <TextEditor
                        value={values.keyHighlights}
                        setValue={(content) =>
                          setFieldValue("keyHighlights", content)
                        }
                        style={{
                          marginBottom: "40px",
                        }}
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        Investment Highlights
                      </StyledFormLabelOptional>
                      <TextEditor
                        value={values.investmentHighlights}
                        setValue={(content) =>
                          setFieldValue("investmentHighlights", content)
                        }
                        style={{
                          marginBottom: "40px",
                        }}
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        Deal Details
                      </StyledFormLabelOptional>
                      <TextEditor
                        value={values.dealDetails}
                        setValue={(content) =>
                          setFieldValue("dealDetails", content)
                        }
                        style={{
                          marginBottom: "40px",
                        }}
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        No Name Description
                      </StyledFormLabelOptional>
                      <TextEditor
                        value={values.noNameDescription}
                        setValue={(content) =>
                          setFieldValue("noNameDescription", content)
                        }
                        style={{
                          marginBottom: "40px",
                        }}
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      fullWidth
                      sx={{
                        gridColumn: "1/-1",
                      }}
                    >
                      <StyledFormLabelOptional>
                        No Name Purpose Of Fundraising
                      </StyledFormLabelOptional>
                      <TextEditor
                        value={values.noNamePurposeOfFundraising}
                        setValue={(content) =>
                          setFieldValue("noNamePurposeOfFundraising", content)
                        }
                        style={{
                          marginBottom: "40px",
                        }}
                      />
                    </StyledFormControl>
                    <StyledFormControl fullWidth>
                      <StyledFormLabelOptional>
                        Financial Sub Heading
                      </StyledFormLabelOptional>
                      <CustomInput
                        name={`financialSubHeading`}
                        onChange={handleChange}
                        value={values.financialSubHeading}
                        onBlur={handleBlur}
                        placeholder={`Enter Financial Sub Heading`}
                      />
                    </StyledFormControl>
                    <StyledFormControl component="fieldset">
                      <StyledFormLabelOptional component="legend">
                        Data Available with Fundrev- Analytics
                      </StyledFormLabelOptional>
                      <div className="flex gap-[24px] mt-[16px]">
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.analytics"
                            checked={
                              values.dataAvailabilityWithFundrev.analytics ===
                              true
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.analytics",
                                true
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          Yes
                        </label>
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.analytics"
                            checked={
                              values.dataAvailabilityWithFundrev.analytics ===
                              false
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.analytics",
                                false
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          No
                        </label>
                      </div>
                    </StyledFormControl>
                    <StyledFormControl component="fieldset">
                      <StyledFormLabelOptional component="legend">
                        Data Available with Fundrev- Financial
                      </StyledFormLabelOptional>
                      <div className="flex gap-[24px] mt-[16px]">
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.financial"
                            checked={
                              values.dataAvailabilityWithFundrev.financial ===
                              true
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.financial",
                                true
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          Yes
                        </label>
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.financial"
                            checked={
                              values.dataAvailabilityWithFundrev.financial ===
                              false
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.financial",
                                false
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          No
                        </label>
                      </div>
                    </StyledFormControl>
                    <StyledFormControl component="fieldset">
                      <StyledFormLabelOptional component="legend">
                        Data Available with Fundrev- Dataroom
                      </StyledFormLabelOptional>
                      <div className="flex gap-[24px] mt-[16px]">
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.dataroom"
                            checked={
                              values.dataAvailabilityWithFundrev.dataroom ===
                              true
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.dataroom",
                                true
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          Yes
                        </label>
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.dataroom"
                            checked={
                              values.dataAvailabilityWithFundrev.dataroom ===
                              false
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.dataroom",
                                false
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          No
                        </label>
                      </div>
                    </StyledFormControl>
                    <StyledFormControl component="fieldset">
                      <StyledFormLabelOptional component="legend">
                        Data Available with Fundrev- ForecastModel
                      </StyledFormLabelOptional>
                      <div className="flex gap-[24px] mt-[16px]">
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.forecastModel"
                            checked={
                              values.dataAvailabilityWithFundrev
                                .forecastModel === true
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.forecastModel",
                                true
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          Yes
                        </label>
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.forecastModel"
                            checked={
                              values.dataAvailabilityWithFundrev
                                .forecastModel === false
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.forecastModel",
                                false
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          No
                        </label>
                      </div>
                    </StyledFormControl>
                    <StyledFormControl component="fieldset">
                      <StyledFormLabelOptional component="legend">
                        Data Available with Fundrev- Management Presentation
                      </StyledFormLabelOptional>
                      <div className="flex gap-[24px] mt-[16px]">
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.managementPresentation"
                            checked={
                              values.dataAvailabilityWithFundrev
                                .managementPresentation === true
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.managementPresentation",
                                true
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          Yes
                        </label>
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.managementPresentation"
                            checked={
                              values.dataAvailabilityWithFundrev
                                .managementPresentation === false
                            }
                            onChange={() =>
                              setFieldValue(
                                "dataAvailabilityWithFundrev.managementPresentation",
                                false
                              )
                            }
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          No
                        </label>
                      </div>
                    </StyledFormControl>
                    <StyledFormControl>
                      <StyledFormLabelOptional>
                        Deal Tags
                      </StyledFormLabelOptional>
                      <CustomInput
                        placeholder="Enter your tag here..."
                        name="dealTags"
                        value={newtag}
                        onChange={(e) => setNewTag(e.target.value)}
                        multiline
                        minRows={1}
                      />
                      <div className="table-edit-buttons">
                        <button
                          onClick={(event) => {
                            event.preventDefault();
                            handleDealTagsChange(values, setFieldValue);
                          }}
                          className="add-row"
                          type="button"
                        >
                          <AiOutlinePlus />
                          &nbsp;&nbsp; Add tag
                        </button>
                      </div>
                      <div className="hightlights-div">
                        <b>Deal tags</b>
                        <ol style={{ listStyleType: "decimal" }}>
                          {values.dealTags.map((tag, index) => (
                            <li key={index} style={{ textWrap: "no-wrap" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p>{tag}</p>
                                <RiDeleteBin6Line
                                  className="red-bin"
                                  onClick={() =>
                                    deleteDealTag(index, values, setFieldValue)
                                  }
                                />
                              </div>
                            </li>
                          ))}
                        </ol>
                      </div>
                    </StyledFormControl>
                  </div>
                </div>
                <div className="admin-key-metrics fundrev-card mb-4">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] ">
                      Key Metrics
                    </p>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Input
                        placeholder="field"
                        type="text"
                        name="field"
                        value={field}
                        onChange={(e) => setField(e.target.value)}
                        onKeyDown={(event) =>
                          handleKeyDown(
                            event,
                            "keyMetrics",
                            values,
                            setFieldValue
                          )
                        }
                      />
                      <Button
                        onClick={() =>
                          addFieldToMetrics("keyMetrics", values, setFieldValue)
                        }
                      >
                        Add field
                      </Button>
                    </div>
                  </div>
                  {renderMetricsRows(values, "keyMetrics", 3, setFieldValue)}
                </div>
                <div className="admin-card-metrics fundrev-card mb-4">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] ">
                      Financial Metrics
                    </p>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Input
                        placeholder="field"
                        type="text"
                        name="secondField"
                        value={secondField}
                        onChange={(e) => setSecondField(e.target.value)}
                        onKeyDown={(event) =>
                          handleKeyDown(
                            event,
                            "financialMetrics",
                            values,
                            setFieldValue
                          )
                        }
                      />
                      <Button
                        onClick={() =>
                          addFieldToMetrics(
                            "financialMetrics",
                            values,
                            setFieldValue
                          )
                        }
                      >
                        Add field
                      </Button>
                    </div>
                  </div>
                  {renderMetricsRows(
                    values,
                    "financialMetrics",
                    3,
                    setFieldValue
                  )}
                </div>
                <div className="admin-card-metrics fundrev-card">
                  <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] ">
                    Verification fields{" "}
                    <span className="text-[14px] !text-[var(--Secondary-Text-Color)]">
                      {" "}
                      (Mark it true inCase we are creating deals beforehand and
                      don't want to send Email)
                    </span>
                  </p>
                  <div className="company-details-input-fields">
                    <StyledFormControl component="fieldset">
                      <StyledFormLabelOptional component="legend">
                        Company approved
                      </StyledFormLabelOptional>
                      <div className="flex gap-[24px] mt-[16px]">
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.analytics"
                            checked={isApproved}
                            onChange={() => setIsApproved(true)}
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          Yes
                        </label>
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.analytics"
                            checked={!isApproved}
                            onChange={() => setIsApproved(false)}
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          No
                        </label>
                      </div>
                    </StyledFormControl>
                    <StyledFormControl component="fieldset">
                      <StyledFormLabelOptional component="legend">
                        Mark deal creation Complete (onboarding)
                      </StyledFormLabelOptional>
                      <div className="flex gap-[24px] mt-[16px]">
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.analytics"
                            checked={onBoardingComplete}
                            onChange={() => setOnBoardingComplete(true)}
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          Yes
                        </label>
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.analytics"
                            checked={!onBoardingComplete}
                            onChange={() => setOnBoardingComplete(false)}
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          No
                        </label>
                      </div>
                    </StyledFormControl>

                    <StyledFormControl component="fieldset">
                      <StyledFormLabelOptional component="legend">
                        Application reviewed
                      </StyledFormLabelOptional>
                      <div className="flex gap-[24px] mt-[16px]">
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.analytics"
                            checked={isApplicationReviewed}
                            onChange={() => setIsApplicationReviewed(true)}
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          Yes
                        </label>
                        <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                          <input
                            type="checkbox"
                            name="dataAvailabilityWithFundrev.analytics"
                            checked={!isApplicationReviewed}
                            onChange={() => setIsApplicationReviewed(false)}
                            onBlur={handleBlur}
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "4px",
                            }}
                          />
                          No
                        </label>
                      </div>
                    </StyledFormControl>
                  </div>
                </div>
                <div className="showInterestButtons-div">
                  <div className="btns-insideContent">
                    {" "}
                    <CustomBlackBtn
                      text="Invite Company Admin"
                      onClick={() => setInviteAdminModal(true)}
                      filled={false}
                      type="button"
                    />
                    <div className="next-buttons">
                      <CustomBlackBtn
                        // onClick={handleSave}
                        filled={false}
                        type="submit"
                        text="save"
                      />
                      {isOpen ? (
                        <div className="stage-status-btn">Deal live</div>
                      ) : isApplicationReviewed ? (
                        <div className="stage-status-btn">Sent for review</div>
                      ) : (
                        <CustomBlackBtn
                          text="Send for review"
                          type="button"
                          onClick={() => {
                            setSendForReviewModal(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default DealDetailsForm;
