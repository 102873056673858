import React, { useEffect, useState } from "react";
import Navbar from "../../../Common/components/navbar";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../Common/components/sidebar";
import Dataroom from "../../../Common/components/dataroom";
import { navItems } from "./navItems";
import { getAllDealInteractions } from "../../../../endpoints/deal";
import { useRecoilValue } from "recoil";
import { investorState } from "../investorState";
import { useLoader } from "../../../Common/LoaderProvider";

const InvDataroom = () => {
  const loader = useLoader();
  const navigate = useNavigate();
  const { dealId } = useParams();
  const [stage, setStage] = useState("interested");
  const { investorId } = useRecoilValue(investorState);
  const token = localStorage.getItem("token");
  const deal = {
    dealId: dealId,
  };
  const fetchAllInteractions = async () => {
    try {
      const response = await getAllDealInteractions(
        deal?.dealId,
        investorId,
        token
      );
      loader.stop();
      if (response.data) {
        const lastResponse = response.data[response.data.length - 1];
        setStage(lastResponse.Stage);
      }
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    if (deal && investorId) fetchAllInteractions();
  }, [deal, investorId]);
  return (
    <div className="bg-neutral-2 w-[100vw] h-[100vh] ">
      <Navbar
        navItems={navItems(navigate, dealId, stage)}
        title={"Company Profile"}
      />
      <Sidebar userRole="investor" type={"current"} active={"ongoingDeals"} />
      <div className="custom-container pt-300 mx-auto">
        <Dataroom deal={deal} stage={"loiSubmitted"} />
      </div>
    </div>
  );
};

export default InvDataroom;
