import React, { useEffect, useMemo } from "react";
import SmallCardGrid from "./AnalyticsPlots/SmallCardGrid";
import FinancialPlot from "./AnalyticsPlots/FinancialPlot";
import TopCustomerTable from "./AnalyticsPlots/TopCustomerTable";
import PlotTemplate from "./AnalyticsPlots/PlotTemplate";
import { dumpInvestorInteraction } from "../../endpoints/common";
import CohortTable from "./AnalyticsPlots/cohortTable";
import ChurnTable from "./AnalyticsPlots/churnTable";
import { isEmptyObject } from "jquery";
import { isEmptyArray } from "formik";
import { getOrdersPerCustomer } from "../Common/functions";

const BusinessAnalytics = ({ data, deal, stage, mapper }) => {
  const token = localStorage.getItem("token");
  useEffect(() => {
    const dumpInteraction = async () => {
      try {
        await dumpInvestorInteraction(
          {
            dealId: deal.dealId,
            type: "view",
            page: "analytics",
            stageOfDeal: stage,
            element: "page",
            pageSection: "businessAnalytics",
          },
          token
        );
      } catch (error) {
        console.error("Error dumping interaction", error);
      }
    };
    dumpInteraction();
  }, []);
  const plots = useMemo(
    () => [
      "revenue",
      "orders",
      "orderPerCustomer",
      "revenueByCustomerType",
      "averageRevenue",
      "costByCategory",
      "percentageGMByCategory",
    ],
    []
  );
  return (
    <>
      <div>
        <SmallCardGrid data={mapper?.fiveCards} title={`Sales Analysis`} />
        <div className="grid grid-cols-2 gap-250 mt-200">
          {!isEmptyArray(data?.customerTapeData?.plotData?.revenueByCategory) &&
            mapper?.plots?.revenueByCategory?.isVisible && (
              <div className="bg-neutral-1 rounded-100 border border-neutral-5 p-200 h-[465px]">
                <PlotTemplate
                  data={{
                    ...mapper?.plots?.revenueByCategory,
                    data: {
                      xLabels: data?.customerTapeData?.plotData?.xLabels,
                      values:
                        data?.customerTapeData?.plotData?.revenueByCategory,
                    },
                  }}
                />
              </div>
            )}
          {!isEmptyObject(data?.customerTapeData?.tableData) &&
            mapper?.tables?.topCustomerTable?.isVisible && (
              <div className="bg-neutral-1 rounded-100 border border-neutral-5 p-200">
                <TopCustomerTable
                  data={{
                    ...mapper?.tables?.topCustomerTable,
                    data: data?.customerTapeData?.tableData,
                  }}
                />
              </div>
            )}
          {!isEmptyObject(data?.customerTapeData?.customerGrowth) &&
            mapper?.plots?.activeCustomers?.isVisible && (
              <div className="bg-neutral-1 rounded-100 border h-[465px] border-neutral-5 p-200">
                <PlotTemplate
                  data={{
                    ...mapper?.plots?.activeCustomers,
                    data: {
                      xLabels: data?.customerTapeData?.plotData?.xLabels,
                      values: {
                        ...data?.customerTapeData?.customerGrowth,
                        data: { type: "bar" },
                      },
                    },
                  }}
                />
              </div>
            )}
          {plots.map((plot, idx) => {
            if (idx === 2) {
              if (
                !isEmptyObject(data?.customerTapeData?.plotData?.orders) &&
                !isEmptyObject(data?.customerTapeData?.customerGrowth)
              ) {
                const orders = data?.customerTapeData?.plotData?.orders;
                const customerGrowth = data?.customerTapeData?.customerGrowth;
                const ordersPerCustomer = getOrdersPerCustomer(
                  orders,
                  customerGrowth
                );
                return (
                  <div className="bg-neutral-1 rounded-100 border h-[465px] border-neutral-5 p-200">
                    <PlotTemplate
                      data={{
                        ...mapper?.plots?.[plot],
                        data: {
                          xLabels: data?.customerTapeData?.plotData?.xLabels,
                          values: ordersPerCustomer,
                        },
                      }}
                      subText={false}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            }
            if (
              isEmptyObject(data?.customerTapeData?.plotData?.[plot]) &&
              mapper?.plots?.[plot]?.isVisible
            ) {
              return <></>;
            }
            return (
              <div className="bg-neutral-1 rounded-100 border h-[465px] border-neutral-5 p-200">
                <PlotTemplate
                  data={{
                    ...mapper?.plots?.[plot],
                    data: {
                      xLabels: data?.customerTapeData?.plotData?.xLabels,
                      values: data?.customerTapeData?.plotData?.[plot],
                    },
                  }}
                />
              </div>
            );
          })}
          <div className="bg-neutral-1 mt-250 rounded-100 border border-neutral-5 p-200 !max-h-[600px] col-span-2">
            <CohortTable
              data={{
                ...mapper?.tables?.cohortTable,
                data: data?.customerTapeData?.cohorts,
              }}
            />
          </div>
          <div className="bg-neutral-1 rounded-100 border border-neutral-5 p-200 !max-h-[465px] col-span-2">
            <ChurnTable
              data={{
                ...mapper?.tables?.churnTable,
                data: data?.customerTapeData?.churnTable,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessAnalytics;
