import React, { useMemo, useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import { formatToLakhs } from "../../../utilities/numberFormatter";
import {
  getRelativeKey,
  useKeySelection,
} from "../../../hooks/analytics/getComparisonKey";
import arrowUp from "../../../Assets/Images/icons/analytics/ArrowUp.svg";
import arrowDown from "../../../Assets/Images/icons/analytics/ArrowDown.svg";

const CashFlowSmallTable = ({ data }) => {
  const [type, setType] = useState("m");
  const [option, setOption] = useState(`previous${type}`);
  const keys = useMemo(
    () =>
      Object.keys(
        data?.data[type]?.[Object.keys(data?.data[type] || {})[0]].values || {}
      ),
    [type, data?.data]
  );
  const { key, ckey } = useKeySelection(data, type, option, keys);

  const rows = [
    "Operating Activity",
    "Investing Activity",
    "Financing Activity",
    "Net Change in Cash",
    "Beginning Cash",
    "Ending Cash",
  ];
  const IMPRows = ["Net Change in Cash", "Ending Cash"];
  function getVariance(row, last = false) {
    let a, b;
    if (last) {
      a = row[Object.keys(row)[0]][Object.keys(row)[0]][key];
      b = row[Object.keys(row)[0]][Object.keys(row)[0]][ckey];
    } else {
      a = data?.data[type]?.[row].values[key];
      b = data?.data[type]?.[row].values[ckey];
    }

    // Check if either 'a' or 'b' is zero to prevent division by zero
    if (a === 0 || b === 0 || !a || !b) {
      return 0;
    }
    return (((a - b) / Math.abs(b)) * 100).toFixed(1);
  }
  const isPYVisible = () => {
    let result = false;
    const ckey = getRelativeKey(
      keys,
      keys?.[keys?.length - 1],
      `last${type}`,
      false
    )?.key;
    rows.forEach((row, index) => {
      if (index < 3) {
        const value =
          data?.data[type]?.[Object.keys(data?.data[type] || {})[index]].values[
            ckey
          ];
        if (value) {
          result = true;
        }
      }
    });
    data?.data[type]?.[Object.keys(data?.data[type] || {})[3]].mappers.forEach(
      (row) => {
        const value = row[Object.keys(row)[0]][Object.keys(row)[0]][ckey];
        if (value) {
          result = true;
        }
      }
    );
    return result;
  };
  const isLTMVisible = () => {
    let result = false;
    const keys = Object.keys(
      data?.data[`ltm`]?.[Object.keys(data?.data[`ltm`] || {})[0]].values || {}
    );
    const ckey =
      keys?.length > 0 &&
      getRelativeKey(keys, keys?.[keys?.length - 1], `previousltm`, false).key;
    rows.forEach((row, index) => {
      if (index < 3) {
        const value =
          data?.data[`ltm`]?.[Object.keys(data?.data[`ltm`] || {})[index]]
            .values[key];
        if (value) {
          result = true;
        }
      }
    });
    data?.data[`ltm`]?.[
      Object.keys(data?.data[`ltm`] || {})[3]
    ].mappers.forEach((row) => {
      const value = row[Object.keys(row)[0]][Object.keys(row)[0]][ckey];
      if (value) {
        result = true;
      }
    });
    return result;
  };

  return (
    <>
      <div>
        <TimePeriodButton
          title={data?.name.slice(0, 5)}
          type={type}
          setType={setType}
          select={true}
          ltm={isLTMVisible()}
          year={false}
          option={option}
          setOption={setOption}
          py={isPYVisible()}
        />
        <div className="mt-200">
          <table aria-label="cash flow summary table">
            <thead>
              {["Summary", ckey, key, "%Change"].map((column, index) => (
                <th
                  className={`p-150 bg-neutral-3 rounded-050 text-desktop-p-s font-medium text-neutral-8 !uppercase ${
                    index === 0 ? "text-left" : "text-right"
                  }`}
                  key={index}
                  align={index === 0 ? "left" : "right"}
                >
                  {column}
                </th>
              ))}
            </thead>
            <tbody>
              {rows.slice(0, 3).map((row, index) => (
                <tr key={index}>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 text-left ${
                      IMPRows.includes(rows[index]) &&
                      "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                    }`}
                    align="left"
                  >
                    {row}
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 text-right ${
                      IMPRows.includes(rows[index]) &&
                      "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                    }`}
                    align="right"
                  >
                    {formatToLakhs(
                      data?.data[type]?.[
                        Object.keys(data?.data[type] || {})[index]
                      ].values[ckey]
                    ) || `-`}
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 text-right ${
                      IMPRows.includes(rows[index]) &&
                      "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                    }`}
                    align="right"
                  >
                    {formatToLakhs(
                      data?.data[type]?.[
                        Object.keys(data?.data[type] || {})[index]
                      ].values[key]
                    ) || `-`}
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 text-right ${
                      IMPRows.includes(rows[index]) &&
                      "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                    } ${
                      getVariance(Object.keys(data?.data[type] || {})[index])
                        ? getVariance(
                            Object.keys(data?.data[type] || {})[index]
                          ) > 0
                          ? "text-[#20BD7E]"
                          : "text-[#FF5454]"
                        : ""
                    }`}
                    align="right"
                  >
                    <div className="flex items-center gap-100 justify-end">
                      {getVariance(
                        Object.keys(data?.data[type] || {})[index]
                      ) ? (
                        getVariance(
                          Object.keys(data?.data[type] || {})[index]
                        ) > 0 ? (
                          <img src={arrowUp} alt="up" />
                        ) : (
                          <img src={arrowDown} alt="down" />
                        )
                      ) : (
                        ``
                      )}
                      {getVariance(Object.keys(data?.data[type] || {})[index])
                        ? getVariance(
                            Object.keys(data?.data[type] || {})[index]
                          ) + `%`
                        : `-`}
                    </div>
                  </td>
                </tr>
              ))}
              {data?.data[type]?.[
                Object.keys(data?.data[type] || {})[3]
              ].mappers.map((row, index) => (
                <tr key={index}>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 text-left ${
                      IMPRows.includes(rows[index + 3]) &&
                      "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                    }`}
                    align="left"
                  >
                    {rows[index + 3]}
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 text-right ${
                      IMPRows.includes(rows[index + 3]) &&
                      "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                    }`}
                    align="right"
                  >
                    {formatToLakhs(
                      row[Object.keys(row)[0]][Object.keys(row)[0]][ckey]
                    ) || `-`}
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 text-right ${
                      IMPRows.includes(rows[index + 3]) &&
                      "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                    }`}
                    align="right"
                  >
                    {formatToLakhs(
                      row[Object.keys(row)[0]][Object.keys(row)[0]][key]
                    ) || `-`}
                  </td>
                  <td
                    className={`text-desktop-p-s p-150 !border-b-neutral-4 text-right ${
                      IMPRows.includes(rows[index + 3]) &&
                      "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                    } ${
                      getVariance(row, true)
                        ? getVariance(row, true) > 0
                          ? "text-[#20BD7E]"
                          : "text-[#FF5454]"
                        : ""
                    }`}
                    align="right"
                  >
                    <div className="flex items-center gap-100 justify-end">
                      {getVariance(row, true) ? (
                        getVariance(row, true) > 0 ? (
                          <img src={arrowUp} alt="up" />
                        ) : (
                          <img src={arrowDown} alt="down" />
                        )
                      ) : (
                        ``
                      )}
                      {getVariance(row, true)
                        ? getVariance(row, true) + `%`
                        : `-`}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CashFlowSmallTable;
