import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { formatToLakhs } from "../../../utilities/numberFormatter";
import { isEmptyObject } from "jquery";

const FinancialStatementTable = ({ data, ltm = true }) => {
  const [type, setType] = useState("m");
  const [open, setOpen] = useState(true);
  const percentageRows = [
    "Gross Profit %",
    "EBITDA %",
    "Net Profit Margin",
    "EBIT %",
  ];
  const IMPRows = [
    "Gross Profit",
    "EBITDA",
    "Net Profit",
    "EBIT",
    "Profit (Before Tax)",
  ];

  useEffect(() => {}, [type, data]);

  function CollapsibleRow(props) {
    const { row } = props;
    return (
      <>
        <tr sx={{ "& > *": { borderBottom: "unset" } }}>
          <td
            className={`text-desktop-p-s sticky p-150 !border-b-neutral-4 bg-neutral-1 text-left z-10 left-0 text-nowrap ${
              IMPRows.includes(row) &&
              "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
            }`}
            align="left"
          >
            {row}

            <IconButton
              aria-label="expand row"
              size="small"
              sx={{
                paddingY: 0,
              }}
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowUpIcon fontSize="3px" />
              ) : (
                <KeyboardArrowDownIcon fontSize="3px" />
              )}
            </IconButton>
          </td>
          {Object.values(data.data[type]["SG&A"]["Total SG&A Expenses"])?.map(
            (value, index) => (
              <td
                key={index}
                className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right z-10 left-0 text-nowrap ${
                  IMPRows.includes(row) &&
                  "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                }`}
              >
                {formatToLakhs(value)}
              </td>
            )
          )}
        </tr>
        {Object.keys(data.data[type][row]).map((inside_row, index) => {
          return (
            inside_row !== "Total SG&A Expenses" &&
            Object.values(data.data[type][row][inside_row]) && (
              <>
                <style>
                  {`
          @keyframes fadeIn {
            0% {
              opacity: 0;
              transform: translateY(-10px);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }

          @keyframes fadeOut {
            0% {
              opacity: 1;
              transform: translateY(0);
            }
            100% {
              opacity: 0;
              transform: translateY(-10px);
            }
          }
        `}
                </style>

                <tr
                  key={index}
                  style={{
                    display: open ? "table-row" : "none",
                    animation: open
                      ? "fadeIn 0.5s ease-in-out forwards"
                      : "fadeOut 0.5s ease-in-out forwards",
                  }}
                >
                  <td
                    className={`text-desktop-p-s sticky p-150 pl-250 !border-b-neutral-4 bg-neutral-1 text-left z-10 left-0 text-nowrap ${
                      IMPRows.includes(row) &&
                      "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                    }`}
                    align="left"
                  >
                    {inside_row}
                  </td>
                  {Object.values(data.data[type][row][inside_row]).map(
                    (val, index) => (
                      <td
                        key={index}
                        className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right text-nowrap ${
                          IMPRows.includes(row) &&
                          "bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                        }`}
                      >
                        {formatToLakhs(val && val) || 0}
                      </td>
                    )
                  )}
                </tr>
              </>
            )
          );
        })}
      </>
    );
  }

  const isLTMVisible = () => {
    if (!isEmptyObject(data?.data?.["ltm"]?.Revenue)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div>
        <TimePeriodButton
          type={type}
          setType={setType}
          ltm={isLTMVisible()}
          title={data.name}
        />
        <div className="overflow-x-auto mt-200">
          <table aria-label="Financial statement table">
            <thead>
              {[
                "Particulars",
                ...Object.keys(
                  data.data[type][Object.keys(data.data[type])[0]]
                ),
              ].map((column, index) => (
                <td
                  key={index}
                  align={index === 0 ? "left" : "right"}
                  className={`p-150 bg-neutral-3 rounded-050 text-desktop-p-s sticky font-medium text-neutral-8 text-nowrap !uppercase ${
                    index === 0 ? "text-left left-0 z-10" : "text-right"
                  }`}
                >
                  {column}
                </td>
              ))}
            </thead>
            <tbody>
              {Object.keys(data.data[type]).map((row, index) =>
                row === "SG&A" ? (
                  <CollapsibleRow row={row} key={index} />
                ) : (
                  <tr key={index}>
                    {row !== "SG&A" && (
                      <td
                        className={`text-desktop-p-s sticky p-150 !border-b-neutral-4 bg-neutral-1 text-left z-10 left-0 text-nowrap ${
                          IMPRows.includes(row) &&
                          "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                        }`}
                        align="left"
                      >
                        {row}
                      </td>
                    )}
                    {row !== "SG&A" &&
                      Object.keys(
                        data.data[type][Object.keys(data.data[type])[0]]
                      ).map((val, index) => (
                        <td
                          key={index}
                          className={`text-desktop-p-s p-150 !border-b-neutral-4 bg-neutral-1 text-right z-10 left-0 text-nowrap ${
                            IMPRows.includes(row) &&
                            "!bg-aero-blue-50 !border-t-[1.5px] !border-t-aero-blue-700 font-bold"
                          }`}
                        >
                          {formatToLakhs(data.data[type][row][val]) || `-`}
                        </td>
                      ))}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FinancialStatementTable;
