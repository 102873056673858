import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { companyState, saveState } from "../companyState";
import { useLoader } from "../../../Common/LoaderProvider";
import { getDetailsById } from "../../../../endpoints/investor";

import { useNavigate } from "react-router-dom";
import { getInvestorsByDealID } from "../../../../endpoints/deal";
import { updateHeaderDealStage } from "../../../Common/functions";

function InvestorDetailsSubHeader({
  activeButton,
  stage,
  page,
  investorID,
  dealId,
  isDealLive,
}) {
  const navigate = useNavigate();
  const loader = useLoader();
  const token = localStorage.getItem("token");
  const [activeBtn, setActiveBtn] = useState(activeButton);
  const [state, setState] = useRecoilState(companyState);
  const [text, setText] = useState();
  const [color, setColor] = useState();
  const [bgcolor, setBgcolor] = useState();
  const handleActiveBtn = async (activeBtn) => {
    if (page === "companyAgreement") {
      try {
        if (investorID) {
          loader.start();

          const dealInterests = await getInvestorsByDealID(dealId, token);
          const dealInterest = dealInterests?.data?.filter(
            (investor) => investor.investorID === investorID
          )?.[0];
          const response = await getDetailsById(investorID, token);
          if (response && dealInterest) {
            navigate("/company/investorDetails", {
              state: {
                passedStage: stage,
                dealId: dealId,
                investor: response.data.data,
                investorID: investorID,
                isDealLive: isDealLive,
                latestStage: dealInterest.latestStage,
                isDealLive:
                  !dealInterest.IsRejected && dealInterest.IsInterested,
                watchlistAccepted: dealInterest.watchlistAccepted,
              },
            });
          }
        }
      } catch (error) {
        console.log(error);
        loader.stop();
      }
    }
    const newState = { ...state, activeBtn: activeBtn };
    setState(newState);
    saveState(newState);
  };
  useEffect(() => {
    setActiveBtn(state.activeBtn);
  }, [state.activeBtn]);
  useEffect(() => {
    updateHeaderDealStage(stage, isDealLive, setText, setColor, setBgcolor);
  }, [stage, isDealLive]);

  return (
    <>
      <div
        className="flex flex-col pt-2 px-4 bg-slate-50 max-md:px-5 border-color-[#EBF0FA] border-b-2"
        style={{
          borderWidth: stage === "requestSent" && "0px",
        }}
      >
        <div className="max-md:max-w-full flex flex-wrap items-end justify-between w-full gap-10">
          <div
            className="flex flex-col min-w-[240px] max-md:max-w-full"
            style={{
              visibility: stage === "requestSent" && "hidden",
            }}
          >
            <div className="flex flex-wrap gap-6 items-center text-sm min-h-[32px] max-md:max-w-full">
              <div
                className="whitespace-nowrap flex items-center self-stretch gap-1 my-auto"
                onClick={() => {
                  setActiveBtn(1);
                  handleActiveBtn(1);
                }}
              >
                <div
                  className={`self-stretch my-auto font-bold cursor-pointer ${
                    activeBtn === 1 ? "text-blue-700" : "text-gray-500"
                  }`}
                >
                  Overview
                </div>
              </div>

              <div
                className="flex items-center self-stretch gap-1 my-auto"
                onClick={() => {
                  setActiveBtn(2);
                  handleActiveBtn(2);
                }}
              >
                <div
                  className={`self-stretch my-auto font-bold cursor-pointer ${
                    activeBtn === 2 ? "text-blue-700" : "text-gray-500"
                  }`}
                >
                  Activity
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-[16px]">
            <div
              className="px-[8px] py-[6px] mb-[4px] justify-center items-center rounded-[8px] border-[1px] border-[#AEB3C1]"
              style={{
                color: color,
                backgroundColor: bgcolor,
              }}
            >
              {text}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvestorDetailsSubHeader;
