import { ArrowRightIcon } from "@radix-ui/react-icons";
import React from "react";

const AnalyticsPageTitle = ({ title, viewMore, handleClick }) => {
  return (
    <div className="flex justify-between items-center mb-300 mt-400">
      <p className="text-neutral-12 text-desktop-p-xl">{title}</p>
      {viewMore && (
        <p
          className="text-desktop-p-mb text-blue-500 flex items-center gap-100 cursor-pointer"
          onClick={handleClick}
        >
          See more <ArrowRightIcon />
        </p>
      )}
    </div>
  );
};

export default AnalyticsPageTitle;
