import React, { useState } from "react";
import {
  MenuItem,
  Select,
  ToggleButtonGroup,
  ToggleButton,
  styled,
  toggleButtonGroupClasses,
} from "@mui/material";

// ----- 1) Interpolation Helpers -----

/**
 * Linearly interpolate between two hex colors at a fraction [0..1].
 */
function interpolateHexColor(startColor, endColor, fraction) {
  const s = startColor.replace("#", "");
  const e = endColor.replace("#", "");

  const sr = parseInt(s.substring(0, 2), 16);
  const sg = parseInt(s.substring(2, 4), 16);
  const sb = parseInt(s.substring(4, 6), 16);

  const er = parseInt(e.substring(0, 2), 16);
  const eg = parseInt(e.substring(2, 4), 16);
  const eb = parseInt(e.substring(4, 6), 16);

  const r = Math.round(sr + (er - sr) * fraction);
  const g = Math.round(sg + (eg - sg) * fraction);
  const b = Math.round(sb + (eb - sb) * fraction);

  return `#${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
}

/**
 * Generate an array of `steps` hex colors, going from:
 *   LIGHT_COLOR -> MID_COLOR -> DARK_COLOR
 * in a 3-stop gradient.
 */
function generate3StopGradient(light, mid, dark, steps) {
  const shades = [];
  for (let i = 0; i < steps; i++) {
    const ratio = i / (steps - 1);
    if (ratio <= 0.5) {
      // Interpolate between light & mid from ratio=0..0.5 → local=0..1
      const localRatio = ratio / 0.5;
      shades.push(interpolateHexColor(light, mid, localRatio));
    } else {
      // Interpolate between mid & dark from ratio=0.5..1 → local=0..1
      const localRatio = (ratio - 0.5) / 0.5;
      shades.push(interpolateHexColor(mid, dark, localRatio));
    }
  }
  return shades;
}

/**
 * Return black (#000) or white (#FFF) depending on
 * the background color’s brightness.
 */
function getContrastingTextColor(hex) {
  let c = hex.replace("#", "");
  // Expand 3-digit hex if needed
  if (c.length === 3) {
    c = c[0] + c[0] + c[1] + c[1] + c[2] + c[2];
  }
  const r = parseInt(c.substring(0, 2), 16);
  const g = parseInt(c.substring(2, 4), 16);
  const b = parseInt(c.substring(4, 6), 16);
  // Approximate luminance
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
  return brightness > 186 ? "#000" : "#FFF";
}

// ----- 2) Our Main Component -----

const CohortTable = ({ data }) => {
  // Which metric are we viewing?
  const [type, setType] = useState("revenue");
  // Toggle for absolute (#) vs percentage (%)
  const [showPercentage, setShowPercentage] = useState(false);

  // MUI styling for the ToggleButtonGroup
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      borderRadius: "4px",
      fontFamily: "Satoshi",
      fontSize: "10px",
      maxWidth: "48px",
      color: "#141414",
      fontWeight: 500,
      '&[aria-pressed="true"]': {
        backgroundColor: "white",
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.05)",
        "&:hover": {
          backgroundColor: "white",
        },
      },
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
      {
        marginLeft: "4px",
      },
  }));

  // Options for the dropdown
  const types = [
    { label: "Users", value: "retention" },
    { label: "Revenue", value: "revenue" },
    { label: "Avg. Order Value", value: "avgOrderValue" },
    { label: "Orders per User", value: "avgOrdersPerUser" },
    { label: "Total Orders", value: "totalOrders" },
  ];

  // ----- 3) Basic Table Setup -----
  // Determine how many columns the table needs (max row length)
  const maxColumns = Math.max(
    ...Object.values(data?.data || {}).map((arr) => arr.length)
  );

  // 3-step color stops
  const LIGHT_COLOR = "#FDFBE6";
  const MID_COLOR = "#CE658A";
  const DARK_COLOR = "#5B138F";

  return (
    <div>
      {/* HEADER / SELECT / TOGGLE */}
      <div className="flex justify-between items-center">
        <div className="text-desktop-p-lb">{data.name}</div>

        <div className="flex items-center gap-100">
          {/* Select to choose metric */}
          <Select
            value={type}
            placeholder="Select Cohort Type"
            onChange={(e) => setType(e.target.value)}
            sx={{
              width: "133px",
              height: "28px",
              borderRadius: "4px",
              fontSize: "14px",
              color: "var(--Secondary-Text-Color)",
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: "200px",
                },
              },
            }}
          >
            {types.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                sx={{
                  fontSize: "14px",
                  color: "var(--Secondary-Text-Color)",
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>

          {/* Toggle between # and % */}
          <StyledToggleButtonGroup
            className="bg-neutral-4 border border-neutral-5 p-050 h-[30px]"
            exclusive
            value={showPercentage ? "%" : "#"}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                setShowPercentage(newValue === "%");
              }
            }}
          >
            <ToggleButton
              value="#"
              sx={{
                border: 0,
                textTransform: "none",
                height: "20px",
                padding: "10px 24px",
              }}
            >
              #
            </ToggleButton>
            <ToggleButton
              value="%"
              sx={{
                border: 0,
                textTransform: "none",
                height: "20px",
                padding: "10px 24px",
              }}
            >
              %
            </ToggleButton>
          </StyledToggleButtonGroup>
        </div>
      </div>

      {/* MAIN TABLE */}
      <div className="overflow-auto max-h-[480px] mt-200">
        <table className="border-separate border-spacing-050">
          <thead>
            <tr>
              <td
                key="header"
                className="py-100 px-150 !w-48 border-none bg-neutral-3 rounded-050 text-desktop-p-s sticky font-medium text-neutral-8 text-nowrap !uppercase text-left left-0 top-0 z-20"
                align="left"
              >
                Months
              </td>

              {/* Generate column headers M1, M2, etc. */}
              {Array.from({ length: maxColumns }, (_, i) => (
                <td
                  key={`col-header-${i}`}
                  className="py-100 px-150 border-none bg-neutral-3 top-0 rounded-050 text-desktop-p-s sticky font-medium text-neutral-8 text-nowrap !uppercase text-right z-10"
                  align="right"
                >
                  {`M${i + 1}`}
                </td>
              ))}
            </tr>
          </thead>

          <tbody>
            {Object.entries(data?.data).map(([key, rowData], rowIndex) => {
              // rowData: array of objects for each M1, M2, ...
              // Gather numeric values for the chosen `type`
              const numericValues = rowData
                .map((obj) => obj[type])
                .filter((v) => v !== null && v !== undefined && !isNaN(v));

              // If there's no numeric data, we can just render dashes
              if (!numericValues.length) {
                return (
                  <tr key={rowIndex}>
                    <td
                      align="left"
                      className="sticky left-0 z-10
                      py-2 px-4 w-48"
                    >
                      {key}
                    </td>
                    {Array.from({ length: maxColumns }, (_, i) => (
                      <td key={i} align="right">
                        -
                      </td>
                    ))}
                  </tr>
                );
              }

              // Compute rowMin, rowMax, plus the row length
              const rowMin = Math.min(...numericValues);
              const rowMax = Math.max(...numericValues);
              const rowLength = rowData.length;

              // Generate array of shades for this row, one shade per column
              const rowShades = generate3StopGradient(
                LIGHT_COLOR,
                MID_COLOR,
                DARK_COLOR,
                rowLength
              );

              // For the first cell’s value (for percentage calculations)
              const firstValue = rowData[0]?.[type];

              return (
                <tr key={rowIndex}>
                  {/* Row Label / Month Name */}
                  <td
                    className="py-100 !w-48 px-150 border-none bg-neutral-2 rounded-050 text-desktop-p-s sticky font-medium text-neutral-8 text-nowrap !uppercase text-left left-0 z-10"
                    align="left"
                  >
                    {key}
                  </td>

                  {/* Render each column in this row */}
                  {rowData.map((cellObj, colIndex) => {
                    const cellVal = cellObj[type];
                    let displayValue = "-";

                    // If we have a valid number
                    if (cellVal !== null && !isNaN(cellVal)) {
                      // Toggle: show as absolute (#) or percentage
                      if (showPercentage && firstValue && firstValue !== 0) {
                        const pct = (cellVal / firstValue) * 100;
                        displayValue = `${pct.toFixed(1)}%`;
                      } else {
                        displayValue = String(cellVal);
                      }
                    }

                    // By default, white background
                    let backgroundColor = "#FFFFFF";

                    // If rowMin === rowMax, you might choose to make them all dark, or all mid, etc.
                    if (rowMax === rowMin && !isNaN(cellVal)) {
                      backgroundColor = DARK_COLOR;
                    }
                    // Otherwise, find the ratio and pick from rowShades
                    else if (
                      cellVal !== null &&
                      !isNaN(cellVal) &&
                      rowMax !== rowMin
                    ) {
                      const ratio = (cellVal - rowMin) / (rowMax - rowMin);
                      const shadeIndex = Math.round(ratio * (rowLength - 1));
                      backgroundColor = rowShades[shadeIndex];
                    }

                    const textColor = getContrastingTextColor(backgroundColor);

                    return (
                      <td
                        key={colIndex}
                        style={{
                          backgroundColor,
                          color: textColor,
                        }}
                        className="rounded-050 border-none py-100 px-150 text-right text-desktop-p-s text-nowrap "
                      >
                        {!showPercentage
                          ? Math.round(displayValue)
                          : displayValue}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CohortTable;
