import { useState, useEffect, useRef } from "react";

const ReadMore = ({ text, maxLines = 3 }) => {
  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);

  const toggleReadMore = () => setExpanded((prev) => !prev);

  useEffect(() => {
    if (contentRef.current) {
      const element = contentRef.current;
      const isContentOverflowing = element.scrollHeight > element.offsetHeight;
      setIsOverflowing(isContentOverflowing);
    }
  }, [text, maxLines]);

  return (
    <div>
      <div
        ref={contentRef}
        style={{
          overflow: "hidden",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: expanded ? "unset" : maxLines,
          textOverflow: "ellipsis",
        }}
      >
        {text || "-"}
      </div>
      {isOverflowing && (
        <button
          onClick={toggleReadMore}
          className="text-blue-500 mt-[5px] cursor-pointer border-none p-0 bg-none font-medium text-desktop-p-mb"
        >
          {expanded ? "Read Less" : "Read More"}
        </button>
      )}
    </div>
  );
};

export default ReadMore;
