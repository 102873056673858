import React, { useEffect, useState } from "react";
import Navbar from "./components/navbar";
import Sidebar from "./components/sidebar";
import analyticsbg from "../../Assets/Images/icons/dataroomBG.png";
import lock from "../../Assets/Images/icons/lock.svg";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { investorState } from "../Investor/Dashboard/investorState";
import { navItems } from "../Investor/Dashboard/companyDetails/navItems";
import { getAllDealInteractions } from "../../endpoints/deal";
import { useLoader } from "./LoaderProvider";
const TempDataroom = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const { investorId } = useRecoilValue(investorState);
  const { dealId } = useParams();
  const [stage, setStage] = useState("interested");
  const fetchAllInteractions = async () => {
    try {
      const response = await getAllDealInteractions(dealId, investorId, token);
      loader.stop();
      if (response.data) {
        const lastResponse = response.data[response.data.length - 1];
        setStage(lastResponse.Stage);
      }
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    if (dealId && investorId) fetchAllInteractions();
  }, [dealId, investorId]);
  return (
    <>
      <div>
        <Navbar
          navItems={navItems(navigate, dealId, stage)}
          title={"Company Profile"}
        />
        <Sidebar userRole="investor" type={"current"} active={"ongoingDeals"} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            alignItems: "center",
            height: "fit-content",
            width: "333px",
            backgroundColor: "#FFF",
            borderRadius: "6px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
            zIndex: 10,
            padding: "24px",
            border: "1px solid #D9D9D9",
          }}
        >
          <div className="gap-300 flex flex-col items-center justify-between">
            <div>
              <div className={`${"bg-[#FFCDB4]"}  p-150 px-200 rounded`}>
                <img src={lock} alt="lock" />
              </div>
            </div>
            <p className="text-center">
              {" "}
              You don’t have access to the company’s dataroom yet. Request
              access after your conversation with the company.
            </p>
          </div>
        </Box>
        <div className="custom-container pt-1">
          <div>
            <img
              src={analyticsbg}
              alt="analyticsbg"
              style={{
                clipPath: "inset(0 0 0 20px)",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TempDataroom;
