import React, { useState, useEffect } from "react";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export default function BreadCrumbs({ breadCrumbs, BreadCrumbClick }) {
  const [condensed, setCondensed] = useState(true);
  const [visibleBreadcrumbs, setVisibleBreadcrumbs] = useState([]);
  const [hiddenBreadcrumbs, setHiddenBreadcrumbs] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  useEffect(() => {
    if (condensed && breadCrumbs.length > 4) {
      setVisibleBreadcrumbs(
        breadCrumbs.slice(0, 1).concat(["•••"], breadCrumbs.slice(-2))
      );
      setHiddenBreadcrumbs(breadCrumbs.slice(1, -2));
    } else {
      setVisibleBreadcrumbs(breadCrumbs);
      setHiddenBreadcrumbs([]);
    }
  }, [breadCrumbs, condensed]);

  return (
    <>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            sx: {
              boxShadow: "none", // Remove box shadow
              border: "1px solid #D9D9D9", // Add border
              borderRadius: "4px", // Optional: Add rounded corners
              padding: "0px 8px",
            },
          },
        }}
      >
        {hiddenBreadcrumbs.map((breadCrumb, index) => (
          <MenuItem
            key={breadCrumb.label || index}
            onClick={() => {
              BreadCrumbClick(breadCrumb.path, index + 1);
              handleMenuClose();
            }}
            sx={{
              "&:hover": {
                backgroundColor: "#E7EDFD",
                borderRadius: "4px",
              },
            }}
          >
            {breadCrumb.label}
          </MenuItem>
        ))}
      </Menu>
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumbs"
        sx={{
          padding: "0px",
        }}
      >
        {visibleBreadcrumbs.map((breadCrumb, index) => {
          const isLast = index === visibleBreadcrumbs.length - 1;
          const isEllipsis = breadCrumb === "•••";

          if (isEllipsis) {
            return (
              <IconButton
                key="ellipsis"
                size="small"
                color="primary"
                onClick={handleMenuOpen}
              >
                <MoreHorizIcon />
              </IconButton>
            );
          }

          return isLast ? (
            <Typography
              key={breadCrumb.label || index}
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                color: "#114FEE",
                fontWeight: "600",
                padding: "0px 10px",
              }}
            >
              {breadCrumb.label}
            </Typography>
          ) : (
            <div
              key={breadCrumb.label || index}
              className="flex items-center cursor-pointer"
              onClick={() => BreadCrumbClick(breadCrumb.path, index)}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  padding: "0px 8px",
                  fontWeight: "600",
                  color: "#141414",
                }}
              >
                {breadCrumb.label}
              </Typography>
            </div>
          );
        })}
      </Breadcrumbs>
    </>
  );
}
