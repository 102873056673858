import React, { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga4";
import star from "../../../Assets/Images/InvestorPage/Dashboard/star.svg";
import filledStar from "../../../Assets/Images/InvestorPage/Dashboard/filledStar.svg";
import LinearProgressBar from "./linearProgressBar";
import NoNameProfile from "../components/company/noNameProfile";

import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { investorState } from "../../../Components/Investor/Dashboard/investorState";

import "react-circular-progressbar/dist/styles.css";
import "../../../CSS/InvestorPage/Dashboard/card.css";
import {
  addStartuptoWishlist,
  removeStartupFromWishlist,
  sendRequestModuleEmail,
} from "../../../endpoints/investor";
import mixpanel from "mixpanel-browser";
import { ToastContainer } from "react-toastify";
import CustomBlackBtn from "./custom/customBlackBtn";
import { Bolt, Check } from "@mui/icons-material";
import CustomTooltip from "./custom/customToolTip";
import industriesIcon from "../../../Assets/Images/icons/sidebar/investorProfile.svg";
import { PiConfetti } from "react-icons/pi";
import CustomDialog from "./custom/customDialog";
import lockIcon from "../../../Assets/Images/icons/lock.svg";
import Toast from "../Toast";
const Card = ({
  deal,
  isStar,
  investorId,
  startPoint,
  isRecommended,
  isPassed,
  stage,
  investorVerified,
  profileCompleted,
  elementRef,
  index,
  setWishlistDealIds,
  primaryModule,
  secondaryModule,
  primaryModuleRequest,
  secondaryModuleRequest,
}) => {
  const {
    organizationId,
    noNameTitle,
    noNameSubHeading,
    roundType,
    industryVerticals,
    subIndustryVerticals,
    backing,
    endMarket,
    fundingRequired,
    fundingRaised,
    dealTags,
    financialMetrics,
    dealType,
  } = deal;
  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [state, setState] = useRecoilState(investorState);
  const [cardTags, setCardTags] = useState([]);
  const containerRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState([]);
  const [remainingTags, setRemainingTags] = useState(0);
  const [isStarFilled, setIsStarFilled] = useState(isStar);
  const [firstTag, setFirstTag] = useState();
  const [cardClick, setCardClick] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [isPrimaryOpen, setIsPrimaryOpen] = useState(true);
  const [isSecondaryOpen, setIsSecondaryOpen] = useState(true);
  const [isPrimaryModuleRequestOpen, setIsPrimaryModuleRequestOpen] =
    useState(primaryModuleRequest);
  const [isSecondaryModuleRequestOpen, setIsSecondaryModuleRequestOpen] =
    useState(secondaryModuleRequest);
  const [percentageFundingRaised, setPercentageFundingRaised] = useState(0);
  function processFirstTag() {
    try {
      setFirstTag(dealTags);
    } catch (error) {}
  }
  useEffect(() => {
    processFirstTag();
  }, [dealTags]);
  const requestModuleEmail = async (module) => {
    const response = await sendRequestModuleEmail(investorId, module, token);
    if (response.data) {
      if (module === "primary") {
        setIsPrimaryModuleRequestOpen(true);
      } else if (module === "secondary") {
        setIsSecondaryModuleRequestOpen(true);
      }
      Toast(`Module request sent successfully!`, `success`, `newDeals`);
    } else {
      Toast(`Failed to send module request`, `error`, `newDeals`);
    }
  };
  const addDealToWishlist = async (dealId, investorId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await addStartuptoWishlist(dealId, investorId, token);

      if (response.data.status === 200) {
        setWishlistDealIds((prev) => {
          return [...prev, dealId];
        });
      }
      if (!response) {
      } else if (response.data !== null) {
        // Toast("Added to wishlist", "success");
      }
    } catch (error) {}
  };

  const removeDealFromWishlist = async (dealId, investorId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await removeStartupFromWishlist(
        dealId,
        investorId,
        token
      );
      if (response.data.status === 200) {
        setWishlistDealIds((prev) => {
          return prev.filter((id) => id !== dealId);
        });
      }
      if (!response) {
      } else if (response.data !== null) {
        // Toast("Removed from wishlist", "success");
      }
    } catch (error) {}
  };
  useEffect(() => {
    const computeCardTags = () => {
      const tags = [];

      // Handle industryVerticals parsing
      try {
        if (industryVerticals.length > 0) {
          if (Array.isArray(industryVerticals)) tags.push(...industryVerticals);
        }
      } catch (error) {
        console.error("Error parsing Industry Verticals:", error);
      }
      try {
        if (subIndustryVerticals.length > 0) {
          if (Array.isArray(subIndustryVerticals))
            tags.push(...subIndustryVerticals);
        }
      } catch (error) {
        console.error("Error parsing sub Industry Verticals:", error);
      }
      // Handle endMarket parsing
      try {
        if (endMarket?.length > 0) {
          if (Array.isArray(endMarket)) tags.push(...endMarket);
        }
      } catch (error) {
        console.error("Error parsing End Market Focus:", error);
        tags.push("_"); // Fallback value
      }
      try {
        if (backing?.length > 0) {
          const fundingTypes = [backing];
          if (Array.isArray(fundingTypes)) tags.push(...fundingTypes);
        }
      } catch (error) {
        console.error("Error parsing Backing:", error);
      }
      return tags;
    };

    // Set computed card tags
    setCardTags(computeCardTags());
  }, [roundType, industryVerticals, endMarket]);
  const handleStarClick = async () => {
    setIsStarFilled((prevIsStarFilled) => {
      const newIsStarFilled = !prevIsStarFilled;
      if (newIsStarFilled) {
        addDealToWishlist(deal.dealId, investorId);
      } else {
        removeDealFromWishlist(deal.dealId, investorId);
      }
      return newIsStarFilled;
    });
  };
  function navigateToCompanyDetails() {
    if (role === "company") {
      Navigate("/company/dashboard", {
        state: {
          deal,
        },
      });
    }
    if (startPoint === "noNameDeals") {
      const label = `No Name Deals Card - ${deal.dealId}`;
      ReactGA.event({
        category: "navigation",
        action: "button_click",
        label: label,
      });
      mixpanel.track("No Name Deal Card Click", {
        dealId: deal?.dealId,
      });
      if (token && role === "investor") {
        Navigate("/investor/newDeals/allListing", {
          state: {
            deal: deal,
            passedStage: stage,
          },
        });
      } else {
        elementRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      if (!investorVerified || !profileCompleted) {
        setCardClick(true);
        return;
      }
      if (state.verified === false) {
      }
      setProfileOpen(true);
      // Navigate(`/investor/companyDetails`, {
      //   state: {
      //     deal: deal,
      //     passedStage: stage,
      //     isPassed: isPassed,
      //   },
      // });
    }
  }
  const handleHover = () => {
    if (role === "company" || role === "admin") {
      return;
    }
    if (
      (!investorVerified || !profileCompleted) &&
      startPoint !== "noNameDeals"
    ) {
      setCardClick(true);
      return;
    } else if (startPoint !== "noNameDeals") {
      if (!primaryModule) {
        setIsPrimaryOpen(false);
      }
      if (!secondaryModule) {
        setIsSecondaryOpen(false);
      }
      return;
    }
  };
  const handleHoverLeave = () => {
    setCardClick(false);
    setIsPrimaryOpen(true);
    setIsSecondaryOpen(true);
  };
  //Calculates the no. of tags that can fit in the width
  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth * 0.9;
      let totalWidth = 0;
      let tagsToShow = [];
      let remainingCount = 0;

      // Calculate width of "Recommended" tag if isRecommended is true
      let recommendedWidth = 0;
      if (isRecommended) {
        const recommendedElement = document.createElement("div");
        recommendedElement.style.display = "inline-block";
        recommendedElement.style.visibility = "hidden";
        recommendedElement.style.position = "absolute";
        recommendedElement.className = "Card-div-6";
        recommendedElement.innerHTML = `
          <div>
            <span class="icon"><Bolt /></span> Recommended
          </div>
        `;
        document.body.appendChild(recommendedElement);

        recommendedWidth = recommendedElement.offsetWidth;
        document.body.removeChild(recommendedElement);
      }

      // Adjust container width based on "Recommended" tag
      const availableWidth = isRecommended
        ? containerWidth - recommendedWidth
        : containerWidth;

      cardTags.forEach((tag) => {
        const tagElement = document.createElement("div");
        tagElement.style.display = "inline-block";
        tagElement.style.visibility = "hidden";
        tagElement.style.position = "absolute";
        tagElement.className = "Card-div-6";
        tagElement.innerText = tag;
        document.body.appendChild(tagElement);

        const tagWidth = tagElement.offsetWidth;
        document.body.removeChild(tagElement);

        if (totalWidth + tagWidth <= availableWidth) {
          totalWidth += tagWidth;
          tagsToShow.push(tag);
        } else {
          remainingCount = cardTags.length - tagsToShow.length;
          return false; // Break the loop
        }
      });

      setVisibleTags(tagsToShow);
      setRemainingTags(remainingCount);
    }
  }, [cardTags, isRecommended]);

  const isModuleRequestOpen = () => {
    if (dealType === "secondary") {
      return isSecondaryModuleRequestOpen;
    } else {
      return isPrimaryModuleRequestOpen;
    }
  };
  const isModuleOpen = () => {
    if (dealType === "secondary") {
      return !isSecondaryOpen;
    } else {
      return !isPrimaryOpen;
    }
  };
  useEffect(() => {
    try {
      if (!fundingRaised) {
        setPercentageFundingRaised(0);
        return;
      }
      const percentage = (fundingRaised / fundingRequired) * 100;
      setPercentageFundingRaised(percentage.toFixed(2));
    } catch (error) {
      setPercentageFundingRaised(0);
    }
  }, [fundingRaised, fundingRequired]);
  return (
    <>
      <div
        className="relative group w-[352px] flex flex-col bg-white rounded-md p-200 border border-neutral-5 no-name-card"
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverLeave}
      >
        <ToastContainer position="top-center" containerId={"card-container"} />
        <CustomDialog open={profileOpen} onClose={() => setProfileOpen(false)}>
          <NoNameProfile
            profileOpen={profileOpen}
            setProfileOpen={setProfileOpen}
            deal={deal}
            isRecommended={isRecommended}
            cardTags={cardTags}
            percentageFundingRaised={percentageFundingRaised}
            isStarFilled={isStarFilled}
            handleStarClick={handleStarClick}
            isPassed={isPassed}
          />
        </CustomDialog>
        <div
          className="card-with-details "
          style={{ cursor: "pointer" }}
          onClick={() => navigateToCompanyDetails()}
        >
          <div className="gap-200 flex flex-col">
            <div className="flex justify-between">
              <div
                className={`text-desktop-p-xs py-075 px-150 rounded font-semibold ${
                  dealType === "secondary"
                    ? "text-blue-700 bg-blue-50"
                    : "text-aero-blue-700 bg-aero-blue-50"
                }`}
              >
                {dealType === "secondary" ? "Secondary" : roundType}
              </div>
              <div className="gap-100 flex items-center">
                {startPoint !== "noNameDeals" && (
                  <CustomTooltip
                    position="bottom"
                    title="Add to wishlist"
                    theme="white"
                    arrow={false}
                    fontSize={"10px"}
                    fontWeight={"400"}
                    paddingX="20px"
                    display={isStarFilled ? "none" : "block"}
                  >
                    <div
                      className={`border-neutral-5 border rounded h-[26px] w-[26px] flex justify-center items-center ${
                        isPassed ? "hidden" : "flex"
                      }`}
                    >
                      <img
                        src={isStarFilled ? filledStar : star}
                        alt="star"
                        className="cursor-pointer w-[14px] h-[14px]"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleStarClick();
                        }}
                      />
                    </div>
                  </CustomTooltip>
                )}
                {dealTags && dealTags?.length > 0 && (
                  <div className="text-neutral-1 py-075 px-150 text-desktop-p-xs font-semibold bg-blue-400 rounded">
                    {dealTags}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col items-start rounded-[4px] gap-150">
              <div className="text-neutral-12 text-wrap font-semibold">
                {noNameTitle}
              </div>
              <div
                style={{
                  WebkitLineClamp: 3,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                className="text-neutral-8 text-desktop-p-s text-wrap"
              >
                {noNameSubHeading}
              </div>
            </div>
            <div
              className="gap-100 company-tags flex flex-wrap items-center w-full"
              ref={containerRef}
            >
              {isRecommended && (
                <div
                  style={{
                    position: "relative",
                    borderRadius: "6px", // Outer border radius
                    background:
                      "linear-gradient(-90deg, #00A6FB 0%, #114FEE 100%)", // Gradient border
                    padding: "2px", // Thickness of the gradient border
                  }}
                >
                  <div
                    className="Card-div-6 text-neutral-12 flex items-center font-semibold"
                    style={{
                      borderRadius: "5px", // Inner border radius (slightly less than outer)
                      background: "white", // Inner background color
                      padding: "4px 12px",
                      fontSize: "10px",
                    }}
                  >
                    <Bolt fontSize="10px" />
                    Recommended
                  </div>
                </div>
              )}
              {visibleTags.map((tag, index) => (
                <div
                  key={index}
                  className={` px-150 py-075 border-neutral-5 border-[0.5px] rounded-[4px] text-desktop-p-xs text-neutral-12 font-medium`}
                >
                  {tag}
                </div>
              ))}
              {remainingTags > 0 && (
                <div className={`Card-div-7 text-brand-blue text-desktop-p-s`}>
                  +{remainingTags}
                </div>
              )}
            </div>
            <div className="card-section-3 gap-150 flex flex-col">
              <p className="text-neutral-12 text-desktop-p-s">Current Round</p>
              <LinearProgressBar value={percentageFundingRaised} />
              <div className="text-desktop-p-xs flex justify-between">
                <p>
                  ₹{fundingRaised || 0}Cr ({percentageFundingRaised}% raised)
                </p>
                <p>₹{fundingRequired}Cr</p>
              </div>
            </div>
            <div>
              <div className="flex justify-around px-[2px]">
                {(financialMetrics &&
                typeof financialMetrics === "object" &&
                !Array.isArray(financialMetrics)
                  ? Object.entries(financialMetrics).slice(0, 4)
                  : Array(4).fill(["-", "-"])
                ).map(([key, value], index, array) => (
                  <React.Fragment key={key || index}>
                    <div className="flex-nowrap flex flex-col items-center justify-center">
                      <div className="text-neutral-8 text-desktop-p-xs">
                        {key || "-"}
                      </div>{" "}
                      <div className="text-neutral-12 text-desktop-p-lb">
                        {value || "-"}
                      </div>{" "}
                    </div>
                    {index < array.length - 1 && (
                      <div style={{ borderRight: "1px solid #D9D9D9" }}></div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className="pending-profile"
          style={{ display: cardClick ? "flex" : "none" }}
        >
          <img src={pendingProfile} alt="pending" />
          <p>
            {profileCompleted
              ? "You're on the Waitlist! Hang tight—exciting investment opportunities are just around the corner!"
              : "Please complete your profile to view the details of the company."}
          </p>
          <div
            onClick={() => {
              Navigate("/investor/account", {
                state: {
                  activeButton: 2,
                },
              });
            }}
            style={{
              display: profileCompleted ? "none" : "flex",
            }}
          >
            <CustomBlackBtn text="Complete Profile" type="button" />
          </div>
        </div> */}
        <div
          style={{
            display: cardClick || isModuleOpen() ? "flex" : "none",
          }}
          className="bg-opacity-10 backdrop-blur-md group-hover:opacity-100 absolute inset-0 flex items-center justify-center transition duration-300 bg-white opacity-0"
        >
          <div className="px-[60px] flex flex-col justify-center items-center gap-300">
            {cardClick && (
              <div
                className={`${
                  profileCompleted ? "bg-blue-100" : "bg-[#FFCDB4]"
                }  p-150 px-200 rounded`}
              >
                {profileCompleted ? (
                  <PiConfetti fontSize={"24px"} />
                ) : (
                  <div
                    style={{
                      width: "18px",
                      height: "24px",
                      backgroundColor: `#262626`,
                      mask: `url(${industriesIcon}) no-repeat center`,
                    }}
                  />
                )}
              </div>
            )}
            {(!isPrimaryOpen || !isSecondaryOpen) && cardClick === false && (
              <div
                className={` ${
                  isModuleRequestOpen() ? "bg-blue-100" : "bg-[#FFCDB4]"
                } p-150 px-200 rounded`}
              >
                {isSecondaryModuleRequestOpen ? (
                  <PiConfetti fontSize={"24px"} />
                ) : (
                  <img src={lockIcon} alt="lock" />
                )}
              </div>
            )}

            {cardClick && (
              <p className="text-neutral-12 text-desktop-p-mb text-center">
                {profileCompleted
                  ? "You're on the Waitlist! Hang tight exciting investment opportunities are just around the corner!"
                  : " Complete your profile to view the details of the company"}
              </p>
            )}
            {!isPrimaryOpen &&
              cardClick === false &&
              dealType !== "secondary" && (
                <>
                  <p className="text-neutral-12 text-desktop-p-mb text-center">
                    {!isPrimaryModuleRequestOpen
                      ? "You currently don't have access to primary deals"
                      : "You're on the Waitlist! Hang tight exciting investment opportunities are just around the corner!"}
                  </p>
                  {!isPrimaryModuleRequestOpen && (
                    <CustomBlackBtn
                      mode="blue"
                      text={`Request Access`}
                      border="sm"
                      variant="sm"
                      onClick={() => requestModuleEmail(`primary`)}
                    />
                  )}
                </>
              )}
            {!isSecondaryOpen &&
              cardClick === false &&
              dealType === "secondary" && (
                <>
                  <p className="text-neutral-12 text-desktop-p-mb text-center">
                    {!isSecondaryModuleRequestOpen
                      ? "You currently don't have access to secondary deals"
                      : "You're on the Waitlist! Hang tight exciting investment opportunities are just around the corner!"}
                  </p>
                  {!isSecondaryModuleRequestOpen && (
                    <CustomBlackBtn
                      mode="blue"
                      text={`Request Access`}
                      border="sm"
                      variant="sm"
                      onClick={() => requestModuleEmail(`secondary`)}
                    />
                  )}
                </>
              )}
            {!profileCompleted && (
              <CustomBlackBtn
                mode="blue"
                text="Complete Your Profile"
                border="sm"
                variant="sm"
                onClick={() => {
                  Navigate("/investor/profile");
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
