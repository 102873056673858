import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../LoaderProvider";
import { ToastContainer } from "react-toastify";
import { Box, Modal } from "@mui/material";
import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { StyledFormControl, StyledFormLabel } from "../functions";
import CustomInput from "../components/custom/customInput";
import CustomBlackBtn from "../components/custom/customBlackBtn";
import { postStageData } from "../../../endpoints/deal";
import Toast from "../Toast";
import { getLatestDealInterestByInvestorDealId } from "../../../endpoints/secondary";

const SecondaryFinalBid = ({
  secondaryFinalBidModal,
  setSecondaryFinalBidModal,
  deal,
  investorID,
  setStage,
  view,
  getLatestDealInterest,
}) => {
  const loader = useLoader();
  const token = localStorage.getItem("token");
  const isClosed = secondaryFinalBidModal === false;
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    if (!secondaryFinalBidModal) setPercentage(0);
  }, [isClosed]);
  const [initialValues, setInitialValues] = useState({
    investmentAmount: null,
    valuation: null,
    stake: null,
  });
  const validationSchema = Yup.object({
    investmentAmount: Yup.number()
      .required("Bid Amount is required")
      .positive("Bid Amount must be greater than 0")
      .max(
        deal?.fundingRequired,
        "Bid Amount must not be greater than the deal size"
      ),
    valuation: Yup.number()
      .required("Valuation is required")
      .positive("Valuation must be greater than 0"),
    stake: Yup.number().max(
      deal?.stakeOffered,
      "Stake must be less than stake offered"
    ),
  });
  const calculateStakeAcquired = (investmentAmount, valuation) => {
    if (!investmentAmount || !valuation) return 0;
    return (investmentAmount / valuation) * 100;
  };
  const calculatePercentage = (value, total) => {
    setPercentage((((value || 0) / total) * 100).toFixed(2));
  };
  const handleSubmit = async (values) => {
    const formData = {
      investorID: investorID,
      dealId: deal?.dealId,
      IsInterested: true,
      Stage: "loiSubmitted",
      dealType: "secondary",
      LOI_Bid_Details: {
        investmentAmount: values.investmentAmount,
        valuation: values.valuation,
        stake: values.stake,
      },
    };
    loader.start("Submitting Bid");
    const response = await postStageData(formData, token, true);
    loader.stop();
    if (response.data) {
      setStage("loiSubmitted");
      setSecondaryFinalBidModal(false);
      getLatestDealInterest();
    } else {
      Toast("Failed to submit bid!", "error", "secondaryFinalBid");
    }
  };
  return (
    <>
      <ToastContainer position="top-center" containerId={`secondaryFinalBid`} />
      <Modal
        open={secondaryFinalBidModal}
        onClose={() => setSecondaryFinalBidModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box"
          sx={{
            width: "650px",
            padding: "20px",
          }}
        >
          <div className="IOI-bid-modal-heading-section">
            <p>Final Bid Details</p>
            <img
              src={closeIcon}
              alt="close"
              style={{
                cursor: "pointer",
              }}
              onClick={() => setSecondaryFinalBidModal(false)}
            />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              touched,
              handleChange: formikHandleChange,
              handleBlur,
              setFieldValue,
              values,
            }) => {
              const handleChange = (e) => {
                formikHandleChange(e); // Let Formik handle the value change
                if (e.target.name === "valuation") {
                  setFieldValue(
                    "stake",
                    Number(
                      calculateStakeAcquired(
                        values.investmentAmount,
                        e.target.value
                      )
                    )
                  );
                }
                if (e.target.name === "investmentAmount") {
                  setFieldValue(
                    "stake",
                    Number(
                      calculateStakeAcquired(e.target.value, values.valuation)
                    )
                  );
                  calculatePercentage(e.target.value, deal?.fundingRequired);
                }
              };
              return (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "40px",
                    width: "100%",
                  }}
                >
                  <div className="IOI-input-fields">
                    <div className="IOI-first-section">
                      <StyledFormControl>
                        <StyledFormLabel>Bid Amount (₹Cr)</StyledFormLabel>
                        <CustomInput
                          placeholder={`Enter Amount`}
                          name={`investmentAmount`}
                          type="number"
                          value={values.investmentAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.investmentAmount && errors.investmentAmount
                          )}
                          helperText={
                            touched.investmentAmount && errors.investmentAmount
                          }
                          endDecorator={
                            <p className="ioi-endDecorator">
                              {`${percentage} % of deal`}
                            </p>
                          }
                          disabled={view}
                        />
                        <p className="helper-text-input">
                          Total Deal size: {deal?.fundingRequired}Cr
                        </p>
                      </StyledFormControl>
                      <StyledFormControl fullWidth>
                        <StyledFormLabel>Valuation (₹Cr)</StyledFormLabel>
                        <CustomInput
                          placeholder="Enter Valuation"
                          name="valuation"
                          type="number"
                          value={values.valuation}
                          onChange={handleChange} // Use custom handleChange here
                          onBlur={handleBlur}
                          error={Boolean(touched.valuation && errors.valuation)}
                          helperText={touched.valuation && errors.valuation}
                          disabled={view}
                        />
                        <p className="helper-text-input">
                          {`Expected Valuation : ${deal?.expectedValuation}Cr`}
                        </p>
                      </StyledFormControl>
                      <StyledFormControl>
                        <StyledFormLabel>Stake (%)</StyledFormLabel>
                        <CustomInput
                          disabled
                          placeholder={`Enter Stake`}
                          name={"stake"}
                          type="number"
                          value={values.stake?.toFixed(2)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.stake && errors.stake)}
                          helperText={touched.stake && errors.stake}
                        />
                        <p className="helper-text-input">
                          Total Stake Offered: {deal?.stakeOffered}%
                        </p>
                      </StyledFormControl>
                    </div>
                  </div>
                  {!view && (
                    <div className="sign-in-btn-section">
                      <CustomBlackBtn type="submit" text="Submit" />
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

export default SecondaryFinalBid;
