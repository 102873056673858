import React, { useState, useRef, useEffect } from "react";
import CustomTable from "../custom/customTable";
import { getCompanyLogo } from "../../functions";
import CustomBlackBtn from "../custom/customBlackBtn";
import CustomTooltip from "../custom/customToolTip";
import SectionContainer from "../SectionContainer";
import ReadMore from "../readMore";
import FilterBox from "../filterBox";
import { useNavigate } from "react-router-dom";
import {
  SectionTitle,
  SectionDescription,
  StatCard,
  ProfileHeader,
} from "./profileHelpers";

import seeAllInvestments from "../../../../Assets/Images/InvestorPage/seeAllInvestments.svg";
import blackArrow from "../../../../Assets/Images/icons/blackArrow.svg";
import DefaultCompany from "../../../../Assets/Images/icons/defaultCompany.svg";
import Sort from "../../../../Assets/Images/icons/sort.svg";

const returnParsedArray = (StringifiedArray) => {
  try {
    const array = JSON.parse(StringifiedArray);
    return array;
  } catch (error) {
    return [];
  }
};
const TruncateText = (value, maxWidth = 200) => {
  const parsedArray = returnParsedArray(value);
  const containerRef = useRef(null);
  const [visibleCount, setVisibleCount] = useState(parsedArray.length);

  useEffect(() => {
    if (!containerRef.current) return;

    const calculateVisibleElements = () => {
      const containerWidth = containerRef?.current?.offsetWidth;
      let totalWidth = 0;
      let count = 0;

      for (const text of parsedArray) {
        const tempElement = document.createElement("span");
        tempElement.style.visibility = "hidden";
        tempElement.style.whiteSpace = "nowrap";
        tempElement.style.position = "absolute";
        tempElement.innerText = `${text}, `;
        document.body.appendChild(tempElement);

        const elementWidth = tempElement.offsetWidth;
        document.body.removeChild(tempElement);

        totalWidth += elementWidth;
        if (totalWidth > containerWidth) break;
        count++;
      }

      setVisibleCount(count);
    };

    calculateVisibleElements();

    const resizeObserver = new ResizeObserver(() => calculateVisibleElements());
    resizeObserver.observe(containerRef.current);

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [parsedArray, maxWidth]);

  return (
    <div ref={containerRef} className="flex-nowrap flex w-full">
      {parsedArray.length > visibleCount ? (
        <CustomTooltip
          title={
            <div className="gap-150 flex flex-col">
              {parsedArray.map((ele, index) => (
                <p key={index} className="font-satoshi">
                  {ele}
                </p>
              ))}
            </div>
          }
          className="min-w-[190px]"
          position="bottom"
          theme="white"
          arrow={false}
          offset={[0, 10]}
        >
          <div className="flex-nowrap flex w-full">
            <p>{parsedArray.slice(0, visibleCount).join(", ")} ,</p>
            <p className="text-brand-blue">
              +{parsedArray.length - visibleCount}
            </p>
          </div>
        </CustomTooltip>
      ) : (
        <p>{parsedArray.join(", ")}</p>
      )}
    </div>
  );
};
const investorStats = (investor) => {
  return {
    overview: [
      {
        icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/founded.svg",
        label: "Founded",
        value: investor?.foundedYear || "-",
      },
      {
        icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/headquarters.svg",
        label: "Headquarters",
        value: investor?.headquarters || "-",
      },
      {
        icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/employees.svg",
        label: "Employees",
        value: investor?.employeeCount || "-",
      },
      {
        icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/noOfInvesments.svg",
        label: "No. of Investments",
        value: returnParsedArray(investor?.keyInvestmentsDetails).length || 0,
      },
    ],
    investmentFocus: [
      {
        icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/assestClass.svg",
        label: "Asset Class",
        value: TruncateText(investor.assetClass) || "-",
      },
      {
        icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/ticketSize.svg",
        label: "Ticket Size",
        value:
          `₹${returnParsedArray(investor.ticketSizevalue)[0] || 0} Cr - ₹${
            returnParsedArray(investor.ticketSizevalue)[1] || 0
          } Cr` || "-",
      },
      {
        icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/portfoiloExposure.svg",
        label: "Portfolio Exposure",
        value:
          `₹${returnParsedArray(investor.totalExposure)[0] || 0} Cr - ₹${
            returnParsedArray(investor.totalExposure)[1] || 0
          } Cr` || "-",
      },
      {
        icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/stakeAcquired.svg",
        label: "Stake Acquired",
        value:
          `${returnParsedArray(investor.stakeAcquired)[0] || 0}% - ${
            returnParsedArray(investor.stakeAcquired)[1] || 0
          }%` || "-",
      },
    ],
    investmentCriteria: {
      publicProfile: [
        {
          icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/ticketSize.svg",
          label: "Ticket Size",
          value:
            `₹${returnParsedArray(investor.ticketSizevalue)[0]} Cr - ₹${
              returnParsedArray(investor.ticketSizevalue)[1]
            } Cr` || "-",
        },
        {
          icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/assestClass.svg",
          label: "Asset Class",
          value: TruncateText(investor.assetClass) || "-",
        },
        {
          icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/endMarketFocus.svg",
          label: "End Market Focus",
          value: TruncateText(investor.endMarketFocus) || "-",
        },
        {
          icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/stageOfInvestment.svg",
          label: "Stage of Investment",
          value: TruncateText(investor.investmentStage) || "-",
        },
      ],
      investorProfile: [
        {
          icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/industries.svg",
          label: "Industries",
          value: TruncateText(investor.industryVerticalOptions) || "-",
        },
        {
          icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/endMarketFocus.svg",
          label: "End Market Focus",
          value: TruncateText(investor.endMarketFocus) || "-",
        },
        {
          icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/stageOfInvestment.svg",
          label: "Stage of Investment",
          value: TruncateText(investor.investmentStage) || "-",
        },
        {
          icon: "https://fundrevstorage2.blob.core.windows.net/website-images/icons/financialCriteria.svg",
          label: "Financial Criteria",
          value: TruncateText(investor.financialCriteria) || "-",
        },
      ],
    },
  };
};

//Don't use tailwind for this component as need to add line-clamp separately

function KeyInvestments({ keyInvestmentsData }) {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = x - startX; // Calculate how far the mouse has moved
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUpOrLeave = () => {
    setIsDragging(false);
  };

  return (
    <div
      ref={containerRef}
      className="investment-scrollbar relative flex items-start gap-3 pb-2 overflow-x-scroll"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUpOrLeave}
      onMouseLeave={handleMouseUpOrLeave}
      style={{
        cursor: isDragging ? "grabbing" : "grab",
      }}
    >
      {keyInvestmentsData.map((startup, index) => (
        <InvestmentCard key={index} {...startup} />
      ))}
    </div>
  );
}

function InvestorProfile({ investor, isCompany = true }) {
  const navigate = useNavigate();
  const [investmentData, setInvestmentData] = useState(["-"]);
  const [keyInvestmentsData, setKeyInvestmentsData] = useState([]);
  const [publicProfile, setPublicProfile] = useState(isCompany);
  const [showAllInvestments, setShowAllInvestments] = useState(false);
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  // const [industryVerticals, setIndustryVerticals] = useState([]);
  // const [roundsTypes, setRoundTypes] = useState([]);
  // const [selectedIndustryVerticals, setSelectedIndustryVerticals] = useState(
  //   []
  // );
  const [filteredKeyInvestments, setFilteredKeyInvestments] = useState([]);
  // const [selectedRoundTypes, setSelectedRoundTypes] = useState([]);
  const [yearSort, setYearSort] = useState("Newest First");
  const [nameSort, setNameSort] = useState("A - Z");
  // function clearAllFilters() {
  //   setSelectedIndustryVerticals([]);
  //   setSelectedRoundTypes([]);
  // }
  const keyInvestmentsRef = useRef(null);
  const columns = [
    "Fund",
    "Vintage",
    "Investments",
    "AUM (₹cr)",
    "Dry Powder (₹cr)",
  ];
  useEffect(() => {
    if (investor?.keyInvestmentsDetails) {
      const parsedKeyInvestmentsData = returnParsedArray(
        investor?.keyInvestmentsDetails
      );

      // const industries = [];
      // const roundTypes = [];

      const updatedKeyInvestmentsData = parsedKeyInvestmentsData.map(
        (investment) => {
          // Collect industry and stage
          // if (investment.industry) {
          //   industries.push(investment.industry);
          // }
          // if (investment.round) {
          //   roundTypes.push(investment.round);
          // }

          // Add logo and return the updated investment object
          return {
            ...investment,
            logo: getCompanyLogo(investment.website),
          };
        }
      );

      // Update states
      setKeyInvestmentsData(updatedKeyInvestmentsData);
      // setIndustryVerticals([...new Set(industries)]);
      // setSelectedIndustryVerticals([...new Set(industries)]);
      // setRoundTypes([...new Set(roundTypes)]);
      // setSelectedRoundTypes([...new Set(roundTypes)]);
    }
  }, []);

  useEffect(() => {
    let invData = [];
    if (investor?.investmentDetails) {
      const parsedInvestmentData = returnParsedArray(
        investor?.investmentDetails
      );
      parsedInvestmentData.map((data) => {
        const array = []; // Create a new array for each iteration
        array.push(data.fund);
        array.push(data.vintage);
        array.push(data.investments);
        array.push(data.aum);
        array.push(data.dryPowder);
        invData.push(array); // Push the newly created array into invData
      });
      setInvestmentData(invData);
    }
  }, [investor]);
  useEffect(() => {
    let data = [...keyInvestmentsData]; // Create a copy to avoid mutating the original array

    // Apply both sorting criteria simultaneously
    data.sort((a, b) => {
      // Sort by year first
      if (yearSort === "Newest First") {
        if (b.year !== a.year) return b.year - a.year;
      } else if (yearSort === "Oldest First") {
        if (b.year !== a.year) return a.year - b.year;
      }

      // If years are equal, sort alphabetically by company name
      if (nameSort === "A - Z") {
        return a.company.localeCompare(b.company);
      } else if (nameSort === "Z - A") {
        return b.company.localeCompare(a.company);
      }

      return 0; // If both criteria are equal
    });

    setFilteredKeyInvestments(data);
  }, [keyInvestmentsData, yearSort, nameSort]);

  // useEffect(() => {
  //   if (
  //     selectedIndustryVerticals.length === 0 &&
  //     selectedRoundTypes.length === 0
  //   ) {
  //     setFilteredKeyInvestments([]);
  //   } else {
  //     const filteredData = keyInvestmentsData.filter((investment) => {
  //       const matchesIndustry =
  //         selectedIndustryVerticals.length === 0 ||
  //         selectedIndustryVerticals.includes(investment.industry);

  //       const matchesRound =
  //         selectedRoundTypes.length === 0 ||
  //         selectedRoundTypes.includes(investment.round);

  //       return matchesIndustry && matchesRound;
  //     });

  //     setFilteredKeyInvestments(filteredData);
  //   }
  // }, [keyInvestmentsData, selectedIndustryVerticals, selectedRoundTypes]);

  const filters = [
    {
      title: "By Date",
      options: ["Newest First", "Oldest First"],
      selectedOptions: yearSort,
      setSelectedOptions: setYearSort,
      isSortFilter: true,
    },
    {
      title: "By Name",
      options: ["A - Z", "Z - A"],
      selectedOptions: nameSort,
      setSelectedOptions: setNameSort,
      isSortFilter: true,
    },
  ];
  return (
    <div className="gap-200 grid h-full grid-cols-12 overflow-scroll">
      <div className="col-span-2"></div>
      <div className="col-span-8">
        <div
          className="investor-profile gap-250 flex flex-col w-full h-full m-auto overflow-scroll"
          style={{
            marginBottom: isCompany ? "100px" : "0px",
          }}
        >
          {isCompany && showAllInvestments && (
            <div className="flex items-center justify-between">
              <p
                className="text-neutral-12 text-desktop-p-2xl scroll-m-10"
                ref={keyInvestmentsRef}
              >
                {"Key Investments"}
              </p>
              <div className="gap-200 flex">
                <CustomBlackBtn
                  text={
                    <p className="font-medium">
                      <div className="gap-100 flex">
                        <img
                          src={blackArrow}
                          alt="arrow"
                          className="rotate-[-180deg]"
                        />
                        Back
                      </div>
                    </p>
                  }
                  filled={false}
                  border="sm"
                  variant="sm"
                  onClick={() => {
                    setShowAllInvestments(false);
                  }}
                />
                <button
                  className="flex items-center gap-2 px-3 border border-[#002132] rounded-[4px]"
                  onClick={() => setFilterBoxOpen(!filterBoxOpen)}
                  id="toggleButton"
                >
                  <img src={Sort} alt={"sort"} />
                </button>
              </div>
            </div>
          )}
          {!isCompany && !publicProfile && !showAllInvestments && (
            <div className="flex items-center justify-between">
              <p
                className="text-neutral-12 text-desktop-p-2xl scroll-m-10"
                ref={keyInvestmentsRef}
              >
                {"Investor Profile"}
              </p>
              <CustomBlackBtn
                text={<p className="font-medium">View Public Profile</p>}
                filled={false}
                border="sm"
                variant="sm"
                onClick={() => {
                  setPublicProfile(!publicProfile);
                }}
              />
            </div>
          )}
          {!isCompany && publicProfile && !showAllInvestments && (
            <div className="flex items-center justify-between">
              <p
                className="text-neutral-12 text-desktop-p-2xl scroll-m-10"
                ref={keyInvestmentsRef}
              >
                {"Investor Profile"}
              </p>
              <CustomBlackBtn
                text={
                  <p className="font-medium">
                    <div className="gap-100 flex">
                      <img
                        src={blackArrow}
                        alt="arrow"
                        className="rotate-[-180deg]"
                      />
                      Back
                    </div>
                  </p>
                }
                filled={false}
                border="sm"
                variant="sm"
                onClick={() => {
                  setPublicProfile(!publicProfile);
                }}
              />
            </div>
          )}
          {!isCompany && publicProfile && showAllInvestments && (
            <div className="flex items-center justify-between">
              <p
                className="text-neutral-12 text-desktop-p-2xl scroll-m-10"
                ref={keyInvestmentsRef}
              >
                {"Key Investments"}
              </p>
              <div className="gap-200 flex">
                <CustomBlackBtn
                  text={
                    <p className="font-medium">
                      <div className="gap-100 flex">
                        <img
                          src={blackArrow}
                          alt="arrow"
                          className="rotate-[-180deg]"
                        />
                        Back
                      </div>
                    </p>
                  }
                  filled={false}
                  border="sm"
                  variant="sm"
                  onClick={() => {
                    setShowAllInvestments(false);
                  }}
                />
                <button
                  className="flex items-center gap-2 px-3 border border-[#002132] rounded-[4px]"
                  onClick={() => setFilterBoxOpen(!filterBoxOpen)}
                  id="toggleButton"
                >
                  <img src={Sort} alt={"sort"} />
                </button>
              </div>
            </div>
          )}
          {!showAllInvestments ? (
            <div className="gap-200 flex flex-col">
              <SectionContainer
                title={"Overview"}
                action={!isCompany && !publicProfile && "edit"}
                actionFunction={() =>
                  navigate("/investor/profile", {
                    state: {
                      editMode: true,
                      activeSection: "Overview",
                    },
                  })
                }
              >
                <ProfileHeader data={investor} role="investor" />

                <div className="grid w-full min-[1300px]:grid-cols-4 grid-cols-3 gap-6">
                  {investorStats(investor)?.overview?.map((stat, index) => (
                    <StatCard
                      key={index}
                      icon={stat.icon}
                      label={stat.label}
                      value={stat.value}
                    />
                  ))}
                </div>

                <div className="gap-150 flex flex-col">
                  <SectionTitle>Firm Description</SectionTitle>
                  <SectionDescription>
                    <ReadMore
                      text={investor?.investorDescription}
                      maxLines={3}
                    />
                  </SectionDescription>

                  {/* <button className="mt-2 font-medium text-left text-blue-500">
            Read more
          </button> */}
                </div>
              </SectionContainer>
              {publicProfile ? (
                <>
                  <SectionContainer
                    title={"Investment Highlights"}
                    action={!isCompany && !publicProfile ? "edit" : "Filter"}
                    actionFunction={() => setFilterBoxOpen(!filterBoxOpen)}
                    className="relative"
                  >
                    <FilterBox
                      filters={filters}
                      filterBoxOpen={filterBoxOpen}
                      setFilterBoxOpen={setFilterBoxOpen}
                      clearAllFilters={() => {}}
                      clearAll={false}
                      className={"absolute right-4 top-8 !w-[190px]"}
                    />
                    <div className="gap-150 flex flex-col">
                      <SectionTitle>Key Investments</SectionTitle>
                      <div className="gap-x-300 gap-y-300 grid grid-cols-3">
                        {filteredKeyInvestments.length > 8 ? (
                          <>
                            {filteredKeyInvestments
                              .slice(0, 8)
                              .map((startup) => (
                                <InvestmentCard key={startup.id} {...startup} />
                              ))}
                            <img
                              className="cursor-pointer"
                              src={seeAllInvestments}
                              alt="See all investments"
                              onClick={() => {
                                setShowAllInvestments(true);
                                keyInvestmentsRef.current?.scrollIntoView({
                                  behavior: "smooth",
                                });
                              }}
                            />
                          </>
                        ) : (
                          filteredKeyInvestments.map((startup) => (
                            <InvestmentCard key={startup.id} {...startup} />
                          ))
                        )}
                      </div>
                    </div>
                  </SectionContainer>
                  <SectionContainer
                    title={"Investment Criteria"}
                    action={!isCompany && !publicProfile && "edit"}
                    actionFunction={() =>
                      navigate("/investor/profile", {
                        state: {
                          editMode: true,
                          activeSection: "Investment Criteria",
                        },
                      })
                    }
                  >
                    {investorStats(investor)
                      ?.investmentCriteria.investorProfile.slice(0, 1)
                      .map((stat, index) => (
                        <StatCard
                          key={index}
                          icon={stat.icon}
                          label={stat.label}
                          value={stat.value}
                          industryVerticals={returnParsedArray(
                            investor.industryVerticalOptions
                          )}
                        />
                      ))}
                    <div className="grid w-full min-[1300px]:grid-cols-4 grid-cols-3 gap-6">
                      {investorStats(
                        investor
                      )?.investmentCriteria.publicProfile.map((stat, index) => (
                        <StatCard
                          key={index}
                          icon={stat.icon}
                          label={stat.label}
                          value={stat.value}
                        />
                      ))}
                    </div>
                  </SectionContainer>
                </>
              ) : (
                <>
                  <SectionContainer
                    title={"Investment Highlights"}
                    action={!isCompany && !publicProfile && "edit"}
                    actionFunction={() =>
                      navigate("/investor/profile", {
                        state: {
                          editMode: true,
                          activeSection: "Investment Highlights",
                        },
                      })
                    }
                  >
                    <div className="gap-150 flex flex-col">
                      <SectionTitle>Key Investments</SectionTitle>
                      {/* Added select none to avoid text getting selected on sliding */}
                      <div className="relative flex items-start gap-3 select-none">
                        <KeyInvestments
                          keyInvestmentsData={keyInvestmentsData}
                        />
                      </div>
                    </div>
                    <div className="gap-150 flex flex-col">
                      <SectionTitle>Investment History</SectionTitle>
                      <CustomTable
                        columns={columns}
                        data={investmentData}
                        equalWidth
                      />
                    </div>
                  </SectionContainer>
                  <SectionContainer
                    title={"Investment Focus"}
                    action={!isCompany && !publicProfile && "edit"}
                    actionFunction={() =>
                      navigate("/investor/profile", {
                        state: {
                          editMode: true,
                          activeSection: "Investment Focus",
                        },
                      })
                    }
                  >
                    <div className="gap-150 flex flex-col">
                      <SectionTitle>Investment Focus</SectionTitle>
                      <SectionDescription>
                        {investor?.investmentThesis}
                      </SectionDescription>
                    </div>
                    <div className="gap-150 flex flex-col">
                      <SectionTitle>Co-Investments</SectionTitle>
                      <SectionDescription>
                        {investor?.coInvestment ? "Yes" : "No"}
                      </SectionDescription>
                    </div>
                    <div className="grid w-full min-[1300px]:grid-cols-4 grid-cols-3 gap-6">
                      {investorStats(investor)?.investmentFocus.map(
                        (stat, index) => (
                          <StatCard
                            key={index}
                            icon={stat.icon}
                            label={stat.label}
                            value={stat.value}
                          />
                        )
                      )}
                    </div>
                  </SectionContainer>
                  <SectionContainer
                    title={"Investment Criteria"}
                    action={!isCompany && !publicProfile && "edit"}
                    actionFunction={() =>
                      navigate("/investor/profile", {
                        state: {
                          editMode: true,
                          activeSection: "Investment Criteria",
                        },
                      })
                    }
                  >
                    <div className="grid w-full min-[1300px]:grid-cols-4 grid-cols-3 gap-6">
                      {investorStats(
                        investor
                      )?.investmentCriteria.investorProfile.map(
                        (stat, index) => (
                          <StatCard
                            key={index}
                            icon={stat.icon}
                            label={stat.label}
                            value={stat.value}
                          />
                        )
                      )}
                    </div>
                    <div className="gap-150 flex flex-col">
                      <SectionTitle>Other Criteria</SectionTitle>
                      <SectionDescription>
                        {investor?.otherCriteria || "-"}
                      </SectionDescription>
                    </div>
                  </SectionContainer>
                </>
              )}
            </div>
          ) : (
            <div className="relative">
              <div className="gap-x-300 gap-y-300 grid w-full grid-cols-3">
                {filteredKeyInvestments.map((startup) => (
                  <InvestmentCard key={startup.id} {...startup} />
                ))}
                <FilterBox
                  filters={filters}
                  filterBoxOpen={filterBoxOpen}
                  setFilterBoxOpen={setFilterBoxOpen}
                  clearAllFilters={() => {}}
                  clearAll={false}
                  className={"absolute right-0 top-[-20px] !w-[190px]"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function InvestmentCard({ logo, company, industry, round, year }) {
  return (
    <div
      className={`flex z-0 flex-col gap-200 p-3 bg-neutral-1 rounded-md border border-solid border-neutral-5 min-w-[216px]`}
    >
      <div className="whitespace-nowrap text-neutral-900 flex items-center self-start gap-3">
        <img
          loading="lazy"
          src={logo || DefaultCompany}
          alt={`${company} company logo`}
          className="object-cover shrink-0 self-stretch my-auto h-[30px] w-[30px] rounded"
          onError={(e) => {
            e.target.src = DefaultCompany; // Fallback to uploadImg if investorLogoPreview fails
          }}
        />
        <div className="text-neutral-12 flex flex-col self-stretch justify-center my-auto">
          <div className=" text-desktop-p-m font-medium">{company}</div>
          <div className="text-desktop-p-s">{industry}</div>
        </div>
      </div>
      <div className="flex items-center justify-between w-full gap-10">
        <div className="flex flex-col self-stretch my-auto w-[77px]">
          <div className="text-neutral-400 text-desktop-p-xs uppercase">
            Stage
          </div>
          <div className="text-neutral-900 text-desktop-p-m font-medium">
            {round}
          </div>
        </div>
        {year && (
          <div className="flex flex-col self-stretch my-auto whitespace-nowrap w-[52px]">
            <div className="text-neutral-400 text-desktop-p-xs uppercase">
              Year
            </div>
            <div className="text-neutral-900 text-desktop-p-m font-medium">
              {year}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default InvestorProfile;
