import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Topbar from "../Company/Deal/topbar";
import SecondarySidebar from "./secondarySidebar";
import DataroomUpload from "../Company/Deal/DataroomUpload";

const SecondaryDataroomPrep = () => {
  const location = useLocation();
  const token = localStorage.getItem(`token`);
  const { dealId } = location.state || {};
  return (
    <>
      <Helmet>
        <title>Admin Panel - Secondary Deal</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        containerId={`secondaryDataroomPrep`}
      />
      <Topbar />
      <SecondarySidebar dealId={dealId} active={`dealDataroom`} />
      <div className="mt-20 h-[90vh] ml-[260px] overflow-scroll bg-slate-200">
        <DataroomUpload />
      </div>
    </>
  );
};

export default SecondaryDataroomPrep;
