import React from "react";
import { MuiTelInput } from "mui-tel-input";

// Custom Phone Input Component
const CustomPhoneInput = ({ value, onChange, error, helperText, ...rest }) => {
  return (
    <MuiTelInput
      defaultCountry="IN"
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      {...rest}
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Karla, sans-serif",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "4px",
          "& fieldset": {
            borderColor: "#BFBFBF", // Custom border color
          },
          "&:hover fieldset": {
            borderColor: "#3C82F6", // Color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "#3C82F6", // Color when focused
          },
          "& input": {
            fontFamily: "'Karla', sans-serif",
            padding: "11px 0px", // Change padding here
            color: "#141414",
          },
        },
      }}
    />
  );
};

export default CustomPhoneInput;
