import React, { useEffect } from "react";
import CustomStepper from "../../../../Common/components/custom/customStepper";
import { isStageCompleted } from "../stepCompletion";
import { useFormikContext } from "formik";
import { useRecoilState } from "recoil";
import { investorState } from "../../investorState";
import { calculateProfileCompletion } from "../stepCompletion";
import Navigation from "../../../../Common/components/navigation";

const ProgressStepper = ({
  activeStep,
  setActiveStep,
  activeSection,
  validationSchema,
  setValidationSchema,
}) => {
  const { values, submitForm } = useFormikContext();
  const [investor] = useRecoilState(investorState);
  const stages = [
    {
      name: "Basic Details",
      completed: isStageCompleted("Basic Details", values),
    },
    {
      name: "Investment Details",
      completed: isStageCompleted("Investment Details"),
    },
    {
      name: "Investment Focus",
      completed: isStageCompleted("Investment Focus", values),
    },
    { name: "Criteria", completed: isStageCompleted("Criteria", values) },
    {
      name: "Profile Complete",
      completed: isStageCompleted("Profile Complete", values),
    },
  ];
  const stageNames = [
    "Basic Details",
    "Investment Details",
    "Investment Focus",
    "Criteria",
  ];
  useEffect(() => {
    const mapping = {
      Overview: "Basic Details",
      "Investment Highlights": "Investment Details",
      "Investment Focus": "Investment Focus",
      "Investment Criteria": "Criteria",
    };
    if (activeSection) {
      setActiveStep(
        stages.indexOf(
          stages.filter((stage) => stage.name === mapping[activeSection])[0]
        ) + 1
      );
    }
  }, []);
  useEffect(() => {
    const element1Ref = document.getElementsByClassName("investor-section")[0];
    const element2Ref = document.getElementsByClassName("progress-stepper")[0];
    if (element1Ref && element2Ref) {
      const element1Height = element1Ref.offsetHeight;
      element2Ref.style.height = `${element1Height}px`;
    }
  }, []);
  const handleNavigationClick = async (item, index) => {
    await setValidationSchema(null);
    await submitForm().then(() => {
      setValidationSchema(validationSchema);
    });
    setActiveStep(index + 1);
  };
  return (
    <div className="progress-stepper flex flex-col p-6 bg-white w-[314px] gap-300 rounded-100 border border-neutral-5">
      {investor?.firm?.profileCompleted ? (
        <div className="gap-200 flex flex-col">
          <div className="text-neutral-12 text-desktop-p-xl w-full font-bold">
            Edit Profile
          </div>

          <Navigation
            stages={stageNames} // Pass all but last stage
            handleClick={handleNavigationClick} // Pass the click handler
            activeIndex={activeStep - 1} // Set the active step
          />
        </div>
      ) : (
        <>
          <p
            className="text-neutral-12 text-desktop-p-xl"
            id="complete-profile"
          >
            Complete Your Profile
          </p>
          <div className="bg-blue-50 text-zinc-900 flex items-center gap-3 p-2 text-xs leading-5 rounded">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/73d3a062790540ae90ec23e7560be34f/53e24eb56a0651966baa833fdf884bc95f340e71553170e6d06e778d3e3cd4e2?apiKey=73d3a062790540ae90ec23e7560be34f&"
              className="object-contain shrink-0 self-stretch my-auto aspect-square w-[18px]"
              alt=""
            />
            <div className="shrink basis-0 flex-1 my-auto">
              Get personalized recommended deals upon completing your profile
            </div>
          </div>
          <div>
            <p className="text-neutral-8 text-desktop-p-mb">Your Progress</p>
            <p className="text-[#00A6FB] text-desktop-h3">
              {calculateProfileCompletion(values)} %
            </p>
          </div>
          <CustomStepper
            stages={stages}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            submitForm={submitForm}
            validationSchema={validationSchema}
            setValidationSchema={setValidationSchema}
          />
        </>
      )}
    </div>
  );
};

export default ProgressStepper;
