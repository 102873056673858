import React from "react";
// import NoDealsImg from "../../../../Assets/Images/InvestorPage/Dashboard/noDeals.svg";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { useNavigate } from "react-router-dom";
import emptyStateFolder from "../../../../Assets/Images/icons/emptyStateFolder.svg";

const NoDeals = ({ text, isEmpty, SetRecommendedOnly }) => {
  const navigate = useNavigate();
  const dealText = text || "deals";
  return (
    <div className="max-w-[350px] flex columns flex-col justify-center gap-200 items-center m-auto pt-[200px] rounded-[24px]">
      <img
        src={emptyStateFolder}
        alt="No Deals available"
        className="w-[40px]"
      />
      <p className="text-neutral-12 text-desktop-p-l font-semibold">
        {isEmpty
          ? `No Recommended deals Live at the Moment`
          : `No ${dealText} deals Live at the Moment`}
      </p>
      <p className="w-[70%] text-center text-desktop-p-m">
        Please check back later or explore other categories to find exciting
        opportunities.
      </p>
    </div>
  );
};

export default NoDeals;
