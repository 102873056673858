import React from "react";
import { useFormikContext, Form } from "formik";
import {
  StyledFormControl,
  StyledFormLabel,
} from "../../../../Common/functions";
import CustomInput from "../../../../Common/components/custom/customInput";
import TextEditor from "../../../../Common/components/custom/textEditor";

const DealPurpose = () => {
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } =
    useFormikContext();
  return (
    <div className="DealPurpose fundrev-card onboardingForm-card">
      <p className="heading">How will you utilize the funds?</p>
      <p className="subheading">Tell us about your purpose of fundraising</p>
      <Form>
        <StyledFormControl fullWidth>
          <StyledFormLabel>
            Write down your purpose of fundraising
          </StyledFormLabel>
          {/* <CustomInput
            placeholder="Enter Purpose of Fundraising"
            name="purposeOfFundraising"
            type="text"
            value={values.purposeOfFundraising}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(
              touched.purposeOfFundraising && errors.purposeOfFundraising
            )}
            helperText={
              touched.purposeOfFundraising && errors.purposeOfFundraising
            }
            multiline
            minRows={10}
          /> */}
          <TextEditor
            placeholder="Enter Purpose of Fundraising"
            value={values.purposeOfFundraising}
            setValue={(content) =>
              setFieldValue("purposeOfFundraising", content)
            }
            style={{
              marginBottom: "40px",
            }}
            rows={15}
          />
        </StyledFormControl>
      </Form>
    </div>
  );
};

export default DealPurpose;
