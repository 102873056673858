import { LinearProgress, linearProgressClasses } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { RxTriangleDown } from "react-icons/rx";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#f0f0f0", // Update this to your desired background color
    ...theme.applyStyles("dark", {
      backgroundColor: "#f0f0f0", // Background color for dark mode
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#05A6FB", // Progress bar color
    ...theme.applyStyles("dark", {
      backgroundColor: "#05A6FB", // Progress bar color for dark mode
    }),
  },
}));

const LinearProgressBar = ({ value }) => {
  // Calculate position for the icon (based on progress value)
  const iconPosition = `calc(${value}% - 2px)`; // Adjust for padding/spacing

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <BorderLinearProgress variant="determinate" value={value} />
      <RxTriangleDown
        style={{
          color: "#595959",
          position: "absolute",
          top: "-15px", // Adjust vertical position
          left: iconPosition,
          transform: "translateX(-50%)",
        }}
      />
    </div>
  );
};
export default LinearProgressBar;
