import React from "react";
import InvestorProfile from "../../../Common/components/investor/profile";
const Overview = ({ investor }) => {
  return (
    <>
      <InvestorProfile investor={investor} />
    </>
  );
};

export default Overview;
