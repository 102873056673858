import axios from "axios";
const token = localStorage.getItem("token");

const backend_url = process.env.REACT_APP_BACKEND_URL;
export const investorSignup = async (finalFormData) => {
  try {
    const response = await axios.post(
      `${backend_url}/investor/signup`,
      finalFormData
    );

    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getValues = async () => {
  try {
    const response = await fetch(`${backend_url}/investor/getValues`, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }
    const json = await response.json();
    return { data: json, error: null };
  } catch (error) {
    console.error("There was a problem with fetching data:", error);
    return { data: null, error: error.message };
  }
};

export const fetchInvestorDetails = async (token, investorID) => {
  try {
    const response = await fetch(
      `${backend_url}/investor/details/${investorID}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    return { data: responseData, error: null };
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    return { data: null, error: error.message };
  }
};

export const PostDetails = async (formDataInput, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/investor/onboarding`,
      // "http://localhost:3000/investor/onboarding",
      formDataInput,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
    }
    return response;
  } catch (error) {
    console.error("Failed to save data:", error);
    return error;
  }
};
export const investorAddMember = async (emails, token) => {
  const formData = {
    members: emails.map((email) => ({ email })),
  };
  try {
    const response = await axios.post(
      `${backend_url}/investor/addmember`,
      // "http://localhost:3000/investor/addMember",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (response.status === 200) {
    }
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Failed to send request", error);
    return { data: null, error: error.message };
  }
};
export const fetchMembers = async (investorID, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/investor/getMembers`,
      // "http://localhost:3000/startup/getMembers",
      { investorID },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    return { data: null, error: error.message };
  }
};
export const memberSignUp = async (memberData) => {
  try {
    const response = await axios.post(
      `${backend_url}/investor/memberSignup`,
      memberData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const memberUpdate = async (memberData, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/investor/memberUpdate`,
      memberData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const deleteMember = async (id, investorID, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/investor/memberDelete/${id}`,
      // `http://localhost:3000/startup/memberDelete/${id}`,
      { investorID },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const investorMemberLogin = async (signInData) => {
  try {
    const response = await axios.post(
      `${backend_url}/investor/memberLogin`,
      signInData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const fetchInvestorMemberDetails = async (token) => {
  try {
    const response = await fetch(`${backend_url}/investor/memberDetails`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      return { data: null };
    }

    const responseData = await response.json();
    return { data: responseData, error: null };
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    return { data: null, error: error.message };
  }
};

export const fetchInvestorMemberDetailsbyId = async (data) => {
  try {
    const response = await axios.post(
      `${backend_url}/investor/memberDetailsId`,
      data,
      {
        headers: {
          Authorization: data.token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const addStartuptoWishlist = async (dealId, investorId, token) => {
  const formData = {
    dealId: dealId,
    investorId: investorId,
  };
  try {
    const response = await axios.post(
      `${backend_url}/investor/addWishlist`,
      // "http://localhost:3000/investor/addWishlist",
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
    }

    return { data: response, error: null };
  } catch (error) {
    console.error("Failed to save data:", error);
    return { data: null, error: error };
  }
};

export const removeStartupFromWishlist = async (dealId, investorId, token) => {
  const formData = {
    dealId: dealId,
    investorId: investorId,
  };
  try {
    const response = await axios.post(
      `${backend_url}/investor/removeWishlist`,
      // "http://localhost:3000/investor/removeWishlist",
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
    }

    return { data: response, error: null };
  } catch (error) {
    console.error("Failed to save data:", error);
    return { data: null, error: error };
  }
};

export const getWishlist = async (investorId, token) => {
  const formData = {
    investorId: investorId,
  };
  try {
    const response = await axios.post(
      `${backend_url}/investor/getWishlist`,
      // "http://localhost:3000/investor/getWishlist",
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
    }
    const responseData = response.data.data;

    return { data: responseData, error: null };
  } catch (error) {
    console.error("Failed to save data:", error);
    return { data: null, error: error };
  }
};
export const memberResendInvite = async (data, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/investor/memberResendInvite`,
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const sendRequestModuleEmail = async (investorId, module, token) => {
  try {
    const response = await axios.get(
      `${backend_url}/investor/requestModule/${investorId}/${module}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    console.log(error);
    return { data: null, error: error };
  }
};

export const toggleInvestorModules = async (investorId, module, token) => {
  try {
    const response = await axios.get(
      `${backend_url}/investor/toggleModule/${investorId}/${module}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    console.log(error);
    return { data: null, error: error };
  }
};

export const getAllInvestors = async (token) => {
  try {
    const response = await fetch(
      `${backend_url}/investor/getAllInvestors`,
      // "http://localhost:3000/investor/getAllInvestors/",
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }
    const json = await response.json();
    return { data: json, error: null }; // Returning data and null for error
  } catch (error) {
    console.error("There was a problem with fetching data:", error);
    return { data: null, error: error.message }; // Returning null for data and the error message
  }
};

export const getDetailsById = async (investorId, token) => {
  try {
    const response = await fetch(
      `${backend_url}/investor/detailsById/${investorId}`,
      // `http://localhost:3000/investor/detailsById/${investorId}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }
    const json = await response.json();
    return { data: json, error: null }; // Returning data and null for error
  } catch (error) {
    console.error("There was a problem with fetching data:", error);
    return { data: null, error: error.message }; // Returning null for data and the error message
  }
};
export const getInvestmentTeamDetails = async (investorID, token) => {
  try {
    const formData = {
      investorID: investorID,
    };
    const response = await axios.post(
      `${backend_url}/investor/getInvestmentTeamDetails`,
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const getProfileCompletionPercentage = async (token) => {
  try {
    const response = await axios.get(
      `${backend_url}/investor/getProfileCompletionPercentage`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const sendJoiningRequestInvestor = async (formData, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/investor/joinRequest`,
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const approveJoiningRequest = async (formData, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/investor/approveJoinRequest`,
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const getCollectiveCalendarFreeSlots = async (startupId, token) => {
  try {
    const response = await axios.get(
      `${backend_url}/calendar/getCollectiveCalendarFreeSlots/${startupId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return { data: null, error: error };
  }
};

export const scheduleMicrosoftEvent = async (
  dealId,
  agenda,
  start,
  token,
  isSecondary
) => {
  try {
    const response = await axios.post(
      `${backend_url}/calendar/microsoft/schedule-meeting`,
      {
        dealId: dealId,
        agenda: agenda,
        start: start,
        isSecondary,
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    return { data: null, error: error };
  }
};
export const getScheduleMeetingByDealId = async (investorId, dealId) => {
  try {
    const response = await axios.get(
      `${backend_url}/investor/getMeetingTimings/${investorId}/${dealId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return { data: null, error: error };
  }
};
