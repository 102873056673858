import { useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import { addHTTPS } from "../../functions";
import { Bolt } from "@mui/icons-material";
import defaultCompanyViolet from "../../../../Assets/Images/icons/defaultCompanyViolet.svg";

export function SectionTitle({ children }) {
  return <div className="text-desktop-p-s text-neutral-8">{children}</div>;
}
export function SectionDescription({ children }) {
  return (
    <div className="text-neutral-12 text-desktop-p-mb font-normal">
      {children}
    </div>
  );
}
export function ProfileHeader({ data, role }) {
  return (
    <div className="flex items-start justify-between">
      <div className="flex items-center gap-4">
        <img
          src={
            role === "investor"
              ? data?.investorLogo || defaultCompanyViolet
              : data?.companyLogo || defaultCompanyViolet
          }
          className="w-16 rounded"
          alt="Company-Logo"
        />
        <div>
          <div className="text-desktop-p-xl text-neutral-12 flex items-center font-bold">
            {data?.companyName}
            <a
              href={addHTTPS(
                role === "investor" ? data?.investorWebsite : data?.website
              )}
              target="_blank"
              referrerPolicy="no-referrer"
            >
              <LinkIcon className="ml-2 rotate-[135deg]" fontSize="16" />
            </a>
          </div>
          <div className="mt-1.5 text-desktop-p-mb text-neutral-8 font-normal">
            {role === "investor" ? data?.investorType : data?.legalEntityName}
          </div>
        </div>
      </div>
      {role === "investor" && (
        <div className="bg-neutral-3 border-neutral-5 text-desktop-p-s text-neutral-8 px-4 py-2 border rounded">
          Investor ID: <span className="font-bold">{data?.investorID}</span>
        </div>
      )}
    </div>
  );
}
export function StatCard({ icon, label, value, industryVerticals }) {
  return (
    <div className="flex flex-col items-start p-3 rounded-md border border-neutral-5 min-w-[149px] gap-150">
      <div className="text-desktop-p-s text-neutral-7 flex flex-col items-start gap-1">
        {icon && <img src={icon} className="w-4" alt="Icon" />}
        <span>{label}</span>
      </div>
      {industryVerticals && industryVerticals.length > 0 ? (
        <div className="gap-150 flex flex-wrap">
          {industryVerticals.map((industry) => {
            return (
              <div className="text-neutral-12 bg-neutral-3 border border-neutral-5 py-[6px] px-[16px] rounded text-desktop-p-s font-normal">
                {industry}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-desktop-p-m text-neutral-12 w-full font-medium">
          {value}
        </div>
      )}
    </div>
  );
}
export const TagGroup = ({ tags, isRecommended }) => {
  return (
    <div className="text-neutral-12 gap-100 flex flex-wrap items-center w-full font-medium">
      {isRecommended && (
        <div
          style={{
            position: "relative",
            borderRadius: "4px",
            background: "linear-gradient(-90deg, #00A6FB 0%, #114FEE 100%)",
            padding: "2px",
          }}
        >
          <div
            className="Card-div-6 text-neutral-12 flex items-center font-semibold"
            style={{
              borderRadius: "3px", // Inner border radius (slightly less than outer)
              background: "white", // Inner background color
              padding: "6.5px 8px",
              fontSize: "12px",
            }}
          >
            <Bolt fontSize="10px" />
            Recommended
          </div>
        </div>
      )}
      {tags &&
        tags.length > 0 &&
        tags.map((tag, index) => (
          <div
            key={index}
            className={`flex gap-1.5 items-center self-stretch px-150 py-100 my-auto text-desktop-p-s ${
              tag.variant === "primary"
                ? "font-bold bg-white rounded border-blue-700 border-solid border-[1.5px] text-neutral-12"
                : "bg-white rounded border-solid border-[0.5px] border-neutral-5"
            }`}
          >
            {tag}
          </div>
        ))}
    </div>
  );
};
export function TeamMemberCard({ member }) {
  const [showFullBio, setShowFullBio] = useState(false);
  const limit = 30;

  const truncateText = (text, wordLimit) => {
    const words = text?.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const handleToggleBio = () => {
    setShowFullBio(!showFullBio);
  };

  const displayedBio = showFullBio
    ? member?.biography
    : truncateText(member.biography, limit);
  return (
    <div className="flex flex-col gap-150 p-3 rounded border border-solid border-zinc-300 max-w-[347px]">
      <div className="flex items-center self-start gap-4">
        <img
          loading="lazy"
          src={member.foundingTeamProfile}
          alt={`${member.name}'s profile`}
          className="shrink-0 aspect-square self-stretch object-contain w-12 my-auto rounded-lg"
        />
        <div className="flex flex-col self-stretch my-auto">
          <div className="text-neutral-12 gap-150 text-desktop-p-mb self-start font-medium">
            {member.name}
          </div>
          <div className="mt-1.5 text-desktop-p-s leading-none text-zinc-600">
            {member.title}
          </div>
        </div>
      </div>

      <div className="text-neutral-12 text-desktop-p-s leading-5">
        {displayedBio}
        {!showFullBio &&
          member.biography &&
          member.biography.split(" ").length > limit && (
            <span
              onClick={handleToggleBio}
              style={{ cursor: "pointer", color: "#00A6FB" }}
            >
              ... more
            </span>
          )}
        {showFullBio && (
          <span
            onClick={handleToggleBio}
            style={{ cursor: "pointer", color: "blue" }}
          >
            &nbsp;show less
          </span>
        )}
      </div>
    </div>
  );
}
