import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";

import {
  getDealIdbyStartupId,
  publishStartup,
} from "../../../../../endpoints/deal";
import { checkDealExist } from "../../../../../endpoints/admin";
import companyOnboardingProcess from "../../../../../Assets/Images/signup/companyOnboardingProcess.svg";
import CustomBlackBtn from "../../../../Common/components/custom/customBlackBtn";
import { useNavigate } from "react-router-dom";
import Toast from "../../../../Common/Toast";

import Illustration from "../../../../../Assets/Images/signup/illustration.svg";
import { useLoader } from "../../../../Common/LoaderProvider";
import confetti from "canvas-confetti";
import TermsAndConditions from "./TnC";

const ApplicationUnderReview = ({
  startupId,
  isReviewedByCompany,
  tncChecked,
  setTncChecked,
}) => {
  const loader = useLoader();
  const navigate = useNavigate();
  const [dealId, setDealId] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const token = localStorage.getItem("token");
  const { values } = useFormikContext();

  const navigateToProfilePreview = (isOpen) => {
    if (isOpen) {
      return;
    } else if (!isReviewedByCompany && values?.isApplicationReviewed) {
      navigate("/company/profilePreview", {
        state: {
          startupId: startupId,
          token: token,
          hideBtn: true,
        },
      });
    }
  };
  const PublishTheStartup = async () => {
    const data = {
      startupId: startupId,
      dealId: dealId,
    };
    loader.start("Publishing deal");
    const response = await publishStartup(data, token);
    loader.stop();
    if (response.data.message === "Startup published successfully") {
      const handleFireCanons = () => {
        const end = Date.now() + 3 * 1000; // 3 seconds
        const colors = ["#00A6FB", "#C2F970", "#114FEE", "#FF9933"];

        const frame = () => {
          if (Date.now() > end) return;

          confetti({
            particleCount: 2,
            angle: 60,
            spread: 55,
            startVelocity: 60,
            origin: { x: 0, y: 0.5 },
            colors: colors.slice(0, 2),
          });
          confetti({
            particleCount: 2,
            angle: 120,
            spread: 55,
            startVelocity: 60,
            origin: { x: 1, y: 0.5 },
            colors: colors.slice(-2),
          });

          requestAnimationFrame(frame);
        };

        frame();
      };
      handleFireCanons();
      isDealOpen();
    } else {
      Toast("Failed to publish the startup", "error", "companyOnboarding");
    }
  };
  const getDealId = async () => {
    try {
      if (values.isApplicationReviewed) {
        const response = await getDealIdbyStartupId(startupId, token);
        if (response) setDealId(response.data.dealId);
      }
    } catch (error) {}
  };
  const isDealOpen = async () => {
    try {
      const response = await checkDealExist(startupId, token);
      setIsOpen(response.data.dealsExist);
      navigateToProfilePreview(response.data.dealsExist);
    } catch (error) {
      setIsOpen(false);
      navigateToProfilePreview(false);
    }
  };
  useEffect(() => {
    getDealId();
    isDealOpen();
  }, [startupId]);
  return (
    <div className="ApplicationUnderReview fundrev-card onboardingForm-card">
      {isOpen ? (
        <>
          <img
            loading="lazy"
            src={Illustration}
            className="w-[200px]"
            alt="all steps completed"
          />
          <div className="text-3 font-semibold">Completed successfully</div>
          <div className="text-4">
            Profile completed! Ready to shine and make meaningful investments.
            Bring on the investment!
          </div>
          <CustomBlackBtn
            text="Go to dashboard"
            onClick={() => navigate("/company/dashboard")}
          />
        </>
      ) : !values.isApplicationReviewed ? (
        <>
          <p className="underReview-heading">
            Your application is under Fundrev review
          </p>
          <p className="underReview-subheading max-w-[800px]">
            Buckle up your excitement seat-belt! Your fundraising application
            has taken center stage under the scrutinizing gaze of our review
            wizards. Now, all that's left is to sit back, relax & twiddle your
            thumbs for
            <span className="blue-text">&nbsp;48-72 hours!</span>{" "}
          </p>
          <img
            src={companyOnboardingProcess}
            alt="Application under fundrev review"
          />
        </>
      ) : !values.acceptTC ? (
        <TermsAndConditions
          tncChecked={tncChecked}
          setTncChecked={setTncChecked}
        />
      ) : (
        <>
          <p className="underReview-heading">
            Are you sure you want to publish your profile?
          </p>
          <p className="underReview-subheading max-w-[800px]">
            Once confirmed, this profile will be shared with the administrator
            before going live for investors to review. Please carefully review
            your profile as this step is crucial and irreversible!
          </p>
          <div className="flex gap-[16px]">
            <CustomBlackBtn
              text="Preview"
              filled={false}
              onClick={() => {
                navigate("/company/profilePreview", {
                  state: {
                    startupId: startupId,
                    token: token,
                    hideBtn: true,
                  },
                });
              }}
            />
            <CustomBlackBtn
              text="Confirm & Publish"
              onClick={PublishTheStartup}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ApplicationUnderReview;
