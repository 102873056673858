import { Box } from "@mui/material";
import React from "react";
import loader from "../../Assets/Images/GIFs/loader.gif";

const Loader = ({ fullScreen = true, message = "Loading..." }) => {
  const override = {
    display: "block",
    margin: "0",
  };

  const loaderStyles = fullScreen
    ? {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999, // Increase zIndex to ensure it's above other elements
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      }
    : {
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: 9999,
      };

  return (
    <div style={loaderStyles}>
      <Box
        sx={{
          backgroundColor: "white",
          flexDirection: "column",
          height: fullScreen ? "320px" : "120px",
          width: fullScreen ? "452px" : "200px",
          borderRadius: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "30px",
          boxShadow: 1,
          gap: 3,
        }}
      >
        <img src={loader} alt="loader" className="w-[120px]" />
        <p
          style={{
            fontSize: fullScreen ? 20 : 16,
            fontWeight: 700,
            textAlign: "center",
            color: "#141414",
          }}
        >
          {message}
        </p>
      </Box>
    </div>
  );
};

export default Loader;
